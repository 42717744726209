import React, { useReducer, useState, useEffect, useRef} from "react";
import SuperAdminCss from "./SuperAdmin.module.css";
import Header from "./Header";
import { Container, Row, Col} from "react-bootstrap";
import { apiBaseURL, apiURL, apiKey } from "../../constant";
import Table from "react-bootstrap/Table";
import Loader from "../../Loader";
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ReceiptIcon from '@mui/icons-material/Receipt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import InvoiceCss from './Invoice.module.css'
import CloseIcon from '@mui/icons-material/Close';
import fbLogo from '../../assets/logo.png';
import PrintIcon from '@mui/icons-material/Print';

function Manage_orders() {
	const handlePrint = ()=>{
        window.print()
    }
  useEffect(()=>{
    getSchool();
	getSchoolTypeLists();
   },[])

  const table = useRef(null);
  const inputBox = useRef(null);
  const inputBoxX = useRef(null);
  const tableX = useRef(null);
  
  const[viewInvoicePopup, setviewInvoicePopup] = useState(false);
  const [showStudentDetailes, setStudentDetailes] = useState ([]);
  const [showLoader, setLoader]= useState(false);
  const [schoolType, setSchoolType] = useState([])
  const [showErr, setErr] = useState({status:false, errMsg:""})
  const [showSchoolDetailes, setSchoolDetailes] = useState([]);
  const [studentUser, SetstudentUser] = useState([]);
  const [showListSch_Instutute, ssetListSch_Instutute] = useState([])
  const [showInvoiceDetailes, setInvoiceDetailes] = useState([])
  const [subjectData, setSubjectData] = useState([])

  // search data functions start
  //function searchData(inputVal) {
		
	// let filter = inputVal.toUpperCase();
	// let tr = document.getElementsByTagName("tr");
	// console.log(tr);
	// for (i = 1; i < tr.length; i++) {
	//   tds = tr[i].getElementsByTagName("td");
	//   let isExist = false;
	//   for (let td of tds) {
	// 	txtValue = td.textContent || td.innerText;
	// 	if (txtValue.toUpperCase().indexOf(filter) > -1) {
	// 	  isExist = true;
	// 	}
	//   }
	//   if (isExist) {
	// 	tr[i].style.display = "";
	//   } else {
	// 	tr[i].style.display = "none";
	//   }
	// }
  //}
  function searchData() {
	
	let filter, tr, tds, i, txtValue;
	console.log(inputBoxX);
	filter = inputBox.current==null ? inputBoxX.current.value.toUpperCase() : inputBox.current.value.toUpperCase();
	tr = inputBox.current==null ? tableX.current.getElementsByTagName("tr") : table.current.getElementsByTagName("tr");
	console.log(tr);
	for (i = 1; i < tr.length; i++) {
	  tds = tr[i].getElementsByTagName("td");
	  let isExist = false;
	  for (let td of tds) {
		txtValue = td.textContent || td.innerText;
		if (txtValue.toUpperCase().indexOf(filter) > -1) {
		  isExist = true;
		}
	  }
	  if (isExist) {
		tr[i].style.display = "";
	  } else {
		tr[i].style.display = "none";
	  }
	}
  }
  // search data functions end

    //  get School fuction start
function getSchool(){
	setLoader(true)
	const xPostData ={}
	  fetch(apiBaseURL+apiURL.getSchoolList,{
		  method: "POST",
		  headers: {'Key' : apiKey},
		  body:JSON.stringify(xPostData)
	  })
	  .then(responce => responce.json())
	  .then((schoolData)=>{
		  if(schoolData.status=="success"){
			setSchoolDetailes(schoolData.data.schools)
		  }else{
			setErr((prev)=>{
				return{
					...prev,
					status:true,
					errMsg:schoolData.message
				}
			})
		  }
	  })
	  .catch((err)=>{
        alert(err);
      })
	  .finally(()=>{
		setLoader(false)
	  })

}


    //  get School fuction end

    // get Student detailes function start
    function getStudent(schoolCode){
      setLoader(true)
      const xPostData = {
        "activeOnly":true,
        "orderBy":"loginID",
        "schoolCode":schoolCode
      };
      fetch(apiBaseURL+apiURL.getUsersList,{
        method: "POST",
        headers : {Key : apiKey},
        body:JSON.stringify(xPostData)
      })
      .then((res)=>{
        res.json()
        .then((studentData)=>{
          // console.log(studentData)
          if(studentData.status == "success"){
            setStudentDetailes(studentData.data.users)
            setLoader(false)
          }else{alert(studentData.message);}
        })
      })
      .catch((err)=>{
        alert(err);
      })
    }
    // get Student detailes function end
// selection Hendlers functions sarat
const[hideShowSections, setList]= useState ({
	school:false,
	institute:false,
	listOfSchool:false,
	schoolList:false,
	institutesList:false,
})
function schoolTypeHendler(schoolTypeID){

	setLoader(true)
	const xPostData ={
		"schoolTypeRefID":schoolTypeID
	}
	fetch(apiBaseURL+apiURL.getSchoolsInstituteList,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((listSchAndInstitute)=>{
		if(listSchAndInstitute.status=="success"){
			ssetListSch_Instutute(listSchAndInstitute.data.schools)
			setList((prev)=>{
				return{
					...prev,
					listOfSchool:true
				}
			})
			setUsers((prev)=>{
				return{
					...prev,
					usersList:false,
				}
			})
		}else{
			ssetListSch_Instutute([])
			setErr((prev)=>{
				return{
					...prev,
					status:true,
					errMsg:listSchAndInstitute.message
				}
			})
		}
	})
	.catch((catch_err)=>{
		ssetListSch_Instutute([])
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})

	if(schoolTypeID==1){
		setList((prev)=>{
			return{
				...prev,
				school:true,
				institute:false,
				schoolList:true,
				institutesList:false
			}
		})
	}else if(schoolTypeID==2){
		setList((prev)=>{
			return{
				...prev,
				institute:true,
				school:false,
				institutesList:true,
				schoolList:false,
			}
		})
	}else{
		alert("No record found")
	}
}

// get type list function end

// get school Type List functions starat
function getSchoolTypeLists(){
	fetch(apiBaseURL+apiURL.getSchoolTypeList,{
		method: "POST",
		headers: {'Key' : apiKey}
	})
	.then(responce => responce.json())
	.then((schoolTypeData)=>{
		if(schoolTypeData.status=="success"){
			setSchoolType(schoolTypeData.data.schoolType)
		}else{
			setSchoolType([])
			setErr((prev)=>{
				return{...prev, status:true, errMsg:schoolTypeData.message}
			})
		}
	})
	.catch((catch_err)=>{
		setSchoolType([])
		alert(catch_err)
	})
}
// get school Type List functions end

// get User list functions start
const[showUsers, setUsers] = useState({
	usersList:false
})
function studentHendlers(type,schoolCode){
	setLoader(true)
	const xPostData ={
		"schoolCode":schoolCode,
		// "userType":3
	}
	fetch(apiBaseURL+apiURL.getUsersOrderList,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((uSerData)=>{
		if(uSerData.status=="success"){
			SetstudentUser(uSerData.data.userOrderDetails)
			setUsers((prev)=>{
				return{
					...prev,
					usersList:true,
				}
			})
			setList((prev)=>{
				return{
					...prev,
					listOfSchool:false
				}
			})
		}else{
			SetstudentUser([])
			setErr((prev)=>{
				return{...prev, status:true, errMsg:uSerData.message}
			})
		}
	})
	.catch((err)=>{
		SetstudentUser([])
		alert(err)
	})
	.finally(()=>{
		setLoader(false)
	})
}
// get User list functions end

// view invoice school/institute start
function viewInvoice(schoolCode){
	setviewInvoicePopup(true)
	setLoader(true)
	const xPostData ={
		"orderID":1,
		"schoolCode":schoolCode
	}
	console.log(xPostData)
	fetch(apiBaseURL+apiURL.orderInvoice,{
		method:"POST",
		headers:{'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((DataInvoice)=>{
		if(DataInvoice.status=="success"){
			setInvoiceDetailes(DataInvoice.data)
			let subData = (DataInvoice.data.orderDetail != undefined && DataInvoice.data.orderDetail[2] != undefined) ? DataInvoice.data.orderDetail[2]['subjects']: ""
			setSubjectData(subData)
		}else{
			setInvoiceDetailes([])
			setErr((prev)=>{
				return{
					...prev,
					status:true,
					errMsg:DataInvoice.message
				}
			})
		}	
	})
	.catch((catch_err)=>{
		setInvoiceDetailes([])
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})
}
function studentViewInvoice(schoolCode, orderID){
	setviewInvoicePopup(true)
	setLoader(true)
	const xPostData ={
		"orderID":orderID,
		"schoolCode":schoolCode
	}
	console.log(xPostData)
	fetch(apiBaseURL+apiURL.orderInvoice,{
		method:"POST",
		headers:{'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((DataInvoice)=>{
		if(DataInvoice.status=="success"){
			let subData = (DataInvoice.data.orderDetail != undefined && DataInvoice.data.orderDetail[2] != undefined) ? DataInvoice.data.orderDetail[2]['subjects']: ""
			setSubjectData(subData)
			setInvoiceDetailes(DataInvoice.data)
		}else{
			setInvoiceDetailes([])
			setErr((prev)=>{
				return{
					...prev,
					status:true,
					errMsg:DataInvoice.message
				}
			})
		}	
	})
	.catch((catch_err)=>{
		setInvoiceDetailes([])
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})
}

const data = {
	orderNo:123456,
	name:'Mohit Sharma',
	address:'4583/15, Daryaganj, New Delh -110002',
	contectNO:'987654321',
	mailId:'mail123@gmail.com',
}


  return (
    <div className={`${SuperAdminCss.backDiv}`}>
      	<div className={`${SuperAdminCss.rightArea}`}>
			{/* Header */}
				<Header />
			{/* Header */}
			<div className={`${SuperAdminCss.buttonBgs}`}>
				<Container fluid>
					<Row>
						<Col><div className={`${SuperAdminCss.Heading}`}>Manage Orders</div></Col>
						<Col>
							<div className={`${SuperAdminCss.backTohome}`}>
								<Link to="/superAdmin"> <button className="btn btn-secondary"><HomeIcon/> DASH BOARD </button> </Link>
							</div>
						</Col>
				  </Row>
				  <hr/>
				  <div className={`${SuperAdminCss.comboBoxBgs}`}>
					<Row>
						<Col>
							<label>School Type</label>
								<select className="form-control" onChange={(e)=>schoolTypeHendler(e.target.value)}>
								<option hidden>Select</option>
									{schoolType.map((item)=>{
										return(
											<option value={item.schoolTypeID}>{item.schoolTypeName}</option>
										)
									})}
								</select>
						</Col>

						{hideShowSections.school &&
							<Col>
								<label>Schools</label>
								<select className="form-control" onChange={(e)=>studentHendlers("School",e.target.value)}>
									<option hidden>Select School</option>
									{showListSch_Instutute.map((item, schoolListKey)=>{
										return(<option  key={schoolListKey} value={item.schoolCode}>{item.schoolName}</option>)
									})}
								</select>
							</Col>
						}

						{hideShowSections.institute &&
							<Col>
								<label>Institutes</label>
								<select className="form-control" onChange={(e)=>studentHendlers("Institutes",e.target.value)}>
									<option hidden>Select Institute</option>
									{showListSch_Instutute.map((item, schoolListKey)=>{
										return(<option  key={schoolListKey} value={item.schoolCode}>{item.schoolName}</option>)
									})}
								</select>
							</Col>
						}

					</Row>
				  </div>

				  {/* schoolList start */}
					
					{hideShowSections.listOfSchool &&
						 <div className={`${SuperAdminCss.tableListOf}`}>
						 <Row>
						   <Col>
							 <div className={`${SuperAdminCss.stulist}`}>
							   School List
							 </div>
						   </Col>
						   <Col className="text-right">
							 <div className={`${SuperAdminCss.inputFiled}`}>
							   <PersonSearchIcon className={`${SuperAdminCss.searchIcons}`}/>
							   <input  type="text" className="form-control" ref={inputBoxX} onKeyUp={searchData} placeholder="Search"/>
							 </div>
						   </Col>
						 </Row>
						 <Row>
						   <div className={`${SuperAdminCss.searchOption}`}></div>
						   <Table  className={`${SuperAdminCss.TableManageT}`} striped bordered hover responsive ref={tableX}>
							 <thead>
							   <tr>
								 <th>Sr No.</th>
								 <th>School Code</th>
								 <th>SchoolName</th>
								 <th>Address</th>
								 <th>Pin Code</th>
								 <th>Contact</th>
								 <th>E-mail ID</th>
								 <th>View Invoice</th>
							   </tr>
							 </thead>
							 <tbody>
							{showListSch_Instutute.map((item, index)=>{
								let schoolDetailes = {...item};
							  return(
								<React.Fragment key={index}>
									<tr>
										<td>{index+1}</td>
										<td>{schoolDetailes.schoolCode}</td> 
										<td>{schoolDetailes.schoolName}</td>
										<td>{schoolDetailes.schoolAddress}</td>
										<td>{schoolDetailes.zipCode}</td>
										<td>{schoolDetailes.conntactNo}</td>
										<td>{schoolDetailes.email}</td>
										<td>
											<button onClick={()=>{viewInvoice(schoolDetailes.schoolCode)}} className="btn btn-primary"><ReceiptIcon/> Invoice </button> 
										</td>
									</tr>
								</React.Fragment>
							  )
							})}
						
						  </tbody>
						   </Table>
						 </Row>
					   </div>
					}

				 
				  {/* schoolList end */}

				  {/* show Institute list */}

				  {/* UserList start */}
				  {showUsers.usersList &&
				  	 <div className={`${SuperAdminCss.tableListOf}`}>
					   <Row>
						 <Col>
						   <div className={`${SuperAdminCss.stulist}`}>
							 Students List
						   </div>
						 </Col>
						 <Col className="text-right">
						   <div className={`${SuperAdminCss.inputFiled}`}>
							 <PersonSearchIcon className={`${SuperAdminCss.searchIcons}`}/>
							 <input  type="text" className="form-control" ref={inputBox} onKeyUp={searchData} placeholder="Search"/>
							 
						   </div>
						 </Col>
					   </Row>
					   <Row>
						 <div className={`${SuperAdminCss.searchOption}`}></div>
						 <Table  className={`${SuperAdminCss.TableManageT}`} striped bordered hover responsive ref={table}>
						   <thead>
								<tr>
									<th>Sr No.</th>
									<th>Student Name</th>
									<th>Father's Name</th>
									<th>School Code</th>
									<th>Contact</th>
									<th>Email ID</th>
									<th>Order ID</th>
									<th>Amount</th>
									<th>View Invoice</th>

								</tr>
						   </thead>
						   <tbody>
                          {studentUser.map((item, index)=>{
							let itemUserDetail = {...item.userDetail};
							let orderDetail = {...item.userOrderDetails}
                            return(
								<tr key={"users"+item.userID}>
									<td>{index+1}</td>
									<td>{itemUserDetail.firstName+" "+itemUserDetail.lastName}</td>
									<td>{itemUserDetail.guardianName}</td>
									<td>{itemUserDetail.schoolCode}</td>
									<td>{itemUserDetail.userContact}</td>
									<td>{itemUserDetail.userEmail}</td>
									<td>{item.orderNumber}</td>
									<td>{item.transactionAmount} INR</td>
									{item.orderID!=null ? 
										<td>
											{/* <button onClick={()=>{studentViewInvoice(itemUserDetail.userRefID)}} className="btn btn-primary"><ReceiptIcon/> Invoice </button>  */}
											<button onClick={()=>{studentViewInvoice(itemUserDetail.schoolCode,item.orderID)}} className="btn btn-primary"><ReceiptIcon/> Invoice </button> 
										</td>
									:
										<td>
											<button disabled  className="btn btn-danger"><ReceiptIcon/> No Record </button>
										</td>
									}
									
						  		</tr>
                            )
                          })}
                      
                        </tbody>
						 </Table>
					   </Row>
					 </div>
				  }

				</Container>                       
			</div>

			{/* view Invoice Poput start */}
			{viewInvoicePopup &&
				<div className={`${SuperAdminCss.basePopDiv}`} id="printablediv">
					 <div className={`${InvoiceCss.container}`}>
						<div className={`${InvoiceCss.invoContainer}`}>
							{/* Header */}
							<div className={`${InvoiceCss.header}`}>
								<div className={`${InvoiceCss.firstHead}`}>
									<div className={`${InvoiceCss.logo}`}>
										<div className={`${InvoiceCss.fbLogo}`}>
											<img src={fbLogo} className={`${InvoiceCss.logoBg}`}/>
										</div>
									</div>

									<div className={`${InvoiceCss.btnSec}`}>
										<button onClick={handlePrint} className={`btn btn-secondary ${InvoiceCss.btnStyle}`}>Print <PrintIcon className={`${InvoiceCss.iconStyle}`}/></button>
										{/* <button className={`btn btn-success ${InvoiceCss.btnStyle}`}>Download <DownloadIcon  className={`${InvoiceCss.iconStyle}`}/></button> */}
										<button onClick={()=>{setviewInvoicePopup(false)}} className={`btn btn-danger ${InvoiceCss.btnStyle}`}> CLOSE <CloseIcon  className={`${InvoiceCss.iconStyle}`}/></button>
									</div>
								</div>

								<div className={`${InvoiceCss.secondHead}`}>
									<div className={`${InvoiceCss.addressSec}`}>
										<div className={`${InvoiceCss.adddress}`}>
										4583/15, Daryaganj,<br/>
										New Delh -110002, PO Box 7226<br/>
										Phone 0011-43585858, 23285568<br/>
										Fax 011-23243519
										</div>
									</div>
									<div className={`${InvoiceCss.invoSec}`}>
										<div className={`${InvoiceCss.invoHead}`}>
											INVOICE
										</div>
										<div className={`${InvoiceCss.invoDeatil}`}>
											<div className="">Invoice No.: {showInvoiceDetailes.orderNumber!=undefined ? showInvoiceDetailes.orderNumber : ""}</div>
											<div className="">Invoice Date: {showInvoiceDetailes.created_at !=undefined ? showInvoiceDetailes.created_at : ""}</div>
										</div>
									</div>
								</div>
							</div>
							{/* Header */}

							{/* orderDetail */}
							<div className={`${InvoiceCss.consumerSec}`}>
								<div className={`${InvoiceCss.studentSec}`}>
									<div className={`${InvoiceCss.lableText}`}>Order No:</div>
									<div className={`${InvoiceCss.inputBox}`}>{showInvoiceDetailes.orderNumber!=undefined ? showInvoiceDetailes.orderNumber: ""}</div>
									<div className={`${InvoiceCss.lableText}`}>Name:</div>
									<div className={`${InvoiceCss.inputBox}`}>{showInvoiceDetailes.userDetail!=undefined ? showInvoiceDetailes.userDetail.firstName : ""}</div>
									<div className={`${InvoiceCss.lableText}`}>Address:</div>
									<div className={`${InvoiceCss.inputBox}`}>{showInvoiceDetailes.userDetail!=undefined ? showInvoiceDetailes.userDetail.userAddress : ""}</div>
									<div className={`${InvoiceCss.lableText}`}>Contect No.:</div>
									<div className={`${InvoiceCss.inputBox}`}>{showInvoiceDetailes.userDetail!=undefined ? showInvoiceDetailes.userDetail.userContact: ""}</div>
									<div className={`${InvoiceCss.lableText}`}>E-mail id:</div>
									<div className={`${InvoiceCss.inputBox}`}>{showInvoiceDetailes.userDetail!=undefined ? showInvoiceDetailes.userDetail.userEmail: ""}</div>
								</div>

								<div className={`${InvoiceCss.paymentSec}`}>
									<div className={`${InvoiceCss.lableText}`}>Transation ID:</div>
									<div className={`${InvoiceCss.inputBox}`}>{showInvoiceDetailes.bankTransID!=undefined ? showInvoiceDetailes.bankTransID : ""}</div>
									<div className={`${InvoiceCss.lableText}`}>Payment Mode:</div>
									<div className={`${InvoiceCss.inputBox}`}>{showInvoiceDetailes.payModeText!=undefined ? showInvoiceDetailes.payModeText: ""}</div>
									<div className={`${InvoiceCss.lableText}`}>Remarks:</div>
									<div className={`${InvoiceCss.inputBox}`}>{showInvoiceDetailes.remarks1!=undefined ? showInvoiceDetailes.remarks : ""}</div>

								</div>

							</div>
							{/* orderDetail */}

							{/* selectSection */}
							<div className={`${InvoiceCss.compSec}`}>
								<div className={`${InvoiceCss.selectList}`}>
								{(showInvoiceDetailes.orderDetail!=undefined && showInvoiceDetailes.orderDetail[1]!=undefined) ? showInvoiceDetailes.orderDetail[1].sectionName : ""}
								
								</div>
								<div className=""></div>
								<div className={`${InvoiceCss.fixedPrice}`}>{(showInvoiceDetailes.orderDetail!=undefined && showInvoiceDetailes.orderDetail[1]!=undefined) ? showInvoiceDetailes.orderDetail[1].amount : ""}</div>
							</div>
							<div className={`${InvoiceCss.sectionB}`}>
								{(showInvoiceDetailes.orderDetail!=undefined && showInvoiceDetailes.orderDetail[2]!=undefined) ? showInvoiceDetailes.orderDetail[2].sectionName : ""}
							</div>
							<div className={`${InvoiceCss.subjectList}`}>
								<table className={`${InvoiceCss.tableStyle}`}>
									<tr>
										<th style={{width: "100px", textAlign:'center'}}>Sr. No.</th>
										<th>Subject</th>
										<th style={{width: "200px", textAlign:'center'}}>Price</th>
									</tr>
								{Object.keys(subjectData).map((item, index)=>{
									return(
										<>
											<tr>
												<td className={`${InvoiceCss.centerAlizn}`}>{index+1}</td>
												<td>{subjectData[item].subjectName}</td>
												<td className={`${InvoiceCss.centerAlizn}`}>{subjectData[item].amount}</td>
											</tr>
										</>
									)
								})}
									
								</table>
							</div>

							<div className={`${InvoiceCss.compSec}`}>
								<div className={`${InvoiceCss.selectList}`}>
								{(showInvoiceDetailes.orderDetail!=undefined && showInvoiceDetailes.orderDetail[3]!=undefined)  ? showInvoiceDetailes.orderDetail[3].sectionName  : ""}
								</div>
								<div className=""></div>
								<div className={`${InvoiceCss.fixedPrice}`}>{(showInvoiceDetailes.orderDetail!=undefined && showInvoiceDetailes.orderDetail[3]!=undefined) ? showInvoiceDetailes.orderDetail[3].amount : ""}</div>
							</div>

							<div>
							<table className={`${InvoiceCss.totalSec}`}>
								<tr>
										<td></td>
										<td style={{width:'200px'}}>Sub Total</td>
										<td style={{width:'230px', textAlign:'center'}}>{showInvoiceDetailes!=undefined ? showInvoiceDetailes.transactionAmount : ""}</td>
									</tr>
									<tr>
										<td></td>
										<td style={{width:'200px'}}>Discount</td>
										<td className={`${InvoiceCss.centerAlizn}`}>0.00</td>
									</tr>
									<tr className={`${InvoiceCss.totalAmt}`}>
										<td style={{border:'none'}}></td>
										<td style={{width:'200px'}}>Total</td>
										<td className={`${InvoiceCss.centerAlizn}`}>{showInvoiceDetailes!=undefined ? (showInvoiceDetailes!='' ? showInvoiceDetailes.transactionAmount : '0.00') : ""}</td>
									</tr>
								</table>
							</div>
							{/* selectSection */}

							{/* subjectList */}
							
							{/* subjectList */}
							{/* sign */}
							<div className={`${InvoiceCss.signSec}`}>
									Authorised Sign
							</div>
							{/* sign */}
							{/* ThankuMsg */}

							<div className={`${InvoiceCss.thankuMsg}`}>
								Thank You
							</div>
							{/* ThankuMsg */}
						</div>
					</div>
				</div>
			}

			{/* err popup code start */}
			{showErr.status &&
				<div className={`${SuperAdminCss.showErrbase}`}>
					<div className={`${SuperAdminCss.inerBox}`}>
						<div><ErrorOutlineIcon className={`${SuperAdminCss.errIcons}`}/></div>
							<div className={`${SuperAdminCss.errMessageDiv}`}>{showErr.errMsg}</div>
						<button onClick={(()=>{setErr(false)})} className='btn btn-danger mt-2 btn-lg'> OK </button>    
					</div>
				</div>
			}
		   {/* err popup code end */}

			{/* Loader pop */}
            {showLoader &&
              <Loader/>
            }
      	</div>
    </div>
  );
}

export default Manage_orders;

import AdminDashBoardCss from './adminDashboard.module.css';
import AdminLeftMenu from './AdminLeftMenu';
import Header from './Header';
import { apiBaseURL, apiURL, apiKey } from "./../../constant";
import React, { useEffect, useState, useRef,useReducer,useContext } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import examGnerate from '../../assets/examGnerate.png';
import GenaratePracticeIcon from '../../assets/topic_wise_practice.png';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import PracticeSetIcon from '../../assets/set_sub.png';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import Loader from '../../Loader';
import userContext from './../../Store';
import AddTaskIcon from '@mui/icons-material/AddTask';


// reducer code start
const reducer = (state, action)=>{
    switch (action.type){
        // generate Practice sets start
        case "generatePracticeSet":
            return{
                showPrac_SetQuestionMark: (state.showPrac_SetQuestionMark = true)
            };
        case "hideQuestionPopup":
            return{
                showPrac_SetQuestionMark: (state.showPrac_SetQuestionMark = false)
            }
        case "PracticeSetForeverQbank":
            return{
                showPrac_SetQuestionMark: (state.showPrac_SetQuestionMark = true),
                showPracticeSetForeverQbank: (state.showPracticeSetForeverQbank = true)
            }
        case "closePracticeSetForeverQbank":
            return{
                showPrac_SetQuestionMark: (state.showPrac_SetQuestionMark = true),
                showPracticeSetForeverQbank: (state.showPracticeSetForeverQbank = false)
            }
        // generate Practice set end
    }
}
// reducer code end




function Generate_Exam() {
useEffect(()=>{
    getAssignSectionsToschool()
    getSubjects();
    getSubjectOfSection();
    getTopicList()
},[])

// state start
const {userData} = useContext(userContext);
const [state, dispatch] = useReducer(reducer,{showPrac_SetQuestionMark : false, dateToDate:false, fixedTime:true})
const [showSubjectData, setSubjectData]=useState([]);
const [showChapterList, setChapterList]=useState([]);
const [showLoader, setLoader]=useState(false);

const[showSchBnkPracSet, setSchBnkPracSet]=useState(false);
const[showAssignSec, setAssignSec]=useState([]);
const[showOfSec_subject, setOfSec_subject]=useState(false);
const[showOfSec_Topics, setOfSec_Topics]=useState(false);
const[showConfirmPop, setConfirmPop]=useState(false)

const[showSubjectListOfSection, setSubjectListOfSection]=useState([]);
const[showToicsList, setToicsList]=useState([]);
const[MockCUETpop, setMockCUETpop]=useState(false);
const[foreverqBankMock, setforeverqBankMock]=useState(false);



// state end



// get Subject function start
function getSubjects(){
    fetch(apiBaseURL+apiURL.getSubjectList,{
        method : 'GET',
        headers: {Key : apiKey}
    })
    .then(response => response.json())
    .then((subjectData)=>{
        if(subjectData.status == "success"){
            setSubjectData(subjectData.data.subjects)
        }else{
            setSubjectData([]);
            alert(subjectData.message)
        }
    })
    .catch((err)=>{
        setSubjectData([]);
        alert(err);
    })
}
// get Subject function end

// get Chapter function start
function getChapter(subjectId){
    setLoader(true)
    const xPostData ={
        "classID" : "12",
        "subjectID":subjectId
    }
    fetch(apiBaseURL+apiURL.getChaptersList,{
        method: 'POST',
        headers: {Key : apiKey},
        body:JSON.stringify(xPostData)
    })
    .then(response => response.json())
    .then((chapterData)=>{
        if(chapterData.status == "success"){
            setChapterList(chapterData.data.chapters)
        }else{
            setChapterList([]);
            alert(chapterData.message)
        }
    })
    .catch((err)=>{
        setChapterList([]);
        alert(err);
    })
    .finally(()=>{
        setLoader(false);
    })
}
// get Chapter function end
// get Section function start
function getAssignSectionsToschool(){
    const xPostData ={
         "classID":12,
         "schoolCode":userData.schoolCode
    }
    fetch(apiBaseURL+apiURL.getAssignedSection,{
         method: "POST",
         headers: {'Key' : apiKey},
         body:JSON.stringify(xPostData)
    })
    .then(response => response.json())
    .then((sectionAssignData)=>{
         if(sectionAssignData.status=="success"){
              setAssignSec(sectionAssignData.data.assignedSections)
         }else{
              setAssignSec([])
              alert(sectionAssignData.message)
         }
    })
    .catch((catch_err)=>{
        alert(catch_err)
    })

}
// get Section function end

// getSubjectOfSection  fun start 
function getSubjectOfSection(){
    const xPostData ={
         "classID":12,
         "schoolCode":userData.schoolCode
    }
    fetch(apiBaseURL+apiURL.getAssignedSubjects,{
         method: "POST",
         headers: {'Key' : apiKey},
         body:JSON.stringify(xPostData)
    })
    .then(response => response.json())
    .then((assignSubjectData)=>{
         if(assignSubjectData.status=="success"){
              let subjects = assignSubjectData.data.assignedSubjects.map((item)=>{
                   return {...item, isChecked:false}
              })
              setSubjectListOfSection(subjects)
         }else{
              setSubjectListOfSection([]) 
              alert(assignSubjectData.message)
         }
    })
    .catch((catch_err)=>{
         alert(catch_err)
    })
}
// getSection Of Section fun end 

// get topic list
function getTopicList(){
	const  xPostData ={
		"section":3
	}
	fetch(apiBaseURL+apiURL.getTopics,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((topicData)=>{
		if(topicData.status=="success"){
			setToicsList(topicData.data.topics)
		}else{
            setToicsList([])
			alert(topicData.message)
		}
	})
	.catch((err)=>{
		alert(err)
	})
}
// get topic list

// school section Hendlers start
function schBnkSecfun(secID, isSubject, istopic){
    if(isSubject==1){
        setOfSec_subject(true);
        setOfSec_Topics(false);
    }else if(istopic=true){
        setOfSec_Topics(true);
        setOfSec_subject(false);
    }
}


  return (
<div  className={`${AdminDashBoardCss.rightArea}`}>

    {/* header start */}
        <Header/>
    {/* header end */}
          
    {/* Left Menu start */}
        <AdminLeftMenu/>
    {/* Left Menu end */}
   
    <div className={`${AdminDashBoardCss.BkgenerateExam}`}>
        <Container fluid>
            <Row className='justify-content-around'>
                <Col  className='col-md-4 col-sm-6 col-12'>
                    <div onClick={(()=>{dispatch({type: "generatePracticeSet"})})} className={`${AdminDashBoardCss.boxesIcon}`} style={{background:state.showPrac_SetQuestionMark && "#f0f1f3"}}>
                         <img src={GenaratePracticeIcon}/>
                         <p>Generate Practice Set</p>
                    </div>
                </Col>
                <Col className='col-md-4 col-sm-6 col-12'>
                     <div onClick={()=>{setMockCUETpop(true)}} className={`${AdminDashBoardCss.boxesIcon}`}>
                         <img src={examGnerate}/>
                         <p>Generate Mock CUET </p>
                    </div>
                </Col>
            </Row>


            {/* Practice sets section start */}
                {state.showPrac_SetQuestionMark &&
                    <div className={`${AdminDashBoardCss.practicesSetSections}`}>
                        <div className={`${AdminDashBoardCss.innerPopup}`}>
                            <QuestionMarkIcon className={`${AdminDashBoardCss.questionMark}`}/>
                            <p className={`${AdminDashBoardCss.headingTxt}`}>
                                Please select your prefrence to generate practice set
                            </p>
                            <div className={`${AdminDashBoardCss.buttonSections}`}>
                                <button onClick={(()=>{dispatch({type: "PracticeSetForeverQbank"})})} className='btn btn-primary btn-lg'>Forever question bank</button>
                                <button onClick={()=>{setSchBnkPracSet(true)}} className='btn btn-secondary btn-lg'>Our question bank</button>
                            </div>
                            <button onClick={(()=>{dispatch({type: "hideQuestionPopup"})})} className='btn btn-danger mt-4'> CLOSE </button>
                        </div>
                    </div>
                }
                

        </Container>
        {/* foraver question bank start */}

        {state.showPracticeSetForeverQbank&&
            <div className={`${AdminDashBoardCss.practicesSetSectionsQbank}`}>
                <div className={`${AdminDashBoardCss.innerPopupBignSize}`}>
                    <div className={`${AdminDashBoardCss.headingBankname}`}>
                            Forever question bank
                            <button onClick={(()=>{dispatch({type: "closePracticeSetForeverQbank"})})} className={`btn btn-light ${AdminDashBoardCss.buttonClose}`}><CloseIcon/></button>
                    </div>
                     {/* assign sections for school */}
                     <div className={`${AdminDashBoardCss.sectiondiv}`}>
                            {showAssignSec.map((item)=>{
                                return(
                                    <>
                                        <div className={`${AdminDashBoardCss.sectionbox}`}>{item.sectionName}</div>
                                    </>
                                )
                            })}
                           
                    </div>
                    <hr/>

                    {/* subject Sections start */}



                      
            </div>
            </div>
        }
        {/* foraver question bank wnd */}


        {/* School question bank start */}
        {showSchBnkPracSet &&
            <div className={`${AdminDashBoardCss.practicesSetSectionsQbank}`}>
                <div className={`${AdminDashBoardCss.innerPopupBignSize}`}>
                    <div className={`${AdminDashBoardCss.headingBankname}`}>
                            School question bank
                            <button onClick={()=>{setSchBnkPracSet(false)}}  className={`btn btn-light ${AdminDashBoardCss.buttonClose}`}><CloseIcon/></button>
                    </div>

                    {/* assign sections for school */}
                    <div className={`${AdminDashBoardCss.sectiondiv}`}>
                            {showAssignSec.map((item)=>{
                                return(
                                    <>
                                    <div className={`${AdminDashBoardCss.sectionbox}`} onClick={()=>{schBnkSecfun(item.sectionID, item.isSubject, item.istopic)}}>
                                             {item.sectionName}
                                    </div>
                                     </>
                                )
                            })}
                           
                    </div>
                    <hr/>

                    {/* subject Sections start */}
                    <Container fluid>
                        {showOfSec_subject &&
                            <div method='get' className={`${AdminDashBoardCss.sectionOfCombobox}`}>
                                <Row>
                                    <Col>
                                        <label>Language</label>
                                        <select required className={`form-control ${AdminDashBoardCss.selectSubject}`}>
                                            <option className={`d-none`}>Select</option>
                                                {showSubjectListOfSection.map((item)=>{
                                                    return(
                                                        <option>{item.subjectName}</option>
                                                     )
                                                })}
                                        </select>
                                    </Col>
                                    <Col>
                                        <label>Chapters</label>
                                        <select required className={`form-control ${AdminDashBoardCss.selectSubject}`}>
                                            <option className={`d-none`}>Select</option>
                                            <option>Chapter coming soon</option>
                                        </select>
                                    </Col>
                                    <Col>
                                        <button onClick={()=>{setConfirmPop(true)}} className={`btn btn-success ${AdminDashBoardCss.gnerateBtn}`}><MiscellaneousServicesIcon/> Generate </button>
                                    </Col>
                                </Row>

                            </div>
                        }
                        {showOfSec_Topics &&
                            <div method='get' className={`${AdminDashBoardCss.sectionOfCombobox}`}>
                                <Row>
                                    <Col>
                                        <label>Topics</label>
                                        <select required className={`form-control ${AdminDashBoardCss.selectSubject}`}>
                                            <option className={`d-none`}>Select</option>
                                                {showToicsList.map((item)=>{
                                                    return(
                                                        <option>{item.questionCatDesc}</option>
                                                    )
                                                })}
                                        </select>
                                    </Col>
                                    <Col>
                                        <label>Sub Topics</label>
                                        <select required className={`form-control ${AdminDashBoardCss.selectSubject}`}>
                                            <option className={`d-none`}>Select</option>
                                            <option>Sub Topic coming soon</option>
                                        </select>
                                    </Col>
                                    <Col>
                                        <button onClick={()=>{setConfirmPop(true)}} className={`btn btn-success ${AdminDashBoardCss.gnerateBtn}`}><MiscellaneousServicesIcon/> Generate </button>
                                    </Col>
                                </Row>
                          </div>
                        }
                      </Container>
                    {/* subject Sections start */}


                    {/* assign sections for school */}

                </div>
            </div>
        }
        {/* School question bank end */}


        {/* Practice sets section end */}
        


       


        {/* Generate Mock CUET section start  */}

            {/* popup ? start */}
                {MockCUETpop &&
                    <div className={`${AdminDashBoardCss.practicesSetSections}`}>
                        <div className={`${AdminDashBoardCss.innerPopup}`}>
                            <QuestionMarkIcon className={`${AdminDashBoardCss.questionMark}`}/>
                            <p className={`${AdminDashBoardCss.headingTxt}`}>
                                Please select your prefrence to generate mock CUET
                            </p>
                            <div className={`${AdminDashBoardCss.buttonSections}`}>
                                <button onClick={()=>{setforeverqBankMock(true)}}  className='btn btn-primary btn-lg'>Forever question bank</button>
                                <button  className='btn btn-secondary btn-lg'>Our question bank</button>
                            </div>
                            <button onClick={(()=>{setMockCUETpop(false)})} className='btn btn-danger mt-4'> CLOSE </button>
                        </div>
                   </div>
                }
            {/* popup ? end */}

            {/* Forever q bank Mock CUET */}
            {foreverqBankMock &&  
                <div className={`${AdminDashBoardCss.practicesSetSectionsQbank}`}>
                    <div className={`${AdminDashBoardCss.innerPopupBignSize}`}>
                        <div className={`${AdminDashBoardCss.headingBankname}`}>
                                Forever question bank (Mock CUET)
                                <button onClick={()=>{setforeverqBankMock(false)}}  className={`btn btn-light ${AdminDashBoardCss.buttonClose}`}><CloseIcon/></button>
                        </div>

                        {/* assign sections for school */}
                        <div className={`${AdminDashBoardCss.sectiondiv}`}>
                                {showAssignSec.map((item)=>{
                                    return(
                                        <>
                                        <div className={`${AdminDashBoardCss.sectionbox}`} onClick={()=>{schBnkSecfun(item.sectionID, item.isSubject, item.istopic)}}>
                                                {item.sectionName}
                                        </div>
                                        </>
                                    )
                                })}
                            
                        </div>
                        <hr/>

                        {/* subject Sections start */}
                        <Container fluid>
                            
                        
                        </Container>
                        {/* subject Sections start */}


                        {/* assign sections for school */}

                    </div>
                </div>
            }
            
        {/* Generate Mock CUET section end  */}
        
        {/* loader */}
        {showLoader &&
            <Loader/>
        }
        {/* loader */}
         {/* Confirm Generate Assessment Popup start */}
		{showConfirmPop && 
				<div className={`${AdminDashBoardCss.basePopConfirm}`}>
					<div className={`${AdminDashBoardCss.innerWhiteBox}`} data-aos="zoom-in">
                        <QuestionMarkIcon className={`${AdminDashBoardCss.questionMark}`} data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="900"/>
						<p className={`${AdminDashBoardCss.messageTxt}`}>Are you sure generate this... ?</p>
						<button className='btn btn-success'>Yes !</button> {' '}
						<button onClick={()=>{setConfirmPop(false)}} className='btn btn-danger'>No !</button>
					</div>
				</div>
		}
    	{/* Confirm Generate Assessment Popup end */}
        
    </div>
</div>
  );
}

export default Generate_Exam;

import React, { useState, useContext, useEffect } from "react";
import CodeLoginCss from "./CodeLogin.module.css";
import LoginImg2 from "../../assets/loginImg2.png";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import {useNavigate} from 'react-router-dom';
import {apiBaseURL,apiURL,apiKey,baseURL} from './../../constant';
import Alert from '@mui/material/Alert';
import userContext from '../../Store';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ManageAccountsSharpIcon from '@mui/icons-material/ManageAccountsSharp';
import { Link } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { Col, Container, Row } from "react-bootstrap";
function LoginPage() {

  
// login code  
    const[accessCode, setaccessCode]=useState("");
    const {login, error, loader, checkLogin} = useContext(userContext);
// console.log(loader);

// login code


const [showTandC, setTandC] = useState(false);
const [showPrivacyPolicy, setPrivacyPolicy] = useState(false);
const [showAboutUs, setAboutUs] = useState(false);
const [showContactUs, setContactUs] = useState(false);
const [showReturnandRefundPolicy, setReturnandRefundPolicy] = useState(false);
const [showProductServicePaymentFlow, setProductServicePaymentFlowTandC] = useState(false);
const [showProducts, setShowProducts] = useState(false);
function closeInstBox(){	
	setTandC(false);
	setPrivacyPolicy(false);
	setAboutUs(false);
	setContactUs(false);
  setReturnandRefundPolicy(false);
  setProductServicePaymentFlowTandC(false);
  setShowProducts(false);
}

  useEffect( ()=>{
    window.addEventListener('popstate', (event) => {
      checkLogin(true);
    });
  },[] )

  return (   
    <div className={`${CodeLoginCss.container}`}>
      {showProducts && (
        <>
          <div className={`${CodeLoginCss.addMoreSubjectBg}`}>
            <div className={`${CodeLoginCss.generalInstructionBg}`}>
              <div className={`${CodeLoginCss.headingBk}`}>
                <Row>
                  <Col>
                    <div className={`${CodeLoginCss.SectionHeadingInst}`}>Products</div>
                  </Col>
                  <Col>
                    <CancelIcon	onClick={() => {closeInstBox()}} className={`${CodeLoginCss.crossbutton}`}/>
                  </Col>
                </Row>
              </div>
              <div className={`${CodeLoginCss.SubjectBodyArea}`}>
                <div className={`${CodeLoginCss.SubjectBodyContainer}`}>
                  <table className={`${CodeLoginCss.tableCLS}`}>
                    <tr>
                      <th colspan = "2"><strong>SECTION IA-ENGLISH</strong></th>
                    </tr>
                    <tr>
                      <td><strong>Subject</strong></td>
                      <td><strong>Rate (Rs.)</strong></td>
                    </tr>
                    <tr>
                      <td>English</td>
                      <td>300.00</td>
                    </tr>
                  </table>
                  <table className={`${CodeLoginCss.tableCLS}`}>
                    <tr>
                      <th colspan = "2"><strong>SECTION II DOMAIN SPECIFIC</strong></th>
                    </tr>
                    <tr>
                      <td><strong>Subject</strong></td>
                      <td rowSpan={11}><strong>Rate (Rs.)</strong><br /><br />1 Subject = Rs. 300<br />2 Subjects = Rs. 250 per subject<br />3 Subjects = Rs. 200 per subject<br />More than 3 Subjects = Rs. 175 per subject</td>
                    </tr>
                    <tr>
                      <td>Mathematics</td>                      
                    </tr>
                    <tr>
                      <td>Physics</td>                      
                    </tr>
                    <tr>
                      <td>Chemistry</td>                      
                    </tr>
                    <tr>
                      <td>Biology/Biological Studies/Biotechnology/Biochemistry</td>                     
                    </tr>
                    <tr>
                      <td>Accountancy</td>                      
                    </tr>
                    <tr>
                      <td>Business Studies</td>                     
                    </tr>
                    <tr>
                      <td>Economics/Business Economics</td>                      
                    </tr>
                    <tr>
                      <td>History</td>                      
                    </tr>
                    <tr>
                      <td>Geography/Geology</td>                      
                    </tr>
                    <tr>
                      <td>Political Science</td>                      
                    </tr>
                    <tr>
                      <td>Computer Science/Informatics Practices</td>                      
                    </tr>                   
                  </table>
                  <table className={`${CodeLoginCss.tableCLS}`}>
                    <tr>
                      <th colspan = "2"><strong>SECTION III</strong></th>
                    </tr>
                    <tr>
                      <td><strong>Subject</strong></td>
                      <td><strong>Rate (Rs.)</strong></td>
                    </tr>
                    <tr>
                      <td>General Test</td>
                      <td>300.00</td>
                    </tr>                    
                  </table>
                </div> 
              </div>
            </div>
          </div>
        </>
      )}
      {showTandC && (
        <>
          <div className={`${CodeLoginCss.addMoreSubjectBg}`}>
            <div className={`${CodeLoginCss.generalInstructionBg}`}>
              <div className={`${CodeLoginCss.headingBk}`}>
                <Row>
                  <Col>
                    <div className={`${CodeLoginCss.SectionHeadingInst}`}>Terms and Conditions</div>
                  </Col>
                  <Col>
                    <CancelIcon	onClick={() => {closeInstBox()}} className={`${CodeLoginCss.crossbutton}`}/>
                  </Col>
                </Row>
              </div>
              <div className={`${CodeLoginCss.SubjectBodyArea}`}>
                <div className={`${CodeLoginCss.SubjectBodyContainer}`}>
                  <p>The following terms and conditions will be deemed to have been accepted by the User on usage of the website <a href={baseURL} target="_blank">{baseURL}</a>. You are requested to read them carefully before you use the services of this site.</p>
                  <ul>
                    <li>The term ‘User’ shall refer to the user who is browsing the Website. The term ‘CUET’ shall refer to CUET and/or its affiliates/subsidiary companies. The term ‘Site’ refers to <a href={baseURL} target="_blank">{baseURL}</a>.</li>
                    <li>By using the Site, you agree to follow and be bound by the following terms and conditions concerning your use of the Site. CUET may revise the Terms of Use at any time without any notice.</li>
                    <li>All the content present on this site is the exclusive property of CUET. The software, text, images, graphics, video and audio used on this site solely belong to CUET. No material from this site may be copied, modified, reproduced, republished, uploaded, transmitted, posted or distributed in any form, whatsoever without prior written permission from CUET. All rights not expressly granted herein are reserved. Unauthorized use of the materials appearing on this site may violate copyright, trademark and other applicable laws, and could result in criminal and/or civil penalties.</li>
                    <li>CUET does not make any warranties, express or implied, including without limitation, those of merchantability and fitness for a particular purpose, with respect to any information, data, statements or products made available on the Site.</li>
                    <li>CUET does not accept any responsibility towards the contents and/or information practices of third party Sites, which have links through CUET Site. The said links to internal or external website locations are only for the purpose of facilitating your visit or clarifying your query.</li>
                    <li>The Site, and all content, materials, information, software, products and services provided on the Site, are provided on an ‘as is’ and ‘as available’ basis. CUET expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement.</li>
                    <li>CUET shall have no responsibility for any damage to a User's computer system or loss of data that may result from the download of any content, materials and information from the Site.</li>
                    <li>The User agrees to indemnify, defend and hold CUET harmless from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, arising out of or relating to any misuse by the User of the content and services provided on the Site.</li>
                    <li>The information contained in the Site has been obtained from sources believed to be reliable. CUET disclaims all warranties as to the accuracy, completeness or adequacy of such information.</li>
                    <li>The User's right to privacy is of paramount importance to CUET. Any information provided by the User will not be shared with any third party. CUET reserves the right to use the information to provide the User a personalized online experience.</li>
                    <li>The Site provides links to Websites and access to content, products and services from third parties, including users, advertisers, affiliates and sponsors of the Site. You agree that CUET is not responsible for the availability of, and content provided on, third party Websites. The User is requested to peruse the policies posted by other Websites regarding privacy and other topics before use.</li>
                    <li>CUET reserves the right in its sole discretion to review, improve, modify or discontinue, temporarily or permanently, the Site or any content or information on the Site with or without notice to User. User agrees that CUET shall not be liable to User or any third party for any modification or discontinuance of the Site.</li>
                  </ul>
                </div> 
              </div>
            </div>
          </div>
        </>
      )}
      {showPrivacyPolicy && (
        <>
          <div className={`${CodeLoginCss.addMoreSubjectBg}`}>
            <div className={`${CodeLoginCss.generalInstructionBg}`}>
              <div className={`${CodeLoginCss.headingBk}`}>
                <Row>
                  <Col>
                    <div className={`${CodeLoginCss.SectionHeadingInst}`}>Privacy Policy</div>
                  </Col>
                  <Col>
                    <CancelIcon	onClick={() => {closeInstBox()}} className={`${CodeLoginCss.crossbutton}`}/>
                  </Col>
                </Row>
              </div>
              <div className={`${CodeLoginCss.SubjectBodyArea}`}>
                <div className={`${CodeLoginCss.SubjectBodyContainer}`}>
                  <p>The following terms and conditions will be deemed to have been accepted by the User on usage of the website <a href={baseURL} target="_blank">{baseURL}</a>. You are requested to read them carefully before you use the services of this site.</p>
                  <ul>
                    <li><p><strong>Cancellation & Refund Policy</strong><br />As of now, we do not provide any option for cancellation of products and/or services you have purchased or subscribed. Once a product/service has been purchased by you, we cannot provide any refund, either totally or partially. We suggest that you first go through the demos and/or free to use contents/products/services before you subscribe to or purchase from swaadhyayan.com.</p></li>
                    <li><p><strong>Pricing Policy</strong><br />All prices are subject to change without any notice. Our Pricing Policy includes all the taxes and norms that are applicable as per the government policies.</p></li>
                    <li><p><strong>Advertisement</strong><br />The site may at its discretion display advertisements. The user’s correspondence or business dealings with, or participation in promotions of, advertisers found on or through swaadhyayan.com service, including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between the user and such advertiser. The user agrees that swaadhyayan.com shall not be responsible or liable for any loss or damage of any sort incurred as a result of any such dealings or as a result of the presence of such advertisers on the swaadhyayan.com service.</p></li>
                    <li><p><strong>Privacy Statement</strong><br />This Privacy Statement is designed to advise you about the type of information that swaadhyayan.com collects and the purposes, for which this information is being processed, used, maintained and disclosed. Swaadhyayan.com treats personal data which it receives through its websites; portals and any other means with due care and is dedicated to safeguard any personal data it receives. This Privacy Policy helps to ensure that swaadhyayan.com complies with applicable laws in the countries in which it operates. We, at swaadhyayan.com, take your privacy seriously, and will only use your personal information for the purposes permitted by law or specified in this Privacy Policy. Please read our Privacy Policy, and let us know if you have any questions.</p>
                        <p>Swaadhyayan.com is committed to protect your privacy whenever you buy our products. Swaadhyayan.com recognizes its responsibility to keep all personal information confidential at all times. Any information that swaadhyayan.com acquires in connection with such transactions is kept confidential. However, please note that the responsibility of swaadhyayan.com is limited to the protection of information that is obtained by swaadhyayan.com only.</p></li>
                    <li><p><strong>Personal Data</strong><br />Personal data refers to any information that tells us something about you or that we can link to you. We will only collect and process personal data about you where we have lawful bases. Lawful bases on which we would process our personal information includes obtaining explicit consent from you for processing your personal information or processing for ‘legitimate interests’ where processing is necessary by us to provide you with the required services. However, please note that your personal data may be shared with legal authorities, if required by law.</p>
                        <p>Swaadhyayan.com uses a variety of technologies and procedures to help protect personal information from unauthorized access, use or disclosure.</p></li>
                    <li><p><strong>Google Analytics</strong><br />Swaadhyayan.com adopts Google Analytics software to carry out Site usage analytics. Google Analytics gathers anonymous info from visitors to assist swaadhyayan.com in tracing Site usage and citations from other sites. The tracked data are employed principally to enhance the website experience for our visitors, however, we may also use the tracked data to help us in improving marketing strategies for our Site.</p>
                        <p>Whatever data is gathered and administered by Google Analytics chiefly comprises the user’s IP address, network and geographical location. All such information is directly collected from the user by Google Analytics, simply by installing a cookie on the user’s computer. Swaadhyayan.com does not share any information it gathers from Google, and Google Analytics does not save any privately identified information. Correspondingly, we cannot operate any info attained by Google Analytics to directly contact a visitor.</p></li>
                    <li><p><strong>Cookies</strong><br />Cookie is a small text file of letters and numbers that is stored by the browser on your computer when you visit certain websites. A cookie allows a website to distinguish your computer from other users’ computers. Cookies do not contain Personal Data unless you have already given this information to the Website. You may delete or block the cookies, if that is what you choose to do. Note that this may make it less convenient for you to use our website. Swaadhyayan.com website may contain links to other websites of your interest. Please note that once the Visitor opts to link to other websites, swaadhyayan.com shall not be responsible for protection of further disclosure of information and this Privacy Policy/statement cease to be applicable for such disclosure of information. The Visitor may therefore, like to exercise caution and look at the privacy statement applicable to those websites. Similarly your Financial Data like Account Number, Debit/Credit Card Number, etc., provided by you to pay us through third party Payment Gateway is protected by the service provider only. Remember, swaadhyayan.com never asks for any such information from you directly and is not in any case, responsible for the misuse of the same.</p>
                        <p>Swaadhyayan.com website may be available on various other third party websites. However, swaadhyayan.com shall not be responsible to the contents and material of such other websites. If any person provides its information on other websites, where the link of swaadhyayan.com or swaadhyayan.com logo is available, swaadhyayan.com shall not be responsible to any act or omission committed by third party websites including but not limited to data theft or misuse of information of such a person.</p>
                        <p>Swaadhyayan.com reserves its right to amend the present Privacy Policy at any time and will place such amended Privacy Policy, if any, on its website. This Privacy Policy is neither intended to and nor does it create any contractual rights whatsoever or any other legal rights, nor does it create any obligations on swaadhyayan.com in respect of any other party or on behalf of any party. By accessing this website and any of its pages, you are agreeing to the terms set out above.</p></li>
                    <li><p><strong>Security Policy</strong><br />The user might receive a password and User ID upon completing the service's registration process. The users are expected to maintain the confidentiality of the password and account, and shall be held solely responsible for all acts and omissions of password or unique ID. Swaadhyayan.com cannot and will not be liable for any loss or damage arising from the user’s failure to comply with the terms and conditions of this agreement.</p></li>
                    <li><p><strong>Termination</strong><br />If users commit any breach of trust under these terms and conditions like disclose your user ID and Password to another who is not authorized, swaadhyayan.com have right to terminate or discontinue services immediately without any notice.</p></li>
                    <li><p><strong>Arbitration</strong><br />Any dispute, difference or question arising out of these terms and conditions shall be settled amicably between the user and officer who is appointed by swaadhyayan.com, failing which, the same shall be referred to arbitrator under the Arbitration and Conciliation Act 1996, and the venue of the arbitration shall be in Delhi only.</p></li>
                    <li><p><strong>Court of Jurisdiction</strong><br />Swaadhyayan.com controls and operates this website from its headquarters in Delhi, India. You agree that any dispute shall be subject to the exclusive jurisdiction of Courts at Delhi only.</p></li>
                    <li><p><strong>Content and Liability Disclaimer</strong><br />This disclaimer of liability applies to any damage or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorized access to, alteration of, or use of record, whether for breach of contract, tortuous behavior, negligence, or under any other cause of action.</p>
                        <p>The documents and graphics on this website may include technical inaccuracies or typographical errors. Changes are periodically updated to the information herein. Swaadhyayan.com may make improvements and/or changes herein at any time. Swaadhyayan.com makes no representations about the accuracy of the information contained in the documents and graphics on this website for any purpose. All documents and graphics are provided ‘as is’. Swaadhyayan.com hereby disclaims all warranties and conditions with regard to this information, including all implied warranties and conditions of merchantability, fitness for any particular purpose, title and non-infringement. In no event, Swaadhyayan.com and/or its licensor/supplier shall not be liable to any party for any direct, indirect, special or other consequential damages for any use of the sites, the information, or on any other hyperlinked website, including, without limitation, any lost profits, business interruption, loss of programs or other data on your information handling system or otherwise, even if Swaadhyayan.com is expressly advised of the possibility of such damages.</p>
                        <p>Use of this website is at your own risk, and the swaadhyayan.com will not be held liable for any error or omission contained in this website. In no event, shall swaadhyayan.com be liable for any special, indirect or consequential damages, or any damage whatsoever, resulting from the loss of use, data or profits whether contract, negligence or any tort action arising out of, or in connection with, the use or performance of the information available from swaadhyayan.com.</p></li>
                  </ul>
                </div> 
              </div>
            </div>
          </div>
        </>
      )}
      {showAboutUs && (
        <>
          <div className={`${CodeLoginCss.addMoreSubjectBg}`}>
            <div className={`${CodeLoginCss.generalInstructionBg}`}>
              <div className={`${CodeLoginCss.headingBk}`}>
                <Row>
                  <Col>
                    <div className={`${CodeLoginCss.SectionHeadingInst}`}>About Us</div>
                  </Col>
                  <Col>
                    <CancelIcon	onClick={() => {closeInstBox()}} className={`${CodeLoginCss.crossbutton}`}/>
                  </Col>
                </Row>
              </div>
              <div className={`${CodeLoginCss.SubjectBodyArea}`}>
                <div className={`${CodeLoginCss.SubjectBodyContainer}`}>
                  <ul>
                    <li><p><strong>Welcome To CUET</strong><br />CUET is the most powerful adaptive and comprehensive E-learning, assessment and sharing platform ever for students to identify their learning style and building their progressive proficiency. Activities and assessments on this Digital Platform are mapped to the latest curriculum which makes the learning process more effective and adaptive. The assessment for kindergarten to class 8 generates multiple reports and the progress card of the child depicting various parameters. It enables all the users to share useful resources and learning outcomes which are beneficial to interaction between the school and the child. </p></li>
                    <li><p><strong>Why CUET?</strong><br />CUET enables students to identify their learning style and proficiency profile along with their scholastic and co-scholastic areas of opportunity. The platform engages learners from kindergarten to class 8 entailing a series of activities and assessments based on Nobel Prize-winning concept of Multiple Intelligences which helps in building the progressive proficiency of the child. Multiple Intelligences help identify the most suitable stream selection and career choice for the user.</p></li>
                    <li><p><strong>Nobody is Left Behind</strong><br />The basic aim of education is to include all the persons involved in it. The learning suggestions and the learning outcomes of CUET define roles and responsibilities for the continuous evaluation of the learners. It engages pre-learning diagnosis followed by learning suggestions and learning outcomes there by upholding the fundamental right of every learner to have quality education.</p></li>
                 </ul>
                </div> 
              </div>
            </div>
          </div>
        </>
      )}
      {showContactUs && (
        <>
          <div className={`${CodeLoginCss.addMoreSubjectBg}`}>
            <div className={`${CodeLoginCss.generalInstructionBg}`}>
              <div className={`${CodeLoginCss.headingBk}`}>
                <Row>
                  <Col>
                    <div className={`${CodeLoginCss.SectionHeadingInst}`}>Contact Us</div>
                  </Col>
                  <Col>
                    <CancelIcon	onClick={() => {closeInstBox()}} className={`${CodeLoginCss.crossbutton}`}/>
                  </Col>
                </Row>
              </div>
              <div className={`${CodeLoginCss.SubjectBodyArea}`}>
                <div className={`${CodeLoginCss.SubjectBodyContainer}`}>
                  <ul>
                    <li><p><strong>ADDRESS</strong><br />4583/15, Opp. LIC Building, Daryaganj,New Delhi-110002</p></li>
                    <li><p><strong>Phone Number</strong><br />011 - 43585858,  23285568 <br />  011 - 23243519, P.O. Box 7226 </p></li>
                    <li><p><strong>Email Address</strong><br />ecommerce@rachnasagar.in</p></li>
                 </ul>
                </div> 
              </div>
            </div>
          </div>
        </>
      )}      
      {showReturnandRefundPolicy && (
        <>
          <div className={`${CodeLoginCss.addMoreSubjectBg}`}>
            <div className={`${CodeLoginCss.generalInstructionBg}`}>
              <div className={`${CodeLoginCss.headingBk}`}>
                <Row>
                  <Col>
                    <div className={`${CodeLoginCss.SectionHeadingInst}`}> Return & Refund Policy </div>
                  </Col>
                  <Col>
                    <CancelIcon	onClick={() => {closeInstBox()}} className={`${CodeLoginCss.crossbutton}`}/>
                  </Col>
                </Row>
              </div>
              <div className={`${CodeLoginCss.SubjectBodyArea}`}>
                <div className={`${CodeLoginCss.SubjectBodyContainer}`}>
                  <ul>
                    <li><p><strong>Return & Refund Policy</strong><br />As of now we do not provide any option for cancellation of products and/or services you have purchased or subscribed. Once a product/service has been purchased by you, we cannot provide any refund, either totally or partially. We suggest that first you go through the demos and/or free to use contents/products/services before you subscribe to or purchase from http://cucet.swaadhyayan.com/.</p></li>
                 </ul>
                </div> 
              </div>
            </div>
          </div>
        </>
      )}
      {showProductServicePaymentFlow && (
        <>
          <div className={`${CodeLoginCss.addMoreSubjectBg}`}>
            <div className={`${CodeLoginCss.generalInstructionBg}`}>
              <div className={`${CodeLoginCss.headingBk}`}>
                <Row>
                  <Col>
                    <div className={`${CodeLoginCss.SectionHeadingInst}`}> Product Service Payment Flow </div>
                  </Col>
                  <Col>
                    <CancelIcon	onClick={() => {closeInstBox()}} className={`${CodeLoginCss.crossbutton}`}/>
                  </Col>
                </Row>
              </div>
              <div className={`${CodeLoginCss.SubjectBodyArea}`}>
                <div className={`${CodeLoginCss.SubjectBodyContainer}`}>
                <p>Product Service Payment Flow</p>
                  <ul>
                    <li><p>Open the URL http://cucet.swaadhyayan.com/.</p></li>
                    <li><p>Click on the register now button.</p></li>
                    <li><p>Fill the complete registration form (email should be working).</p></li>
                    <li><p>After submitting the registration form, user will receive login credential thru email provided.</p></li>
                    <li><p>After that user will select payment gateway either Paytm or Ccavenue.</p></li>
                    <li><p>User will redirect to payment gateway for payment process.</p></li>
                    <li><p>After complete the payment process user will redirect at the response page.</p></li>
                    <li><p>User will receive payment detail and invoice at register email id.</p></li>
                    <li><p>Your selected subjects and sections will be added automatically.</p></li>
                 </ul>
                </div> 
              </div>
            </div>
          </div>
        </>
      )}
      {loader && 

       <div className="loader">
           <div className="box">
               <div className="circle"></div>
           </div>
           <div className="box">
               <div className="circle"></div>
           </div>
       </div>
      
      }


      <div className={`${CodeLoginCss.loginDiv}`}>
        <div className={`${CodeLoginCss.loginImg2}`}>
          <img src={LoginImg2} />
        </div>


        {/* form Section start */}
        <form className={`${CodeLoginCss.loginForm}`} onSubmit={(e)=>{e.preventDefault(); login({'eacode':accessCode})}}>
          <div className={`${CodeLoginCss.loginHead}`}>
            <h1>Login</h1>
            {error.status &&
                <Alert className={`${CodeLoginCss.erroBox}`}  severity="error" id="alertmsg">
                    {error.msg}
                </Alert>
            }
          </div>

          <label className={`${CodeLoginCss.lableHead}`}>
            Enter Your Access Code:
          </label>

          <div className={`${CodeLoginCss.inputRow}`}>
            <div className={`${CodeLoginCss.icon}`}>
              <ClosedCaptionIcon className={`${CodeLoginCss.icons}`} />
            </div>

            <div className={`${CodeLoginCss.inputBox}`}>
              <input autoComplete="off" required name="UserName" type="password" placeholder="Enter Access Code" 
               onChange={(e)=>{setaccessCode(e.target.value)}} />
            </div>
          </div>

          <div className={`${CodeLoginCss.buttonRow}`}>
            <button type="submit" className={`${CodeLoginCss.logButton}`}><LockOpenIcon/> Login </button>
            <Link to="/signup">
            <button type="button" className={`${CodeLoginCss.logButton}`}><ManageAccountsSharpIcon/> Register Now </button>
            </Link>
          </div>
        </form>
        {/* form Section start */}


      </div>
      <div className={`${CodeLoginCss.bottomMenu}`}>
        <ul>
          <li onClick={() => {closeInstBox();setTandC(true);}}>Terms and Conditions</li>
          <li onClick={() => {closeInstBox();setPrivacyPolicy(true);}}>Privacy Policy</li>
          <li onClick={() => {closeInstBox();setAboutUs(true);}}>About Us</li>
          <li onClick={() => {closeInstBox();setContactUs(true);}}>Contact Us</li>
          <li onClick={() => {closeInstBox();setReturnandRefundPolicy(true);}}>Return and Refund Policy</li>
          <li onClick={() => {closeInstBox();setProductServicePaymentFlowTandC(true);}}>Product Service Payment Flow</li>
          <li onClick={() => {closeInstBox();setShowProducts(true);}}>Products</li>
        </ul>
      </div>
      <div className={`${CodeLoginCss.area}`}>
        <ul className={`${CodeLoginCss.circles}`}>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  );
}
export default LoginPage;

import React, { useState, useEffect, useContext } from 'react';
import managQueCss from './ManageQue.module.css';

import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import CloseIcon from '@mui/icons-material/Close';

import {ckFinderURL} from '../../constant';

const ckEditorConfiguration = {
    toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'superscript', 'subscript', '|', 'alignment', 'insertTable', 'bulletedList', 'numberedList', '|', 'uploadImage', 'specialCharacters', 'MathType', 'ChemType'],
    ckfinder: {
        uploadUrl: ckFinderURL
    }
};


function EditQuestionPopup({quesProcessingData, ckeditorHandler, updateQuesHandler, quesProcessingDataHandler, editQuesClosePopupHandler}) {

   
    const [isLoaderActive,setIsLoaderActive] = useState(true);
    const [ckInit,setCkInit] = useState(false);

    const ckInitHandler = ( stats ) => {
        if(stats){
            setIsLoaderActive(false);
        }
        setCkInit(stats);
    };

    
  return (

  <div className={`${managQueCss.opupBox}`} onClick={editQuesClosePopupHandler}>
      <div className={`${managQueCss.crossBtn}`} onClick={ editQuesClosePopupHandler }><CloseIcon className={`${managQueCss.cancleBtn}`}/></div>
      <div className={`${managQueCss.addqueForm}`}onClick={(event)=>{event.stopPropagation();}}>

        <div className={`${managQueCss.queTextBox}`}>
                <div className={`${managQueCss.lableText}`}><p>Question Heading</p></div>
                <div className={`${managQueCss.ckEditorBox}`}>
                    
                    <CKEditor
                        data={quesProcessingData.quesHeading}
                        editor={ClassicEditor}
                        config={ckEditorConfiguration}
                        onReady={(editor) => {
                            // ckInitHandler(true);
                            console.log('init')
                        }}
                        onChange={ (e, editor) => {
                            ckeditorHandler( 'quesHeading', editor.getData() );
                        } }
                    />
                </div>
            </div>

            <div className={`${managQueCss.optionContainer}`}>
                <div className={`${managQueCss.optionTextBox}`}>
                    <div className={`${managQueCss.lableText}`}><p>(A)</p></div>
                    <div className={`${managQueCss.ckEditorBox}`}>
                        <CKEditor
                            data={quesProcessingData.quesOptionA}
                            editor={ClassicEditor}
                            config={ckEditorConfiguration}
                            onReady={(editor) => {
                                console.log('init')
                            }}
                            onChange={ (e, editor) => {
                                ckeditorHandler( 'quesOptionA', editor.getData() );
                            } }
                        />
                    </div>
                </div>
                
                <div className={`${managQueCss.optionTextBox}`}>
                    <div className={`${managQueCss.lableText}`}><p>(B)</p></div>
                    <div className={`${managQueCss.ckEditorBox}`}>
                        <CKEditor
                            data={quesProcessingData.quesOptionB}
                            editor={ClassicEditor}
                            config={ckEditorConfiguration}
                            onReady={(editor) => {
                                console.log('init')
                            }}
                            onChange={ (e, editor) => {
                                ckeditorHandler( 'quesOptionB', editor.getData() );
                            } }
                        />
                    </div>
                </div>

                <div className={`${managQueCss.optionTextBox}`}>
                    <div className={`${managQueCss.lableText}`}><p>(C)</p></div>
                    <div className={`${managQueCss.ckEditorBox}`}>
                        <CKEditor
                            data={quesProcessingData.quesOptionC}
                            editor={ClassicEditor}
                            config={ckEditorConfiguration}
                            onReady={(editor) => {
                                console.log('init')
                            }}
                            onChange={ (e, editor) => {
                                ckeditorHandler( 'quesOptionC', editor.getData() );
                            } }
                        />
                    </div>
                </div>

                <div className={`${managQueCss.optionTextBox}`}>
                    <div className={`${managQueCss.lableText}`}><p>(D)</p></div>
                    <div className={`${managQueCss.ckEditorBox}`}>
                        <CKEditor
                            data={quesProcessingData.quesOptionD}
                            editor={ClassicEditor}
                            config={ckEditorConfiguration}
                            onReady={(editor) => {
                                console.log('init')
                            }}
                            onChange={ (e, editor) => {
                                ckeditorHandler( 'quesOptionD', editor.getData() );
                            } }
                        />
                    </div>
                </div>

            </div>
            <div className={`${managQueCss.queTextBox}`}>
                <div className={`${managQueCss.explnBox}`}>
                    <div className={`${managQueCss.lableText}`}><p>Explanation</p></div>
                    <div className={`${managQueCss.CorrectAns}`}>
                        <select 
                            className={`${managQueCss.optBtn}`}
                            value={ quesProcessingData.quesRightOption }
                            onChange={ (e)=> { e.target.name='quesRightOption'; quesProcessingDataHandler(e); } }
                        >
                            <option value="0" className={`${managQueCss.selectOpt}`}>Correct Answer</option>
                            <option value="1">A</option>
                            <option value="2">B</option>
                            <option value="3">C</option>
                            <option value="4">D</option>
                        </select>
                    </div>
                </div>

                <div className={`${managQueCss.ckEditorBox}`}>
                    <CKEditor
                        data={quesProcessingData.quesExplanation}
                        editor={ClassicEditor}
                        config={ckEditorConfiguration}
                        onReady={(editor) => {
                            console.log('init')
                        }}
                        onChange={ (e, editor) => {
                            ckeditorHandler( 'quesExplanation', editor.getData() );
                        } }
                    />
                </div>
            </div>
            <div className={`${managQueCss.saveQueBtn}`}>

                {quesProcessingData.mode=='view' &&
                    <button 
                        className={`${managQueCss.saveQue}, btn btn-primary`}
                        onClick={ updateQuesHandler }
                    >
                        Update Question
                    </button>
                }
                
            </div>
      </div>
  </div>
  );
}

export default EditQuestionPopup;

import {useContext } from 'react';
import StuDashboard from './components/studentDashboard/studentDashboard';
import AdminDashboard from './components/adminDashboard/adminDashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {Routes, Route} from 'react-router-dom';
import AdminLogin from './components/login/AdminLogin';
import ManageQuestion from './components/adminDashboard/ManageQue';

import GeneratePracticeSet from './components/adminDashboard/GeneratePracticeSet';
import Manage_orders from './components/superAdmin/Manage_orders';
import Home from './components/homePage/Home';
//import GuruAccount from './components/guruAccount/Home';
import GuruHome from './components/guruAccount/GuruHome';
import AccountsguruRegister from './components/register/AccountsguruRegister';
import CodeLogin from './components/login/CodeLogin';
import CuetPromo from './components/login/CuetPromo';

import WelcomePage from './components/welcome/Welcomeuser';
import Generate_Exam from './components/adminDashboard/Generate_Exam';
import TopicWisePractice from './components/studentDashboard/TopicWisePractice';
import PracticeSets from './components/studentDashboard/PracticeSet';
import QuickNotes from './components/studentDashboard/studentQuickNotesPage';

//---------------------------------------------------------------------
import ExaminationPaper from './components/studentDashboard/ExaminationPaper';
import Aboutus from './components/layout/Aboutus';
import Contactus from './components/layout/Contactus';
import Faq from './components/layout/Faq';
import Disclaimer from './components/layout/Disclaimer';
import Privacypolicy from './components/layout/Privacypolicy';
import Concellationrefundpolicy from './components/layout/Concellationrefundpolicy';
import Products from './components/layout/Products';
import ProductServicePaymentFlow from './components/layout/ProductServicePaymentFlow';
import TermCondition from './components/layout/TermCondition';
import Blog from './components/layout/Blog';
//---------------------------------------------------------------

import AttemptedScreen from './components/studentDashboard/AttemptedMcq';

//-----------------------------------------------------------
import AttemptedPaperScreen from './components/studentDashboard/AttemptedPaper';
//--------------------------------------------------
import SelfGeneExams from './components/studentDashboard/SelfGeneExams';
import StudentReport from './components/studentDashboard/report/Report';
import Register from './components/register/Register';
import UserProfile from './components/studentDashboard/UserProfile';
import AddEdit from './components/adminDashboard/AddEditInstruction';
import AddQue from './components/adminDashboard/ManageQue';
import SuperAdmin from './components/superAdmin/SuperAdmin';
import SuperAdminReport from './components/superAdmin/Report';
import FileNotFound from './FileNotFound';
import ManageStudent_Admin from './components/adminDashboard/Manage_student';
import Report_Admin from './components/adminDashboard/Report';
import Mock_CUET from './components/studentDashboard/MockCUET';
import ExamInstructions from './components/studentDashboard/ExamInstructions';
import Success from './components/register/Success';
import ManageStudentSuperAdmin from './components/superAdmin/Manage_student';
// import ManageDemoSuperAdmin from './components/superAdmin/Manage_demo_user';
import ManageQSchool from './components/adminDashboard/ManageSchoolQue';

import userContext from './Store';
import React from 'react';
import {RouteURL} from './constant';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

AOS.init(); 
function App() {
    const {userData} = useContext(userContext);

    // useEffect((item)=>{
    //     checkLogin()
    // }, [])
    // useEffect(()=>{
    //     checkLogin()
    // }, [])

    // function checkLogin(){
    //     const loginToken = localStorage.getItem('token')
    //     alert(loginToken)
    // }

    return (
        
        <div className="App">

            <Helmet>
                <title>NTA CUET UG Online Mock Test Series 2024 by Rachna Sagar with subject wise Practice paper for All Streams</title>
                <meta name="description" content="NTA CUET mock test series 2024 presents Subject wise and Chapter wise practice questions for all streams based on Exam Pattern. Practice subject-wise questions banks & self-generated assessments. Attempt Full Course Test paper with best options for excellence in 2024 entrance exam." />
                {/* <meta name="google-site-verification" content="VbA264QPvriVkd20tU5gYt_B8YQBw8SbH2qkepfUiis" /> */}
                {/* <meta property="og:title" content="Digital Learning Platform for Classes K to 8 | Swa Adhyayan LMS" /> 
                <meta property="og:description" content="Swa Adhyayan an adaptive best E-learning platform for students, Parents, Teachers and Schools based on Multiple Intelligences includes learning & assessment processes both"/> 
                <meta property="og:url" content="https://swaadhyayan.com/" /> 
                <meta property="og:site_name" content="Swa Adhyayan" /> 
                <meta property="og:image" content="https://swaadhyayan.com/static/media/banner3.27e148c4.png" /> 
                <meta name="twitter:site" content="@swaadhyayan"/> 
                <meta name="twitter:title" content="Digital Learning Platform for Classes K to 8 | Swa Adhyayan LMS"/>
                <meta name="twitter:description" content="Swa Adhyayan an adaptive best E-learning platform for students, Parents, Teachers and Schools based on Multiple Intelligences includes learning & assessment processes both" />
                <meta name="twitter:image" content="https://swaadhyayan.com/static/media/banner3.27e148c4.png"/> */}
            </Helmet>

             <Routes> 
                {/* Common routing - start */}
                    <Route path={RouteURL.homePage}  element={<Home/>}></Route>
                    {/* <Route path={RouteURL.GuruAccount}  element={<Home/>}></Route> */}
                    <Route path={RouteURL.guruAccount}  element={<GuruHome/>}></Route>

                    <Route path={RouteURL.studentLoginPage}  element={<CodeLogin/>}></Route>
                    <Route path={RouteURL.studentCuetPromo}  element={<CuetPromo/>}></Route>
                    <Route path={RouteURL.Aboutus}  element={<Aboutus/>}></Route>
                    <Route path={RouteURL.Contactus}  element={<Contactus/>}></Route>
                    <Route path={RouteURL.Faq}  element={<Faq/>}></Route>
                    <Route path={RouteURL.Disclaimer}  element={<Disclaimer/>}></Route>
                    <Route path={RouteURL.Privacypolicy}  element={<Privacypolicy/>}></Route>
                    <Route path={RouteURL.Concellationrefundpolicy}  element={<Concellationrefundpolicy/>}></Route>
                    <Route path={RouteURL.Products}  element={<Products/>}></Route>
                    <Route path={RouteURL.ProductServicePaymentFlow}  element={<ProductServicePaymentFlow/>}></Route>
                    <Route path={RouteURL.TermCondition}  element={<TermCondition/>}></Route>
                    <Route path={RouteURL.Blog}  element={<Blog/>}></Route>

                    <Route path={RouteURL.AccountsguruRegister}  element={<AccountsguruRegister/>}></Route>
                    <Route path={RouteURL.signupPage}  element={<Register/>}></Route>
                    <Route path={RouteURL.PaymentSuccess}  element={<Success/>}></Route>
                    <Route path={RouteURL.masterLoginPage}  element={<AdminLogin/>}></Route>
                {/* Common routing - end */}

                {/* Account Admin Dashboard routing - start */}
                    { userData.userTypeID===5 &&
                    <>
                        <Route path={RouteURL.superAdmin} element={<SuperAdmin/>}></Route>
                        <Route path={RouteURL.superAdminReport} element={<SuperAdminReport/>}></Route>
                        <Route path={RouteURL.manageStuSuperAdmin} element={<ManageStudentSuperAdmin/>}></Route>
                        {/* <Route path={RouteURL.manageDemoSuperAdmin} element={<ManageDemoSuperAdmin/>}></Route> */}
                    </>
                    }
                {/* Account Admin dashboard routing - end */}

                {/* Super Admin Dashboard routing - start */}
                    { userData.userTypeID===1 &&
                        <>
                            <Route path={RouteURL.superAdmin} element={<SuperAdmin/>}></Route>
                            <Route path={RouteURL.superAdminReport} element={<SuperAdminReport/>}></Route>
                            <Route path={RouteURL.manageStuSuperAdmin} element={<ManageStudentSuperAdmin/>}></Route>
                            {/* <Route path={RouteURL.manageDemoSuperAdmin} element={<ManageDemoSuperAdmin/>}></Route> */}
                        </>
                    }
                {/* Super Admin Dashboard routing - end */}


                {/* School Admin Dashboard routing - start */}
                    { userData.userTypeID===2 &&
                        <>
                            <Route path={RouteURL.adminDashboard}  element={<AdminDashboard/>}></Route>
                            <Route path={RouteURL.addEditInst}  element={<AddEdit/>}></Route>
                            <Route path={RouteURL.addQuestion}  element={<AddQue/>}></Route>
                            <Route path={RouteURL.ManageStudent_Admin}  element={<ManageStudent_Admin/>}></Route> 
                            <Route path={RouteURL.adminGenerateExam}  element={<Generate_Exam/>}></Route> 
                            <Route path={RouteURL.adminRepott}  element={<Report_Admin/>}></Route> 
                            <Route path={RouteURL.manageSchooQuestion} element={<ManageQSchool/>}></Route>
                        </>
                    }
                {/* School Admin Dashboard routing - end */}
                

                {/* Student Dashboard routing - start */}
                    { userData.userTypeID===3 &&
                        <>
                            <Route path={RouteURL.studentWelcomePage}  element={<WelcomePage/>}></Route>
                            <Route path={RouteURL.userProfile}  element={<UserProfile/>}></Route>
                            <Route path={RouteURL.studentDashboard}  element={<StuDashboard/>}></Route>
                            <Route path={RouteURL.studentQuickNotesPage}  element={<QuickNotes/>}></Route>

                            <Route path={RouteURL.ExaminationPaper}  element={<ExaminationPaper/>}></Route>

                            <Route path={RouteURL.studentTopicWisePracticePage}  element={<TopicWisePractice/>}></Route>
                            <Route path={RouteURL.studentPracticeSetPage}  element={<PracticeSets/>}></Route>
                            <Route path={RouteURL.studentSelfGenerteAssessmentPage}  element={<SelfGeneExams/>}></Route>
                            <Route path={RouteURL.studentAttemptScreenPage+'/:assessmentID'+'/:assessmentName'+'/:schoolBankType'}  element={<AttemptedScreen/>}></Route>

                            <Route path={RouteURL.studentAttemptPaperScreenPage+'/:assessmentID'+'/:assessmentName'+'/:schoolBankType'}  element={<AttemptedPaperScreen/>}></Route>

                            {/* <Route path={RouteURL.studentAttemptScreenPage+'/:assessmentID'+'/:assessmentName'+'/:schoolBankType'}  element={<AttemptedScreen/>}></Route> */}
                            <Route path={RouteURL.studentReport}  element={<StudentReport/>}></Route> 
                            <Route path={RouteURL.mock_cuet}  element={<Mock_CUET/>}></Route>
                            <Route path={RouteURL.examInstructions}  element={<ExamInstructions/>}></Route>
                        </>
                    }
                {/* Student Dashboard routing - end */}


                {/* DTP Dashboard routing - start */}
                    { userData.userTypeID===4 &&
                        <>
                            <Route path={RouteURL.dtpDashboardPage} element={<ManageQuestion/>}></Route>
                            <Route path={RouteURL.dtpGeneratePracticeSet} element={<GeneratePracticeSet/>}></Route>
                        </>
                    }
                {/* DTP Dashboard routing - end */}

                {/* Manage Order Routing - start */}
                    <Route path={RouteURL.manage_order} element={<Manage_orders/>}></Route>
                {/* Manage Order Routing - end */}



                {/* 404 page - start */}
                    <Route path='*' element={<FileNotFound/>}></Route>
                {/* 404 page - end */}
            </Routes>
           <footer>© 2024 Forever Books All Right Reserved.</footer>

        </div>
        
    );
}

export default App;

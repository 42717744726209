import StuDashBoardCss from './stuDashboard.module.css';
import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import ImgTest from './../../assets/onlineTest.png';
import AllThebest from './../../assets/allThebest.png';

import AdjustIcon from '@mui/icons-material/Adjust';

function ExamInstructions() {
  return (
	<div className={`${StuDashBoardCss.examsBgs}`}>
		<div className={`${StuDashBoardCss.innerBoxIntre}`}>
			{/* <div className={`${StuDashBoardCss.headersLine}`}></div> */}
				<div className={`${StuDashBoardCss.innerBoxcontent}`}>
					
						<Container fluid>
							<Row>
								<Col className="col-md-5">
									<div className={`${StuDashBoardCss.colImgs}`}>
										<img src={ImgTest}/>
									</div>
								</Col>
								<Col>
									<div className={`${StuDashBoardCss.Heading}`}>Mock CUET Exam Instructions</div>
									<hr/>
									<div className={`mt-5 ${StuDashBoardCss.rowDivs}`}>
										<div><AdjustIcon className={`${StuDashBoardCss.headingDot}`}/></div>
										<div> You must use a functioning webcam and microphone</div>
									</div>
									<div className={`${StuDashBoardCss.rowDivs}`}>
										<div><AdjustIcon className={`${StuDashBoardCss.headingDot}`}/></div>
										<div> Your desk/table must be clear or any materials except your test-taking device</div>
									</div>

									<div className={`${StuDashBoardCss.rowDivs}`}>
										<div><AdjustIcon className={`${StuDashBoardCss.headingDot}`}/></div>
										<div> In the “Don’t do” examples below, you’ll see the importance of being clear about the rules and expectations to avoid any confusion.</div>
									</div>

									<div className={`${StuDashBoardCss.rowDivs}`}>
										<div><AdjustIcon className={`${StuDashBoardCss.headingDot}`}/></div>
										<div> In the “Don’t do” example, the student may think: “my peer from class isn’t my friend or family, so it’s okay to speak with them for help.”</div>
									</div>

									<div className={`${StuDashBoardCss.rowDivs}`}>
										<div><AdjustIcon className={`${StuDashBoardCss.headingDot}`}/></div>
										<div> In the “Don’t do” example, the student may think: I can’t have other devices in view of the webcam, but I can use other devices if they’re out of sight of the webcam.”</div>
									</div>

									<div className={`${StuDashBoardCss.rowDivs}`}>
										<div><AdjustIcon className={`${StuDashBoardCss.headingDot}`}/></div>
										<div> In the “Don’t do” examples below, you’ll see the importance of being clear about the rules and expectations to avoid any confusion.</div>
									</div>

									<div className={`${StuDashBoardCss.bestImages}`}>
										<img src={AllThebest}/>
									</div>
									<Row className={`${StuDashBoardCss.rowWithicons}`}>
										<Col>
											<label className={`${StuDashBoardCss.iaccInput}`}>
												<input  type="checkbox" />  I Accept
											</label>
										</Col>
										<Col>
											<div className={`${StuDashBoardCss.buttonSectionRow}`}>
												<button className="btn btn-success btn-lg">START EXAMS !</button>
											</div>
										</Col>
									</Row>
									

								</Col>
							</Row>
						</Container>
				</div>
		</div>
	</div>
  );
}

export default ExamInstructions;

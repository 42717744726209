import StuDashBoardCss from './stuDashboard.module.css';
import LeftMenu from './LeftMenu';
import Header from './header';
import notes from '../../assets/notes.webp';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Container, Row, Col } from 'react-bootstrap';
import React, { useEffect, useState, useContext } from "react";
import {apiBaseURL,apiURL,apiKey,RouteURL} from './../../constant';

import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Loader from "./../../Loader";
import userContext from './../../Store';
import Swal from 'sweetalert2';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from "@mui/icons-material/Cancel";
function StudentDash() {
  useEffect(()=>{
    getSectionAssignOfStudent()
  },[])
  const {userData} = useContext(userContext);

  const[showLoader, setLoader]=useState(false)
  const[showTopicList, setTopicList]=useState([]);
  const[showSubjectList, setSubjectList]=useState([]);
  const[showChapter, setChapterList]=useState([]);
  const[showSection, setSection]=useState([]);
  const[getSubTopic, setSubTopicData]=useState([]);
  const[btn,setBtn] = useState(false)
  // radio button
  const[sltSectionA, setSlSectionA]=useState(true);
  const[checkBtn, setCheckedBtn] = useState("");
  const[allData, setAllData] = useState({subjectId:'', topicId:'',chapID:'',languageID:'',subTopicID:'',schoolBankType:''})
  const[schoolBankTypeData, setSchoolBankType ] = useState() 
  // radio button

  // Section state
  const[showSection_I, setSection_I] =useState(false);
  const[showSection_II, setSection_II] =useState(false);
  const[showSection_III, setSection_III] =useState(false);
  const[subject, setsubject] =useState(false);
  const[topics, settopics]=useState(false);
  const[chapter,setChapter] = useState(false)
  const[subTopic, setSubTopic] = useState(false)
  const[assID, setAssID] =useState()

  // Section state

  const[showErrPopup, setErrPopup] =useState({status:false, msg:null});
  	const [showInstructionOn, setInstructionOn] = useState(false);
	function closeCartBox(){	
		setInstructionOn(false);	
	}
  function checkBtnData(secID,isSubject){
	
		if(secID==1){
			setSection_I(true)
			setSection_II(false)
			setSection_III(false)
		 }else if(secID==2){
			setSection_II(true)
			setSection_I(false)
			setSection_III(false)
			setChapter(false)
		 }else{
			setSection_III(true)
			setSection_I(false)
			setSection_II(false)
		 }
		setCheckedBtn(secID)
		setLoader(true)
		
		if( isSubject=='1' ){
			const xPostData ={
				"section":secID,
				"schoolCode" : userData.schoolCode,
				"userRefID" : userData.userRefID
			}
			fetch(apiBaseURL+apiURL.getSubjectsOfSection,{
				method: "POST",
				headers: {'Key' : apiKey},
				body:JSON.stringify(xPostData)
			})
			.then(responce => responce.json())
			.then((subjectData)=>{
				if(subjectData.status=="success"){
					setSubjectList(subjectData.data.subjects)
				}else{
					setSubjectList([])
					alert(subjectData.message)
				}
			})
			.catch((catch_err)=>{
				setSubjectList([])
				alert(catch_err)
			})
			.finally(()=>{
				setLoader(false)
			})

		}else{
			const xPostData ={
				"section":secID,
			}
			fetch(apiBaseURL+apiURL.getTopics,{
				method: "POST",
				headers: {'Key' : apiKey},
				body:JSON.stringify(xPostData)
			})
			.then(responce => responce.json())
			.then((topicData)=>{
				if(topicData.status=="success"){
					setTopicList(topicData.data.topics)
				}else{
					setTopicList([])
					alert(topicData.message)
				}
			})
			.catch((catch_err)=>{
				setTopicList([])
				alert(catch_err)
			})
			.finally(()=>{
				setLoader(false)
			})
		}

		if(isSubject=="1"){
		   setsubject(true)
		   settopics(false)
	
		}else{
			setsubject(false)
			settopics(true)
		}
  }
// get chapters list  function

  // get section function
  function getSectionAssignOfStudent(){
	const xPostData ={
		"classID":12,
		"schoolCode":userData.schoolCode,
		"studentID":userData.userRefID
	}
	setLoader(true)
	fetch(apiBaseURL+apiURL.getAssignedSection,{
		method : 'POST',
		headers: {'Key':apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json() )
	.then((sectionData)=>{
		if(sectionData.status=="success"){
			setSection(sectionData.data.assignedSections)
		}else{
			setSection([])
			alert(sectionData.message)
		}
	})
	.catch((catch_err)=>{
		setSection([])
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})
}
  // get section function


  function getSectionTopic(sectionID){
	  if(sectionID==3){
		const xPostData ={
			"section":sectionID
		}
		setLoader(true)
		fetch(apiBaseURL+apiURL.getTopics,{
			method : 'POST',
			headers: {'Key':apiKey},
			body:JSON.stringify(xPostData)
		})
		.then(responce => responce.json() )
		.then((topicData)=>{
			if(topicData.status=="success"){
				setTopicList(topicData.data.topics)
			}else{
				setTopicList([])
				alert(topicData.message)
			}
		})
		.catch((catch_err)=>{
			setTopicList([])
			alert(catch_err)
		})
		.finally(()=>{
			setLoader(false)
		})
	  }
  }
//   subject hendler start
function subjectHendler(subjectID){
	setLoader(true)
	const xPostData ={
		"section":checkBtn,
		"subject":subjectID
	}
	fetch(apiBaseURL+apiURL.getTopics,{
		method : 'POST',
		headers: {'Key':apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json() )
	.then((topicData)=>{
		if(topicData.status=="success"){
			setTopicList(topicData.data.topics)
			setAllData((prev)=>{
				return {...prev,subjectId:subjectID}
			})
			settopics(true)
		}else{
			setTopicList([])
			alert(topicData.message)
		}
	})
	.catch((catch_err)=>{
		setTopicList([])
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})
}

function getChapHendler(subjectID){
	
	setLoader(true)
	const xPostData ={
		"section":checkBtn,
		"classID":12,
		"subjectID":subjectID
	}
	fetch(apiBaseURL+apiURL.getChapterList,{
		method : 'POST',
		headers: {'Key':apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json() )
	.then((chapData)=>{
		if(chapData.status=="success"){
			setChapterList(chapData.data.chapters)
			setAllData((prev)=>{
				return {...prev,subjectId:subjectID,subTopicID:''}
			})
			setChapter(true)
		}else{
			setChapterList([])
			alert(chapData.message)
		}
	})
	.catch((catch_err)=>{
		setChapterList([])
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})
}

function topicHandler(topicId, sectionType){
	setAllData((prev)=>{
		return {...prev, subTopicID:''}
	})
	setBtn(true)
	if(sectionType=="section1"){
		setAllData((prev)=>{
			return {...prev,topicId: topicId,chapID:''}
		})
		if(topicId==1 || topicId==2){
			setLoader(true)
				const xPostData ={
					"topicID":topicId
				}
				fetch(apiBaseURL+apiURL.getSubTopics,{
					method : 'POST',
					headers: {'Key':apiKey},
					body:JSON.stringify(xPostData)
				})
				.then(responce => responce.json() )
				.then((subTopic)=>{
					if(subTopic.status=="success"){
						setSubTopicData(subTopic.data.subTopics)
						setSubTopic(true)
						setBtn(false)
					}else{
						setSubTopicData([])
						alert(subTopic.message)
					}
				})
				.catch((catch_err)=>{
					setSubTopicData([])
					alert(catch_err)
				})
				.finally(()=>{
					setLoader(false)
				})
		}else{
			setSubTopic(false)
		}

	}
	if(sectionType=="section3"){
		setAllData([])
		setAllData((prev)=>{
			return {...prev,topicId: topicId,chapID:'',subjectId:''}
		})
		if(topicId!=7){
			setLoader(true)
				const xPostData ={
					"topicID":topicId
				}
				fetch(apiBaseURL+apiURL.getSubTopics,{
					method : 'POST',
					headers: {'Key':apiKey},
					body:JSON.stringify(xPostData)
				})
				.then(responce => responce.json() )
				.then((subTopic)=>{
					if(subTopic.status=="success"){
						setSubTopicData(subTopic.data.subTopics)
						setSubTopic(true)
						setBtn(false)
					}else{
						setSubTopicData([])
						alert(subTopic.message)
					}
				})
				.catch((catch_err)=>{
					setSubTopicData([])
					alert(catch_err)
				})
				.finally(()=>{
					setLoader(false)
				})
		}else{
			setSubTopic(false)
		}
	}
	
}

function subTopicHandler(subTopicID){
	
	setAllData((prev)=>{
		return {...prev, subTopicID:subTopicID}
	})
	setBtn(true)
}

function chapHandler(chapId){
	setBtn(true)
	setAllData((prev)=>{
		return {...prev,chapID: chapId,topicId:''}
	})
}

function languageHandler(langID){
	setBtn(true)
	setAllData((prev)=>{
		return {...prev,languageID: langID}
	})
}


const startTopicWisePracticeHandler = (val) => {

	Swal.fire({
		title: 'Are you sure ?',
		html: 'Do you really want to Proceed ? <br> Note: This action will Successfully .',
		icon: 'question',
		confirmButtonText: 'Yes',
		confirmButtonColor: '#d33',
		focusCancel: true,
		showCancelButton: true,
		showLoaderOnConfirm: true,
		preConfirm: () => {
			if(val=="topic"){
				const conf = {
					method:'post',
					headers:{'key':apiKey},
					body:JSON.stringify({'section':checkBtn,'subjectID':allData.subjectId,"topicID":allData.topicId})
				};
			}else if(val=="topic3"){
				const conf = {
					method:'post',
					headers:{'key':apiKey},
					body:JSON.stringify({'section':checkBtn,"topicID":allData.topicId})
				};
			}else{
				const conf = {
					method:'post',
					headers:{'key':apiKey},
					body:JSON.stringify({'section':checkBtn,'subjectID':allData.subjectId,"chapterID":allData.chapID})
				};
			}
			
			// setLoader(true);
			// return fetch( apiBaseURL+apiURL.deletePassage, conf )
			// 		.then( response => response.json() )
			// 		.then( ( result ) => {
			// 			if( result.status!=='success' ) {
			// 				throw new Error(result.message)
			// 			}
			// 			// getPassages();
			// 			return result.message;
			// 		} )
			// 		.catch( (error) => {
			// 			Swal.showValidationMessage("failed");
			// 		} )
		},
		allowOutsideClick: () => !Swal.isLoading()
	}).then( (r) => {

		if( r.isConfirmed ){
			Swal.fire(
				'Success', 'Successfully Done', 'success'
			);
		}

	} );
}


//   subject hendler end





function getChapters(subjectID, isChapter){
	setLoader(true)
	const xPostData ={
		"classID":12,
		"subjectID":subjectID
	}
	fetch(apiBaseURL+apiURL.getChaptersList,{
		method:"POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((chapterData)=>{
		if(chapterData.status=="success"){
			setChapterList(chapterData.data.chapters)
		}else{
			setChapterList([])
			alert(chapterData.message)
		}
	})
	.catch((catch_err)=>{
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})

	if(isChapter=true){
		setChapter(true)
	}else{
		setChapter(false)
	}
}

function mockTesHandler(questionType){
	setAllData((prev)=>{
		return {...prev, schoolBankType:questionType}
	})
}

function TopicWiseQuestion(){
	if(schoolBankTypeData==undefined && userData.schoolCode!="SWASCH1"){
		setErrPopup((prev)=>{
			return {...prev, status:true, msg:"Please select School Bank Type"}
		})
		return
	}
	let assessmentID = 0;
	let assessmentName = ''
	let schoolBankType= ''

	Swal.fire({
		// title: 'Are you sure ?',
		html: 'Before proceeding further make sure you have read all general instructions carefully. <br/><br/>Do you really want to proceed ? <br> ',
		// icon: 'question',
		confirmButtonText: 'Yes',
		confirmButtonColor: '#198754',
		focusCancel: true,
		showCancelButton: true,
		showLoaderOnConfirm: true,
		preConfirm: () => {
			
			const conf = {
				method:'post',
				headers:{'key':apiKey},
				body:JSON.stringify({'classID':12,'schoolCode':userData.schoolCode,"userRefID" : userData.userRefID,"sectionID":checkBtn,"subjectID":allData.subjectId ,"topicID":allData.topicId,"subTopicID":allData.subTopicID ? allData.subTopicID : "" ,"chapterID":allData.chapID,"classID":12, "schoolBankType":(userData.schoolCode=="SWASCH1") ? 1 : schoolBankTypeData})
			};
			
			setLoader(true);
			return fetch( apiBaseURL+apiURL.topicChapterWisePracticeExam, conf )
					.then( response => response.json() )
					.then( ( result ) => {
						if( result.status=='success' ) {
							// alert("ok")
							// setSecIdWithSubject((prev)=>{
							// 	return {...prev,assessmentID:result.data.assessmentID}
							// })
							setAssID(result.data.assessmentID)
							assessmentID = result.data.assessmentID;
							assessmentName = result.data.assessmentName;
							schoolBankType = result.data.schoolBankType;
							setLoader(false);
						}else{
							return result.message;
						}
						
					} )
					.catch( (error) => {
						Swal.showValidationMessage("failed");
					} )
		},
		allowOutsideClick: () => !Swal.isLoading()
	}).then( (r) => {
		if( r.isConfirmed ){
			if(assessmentID!=0){
				// Swal.fire(
				// 	'Success', 'Successfully Done.', 'success'
				// );
				// let assessmentID = secIdWithSubject.assessmentID;
				const attemptURL = {
					pathname: RouteURL.studentAttemptScreenPage+"/"+assessmentID+"/"+assessmentName+"/"+schoolBankType
				}
				window.open(attemptURL.pathname, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=2000px,height=1124")
			}else{
				setLoader(false);
				Swal.fire(
					'failed', ' Questions are not available.', 'warning'
				);
			}
		
			
		}

	} );
	
}  
  return (
   <div className={`${StuDashBoardCss.Backbody}`}>
        
		{showInstructionOn && (
        <>
			<div className={`${StuDashBoardCss.addMoreSubjectBg_instruction}`}>
				<div className={`${StuDashBoardCss.generalInstructionBg_instruction}`}>
					<div className={`${StuDashBoardCss.headingBk}`}>
						<Row>
							<Col>
								<div className={`${StuDashBoardCss.SectionHeading}`}>General Instructions</div>
							</Col>
							<Col>
								<CancelIcon	onClick={() => {closeCartBox()}} className={`${StuDashBoardCss.crossbutton}`}/>
							</Col>
						</Row>
					</div>
					<div className={`${StuDashBoardCss.subjectBody_instruction}`}>
						<h5>Topic/Chapter Wise Practice </h5>
						<ol>
							<li>You can review answers of attempted questions only.</li>
							<li>Each question carries 5 marks. There is a negative marking of 1 mark  for every wrong answer.</li>
						</ol>
					</div>
				</div>
			</div>
        </>
        )}

      <Header/>
      <LeftMenu/>
      <div className={`${StuDashBoardCss.backdiv}`}>
        <div className={`${StuDashBoardCss.RowCOlm}`}>
             <div className={`${StuDashBoardCss.headingPractice}`}>Topic/Chapter Wise Practice 
			 	<div className={`${StuDashBoardCss.headingHelpIcon}`} onClick={() => {setInstructionOn(true);}}>General Instructions <i class="fa"> <InfoIcon /> </i></div>
			</div>
			{/* <Row>
				<Col>
					<label>Select Question Bank</label>
					<select className='form-control' onChange={(e)=>{setSchoolBankType(e.target.value); mockTesHandler(e.target.value)}}>
						<option hidden>Select</option>
						<option value="1">Forever Question Bank</option>
						{userData.schoolCode=="SWASCH1" ? "" : <option value="2">School/Institution Question Bank</option>}
					</select>
				
				</Col>
			</Row> */}
			{userData.schoolCode!="SWASCH1" &&
			<Row>
				<Col>
					<label>Select Question Bank</label>
					<select className='form-control' onChange={(e)=>{setSchoolBankType(e.target.value); mockTesHandler(e.target.value)}}>
						<option hidden>Select</option>
						<option value="1">Forever Question Bank</option>
						<option value="2">School/Institution Question Bank</option>
					</select>
				
				</Col>
			</Row>
			}
			
        </div>
		
		

		
        
      {sltSectionA  && 
        <>
        {showSection.map((item)=>{
          return(
            <FormControlLabel className={ `mt-3 ${StuDashBoardCss.sectionBtndiv}`} checked={checkBtn==item.sectionID} name='section' value="sction" control={<Radio />} label={item.sectionName} onClick={()=>{checkBtnData(item.sectionID,item.isSubject); setBtn(false)}}/>
          )
        })}
        </>
      }
        <hr/>
        <Container fluid>

       {/* section A start*/}
        {showSection_I &&
			<form  className={`${StuDashBoardCss.TopicWisePractice}`}>
				<Row>

					{/* {subject && 
						<Col >
							<label> Language </label>
							<select required className={`${StuDashBoardCss.selectSubject}`} onChange={()=>{subjectHendlerSec_1(showSubjectList[0].sectionIDs)}}>
								<option className={`d-none`}>Select </option>
								{showSubjectList.map((item, index)=>{
									return(
										<option key={index} value={item.subjectID}>{item.subjectName}</option>
									)
								})}
							</select>
						</Col>
					} */}

					{subject && 
						<Col >
							<label> Language </label>
							<select required className={`${StuDashBoardCss.selectSubject}`} onChange={(e)=>{subjectHendler(e.target.value)}}>
								<option className={`d-none`}>Select </option>
								{showSubjectList.map((item, index)=>{
									return(
										<option key={index} value={item.subjectID}>{item.subjectName}</option>
									)
								})}
							</select>
						</Col>
					}

					{topics &&
					<Col>
						<label>Topic</label>
						<select required className={`${StuDashBoardCss.selectSubject}`} onChange={(event)=>{topicHandler(event.target.value,"section1")}}>
							<option className={`d-none`}>Select</option>
							{showTopicList.map((item)=>{
								return(
									<>
										<option value={item.questionCatID}>{item.questionCatDesc}</option>
									</>
								)
							})}
						</select>
					</Col>
					}

					{subTopic &&
					<Col>
						<label>Sub Topic</label>
						<select required className={`${StuDashBoardCss.selectSubject}`} onChange={(event)=>{subTopicHandler(event.target.value,"section1")}}>
							<option className={`d-none`}>Select</option>
							{getSubTopic.map((item)=>{
								return(
									<>
										<option value={item.subTopicID}>{item.subTopicDesc}</option>
									</>
								)
							})}
						</select>
					</Col>
					}


					{btn &&
					<Col className='mt-4'>
						{/* <button type='button' onClick={()=>{startTopicWisePracticeHandler("topic")}} className='btn btn-success mt-2'>Start</button> */}
						<button type='button' onClick={()=>{TopicWiseQuestion()}} className='btn btn-success mt-2'>Start</button>
						
					</Col>
					}
				</Row>
			</form>
           }

		   {/* this is for section 2 */}

		   {showSection_II &&
			<div className={`${StuDashBoardCss.TopicWisePractice}`}>
				<Row>

					{subject && 
						<Col >
							<label>Subject</label>
							<select required className={`${StuDashBoardCss.selectSubject}`} onChange={(e)=>getChapHendler(e.target.value)}>
								<option className={`d-none`}>Select </option>
								{showSubjectList.map((item, index)=>{
									return(
										<option key={index} value={item.subjectID}>{item.subjectName}</option>
									)
								})}
							</select>
						</Col>
					}

					{chapter &&
					<Col>
						<label>Chapters</label>
						<select required className={`${StuDashBoardCss.selectSubject}`} onChange={(event)=>{chapHandler(event.target.value)}}>
							<option className={`d-none`}>Select</option>
							{showChapter.map((item)=>{
								return(
									<>
										<option value={item.chapterID}>{item.chapterName}</option>
									</>
								)
							})}
						</select>
					</Col>
					}

					{btn &&
					<Col className='mt-4'>
						<button type='button' onClick={()=>{TopicWiseQuestion()}} className='btn btn-success mt-2'>Start</button>
					</Col>
					}
				</Row>
			</div>
           }

		   {/* section 3 part */}

		   {showSection_III &&
			<div className={`${StuDashBoardCss.TopicWisePractice}`}>
				<Row>
					{topics &&
					<Col>
						<label>Topic</label>
						<select required className={`${StuDashBoardCss.selectSubject}`} onChange={(event)=>{topicHandler(event.target.value,"section3")}}>
							<option className={`d-none`}>Select</option>
							{showTopicList.map((item)=>{
								return(
									<>
										<option value={item.questionCatID}>{item.questionCatDesc}</option>
									</>
								)
							})}
						</select>
					</Col>
					}
					{subTopic &&
					<Col>
						<label>Sub Topic</label>
						<select required className={`${StuDashBoardCss.selectSubject}`} onChange={(event)=>{subTopicHandler(event.target.value,"section3")}}>
							<option className={`d-none`}>Select SubTopic</option>
							{getSubTopic.map((item)=>{
								return(
									<>
										<option value={item.subTopicID}>{item.subTopicDesc}</option>
									</>
								)
							})}
						</select>
					</Col>
					}

					{btn &&
					<Col className='mt-4'>
						<button type='button' onClick={()=>{TopicWiseQuestion()}} className='btn btn-success mt-2'>Start</button>
					</Col>
					}
				</Row>
			</div>
           }
           {/* section A end*/}

        </Container>
      </div>
      {/* err popup */}
		{showErrPopup.status && 
		<div className={`${StuDashBoardCss.popupBack}`}>
			<div className={`${StuDashBoardCss.errBox}`}>
				<CloseIcon className={`${StuDashBoardCss.crossPopup}`} onClick={()=>{setErrPopup(false)}}/>
				<Alert severity="error">
					{showErrPopup.msg}
				</Alert>
			</div>       
		</div>
		}
    {/* err popup */}

	{/* Loader start */}
		{showLoader &&
			<Loader/>
		}
	{/* Loader end */}
    </div>
  
  );
}

export default StudentDash;

import React, { useState, useEffect, useContext } from 'react';
import managQueCss from './ManageQue.module.css';

import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import CloseIcon from '@mui/icons-material/Close';

import {ckFinderURL} from '../../constant';

const ckEditorConfiguration = {
    toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'superscript', 'subscript', '|', 'alignment', 'insertTable', 'bulletedList', 'numberedList', '|', 'uploadImage', 'specialCharacters', 'MathType', 'ChemType'],
    ckfinder: {
        uploadUrl: ckFinderURL
    }
};


function EditPassagePopupPage({isLoaderActive,quesProcessingData, ckeditorHandler, updatePassageHandler, editPassageClosePopupHandler}) {

   
    // const [isLoaderActive,setIsLoaderActive] = useState(true);
    // const [ckInit,setCkInit] = useState(false);

    // const ckInitHandler = ( stats ) => {
    //     if(stats){
    //         setIsLoaderActive(false);
    //     }
    //     setCkInit(stats);
    // };

    
  return (

    <div className={`${managQueCss.opupBox}`} onClick={editPassageClosePopupHandler}>
        <div className={`${managQueCss.crossBtn}`} onClick={ editPassageClosePopupHandler }>
          <CloseIcon className={`${managQueCss.cancleBtn}`}/>
        </div>
      <div className={`${managQueCss.addqueForm}`}onClick={(event)=>{event.stopPropagation();}}>

        <div className={`${managQueCss.queTextBox}`}>
            <div className={`${managQueCss.lableText}`}><p>New Passage Text</p></div>
            <div className={`${managQueCss.ckEditorBox}`}>
                <CKEditor 
                    data={quesProcessingData.quesParagraph}
                    editor={ClassicEditor}
                    config={ckEditorConfiguration}
                    onReady={(editor) => {
                        // console.log('init')
                    }}
                    onChange={ (e, editor) => {
                        ckeditorHandler( 'quesParagraph', editor.getData() );
                    } }
                />
            </div>
        </div>
        <div className={`${managQueCss.saveQueBtn}`}>
            <button 
                className='btn btn-success my-4' 
                onClick={()=>{updatePassageHandler()}}
            >
                Save Passage
            </button>
        </div>

        { isLoaderActive && 
            <div className={managQueCss.loaderClass}>
                <div className="spinner-border text-primary" role="status"></div>
            </div>
        }

      </div>
  </div>
  );
}

export default EditPassagePopupPage;

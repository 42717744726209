import React,{useEffect,useContext} from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Helmet} from "react-helmet";
// import userContext from "../../Store";


function ScreenMCQ({userData,EditQueCss,questionData,currentPage,totalQuestions,editQuesSegregationButtonHandler,editQuesButtonHandler,deleteQuesButtonHandler}){
    // console.log( questionData );
    // const {userData} = useContext(userContext);
    return(
        <div className={`${EditQueCss.questionContainer}`}>
            <div className={`${EditQueCss.queTopBar}`}>
                <div className={`${EditQueCss.queId}`}>
                    <div className={`${EditQueCss.idBox}`}>QUI:{questionData.questionID}</div>
                </div>
                <div className={`${EditQueCss.quiTypeHead}`}>
                    <h4>MCQ Question:{currentPage}/{totalQuestions}</h4>
                    <p className="mb-0">Bank Type : { questionData.questionTypeDesc }</p>
                </div>
                <div className={`${EditQueCss.editDeleteBtn}`}>
                    { (( userData.userRole.includes('generate') || userData.userRole.includes('all')) && userData.schoolCode=="SWASCH1") &&
                        <button 
                            className={`btn btn-dark btn-sm m-1`}
                            onClick={ ()=>{
                                editQuesSegregationButtonHandler(true,questionData);
                            } }
                        >
                            Modify Segregation 
                        </button>
                    }
                    { ( userData.userRole.includes('edit') || userData.userRole.includes('all') ) &&
                        <button 
                            className={`btn btn-primary btn-sm m-1`}
                            onClick={ ()=>{
                                editQuesButtonHandler(true,questionData);
                            } }
                        >
                            Edit
                        </button>
                    }
                    { (( userData.userRole.includes('delete') || userData.userRole.includes('all') ) && userData.schoolCode=="SWASCH1") &&
                        <button 
                            className="btn btn-secondary btn-sm m-1"
                            onClick={ () => {
                                deleteQuesButtonHandler(questionData.questionID);
                            } }
                        >
                            Delete
                        </button>
                    }
                </div>
            </div>

            {questionData.passageDetail!='' && questionData.passageDetail!=null &&

                <div className={EditQueCss.quesPassage}>
                    <div className={`${EditQueCss.QuestionNo}`}>Passage: </div>
                    <div dangerouslySetInnerHTML={{__html: questionData.passageDetail.paragraphText}}></div>
                </div>
            }

            <div className={`${EditQueCss.rowGrid}`}>
                <div className={`${EditQueCss.colFixed}`}>
                    <div className={`${EditQueCss.QuestionNo}`}>Question: </div>
                </div>
                <div className={`${EditQueCss.questionDiv}`}>
                    <b dangerouslySetInnerHTML={{__html: questionData.questionText}}></b>
                    <div className={`${EditQueCss.SpaceVar}`}></div>
                    {/* <div>
                        Assertion: Self-pollen cannot effect fertilisation in
                        self-incompatible pistil.
                    </div>
                    <div>
                        Reason : Self-incompatibility prevent the germination of plllen
                        grains of retard growth of pollen tube.
                    </div> */}
                    <div className={`${EditQueCss.SpaceVar}`}></div>
                    <div className={`${EditQueCss.SpaceVar}`}></div>
                    <Row>
                        <Col className="col-md-6 col-sm-12 col-12">
                            <label>
                                <div className={`${EditQueCss.optionsRow}`}>
                                    <div className={`${EditQueCss.divOne}`}>
                                        <input 
                                            type="radio" 
                                            name="options" 
                                            value='1'
                                            checked={questionData.correctAnswerIDs==1 ? true : false}
                                            disabled
                                        /> A.
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: questionData.optionText1}}></div>
                                </div>
                            </label>
                        </Col>
                        <Col className="col-md-6 col-sm-12 col-12">
                            <label>
                                <div className={`${EditQueCss.optionsRow}`}>
                                    <div className={`${EditQueCss.divOne}`}>
                                        <input 
                                            type="radio" 
                                            name="options" 
                                            value='2'
                                            checked={questionData.correctAnswerIDs==2 ? true : false}
                                            disabled
                                        /> B.
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: questionData.optionText2}}></div>
                                </div>
                            </label>
                        </Col>
                        <Col className="col-md-6 col-sm-12 col-12">
                            <label>
                                <div className={`${EditQueCss.optionsRow}`}>
                                    <div className={`${EditQueCss.divOne}`}>
                                        <input 
                                            type="radio" 
                                            name="options" 
                                            value='3'
                                            checked={questionData.correctAnswerIDs==3 ? true : false}
                                            disabled
                                        /> C.
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: questionData.optionText3}}></div>
                                </div>
                            </label>
                        </Col>
                        <Col className="col-md-6 col-sm-12 col-12">
                            <label>
                                <div className={`${EditQueCss.optionsRow}`}>
                                    <div className={`${EditQueCss.divOne}`}>
                                        <input 
                                            type="radio" 
                                            name="options" 
                                            value='4'
                                            checked={questionData.correctAnswerIDs==4 ? true : false}
                                            disabled
                                        /> D.
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: questionData.optionText4}}></div>
                                </div>
                            </label>
                        </Col>
                    </Row>
                </div>
                {/* { questionData.questionExplanation!='' && 

                    <div>
                        <div className={`${EditQueCss.QuestionNo}`}>Explanation1111: </div>
                        <div dangerouslySetInnerHTML={{__html: questionData.questionExplanation}}></div>
                    </div>

                } */}
            </div>
            
            {questionData.questionExplanation!='' &&

                <div className={EditQueCss.quesExplanation}>
                    <div className={`${EditQueCss.QuestionNo}`}>Explanation: </div>
                    <div dangerouslySetInnerHTML={{__html: questionData.questionExplanation}}></div>
                </div>
            }

            { userData.userRole.includes('all') &&
                <div className={`${EditQueCss.queEditInfo}`}>
                    <div className={`${EditQueCss.queInfoHead}`}>Question Information:</div>
                    <div className={`${EditQueCss.tableDiv}`}>
                        <table className={`${EditQueCss.queTable}`}>
                            <thead>
                                <tr>
                                    <th>Added By:</th>
                                    <th>Added On:</th>
                                    <th>Edited By:</th>
                                    <th>Edited On:</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{questionData.addByDetail.firstName}</td>
                                    <td>{questionData.addedDate}</td>
                                    {questionData.questionUpdatedBy >= 1 &&
                                        <>
                                            <td>{questionData.updatedByDetail.firstName}</td>
                                            <td>{questionData.questionUpdatedDate}</td>
                                        </>
                                    }
                                    {!(questionData.questionUpdatedBy >= 1) &&
                                        <>
                                            <td>--</td>
                                            <td>--</td>
                                        </>
                                    }
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            }

            <Helmet>
                {/* <script async src='https://www.swaadhyayan.com/pariksha/public/wiris/integration/WIRISplugins.js?viewer=image'></script> */}
                {/* <script type="text/javascript" src='https://demoiz.com/wiris/integration/WIRISplugins.js?viewer=image'></script> */}
            </Helmet>
            
            {/* <script type="text/javascript" async src='https://demoiz.com/wiris/integration/WIRISplugins.js?viewer=image'></script> */}

        </div>
    );


}

export default ScreenMCQ;
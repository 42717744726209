import StuDashBoardCss from './stuDashboard.module.css';
import LeftMenu from './LeftMenu';
import Header from './header';
import withoutTime from '../../assets/self_asmt.png';
import withTime from '../../assets/withTime.png';
import Radio from '@mui/material/Radio';
import {Container, Row, Col } from 'react-bootstrap';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useEffect, useState,useContext } from 'react';
import {apiBaseURL,apiURL,apiKey,RouteURL} from '../../constant';
import userContext from '../../Store';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import Loader from "../../Loader";
import FormControlLabel from '@mui/material/FormControlLabel';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import Swal from 'sweetalert2';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from "@mui/icons-material/Cancel";
function ExaminationPaper() {

  const[showLoader, setLoader]=useState(false)
  const {userData} = useContext(userContext);
  const[showSection, setSection]=useState([]);
  //const[showSectionWithoutTime, setSectionWithoutTime]=useState(false)
  //---------------------------/ ani \----------------------------------
  const[showSectionWithoutTime, setSectionWithoutTime]=useState(true)
  //---------------------------\ ani /----------------------------------

  const[showSectionWiseWithTime, setSectionWiseWithTime]=useState(false)
  const[showConfirmPop, setConfirmPop]=useState(false)
  const[language, setlanguage]=useState(false);
  const[subject, setsubject]=useState(false);

  const[generateButton, setgenerateButton]=useState(false)
  const[showSubjectList, setSubjectList]=useState([]);
  const[sectionID,setSectionID] = useState({sectionID:"",subjectID:""});
  //const[examTimeBase , setExamTimeBase] = useState('')
  //---------------------------/ ani \----------------------------------
  const[examTimeBase , setExamTimeBase] = useState('withOutTime')
  //---------------------------------------------------------
  const[yearData, setyearData] = useState(2023);
  //--------------------------\ ani /-------------------------------


  const[paperBUtton, setpaperBUtton]= useState(false)
  const[allSet, setAllSet] = useState([])
  const [showInstructionOn, setInstructionOn] = useState(false);
	function closeCartBox(){	
		setInstructionOn(false);	
	}
  
	useEffect(()=>{
		getSectionAssignOfStudent();
	},[])



// with out time section  code
function checkBtnData(secID){
	setCheckedBtn2([])
    setCheckedBtn(secID)

  if(secID==1){
	setSectionID([]);
	setlanguage(true);
	setsubject(false)
	setgenerateButton(false)
	setSectionID((prev)=>{
		return {...prev,sectionID:secID}
	})

  }else if(secID==2) {
	setSectionID([]);
	setsubject(true)
	setlanguage(false);
	setgenerateButton(false)
	setSectionID((prev)=>{
		return {...prev,sectionID:secID}
	})
  }else if(secID==3){
	setSectionID([]);
	setlanguage(false);
	setsubject(false)
	setConfirmPop(true)
	setgenerateButton(false)
	// setSectionID((prev)=>{
	// 	return {...prev,sectionID:secID}
	// })
	ExamPaperYearWise( secID);
  }else{
    setlanguage(false);
	setsubject(false);
	return false
  }

  if(secID==1 || secID==2){
	const xPostData ={
		"section":secID	,
		"userRefID" : userData.userRefID
	}
	fetch(apiBaseURL+apiURL.getSubjectsOfSection,{
		method: "POST",
		headers:{'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((subjectData)=>{
		if(subjectData.status=="success"){
			setSubjectList(subjectData.data.subjects)
		}else{
			setSubjectList([])
			alert(subjectData.message)
		}
	})
	.catch((catch_err)=>{
		setSubjectList([])
		alert(catch_err)
	})
  }
}
// with out time section code

// With Time section data button start
// function checkBtnSecWiseWith_Time(secID){
// 	setCheckedBtn2(secID)
// 	if(secID==1){
// 		setsection2_I(true)
// 	}
// }
// With Time section data button end


  // Section state
  const[checkBtn, setCheckedBtn] = useState("");
  const[checkBtn2, setCheckedBtn2] = useState("");
  // Section state



// section wise
// get section function
function getSectionAssignOfStudent(){
	setLoader(true)
	const xPostData ={
		"classID":12,
		"schoolCode":userData.schoolCode,
		"studentID":userData.userRefID
	}
	fetch(apiBaseURL+apiURL.getAssignedSection,{
		method : 'POST',
    	headers: {'Key':apiKey},
   		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json() )
	.then((sectionData)=>{
		if(sectionData.status=="success"){
			setSection(sectionData.data.assignedSections)
		}else{
			setSection([])
			alert(sectionData.message)
		}
	})
	.catch((catch_err)=>{
		setSection([])
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})
}
// get section function

// language hendler start
// function languageHendler(){
// 	setgenerateButton(true)
// }
// language hendler end

// subject hendler start
function subjectHendler(subjectID){
	//console.log("ani"+subjectID);
	setgenerateButton(true)
	setSectionID((prev)=>{
		return {...prev, subjectID:subjectID}
	})
}
// subject hendler end

function generateAsse(){

}



//------------------------ / ani \ -----------------------------------
const ExamPaperYearWise = (secID) => {
	let assessmentID = 0;
	let assessmentName = ''
	Swal.fire({
		// title: 'Are you sure ?',
		html: 'Before proceeding further make sure you have read all general instructions carefully. <br/><br/>Do you really want to proceed ? <br>',
		// icon: 'question',
		confirmButtonText: 'Yes',
		confirmButtonColor: '#198754',
		focusCancel: true,
		showCancelButton: true,
		showLoaderOnConfirm: true,
		preConfirm: () => {
			const xPostData ={
				"classID":12,
				"year":yearData,
				"schoolCode":userData.schoolCode,
				"userRefID":userData.userRefID,
				"sectionID":secID ? secID : sectionID.sectionID,
				"subjectID":secID ? "" : sectionID.subjectID,
				//"questionTypeID":examTimeBase=="withOutTime" ? 4 : 3,
				"questionTypeID":6,
	
			}
			const conf = {
				method:'post',
				headers:{'key':apiKey},
				body:JSON.stringify(xPostData)
			};
			
			setLoader(true);
			return fetch( apiBaseURL+apiURL.generateExampPaperYearWise, conf )
					.then( response => response.json() )
					.then( ( result ) => {
						if( result.status=='success' ) {
							assessmentID = result.data.assessmentID;
							assessmentName = result.data.assessmentName;
							assessmentName = "examination-paper";
							setLoader(false);
						}else{
							return result.message;
						}
						
					} )
					.catch( (error) => {
						Swal.showValidationMessage("failed.");
					} )
		},
		allowOutsideClick: () => !Swal.isLoading()
	}).then( (r) => {
		// console.log(assessmentID);
		if( r.isConfirmed ){
			if(assessmentID!=0){
				// Swal.fire(
				// 	'Success', 'Successfully Done.', 'success'
				// );
				// let assessmentID = secIdWithSubject.assessmentID;
				const attemptURL = {
					pathname: RouteURL.studentAttemptPaperScreenPage+"/"+assessmentID+"/"+assessmentName+"/"+1
				}
				console.log(assessmentID);
				console.log(assessmentName);
				console.log(attemptURL.pathname);
				window.open(attemptURL.pathname, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=2000px,height=1124")
			}else{
				setLoader(false);
				Swal.fire(
					'failed..', 'Sufficient  Questions are not available..', 'warning'
				);
			}
		
			
		}

	} );
}
//----------------------- \ ani / --------------------------



  
  return (

   <div className={`${StuDashBoardCss.Backbody}`}>

		{showInstructionOn && (
        <>
			<div className={`${StuDashBoardCss.addMoreSubjectBg_instruction}`}>
				<div className={`${StuDashBoardCss.generalInstructionBg_instruction}`}>
					<div className={`${StuDashBoardCss.headingBk}`}>
						<Row>
							<Col>
								<div className={`${StuDashBoardCss.SectionHeading}`}>General Instructions</div>
							</Col>
							<Col>
								<CancelIcon	onClick={() => {closeCartBox()}} className={`${StuDashBoardCss.crossbutton}`}/>
							</Col>
						</Row>
					</div>
					<div className={`${StuDashBoardCss.subjectBody_instruction}`}>
						<h5>CUET Examination Papers </h5>
						<ol>
							<li>From three passages, only 2 passages are to be attempted. (If you attempt all three passages , then first two passages will be considered.)</li>
							<li>From literary aptitude, all 10 questions are to be attempted.</li>
							<li>From vocabulary section all 10 questions are to be attempted.</li>
							<li>Each question carries 5 marks. There is a negative marking of 1 mark for every wrong answer.</li>
							<li>You can review answers of attempted questions only.</li>
						</ol>
						{/* <h6>Section-I</h6> */}
						{/* <ol>
							<li>From three passages, only 2 passages are to be attempted. (If you attempt all three passages , then first two passages will be considered.)</li>
							<li>From literary aptitude, all 10 questions are to be attempted.</li>
							<li>From vocabulary section all 10 questions are to be attempted.</li>
							<li>Each question carries 5 marks. There is a negative marking of 1 mark for every wrong answer.</li>
							<li>You can review answers of attempted questions only.</li>
						</ol> */}
						{/* <h6>Section-II</h6>
						<p><strong>Maths</strong></p>
						<ol>
							<li>There will be one Question Paper which will contain Two Sections i.e. <strong>Section A and Section B1</strong>.</li>
							<li>Section A will have 15 questions covering Mathematics which will be compulsory for all candidates.</li>
							<li>Section B1 will have 35 questions from Mathematics out of which 25 questions need to be attempted.</li>
							<li>Each question carries 5 marks. There is a negative marking of 1 mark for every wrong answer.</li>
							<li>If you attempt more than 40 questions, then first 40 questions will be considered.</li>
							<li>You can review answers of attempted questions only.</li>
						</ol>
						<p><strong>Computer Science</strong></p>
						<ol>
							<li>There will be one Question Paper which will contain Two Sections i.e. Section A and Section B1.</li>
							<li>Section A will have 15 questions covering Computer Science which will be compulsory for all candidates.</li>
							<li>Section B1 will have 35 questions from Computer Science out of which 25 questions need to be attempted.</li>
							<li>Each question carries 5 marks. There is a negative marking of 1 mark for every wrong answer.</li>
						</ol>
						<p><strong>Physics/Chemistry/Biology/Business Studies/Accountancy/Economics/History/Geography/Pol Science</strong></p>
						<ol>
							<li>There are 50 questions in this paper.</li>
							<li>40 questions are to be attempted only.</li>
							<li>Each question carries 5 marks. There is a negative marking of 1 mark for every wrong answer.</li>
							<li>If you attempt more than 40 questions then first 40 questions will be considered.</li>
							<li>You can review answers of attempted questions only.</li>
						</ol>
						<h6>Section-III</h6>						
						<ol>
							<li>There are 75 questions in this paper.</li>
							<li>60 questions are to be attempted only.</li>
							<li>Each question carries 5 marks. There is a negative marking of 1 mark for every wrong answer.</li>
							<li>If you attempt more than 60 questions then first 60 questions will be considered.</li>
							<li>You can review answers of attempted questions only.</li>
						</ol> */}

					</div>
				</div>
			</div>
        </>
        )}

      <Header/>
      <LeftMenu/>
	  
      <div className={`${StuDashBoardCss.backdiv}`}>
        	<div className={`${StuDashBoardCss.headingPractice}`}>CUET Examination Papers
				<div className={`${StuDashBoardCss.headingHelpIcon}`} onClick={() => {setInstructionOn(true);}}>General Instructions <i class="fa"> <InfoIcon /> </i></div>
			</div>
             	<Container fluid>
					{/* ----------------------// \\ -------------------------- */}
					{/* <Row>
						<Col className="col-md-6 col-12 col-sm-12">
							<div onClick={()=>{ setSectionWithoutTime(true); {setSectionWiseWithTime(false); setExamTimeBase("withOutTime")}}} className={`${StuDashBoardCss.learnBtnSelf}`}>
								<div className={`${StuDashBoardCss.logoCircleSelf}`}><img src={withoutTime} style={{maxWidth: "100%"}} /></div>
								<div className={`${StuDashBoardCss.headingDivSelf}`} style={{backgroundColor:showSectionWithoutTime&&"#183e6a"}}>SECTION WISE </div>
								<div className={`${StuDashBoardCss.timeLimetButton}`}>without time limit</div>
								{showSectionWithoutTime && 
									<div> <ArrowDropDownIcon className={`${StuDashBoardCss.dropArrow}`}/></div>
								}
							</div>
						</Col>
						<Col className="col-md-6 col-12 col-sm-12">
							<div onClick={()=>{setSectionWiseWithTime(true); {setSectionWithoutTime(false);setgenerateButton(false); {setlanguage(false); { setsubject(false); setExamTimeBase("withTime")}}}}} className={`${StuDashBoardCss.learnBtnSelf}`}>
								<div className={`${StuDashBoardCss.logoCircleSelf}`}><img src={withTime} /></div>
								<div className={`${StuDashBoardCss.headingDivSelf}`} style={{background:showSectionWiseWithTime&&"#183e6a"}}>SECTION WISE</div>
								<div className={`${StuDashBoardCss.timeLimetButton}`}>with time limit</div>
								{showSectionWiseWithTime &&
									<div><ArrowDropDownIcon className={`${StuDashBoardCss.dropArrow}`}/></div>
								}
							</div>
						</Col>
         		 	</Row> */}
					{/* -----------------------\\ // ------------------------- */}
					{/* Show Section with out Time start */}
					<Row>
					<Col className='col-md-12 col-sm-12 col-12'>
					{showSectionWithoutTime && 
						// <div className={`${StuDashBoardCss.showSectionBack}`}>
						<div>
							{showSection.map((item)=>{
								return(
									<FormControlLabel data-atr={item.sectionID} className={`${StuDashBoardCss.sectionDivRadio}`} 
										checked={checkBtn==item.sectionID} name='section' value={item.sectionID} control={<Radio />} 
										label={`${item.sectionName}`} onChange={()=>{checkBtnData(item.sectionID)}}
									/>
								)
							})}
							<hr className='mt-5'/>
						</div>
					}
					</Col>
					</Row>
					{/* Show Section with out Time end */}

					{/* Section wise with Time start */}
					{/* ------------------------ // \\ ------------------------ */}
					{/* {showSectionWiseWithTime &&
						<div className={`${StuDashBoardCss.showSectionBack}`}>
							{showSection.map((item)=>{
								return(
									<FormControlLabel className={`${StuDashBoardCss.sectionDivRadio}`} checked={checkBtn==item.sectionID} name='section' value="section" control={<Radio />} label={item.sectionName} onChange={()=>{checkBtnData(item.sectionID)}}/>
								)
							})}
							<hr className='mt-5'/>
						</div>
					} */}
					{/* ------------------------ \\ // ------------------------ */}
					{/* Section wise with Time end */}

					{/* With Out time data sohow start */}
							{/* section I start*/}
									<form className={`${StuDashBoardCss.TopicWisePractice}`}>
									{/* ------------------------------------- */}
									{/* <Row>
										<Col className='col-md-5 col-5 col-sm-5'>
											<label>Select Year</label>
											<select className='form-control' onChange={(e)=>{setyearData(e.target.value);}}>
												<option value="2023">2023</option>
												<option value="2022">2022</option>
												
											</select>
										</Col>
										<hr/>
									</Row> */}
									{/* ------------------------------------- */}
										<Row>
											{language && 
												<>
												<Col className='col-md-5 col-5 col-sm-5'>
													<label>Select Year</label>
													<select className='form-control' onChange={(e)=>{setyearData(e.target.value);}}>
														<option value="2023">2023</option>
														<option value="2022">2022</option>
														
													</select>
												</Col>
												<Col className='col-md-5 col-sm-5 col-5'>
													<label>Language</label>
													<select required className={`${StuDashBoardCss.selectSubject}`} onChange={(e)=>subjectHendler(e.target.value)}>
														<option className={`d-none`}>Select </option>
														{showSubjectList.map((item, language)=>{
															return(
																<option key={language} value={item.subjectID}>{item.subjectName}</option>
															)
														})}
													</select>
												</Col>
												</>
											}
											{subject && 
												<>
												<Col className='col-md-5 col-5 col-sm-5'>
													<label>Select Year</label>
													<select className='form-control' onChange={(e)=>{setyearData(e.target.value);}}>
														<option value="2023">2023</option>
														<option value="2022">2022</option>
														
													</select>
												</Col>
												<Col className='col-md-5 col-sm-5 col-5'>
													<label>Subjects</label>
													<select required className={`${StuDashBoardCss.selectSubject}`} onChange={(e)=>subjectHendler(e.target.value)}>
														<option className={`d-none`}>Select </option>
														{showSubjectList.map((item, subject)=>{
															return(
																<option key={subject} value={item.subjectID}>{item.subjectName}</option>
															)
														})}
													</select>
												</Col>
												</>
											}
											{generateButton &&
												<Col className='mt-2 col-md-2 col-sm-2 col-2'>
													<button onClick={()=>{generateAsse(setConfirmPop(true)); ExamPaperYearWise()}} type='button' className='btn btn-success mt-4'> <DisplaySettingsIcon/> Generate</button>
												</Col>
											}
										</Row>
									</form>
								
							{/* section I end*/}
							
					{/* With Out time data sohow end */}


					
											
             </Container>
        </div>
        
      {/* {sltSectionA  && 
      } */}


      {/* Loader start */}
        {showLoader &&
          <Loader/>
        }
      {/* Loader end */}

	  
       
   
      </div>
  
  );
}

export default ExaminationPaper;

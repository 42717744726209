import React, {useState, useContext, useEffect, useRef } from "react";

import CodeLoginCss from "./CodeLogin.module.css";
import LoginImg2 from "../../assets/loginImg2.png";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import {useNavigate} from 'react-router-dom';
import {apiBaseURL,apiURL,apiKey,baseURL} from './../../constant';
import Alert from '@mui/material/Alert';
import userContext from '../../Store';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ManageAccountsSharpIcon from '@mui/icons-material/ManageAccountsSharp';
import { Link } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { Col, Container, Row } from "react-bootstrap";
import Register from "../register/Register";
import CloseIcon from '@mui/icons-material/Close';
import Loader from "../../Loader";

function LoginPage() {
    const navigate = useNavigate();
  
// login code  
    const[accessCode, setaccessCode]=useState("");
    const {login, error, loader, checkLogin} = useContext(userContext);
    const [showReg, setShowReg] = useState(false);
    const[showErrPopup, setErrPopup] =useState({status:false, msg:null});
    const[showLoader, setLoader]=useState(false)
    const [promoCodeData, setPromoCodeData] = useState([]);
    const inputRef = useRef("");
    const [isPromo, setPromo] =  useState(false)
    
// console.log(loader);

// login code
  useEffect( ()=>{
    window.addEventListener('popstate', (event) => {
      checkLogin(true);
    });
  },[] )

  
  // let promoCode= "";
  const shoRegister=()=>{
    if(accessCode==""){
      
        setErrPopup((prev)=>{
          return {...prev, status:true, msg:"Please Enter Promo Code"}
        })
    }else{
      // add by Ajay new
        setLoader(true)
        const xPostData ={
          "promoCode": inputRef.current.value	
        }
        fetch( apiBaseURL+apiURL.getPromoCodeData,{
            method:'POST',
            headers: { 'key':apiKey },
            body:JSON.stringify(xPostData)
        } )
            .then( response => response.json() )
            .then( (result)=>{
                setLoader(false);  
                if(result.status=='success' ){
                  setPromo(true)
                  setPromoCodeData(result.data);
                  document.getElementById("button").click()
                }else{
                  setErrPopup((prev)=>{
                    return {...prev, status:true, msg:result.message}
                  })
                }
            } ).catch( (err) => {
                setLoader(false);
            }).finally( ()=>{
                setLoader(false)
        });
      // add by new ajay
    }
  }

  const removeSpace=(val)=>{
    let newVal = val.replace(" ", "");
    setaccessCode(newVal);
  }
  return (   
    <div className={`${CodeLoginCss.container}`}>
    
      {loader && 

       <div className="loader">
           <div className="box">
               <div className="circle"></div>
           </div>
           <div className="box">
               <div className="circle"></div>
           </div>
       </div>
      
      }

    


      <div className={`${CodeLoginCss.loginDiv}`}>
        <div className={`${CodeLoginCss.loginImg2}`}>
          <img src={LoginImg2} />
        </div>
        
        
        {/* form Section start */}
        <form className={`${CodeLoginCss.loginForm}`} onSubmit={(e)=>{e.preventDefault()}}>
          <div className={`${CodeLoginCss.loginHead}`}>
            {error.status &&
                <Alert className={`${CodeLoginCss.erroBox}`}  severity="error" id="alertmsg">
                    {error.msg}
                </Alert>
            }
          </div>

          <label className={`${CodeLoginCss.lableHead}`}>
            Enter Your Promo Code:
          </label>

          <div className={`${CodeLoginCss.inputRow}`}>
            <div className={`${CodeLoginCss.icon}`}>
              <ClosedCaptionIcon className={`${CodeLoginCss.icons}`} />
            </div>

            <div className={`${CodeLoginCss.inputBox}`}>
              <input
                autoComplete="off"
                required
                name="UserName"
                type="text"
                maxlength="32"
                placeholder="Enter Promo Code"
                value={accessCode}
                ref={inputRef}
                onChange={(e)=>{setaccessCode(inputRef.current.value); removeSpace(inputRef.current.value)}}
              />
            </div>
          </div>

          <div className={`${CodeLoginCss.buttonRow}`}>
            {/* <Link to="/signup">
            <button type="button" className={`${CodeLoginCss.logButton}`}><ManageAccountsSharpIcon/> Submit </button>
            </Link> */}

            {isPromo==true ? 
                <Link to="/signup" id="button" state={{accessCode: accessCode.toUpperCase(), promoCodeData:promoCodeData}}>
                    <button type="submit" className={`${CodeLoginCss.logButton}`}><ManageAccountsSharpIcon/> Submit </button>
                </Link>
            : <button type="submit" className={`${CodeLoginCss.logButton}`} onClick={shoRegister}><ManageAccountsSharpIcon/> Submit </button>
            }
            
          </div>
        </form>


        {showErrPopup.status && 
            <div className={`${CodeLoginCss.popupBack}`}>
              <div className={`${CodeLoginCss.errBox}`}>
                <CloseIcon className={`${CodeLoginCss.crossPopup}`} onClick={()=>{setErrPopup(false)}}/>
                <Alert severity="error">
                  {showErrPopup.msg}
                </Alert>
              </div>       
            </div>
        }
        {/* form Section start */}
      </div>

        {showLoader && 
          <Loader/>
        }
      
    </div>
  );
}
export default LoginPage;

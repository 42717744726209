import React,{useState,useContext,useEffect} from 'react'
import LoginCss from './Login.module.css'
import LoginImg from '../../assets/loginImg.png'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import Alert from '@mui/material/Alert';
import userContext from '../../Store';
import Loader from "../../Loader";


function MasterLoginPage() {
    

    const[loginDetail, setLoginDetail]=useState({});
    // const [isLoaderActive,setIsLoaderActive] = useState(false);

    const inputHandleOnChange = e => setLoginDetail( (prev)=>{
        return {...prev, [e.target.name] : e.target.value }
    });

    const {login, error, loader,checkLogin} = useContext(userContext);
    
    useEffect( ()=>{
        window.addEventListener('popstate', (event) => {
            checkLogin(true);
        });
    },[] )
    
    return (
        <div className={`${LoginCss.container}`}>
            <div className={`${LoginCss.loginDiv}`}>
                <div className={`${LoginCss.loginForm}`}>
                    <form onSubmit={(e)=>{e.preventDefault(); login(loginDetail) }}>
                        <div className={`${LoginCss.form}`}>
                            <h1 className={`${LoginCss.LoginH}`}>Master Login</h1>

                            {error.status &&
                                <Alert className={`${LoginCss.erroBox}`}  severity="error" id="alertmsg">
                                    {error.msg}
                                </Alert>
                            }

                            <label className={`${LoginCss.lableHead}`}>Username</label>
                            <div className={`${LoginCss.inputRow}`}>
                                <div className={`${LoginCss.icon}`}>
                                    <AccountCircleIcon className={`${LoginCss.icons}`}/>
                                </div>
                                <div className={`${LoginCss.inputBox}`}>
                                <input 
                                    type="text" 
                                    autoComplete="off" 
                                    placeholder="Enter username"
                                    name="username" 
                                    id="username" 
                                    required 
                                    value={loginDetail.username}
                                    onChange={inputHandleOnChange}
                                />
                                </div>
                            </div>

                            <label className={`${LoginCss.lableHead}`}>Password</label>
                            <div className={`${LoginCss.inputRow}`}>
                                <div className={`${LoginCss.icon}`}>
                                    <LockIcon className={`${LoginCss.icons}`}/>
                                </div>
                                <div className={`${LoginCss.inputBox}`}>
                                <input 
                                    type="password" 
                                    autoComplete="off"
                                    placeholder="Enter Password"
                                    name="userpass" 
                                    id="userpass" 
                                    required 
                                    value={loginDetail.userpass}
                                    onChange={inputHandleOnChange}
                                />
                                </div>
                            </div>

                            <div className={`${LoginCss.btnRow}`}>
                                    <div>
                                        <button type="submit" className={`${LoginCss.logButton}`}>Login</button>
                                    </div>
                                    <div className={`${LoginCss.forText}`}>Forgot <span className={`${LoginCss.forgetPass}`}>Password</span>?</div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className={`${LoginCss.loginImg}`}>
                    <img className='invisible' src={LoginImg}/>
                </div>
            </div>
            <div className={`${LoginCss.area}`}>
                <ul className={`${LoginCss.circles}`}>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>

            {loader && <Loader/>}

        </div>
        

    );
}
export default MasterLoginPage;
import AdminDashBoardCss from './adminDashboard.module.css';
import DashBoardButton from './dashBoardButton';
import Header from './Header';

function AdminDash() {
 
  return (
    <div className={`${AdminDashBoardCss.backDiv}`}>
    {/* Header Section */}
  <div className={`${AdminDashBoardCss.rightArea}`}>
    {/* Header */}
    <Header/>
    {/* Header */}


   {/* Box Container */}
     <div className={`${AdminDashBoardCss.containerBox}`}>
       <DashBoardButton/>
      </div>
   {/* Box Container */}

 
  </div>
  {/* Right Area */}
 </div>
  );
}

export default AdminDash;

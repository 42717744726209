export const RouteURL = Object.freeze({
    'homePage' : "/",
    'guruAccount' : "/accountsguru",
    'AccountsguruRegister' :"/accountsguruRegister",
    //'GuruAccount' : "/accountsguru",
    'AccountsguruRegister' :"accountsguruRegister",
    'studentLoginPage' : "/codeLogin",
    'studentCuetPromo' : "/cuetpromo",
    'loginPage' : "/login",
    'signupPage' : "/signup",
    'masterLoginPage' : "/masterlogin",
    'adminLogin' : "/admin-login",
    'userProfile' : "/student/profile",
    'adminDashboard' : "/admin",


    //--------------------------- / ani \--------------------------
    'ExaminationPaper' : "/student/examination-paper",
    'Aboutus' : "/aboutus",
    'Contactus' : "/contactus",
    'Faq' : "/faq",
    'Disclaimer' : "/disclaimer",
    'Privacypolicy' : "/privacypolicy",
    'Concellationrefundpolicy' : "/concellationrefundpolicy",
    'Products' : "/products",
    'ProductServicePaymentFlow' : "/productServicePaymentFlow",
    'TermCondition' : "/termcondition",
    //'Blog' : "/blog",

    //---------------------------- \ ani /--------------------------

    // 'manageQschool' : "/admin/manage-question",
    'ManageStudent_Admin' : "/admin/manage-student",
    'adminRepott' : "/admin/report",
    'adminGenerateExam' : "/admin/generate-exam",
    'dtpDashboardPage' : "/dtp",
    'dtpGeneratePracticeSet' : "/dtp/generate-practice-set",
    'studentDashboard' : "/student",
    'studentWelcomePage' : "/student/welcome",
    'studentQuickNotesPage' : "/student/quickNotes",
    'studentTopicWisePracticePage' : "/student/TopicWisePractice",
    'studentPracticeSetPage' : "/student/practice-sets",
    'studentAttemptScreenPage' : "/student/attempt",
    //-------------------------- / ani \ ------------------------------
    'studentAttemptPaperScreenPage' : "/student/attemptpaper",
    //---------------------------\ ani / ------------------------------
    'studentSelfGenerteAssessmentPage' : "/student/Self-Generated-Exams",
    'mock_cuet' : "student/mock-cuet",
    'studentReport' : "/student/Report",
    'AddSubjectButton' : "/student/Add-subject",
    'addEditInst' : "/admin/addEditins",
    'addQuestion' : "/admin/manageQuestion",
    'manageStu' : "/admin/manageStu",
    'superAdmin' : "/superAdmin",
    'superAdminReport' : "/superAdmin/Report",
    'examInstructions': "/student/Exam-Instructions",
    'manage_order': "/Manage-Orders",
    'PaymentSuccess': "/Payment-success",
    'manageStuSuperAdmin': "superAdmin/manage-student",
    // 'manageDemoSuperAdmin': "superAdmin/manage-demo-user",
    'manageSchooQuestion': "/admin/manage-sch-ques"


});

//export const apiBaseURL = "http://127.0.0.1:8000/api";
//export const apiBaseURL = "http://192.168.2.234/catExamAPI/api";
//export const baseURL = "https://cucet.swaadhyayan.com/";
//export const baseURL = "https://swaadhyayan.com/cuet/";
// export const baseURL = "https://cuet.foreverbooks.co.in/";
// export const apiBaseURL = baseURL+"connect/api";
// export const apiKey = "swacatexam_web";

//export const baseURL = "http://localhost/";    // for localhost
export const baseURL = "https://cuet.rachnasagar.in/";   //  for Online
export const apiBaseURL = baseURL+"connect/api";
export const apiKey = "swacatexam_web";
export const ckFinderURL = "https://cuet.rachnasagar.in//ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json";
export const wirisMathML = "https://cuet.rachnasagar.in//wiris/integration/WIRISplugins.js?viewer=image";


// export const baseURL = "https://cuet.foreverbooks.co.in/";
// export const apiBaseURL = baseURL+"connect/api";
// export const apiKey = "swacatexam_web";
// export const ckFinderURL = "https://cuet.foreverbooks.co.in//ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json";
// export const wirisMathML = "https://cuet.foreverbooks.co.in//wiris/integration/WIRISplugins.js?viewer=image";

// export const baseURL = "https://cuet.swaadhyayan.com/";
// export const apiBaseURL = baseURL+"connect/api";
// export const apiKey = "swacatexam_web";
// export const ckFinderURL = "https://cuet.swaadhyayan.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json";
// export const wirisMathML = "https://cuet.swaadhyayan.com/wiris/integration/WIRISplugins.js?viewer=image";
export const apiURL = Object.freeze({
    'login' : "/login",
    'signup' : "/signup",
    'logout' : "/logout",
    'getSectionList' : "/getSections",
    'getStateList' : "/getStates",
    'getChapterList' : "/getChapters",
    'loginCheck':'/login-check',
    'getTopics':'/getTopicList',
    'getSubjectList':'/getSubjects',
    'getSubjectsOfSection':'/getSubjectsOfSection',
    'getChaptersList':'/getChapters',
    'getSubTopics':'/getSubTopicList',
    'updateQuestion':'/questionUpdate',
    'updatePassage':'/passageUpdate',
    'deleteQuestion':'/questionDelete',
    'createQuestion':'/questionCreate',
    'createPassage':'/passageCreate',
    'deletePassage':'/passageDelete',
    'getQuickNotes': '/getQuickNotes',
    'getPassagesList': '/getPassages',
    'searchQuestions':'/searchQuestions',
    'searchQuestionsOfSet':'/searchQuestionsOfSet',
    'getSchoolList':'/getSchoolList',
    'getUsersList':'/getUsersList',
    'schoolAdminDashboardStats':'/schoolAdminDashboardStats',
    'getSchoolTypeList':'/getSchoolTypeList',
    'allocateSubjecttoUser':'/allocateSubjecttoUser',
    'allocateSubjecttoSchool':'/allocateSubjecttoSchool',
    'updateUserDetails':'/updateUserDetails',
    'getSetsList':'/getSetsList',
    'addSchoolWithAssignSubject':'/addSchoolWithAssignSubject',
    'getQuestionBankTypes':'/getQuestionBankTypes',
    'updateQuestionTypeID':'/updateQuestionTypeID',
    'bulkUploadUser':'/bulkUploadUser',
    'getAssignedSection':'/getAssignedSection',
    'getAssignedSubjects':'/getAssignedSubjects',
    'updateAssignSectionSubjecttoUser':'/updateAssignSectionSubjecttoUser',
    'generatePracticeSet':'/generatePracticeSet',
    'getExamSetList':'/getExamSetList',
    'generatePracticeSetExam':'/generatePracticeSetExam',
    'startAssessmentAttempt':'/startAssessmentAttempt',
    "generateSelfGeneratedExam": "/generateSelfGeneratedExam",
    "mockCuetExamList" : "/mockCuetExamList",

    //------------------------/ ani \-------------------------
    "generateExampPaperYearWise": "/generateExampPaperYearWise",
    "subscribe": "/subscribe",
    //------------------------ \ ani / -------------------------------

    "attemptSubmit":"/attemptSubmit",
    "topicChapterWisePracticeExam": "/topicChapterWisePracticeExam",
    'getSchoolsInstituteList': "/getSchoolsInstituteList",
    'orderInvoice': "/orderInvoice",
    "attemptReport" : "/attemptReport",
    "viewAttemptQuestionExplanation": "/viewAttemptQuestionExplanation",
    "generateMockCuetSetExam" : "/generateMockCuetSetExam",
    "selfCreateQuestion":"/selfCreateQuestion",
    "selfSearchQuestions": "/selfSearchQuestions",
    "selfSchoolGeneratePracticeSet" : "/selfSchoolGeneratePracticeSet",
    "selfSchoolSearchQuestionsOfSet" : "/selfSchoolSearchQuestionsOfSet",
    "selfSchoolQuestionDelete": "/selfSchoolQuestionDelete",
    "selfSchoolMockCuetExamList": "/selfSchoolMockCuetExamList",
    "deleteSchool" : "/deleteSchool",
    "changeSchoolStatus": "/changeSchoolStatus",
    "studentRegistration": "/studentRegistration",
    "studentOrderUpdation": "/studentOrderUpdation",
    "paymentResponse": "/paymentResponse",
    "paytmPayment": "/paytmPayment",
    "paytmCallback": "/paytmCallback",
    "studentDelete": "/studentDelete",
    "changeStudentStatus":"/changeStudentStatus",
    "updateSchoolWithAssignSubject":"/updateSchoolWithAssignSubject",
    "studentPurchaseSectionSubject":"/studentPurchaseSectionSubject",
    "userVerification" : "/userVerification",
    "getStudentAssessmentReport": "/getStudentAssessmentReport",
    "viewStudentAssessmentReport":"/viewStudentAssessmentReport",
    "selfSchoolCreatePassage": "/selfSchoolCreatePassage",
    "getSelfSchoolPassagesList": "/getSelfSchoolPassagesList",
    "selfSchoolUpdatePassage": "/selfSchoolPassageUpdate",
    "selfSchoolUpdateQuestion": "/selfSchoolQuestionUpdate",
    "bulkUploadUserForSuperAdmin":"/bulkUploadUserForSuperAdmin",
    "getUsersOrderList": "/getUsersOrderList",
    "getPromoCodeData":"/getPromoCodeData",
    "getCountPromoCode" : "/getCountPromoCode"

});

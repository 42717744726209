import AdminDashBoardCss from './adminDashboard.module.css';
import AdminLeftMenu from './AdminLeftMenu';
import {Container, Row, Col, Table} from 'react-bootstrap';
import Header from './Header';
import { apiBaseURL, apiURL, apiKey } from "./../../constant";
import React, { useEffect, useState, useRef,useReducer,useContext } from "react";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddTaskIcon from '@mui/icons-material/AddTask';
import Pagination from "react-bootstrap/Pagination";
import Loader from '../../Loader';
import userContext from './../../Store';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BackupIcon from '@mui/icons-material/Backup';
import BUlkSample from './user.csv';
// Reducer codestart start
const reducer = (state, action)=>{
     switch (action.type){
          // student allocate case start
          case "allocateSubject":
               return{
                    showallocatePopup: (state.showallocatePopup = true)
               }
          case "closeSubjectPopup":
               return{
                    showallocatePopup: (state.showallocatePopup = false)
               }
          case "close_showEditStudentPop":
               return{
                    showEditStudentPop: (state. showEditStudentPop = false)
               }

     };
}
// Reducer codestart end




function ManageStudent_Admin() {
  const [state, dispatch ] = useReducer(reducer, {showallocatePopup : false})
  const[showEditStudentPop, editStudent]=useState(false)

  const inputBox = useRef(null);
  const table = useRef(null);

  useEffect(()=>{
    getState();
    getAssignSectionsToschool();
    getSubjectOfSection();
  },[])

  
  const[showSubjectList, setSubjectList]= useState([]);
  const[showLoader, setLoader] = useState(false);
  const {userData} = useContext(userContext);
  const[showStuList, setStuList]=useState([])
  const [showStateList, setwStateList] = useState([]);
  const [searchStudentBySubjectID,setSearchStudentBySubjectID] = useState("");
  const[showAddStuPop, setAddStuPop]=useState(false);
  const[showAssignSec, setAssignSec]=useState([]);
  const[showSubJectBox, setSubJectBox]=useState(false)

  const[showSubjectListOfSection, setSubjectListOfSection]=useState([]);


  const[bulkUploadData, setbulkUploadData]=useState({
       status:false,
       file:"",
  })
  const[showSecNSubjectPop, setSecNSubjectPop]=useState({
       status:false,
       userRefId:"",
      
  })
  function togglePopup(val){
     setbulkUploadData((prev)=>{
          return {...prev, status:val}
     })
  }
  function assignSectionNsubject(val, userRefId, subjectIds, sectionIds){
     
     setSecNSubjectPop((prev)=>{
          return {
               ...prev, 
               status:val, 
               userRefId:userRefId,
          }
     });
     let subjectIdArray = subjectIds?subjectIds.split(","):[];
     let sectionIdArray = sectionIds?sectionIds.split(","):[];
     // console.log(subjectIdArray);
     // console.log(sectionIdArray)
     setSubjectListOfSection((prev)=>{
          let oldData = [...prev];
          oldData = oldData.map((item)=>{
               let isChecked = false;
               let isDisabled = false;
               if(subjectIdArray.includes(item.subjectID.toString())){
                    isChecked = true;
                    isDisabled = true;
               }
               console.log(item.subjectID)
               return {...item, isChecked:isChecked,isDisabled:isDisabled}
          })
          console.log(oldData)
          return oldData
     })
     setAssignSec((prev)=>{
          let oldData = [...prev];
          oldData = oldData.map((item)=>{
               let isChecked = false;
               let isDisabled = false;
               if(sectionIdArray.includes(item.sectionID.toString())){
                    isChecked = true;
                    isDisabled = true;
                    if(item.isChoosable==1){
                         setSubJectBox(true)
                    }
               }
               return {...item, isChecked:isChecked,isDisabled:isDisabled}
          })
          return oldData
     })
     
  }

  function searchData() {
    let filter, tr, tds, i, txtValue;
    filter = inputBox.current.value.toUpperCase();
    tr = table.current.getElementsByTagName("tr");
    console.log(tr);
    for (i = 1; i < tr.length; i++) {
      tds = tr[i].getElementsByTagName("td");
      let isExist = false;
      for (let td of tds) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          isExist = true;
        }
      }
      if (isExist) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
  // search data functions end


const getStudentHandler = (subjectID) => {
     setSearchStudentBySubjectID( subjectID ); 
     getStudent(subjectID);
}

//   get studentList function start
   function getStudent(subjectID){
        setLoader(true)
        const xPostData = {
          "subjectID" : subjectID,
          'schoolCode':userData.schoolCode,
          "userType":3
        }

     //    console.log(xPostData)
        fetch(apiBaseURL+apiURL.getUsersList,{
             method: 'POST',
             headers: {'key': apiKey},
             body:JSON.stringify(xPostData)
        })
        .then(response => response.json())
        .then((sutdentNane)=>{
          //    console.log(sutdentNane)
          if(sutdentNane.status == "success"){
               setStuList(sutdentNane.data.users)
          }else{
               setStuList([]);
               alert( sutdentNane.message );
          }
        })
        .catch((err)=>{
               setStuList([]);
               alert(err)
        })
        .finally(()=>{
          setLoader(false)
        })
        
   }
//   get studentList function end





  // get state functions start
  function getState() {
     fetch(apiBaseURL + apiURL.getStateList, {
       method: "GET",
       headers: {'key': apiKey },
     })
     .then(response => response.json())
     .then((stateData)=>{
          if(stateData.status == "success"){
               setwStateList(stateData.data)
          }else{
               setwStateList([])
               alert(stateData.message)
          }
     })
     .catch((err)=>{
          setwStateList([])
          alert(err)
     })
   }
// get state functions end

// Bulk upload code start
const bulFile = e =>{
     setbulkUploadData((prev)=>{
          return {...prev, file:e.target.files[0]}
     })
}
// console.log(bulkUploadData.file)

function createMultiUserOnsubmit(e){
     const xPostData =  new FormData();
     xPostData.append("schoolCode", userData.schoolCode);
     xPostData.append("user_file", bulkUploadData.file)
     e.preventDefault()
     fetch(apiBaseURL+apiURL.bulkUploadUser,{
          method: "POST",
          headers: {'Key' : apiKey},
          body:xPostData
     })
     .then(response => response.json())
     .then((bulkuploData)=>{
          if(bulkuploData.status=="success"){
               alert(bulkuploData.message)
               setbulkUploadData(false)
          }else{
               alert(bulkuploData.message)

          }
     })
     .catch((err)=>{
          alert(err)
     })
}
// Bulk upload code end


//   add Student state start


const[studentAdd, setudentAdd]=useState({
     studentName: "",
     fathertName: "",
     address: "",
     studentState: "",
     pincode: "",
     contactNo: "",
     studentEmailID: "",
     dob : ""
 })
 const[showErrstu, setErrstu]=useState({status:false,errMsg:""});
 const[showSuccess, setSuccess]=useState({status:false,errMsg:""})

 const{studentName, fathertName, address, studentState, pincode, contactNo, studentEmailID, dob} = studentAdd;
 const addStudentOnchange = e =>{
     setudentAdd({...studentAdd,[e.target.name]: e.target.value});
 } 
 //   add Student state end
 
 // addStudent functions start 
  function onSubmitcreateStudent(e){
       e.preventDefault();
     setLoader(true)
     const xPostData ={
          "stuName" : studentAdd.studentName,
          "fatherName" :studentAdd.fathertName,
          "address" : studentAdd.address,
          "state" : studentAdd.studentState,
          "pinCode" :studentAdd.pincode,
          "contact" :studentAdd.contactNo,
          "email" :studentAdd.studentEmailID,
          "dob" :studentAdd.dob,
          "schoolCode" : userData.schoolCode,
          "isDemoUser":userData.isDemoUser
     }
     // console.log(xPostData)
     // console.log("%%%%%%%%%%%%")
     fetch(apiBaseURL+apiURL.signup,{
         method: 'POST',
         headers: {'key' : apiKey},
         body:JSON.stringify(xPostData)
     })
     .then(response => response.json())
     .then((createStudentData)=>{
          if(createStudentData.status == "success"){
               setudentAdd([])
               setSuccess((prev)=>{
                    return{...prev,status:true, errMsg:createStudentData.message}
               })
          }else{
               setErrstu((prev)=>{
                    return{...prev,status:true, errMsg:createStudentData.message}
               })
               // alert(createStudentData.message)
          }
     })
     .catch((err)=>{
          alert(err)
     })
     .finally(()=>{
          setLoader(false)
     })
 }
 // addStudent functions end 

//  student edit function code start 
const[studentEdit, setstudentEdit] = useState([]);

// const[edit_studentAdd, setedit_udentAdd]=useState({
    
//  })
const editStudentOnchange = e =>{
     setstudentEdit( (prev)=>{
          return {...prev, [e.target.name] : e.target.value }
     } )
 }

 function onSubmit_Edit_Student(userRefID){
     setLoader(true)
     const xPostData ={
          "stuName" : studentEdit.firstName,
          "fatherName" :studentEdit.guardianName,
          "address" : studentEdit.userAddress,
          "stateID" : studentEdit.stateCode,
          "pinCode" :studentEdit.zipCode,
          "contact" :studentEdit.userContact,
          "email" :studentEdit.userEmail,
          "dob" :studentEdit.dateOfBirth,
          "userRefID" : userRefID

          // "schoolCode" : userData.schoolCode
     }
     // console.log(xPostData)
     fetch(apiBaseURL+apiURL.updateUserDetails,{
          method: "POST",
          headers: {'Key' : apiKey},
          body:JSON.stringify(xPostData)
     })
     .then(response => response.json())
     .then((editStudent)=>{
          if(editStudent.status == "success"){
               setSuccess((prev)=>{
                    return{...prev,status:true, errMsg:"Student details updated successfully !"}
               })

          }else{
               setErrstu((prev)=>{
                    return{...prev,status:true, errMsg:editStudent.message}
               })
          }
     })
     .catch((err)=>{
          alert(err)
     })
     .finally(()=>{
          setLoader(false)
     })
 }
//  student edit function code end 

// get Section function start
function getAssignSectionsToschool(){
     const xPostData ={
          "classID":12,
          "schoolCode":userData.schoolCode
     }
     fetch(apiBaseURL+apiURL.getAssignedSection,{
          method: "POST",
          headers: {'Key' : apiKey},
          body:JSON.stringify(xPostData)
     })
     .then(response => response.json())
     .then((sectionAssignData)=>{
          if(sectionAssignData.status=="success"){
               let newData = sectionAssignData.data.assignedSections.map((item)=>{
                    return {...item, isChecked:false}
               })
               setAssignSec(newData)
          }else{
               setAssignSec([])
               alert(sectionAssignData.message)
          }
     })
     .catch((catch_err)=>{
          alert(catch_err)
     })

}
// get Section function end



// onclick showSubject start
function checkBtnData(item,index,target){

     if(item.isChoosable===1 && target.checked ){
          setSubJectBox(true)
     } else if(item.isChoosable==1) {
          setSubJectBox(false)
     }
     setAssignSec((prev)=>{
          let oldData = [...prev];
          if(oldData[index]!=undefined){
               oldData[index].isChecked = target.checked
               oldData[index].isDisabled = false
          }
          return oldData;
     })
 }
// onclick showSubject end

// onclick subjectHendler start
function subjectHendler(item,index,target){
// console.log(item)
// console.log(index)
// console.log(target)
     setSubjectListOfSection((prev)=>{
          let oldSubjectData = [...prev];
          if(oldSubjectData[index]!=undefined){
               oldSubjectData[index].isChecked = target.checked
               oldSubjectData[index].isDisabled = false;
          }          
          return oldSubjectData;          
     })
     console.log(showSubjectListOfSection);
 }
// onclick subjectHendler end

// getSection Of Section fun start 
function getSubjectOfSection(){
     const xPostData ={
          "classID":12,
          "schoolCode":userData.schoolCode
     }
     fetch(apiBaseURL+apiURL.getAssignedSubjects,{
          method: "POST",
          headers: {'Key' : apiKey},
          body:JSON.stringify(xPostData)
     })
     .then(response => response.json())
     .then((assignSubjectData)=>{
          if(assignSubjectData.status=="success"){
               let subjects = assignSubjectData.data.assignedSubjects.map((item)=>{
                    return {...item, isChecked:false,isDisabled:false}
               })
               setSubjectListOfSection(subjects)
          }else{
               setSubjectListOfSection([]) 
               alert(assignSubjectData.message)
          }
     })
     .catch((catch_err)=>{
          alert(catch_err)
     })
}
// getSection Of Section fun end 


// assign section and subjects start
function assignSectionAndSubject(e){
     e.preventDefault()
     let sections = [];
     let subject = [];

     for(let sec of showAssignSec){
          if(sec.isChecked){
               sections.push(sec.sectionID)
          }
     }
     for(let subj of showSubjectListOfSection){
            if(subj.isChecked){
               subject.push(subj.subjectID)
           }
     }
     const xPostData ={
          "schoolCode":userData.schoolCode,
          "sectionIDs":sections,
          "userRefID":showSecNSubjectPop.userRefId,
          "subjectIDs":subject
     }
     // debugger;

     console.log(xPostData)
     fetch(apiBaseURL+apiURL.updateAssignSectionSubjecttoUser,{
          method: "POST",
          headers: {'Key' : apiKey},
          body:JSON.stringify(xPostData)
     })
     .then(response => response.json())
     .then((sesctionNsubjectData)=>{
          if(sesctionNsubjectData.status=="success"){
               setSuccess((prev)=>{
                    return{...prev,status:true, errMsg:sesctionNsubjectData.message}
               })
               setSecNSubjectPop(false)
          }
          else{
               setErrstu((prev)=>{
                    return{...prev,status:true, errMsg:sesctionNsubjectData.message}
               })
               // alert(sesctionNsubjectData.message)
          }          
     })
     .catch((catch_err)=>{
          alert(catch_err)
     })
}
// assign section and subjects end










  return (
  <div>
    <div  className={`${AdminDashBoardCss.rightArea}`}>
          {/* header start */}
          <Header/>
          {/* header end */}
          
          {/* Left Menu start */}
          <AdminLeftMenu/>
          {/* Left Menu end */}
   
     <div className={`${AdminDashBoardCss.BkgenerateExam}`}>
          <Container fluid>
                <div className={`${AdminDashBoardCss.selectsubjectBg}`}>
                     <div className={`${AdminDashBoardCss.rowbcks}`}>
                         <Row>
                              <Col>
                                   <select className='form-control' onChange={(event)=>{getStudentHandler(event.target.value)}}>
                                        <option className='d-none'>Select Subject</option>
                                        <option value={''}>All student</option>
                                        {showSubjectListOfSection.map((item,index)=>{
                                             return(
                                                  <option key={index} value={item.subjectID}>{item.subjectName}</option>
                                             )
                                        })}
                                   </select>
                              </Col>

                              <Col>
                                   <div className={`${AdminDashBoardCss.inputFiled}`}>
                                        <PersonSearchIcon  className={`${AdminDashBoardCss.searchIcons}`}/>
                                        <input type="text" className="form-control" ref={inputBox} onKeyUp={searchData} placeholder="Search Student"/>
                                   </div>
                              </Col>
                         </Row>
                         </div>
                     <hr/>
                     <Row>
                          <div className={`${AdminDashBoardCss.ManageStudent}`}>
                              <div className={`${AdminDashBoardCss.tableBgs}`}>
                                   <div className={`${AdminDashBoardCss.searchOption}`}></div>
                                         <Table className={`${AdminDashBoardCss.TableManageT}`} striped  bordered  hover responsive ref={table}>
                                              <thead>
                                                  <tr>
                                                       <th>Sr No.</th>
                                                       <th>Access Code</th>
                                                       <th>Student’s Name</th>
                                                       <th>Father's Name</th>
                                                       <th>Address</th>
                                                       <th>State</th>
                                                       <th>Pincode</th>
                                                       <th>Contact No</th>
                                                       <th>School Code</th>
                                                       <th>Registration No</th>
                                                       <th>Email ID</th>
                                                       <th>Date of birth</th>
                                                       <th  className={`${AdminDashBoardCss.onscrollFUpdateUsrD}`}>Assign Section & Subjects</th>
                                                       {/* <th>Active/Inactive</th> */}
                                                       <th className={`${AdminDashBoardCss.onscrollFUpdateUsrD}`}>Edit</th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                             
                                             
                                             
                                             {showStuList.map((item,index)=>{
                                                  let itemUserDetail = {...item.userDetail};
                                                  if(itemUserDetail==null){
                                                       // console.log(item)
                                                       return;
                                                  }
                                                  return(
                                                    <tr key={index}>
                                                       <td>{index+1}</td>
                                                       <td>{item.userName}</td>
                                                       <td>{itemUserDetail.firstName+" "+itemUserDetail.middleName}</td>
                                                       <td>{itemUserDetail.guardianName}</td>
                                                       <td>{itemUserDetail.userAddress}</td>
                                                       <td>{itemUserDetail.stateName}</td>
                                                       <td>{itemUserDetail.zipCode}</td>
                                                       <td>{itemUserDetail.userContact}</td>
                                                       <td>{itemUserDetail.schoolCode}</td>
                                                       <td>{itemUserDetail.userCode}</td>
                                                       <td>{itemUserDetail.userEmail}</td>
                                                       <td>{itemUserDetail.dateOfBirth}</td>

                                                       <td className={`${AdminDashBoardCss.onscrollFAssin}`}>
                                                            <button onClick={()=>{assignSectionNsubject((true),itemUserDetail.userRefID,itemUserDetail.subjectIDs, itemUserDetail.sectionIDs,)}} className="btn btn-secondary"> <AddTaskIcon/> Assign Sections & Subjects </button>
                                                       </td>
                                                       {/* <td>
                                                            <label className={`${AdminDashBoardCss.switch}`}>
                                                                 <input type="checkbox" />
                                                                 <span className={`${AdminDashBoardCss.slider} ${AdminDashBoardCss.round}`}></span>
                                                            </label>
                                                       </td> */}
                                                       <td title="Edit Student" className={`${AdminDashBoardCss.onscrollFUpdateUsr}`}>
                                                            <EditIcon onClick={(()=>{editStudent(true); setstudentEdit(itemUserDetail);})} className={`${AdminDashBoardCss.editButton}`} />
                                                       </td>
                                                    </tr>
                                                  )
                                             })}
                                             
                                             </tbody>
                                         </Table>
                        <Row className={`${AdminDashBoardCss.rowFixbuttonsec}`}>
                         <Col>
                              <button onClick={(()=>{setAddStuPop(true)})} className="btn btn-primary"> <AddBoxIcon /> ADD STUDENT </button>{' '}
                              <a  href={BUlkSample} download className="btn btn-secondary">  <span style={{color:"#fff"}}>BULK DATA SHEET ENTRY FORM  </span> </a>{' '}
                              <button className="btn btn-info" onClick={()=>togglePopup(true)}>  BULK UPLOAD </button>{' '}
                               
                         </Col>
                    </Row>
                  </div>
               </div>
            </Row>
          </div>
        </Container>


        {/* add student popup start */}
        {showAddStuPop &&
           <div className={`${AdminDashBoardCss.popupBase}`}>
           <div className={`${AdminDashBoardCss.AllocateSubjectList}`}>
                <div className={`${AdminDashBoardCss.allo_subjact}`}>
                     <div className={`${AdminDashBoardCss.addstuHeading}`}>
                           Add Student
                     </div>
                     <form className={`${AdminDashBoardCss.addstuForm}`} onSubmit={onSubmitcreateStudent}>
                        <div className={`${AdminDashBoardCss.addStuForm}`}>

                            <div className="mt-3">
                                <label>Student Name</label>
                                <input id='stuNameFi'  name="studentName" value={studentName} onChange={e=>addStudentOnchange(e)} type="text" placeholder="Student Name" className="form-control createStuFields"/>
                            </div>

                            <div className="mt-3">
                                <label>Father's Name</label>
                                <input  name="fathertName" value={fathertName} onChange={e=>addStudentOnchange(e)}  type="text" placeholder="Father Name" className="form-control createStuFields"/>
                            </div>
                            <div className="mt-3">
                                <label>Address</label>
                                <input  name="address" value={address} onChange={e=>addStudentOnchange(e)}  type="text" placeholder="Address" className="form-control createStuFields"/>
                            </div>

                            <div className="mt-3">
                                <label>Select State </label>
                                <select name="studentState"  className="form-control createStuFields" onChange={e=>addStudentOnchange(e)}>
                                 <option className="d-none">Select</option>
                                 <option className="d-none">Select</option>
                                        {showStateList.map((item,index) => {
                                            return <option key={index} value={item.stateID}>{item.stateNameLang1}</option>
                                        })}       
                                </select>
                            </div>
                            <div className="mt-3">
                                <label>Pin code</label>
                                <input onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}   name="pincode" value={pincode} onChange={e=>addStudentOnchange(e)} type="number" placeholder="Pincode" className="form-control createStuFields"/>
                            </div>
                            <div className="mt-3">
                                <label>Contact No</label>
                                <input maxLength="12"  onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  name="contactNo" value={contactNo} onChange={e=>addStudentOnchange(e)} type="number" placeholder="Contact" className="form-control createStuFields"/>
                            </div>
                            <div className="mt-3">
                                <label>E-mail ID</label>
                                <input  name="studentEmailID" value={studentEmailID} onChange={e=>addStudentOnchange(e)} type="email" placeholder="E-mail ID" className="form-control createStuFields"/>
                            </div>
                            <div className="mt-3">
                                <label>Date of birth</label>
                                <input  name="dob" value={dob} onChange={e=>addStudentOnchange(e)} type="date" className="form-control createStuFields"/>
                            </div>
                           
                            <div className={`mt-3 ${AdminDashBoardCss.footerbuttonRow}`}>
                                <input type="submit" className={`btn btn-success ${AdminDashBoardCss.SubmitBTN}`} value="SAVE"/>   
                                <button type="reset" className="btn btn-danger">  RESET </button>
                                <button  onClick={(()=>{setAddStuPop(false); setudentAdd([])})} className="btn btn-secondary"> CLOSE </button>
                            </div>
                            </div>
                    </form>
                </div>
          </div>
           </div>
        }
        {/* add student popup end */}

      </div>
      </div>
     

      {/* if(resetData['userDetail']!=undefined){
  console.log(resetData['userDetail'].firstName)

  } */}
     {/* student edit popup start */}
        {showEditStudentPop &&
           <div className={`${AdminDashBoardCss.popupBase}`}>
           <div className={`${AdminDashBoardCss.AllocateSubjectList}`}>
                <div className={`${AdminDashBoardCss.allo_subjact}`}>
                     <div className={`${AdminDashBoardCss.addstuHeading}`}>
                         <EditIcon/> Edit Student
                     </div>
                     <form className={`${AdminDashBoardCss.addstuForm}`}>
                        <div className={`${AdminDashBoardCss.addStuForm}`}>

                            <div className="mt-3">
                                <label>Student Name</label>
                                <input required name="firstName" value={studentEdit.firstName} onChange={editStudentOnchange} type="text" placeholder="Student Name" className="form-control"/>
                            </div>

                            <div className="mt-3">
                                <label>Father's Name</label>
                                <input required name="guardianName" value={studentEdit.guardianName} onChange={editStudentOnchange}  type="text" placeholder="Father Name" className="form-control"/>
                            </div>
                            <div className="mt-3">
                                <label>Address</label>
                                <input required name="userAddress" value={studentEdit.userAddress} onChange={editStudentOnchange}  type="text" placeholder="Address" className="form-control"/>
                            </div>

                            <div className="mt-3">
                                <label>Select State </label>
                                <select name="stateName"  className="form-control" onChange={editStudentOnchange}>
                                 <option className="d-none">Select</option>
                                 <option className="d-none">Select</option>
                                        {showStateList.map((item,index) => {
                                            return <option key={index} value={item.stateID}>{item.stateNameLang1}</option>
                                        })}       
                                </select>
                            </div>
                            <div className="mt-3">
                                <label>Pin code</label>
                                <input onKeyPress={(event) => {if (!/[0-9]/.test(event.key))   {event.preventDefault();}}}  required name="zipCode" value={studentEdit.zipCode} onChange={editStudentOnchange} type="number" placeholder="Pincode" className="form-control"/>
                            </div>
                            <div className="mt-3">
                                <label>Contact No</label>
                                <input maxLength="12"  onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} required name="userContact" value={studentEdit.userContact} onChange={editStudentOnchange} type="number" placeholder="Contact" className="form-control"/>
                            </div>
                            <div className="mt-3">
                                <label>E-mail ID</label>
                                <input required name="userEmail" value={studentEdit.userEmail} onChange={editStudentOnchange} type="email" placeholder="E-mail ID" className="form-control"/>
                            </div>
                            <div className="mt-3">
                                <label>Date of birth</label>
                                <input required name="dateOfBirth" value={studentEdit.dateOfBirth} onChange={editStudentOnchange} type="date" className="form-control"/>
                            </div>
                           
                            <div className={`mt-3 ${AdminDashBoardCss.footerbuttonRow}`}>
                                <input onClick={()=>{onSubmit_Edit_Student(studentEdit.userRefID)}} type="button" className={`btn btn-success ${AdminDashBoardCss.SubmitBTN}`} value="SAVE"/>   
                                {/* <button type="reset" className="btn btn-danger">  RESET </button> */}
                                <button  onClick={(()=>{editStudent(false)})} className="btn btn-secondary"> CLOSE </button>
                            </div>
                            </div>
                  </form>
                </div>
          </div>
           </div>
        }
     {/* Loader popup start */}
          {showLoader&&
               <Loader/>
          }
     {/* Loader popup end */}

     {/* student add err popup start  */}
          {showErrstu.status &&
               <div className={`${AdminDashBoardCss.showErrbase}`}>
                    <div className={`${AdminDashBoardCss.inerBox}`}>
                         <div><ErrorOutlineIcon className={`${AdminDashBoardCss.errIcons}`}/></div>
                              <div className={`${AdminDashBoardCss.errMessageDiv}`}>{showErrstu.errMsg}</div>
                         <button onClick={(()=>{setErrstu(false)})} className='btn btn-danger mt-2 btn-lg'> OK </button>    
                    </div>
               </div>
          }
     {/* student add err popup end  */}


     {/* student add suceess popup start  */}
     {showSuccess.status &&
          <div className={`${AdminDashBoardCss.showErrbase}`}>
               <div className={`${AdminDashBoardCss.inerBox}`}>
                    <div><CheckCircleOutlineIcon className={`${AdminDashBoardCss.successIcons}`}/></div>
                         <div className={`${AdminDashBoardCss.successMes}`}>{showSuccess.errMsg}</div>
                    <button onClick={(()=>{setSuccess(false); {setAddStuPop(false)}})} className='btn btn-success mt-2 btn-lg'> OK </button>    
               </div>
          </div>
     }
     {/* student add suceess popup end */}

     {/* bulkUpload Popup start */}
     {bulkUploadData.status && 
          <div className={`${AdminDashBoardCss.bulkUploadPop}`}>
               <div className={`${AdminDashBoardCss.innerBox}`} data-aos="flip-left">
                    <BackupIcon className={`${AdminDashBoardCss.iconsUpload}`}/>
                    <form className={`${AdminDashBoardCss.uolpadFilesBulk}`} onSubmit={createMultiUserOnsubmit}>
                         <label>Please Choose File</label>
                         <input name='user_file'  onChange={(e)=>bulFile(e)} className='form-control mt-1' type="file" />
                         <div className={`${AdminDashBoardCss.buttonSection}`}>
                              <input type='submit' className='btn btn-success' value="SUBMIT !"/> {' '}
                              <div type='reset' className='btn btn-danger'  onClick={()=>togglePopup(false)}>CANCEL !</div>
                         </div>

                    </form>
               </div>
          </div>
     }
     {/* bulkUpload Popup end */}

     {/* Assign subject and ection pop start */}
     {showSecNSubjectPop.status &&
            <div className={`${AdminDashBoardCss.bulkUploadPop}`}>
                <div className={`${AdminDashBoardCss.innerSidePop}`}>
                     <div className={`${AdminDashBoardCss.heading2}`}> Assign Section and Subjects </div>
                     <form className={`${AdminDashBoardCss.AlocateSubjectFormBk}`} onSubmit={assignSectionAndSubject} >
                         <div className={`${AdminDashBoardCss.checkboxBgs}`}>
                              {showAssignSec.map((item, index)=>{
                                   return(
                                        <label key={'isChecket'+index}>
                                             <input  key={'isChecket'+index} value={item.sectionID} checked={item.isChecked} disabled={item.isDisabled} id={'isChecket'+index}  name="assignSection" onChange={(e)=>{checkBtnData(item, index, e.target)}} type="checkbox"/>{item.sectionName}
                                        </label>
                                   )
                              })}
                              <hr/>
                              {showSubJectBox && 
                                   <div className={`${AdminDashBoardCss.subjectGroups}`}>
                                        <div className={`${AdminDashBoardCss.heading2}`}> Subjects for section - II </div>
                                             {showSubjectListOfSection.map((item, index)=>{
                                                  return(
                                                       <label key={'isChecket'+index}>
                                                            <input  key={'isChecket'+index} value={item.subjectID} checked={item.isChecked} disabled={item.isDisabled} id={'isChecket'+index} name='subjectID' type="checkbox" onChange={(e)=>{subjectHendler(item, index, e.target)}} />{item.subjectName}
                                                       </label>  
                                                  )
                                             })}
                                   </div>
                              }
                              
                         </div>
                         <div className={`mt-3 ${AdminDashBoardCss.buttoninFooter}`}>
                              <button type="submit" className="btn btn-success"> SAVE </button>
                              <button type='button' className="btn btn-danger" onClick={()=>{setSecNSubjectPop(false); {setSubJectBox(false)}}}>CLOSE</button>
                         </div>
                    </form>
                </div> 
           </div>
     }
     {/* Assign subject and ection pop end */}


 </div>
  );
}

export default ManageStudent_Admin;
import React, { useState, useContext, useEffect } from "react";
import { Container , Row , Col  } from "react-bootstrap";
import { Link} from "react-router-dom";

import facebook from "../../assets/fb.png";
import Instagram from "../../assets/insta.png";
import linkdin from "../../assets/linkdin.png";
import twiter from "../../assets/twiter.png";
import youtube from "../../assets/youtube.png";
import whatsapp from "../../assets/whatsapp.png";
import paymentIcon from "../../assets/payment-icon.png";
import imgLoader from '../../assets/loader.gif';

import {apiBaseURL,apiURL,apiKey,baseURL} from '../../constant';

const Footer = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [subscribeData, setSubscribeData] = useState({
    text:"",
    status:"",
    msg:"",
    action:"newsletter"
  });

  function setDataForSubscribe(value, type){
    console.log(value);
    console.log(type);
    setSubscribeData((prev)=>{
      return {...prev, [type]:value}
    })   
  }

  function sendSubscription(){
    console.log("Aniket");
    //alert("Aniket");
    //console.log(isLoader);
    //console.log(subscribeData.text);
    //console.log(apiBaseURL);
    //console.log(apiURL);
    //console.log(apiURL.subscribe);
    //console.log(apiBaseURL+apiURL.subscribe);  
    /*
    setSubscribeData((prev)=>{
      return {...prev, status:"loading"}
    })*/

    console.log(subscribeData);
    
    setIsLoader(true);    

    let postData = {
      "email":subscribeData.text,
      "action":subscribeData.action,
    }
    //fetch(apiBaseURL+apiURL.subscribe,{
    fetch('https://cuet.rachnasagar.in/cuetnta/api/subscribe',{
      method:'POST',
      headers:{
          'key':"cuetnta"
          //'key':apiKey
      },
      body:JSON.stringify(postData)
      //body:JSON.stringify({"email":subscribeData.text} )
    })
    .then( (result) => {
      result.json().then((res) => {
        if (res.status == "1") {
          setIsLoader(false);

          console.log(subscribeData);

          setSubscribeData((prev)=>{
            return {...prev, status:"success", text:"", msg:res.message}
          })        
        

          setTimeout(() => {
            setSubscribeData({status: "success",msg: "",action: "newsletter"});
          }, 3000);

          console.log(subscribeData);

        } else {

            //console.log(subscribeData);
            //console.log("222222");
            //console.log(res);
            setIsLoader(false);
            setSubscribeData((prev)=>{
              return {...prev, status:"success", msg:res.message}
            })
        }

      });
    }).catch( (err)=>{
        console.log(err);
        console.log(subscribeData);

        setIsLoader(false); 
        setSubscribeData((prev)=>{
          return {...prev, status:"success", msg:"Network Error."}
        })
    })

  }

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  
 return (  
    <>

    <div className="footer-section">
      <div className="width-section">
          <h2> WE ARE HERE TO HELP YOU, GET IN TOUCH WITH US </h2> 
          <h3> Return & Refund Policy </h3>
          <Row>
            <Col md="4"> 
              <p>No cancellation or Refund (either totally or partially) could be provided, once the payment has been made. We suggest you to 
                select the subjects as per your requirement and go through the subjects/sections you have added to make the purchase, before
                 making the payment. </p>                 
            </Col>           
            <Col md="4" className="terms-main"> 
              <div className="termsHedi"> TERMS OF USE </div> 
              <ul className="list-section">
                <li><Link to="/termcondition"> &#8594; Terms & Conditions </Link></li>
                <li><Link to="/concellationrefundpolicy"> &#8594; Cancellation & Refund Policy </Link></li>
                <li><Link to="/privacypolicy"> &#8594; Privacy Policy </Link></li>
                <li><Link to="/disclaimer"> &#8594; Disclaimer </Link></li>
                <li><Link to="/faq">&#8594; FAQ </Link> </li>
                <li><Link to="/productServicePaymentFlow">&#8594; Product Service Payment Flow</Link> </li>
              </ul>
            </Col>
            <Col md="4"> 
            <div className="socialHedi">NEWSLETTER SIGN UP </div>
              <Row className="subscribe">
                <Col md="8" xs="8">                    
                    <input type="text" placeholder="Enter your email Id." value={subscribeData.text} onChange={(e)=>{setDataForSubscribe(e.target.value, 'text')}}/>
                </Col>
                <Col md="4" xs="4"> 
                  <button className="subscribe-btn" type="button" onClick={sendSubscription}>Submit</button>             
                </Col>
              </Row>

              <Row className="subscribe">
                <Col md="12" xs="12">
                {(subscribeData.status=="success" &&  subscribeData.msg!="")  && 
                        <>
                        <div  style = {{padding:'3px 0', textAlign:'center', borderBottom:'1px solid #ccc'}} role="alert">{subscribeData.msg}</div>
                        </>
                      }
                      {isLoader && 
                        <div className="alert alert-info" style = {{padding:'3px 0', textAlign:'center'}} role="alert">
                          <img src={imgLoader}/> Processing ...
                        </div>
                      }
                </Col>
              </Row>
              <p>Sign up for exclusive updates, and notifications. </p>            
             </Col>
          </Row>
          <Row>
            <Col md={3}>
              <h4> FOLLOW US </h4> 
              <div className="social-links">
                <a href="https://www.facebook.com/rachnasagargrp/" target="_blank"><img src={facebook}/></a> 
                <a href="https://www.instagram.com/rachnasagar.grp/" target="_blank"><img src={Instagram}/></a> 
                <a href="https://www.linkedin.com/company/rachnasagar" target="_blank"><img src={linkdin}/></a> 
                <a href="https://twitter.com/rachnasagargrp" target="_blank"><img src={twiter}/></a> 
                <a href="https://www.youtube.com/user/rachnasagarin" target="_blank"><img src={youtube}/></a> 
                <a target="_blank" href="https://wa.aisensy.com/QEsnfY"><img src={whatsapp}/></a> 
              </div>
            </Col>
            <Col md={5}>
              <h4>CONTACT US</h4>
              <div className="address"> 
                <i class="fa fa-phone favi" aria-hidden="true"></i> <span> +91-11-43585858 </span>
                <i class="fa fa-envelope favi" aria-hidden="true"></i>  <span> info@rachnasagar.in </span>
              </div>   
            </Col>
            <Col md={4}>
              <Row className="address">                 
                <div className="payment-section"> PAYMENT METHOD </div>
                <img src={paymentIcon}/>
              </Row> 
            </Col>
          </Row>
      </div>   
    </div> 
    </>
  );
}
export default Footer;

import React, { useEffect } from "react";
import Header from '../homePage/Header';
import Footer from '../homePage/Footer';

import AOS from "aos";
import "aos/dist/aos.css";

import { Button, Col, Container, Row } from "react-bootstrap";

import layout from "../layout/innerPage.css";
import innerResp from "../layout/innerResp.css";

const ProductServicePaymentFlow = () => { 

  useEffect( () =>{
    AOS.init({
      offset:100,
      duration:3000,
    });
  },[] );

 return (   
    <>
    <Header/>
    <div className="contactSection" data-aos="fade-right">
        <Container>
          <Row>
            <Col md={12}>
              <h1 style={{margin: "4px 0"}}> Product Service Payment Flow </h1>                     
            </Col>            
          </Row>         
        </Container>
      </div> 
      <Container>
        <Row>
          <Col md={12}>
            <div className="product-service" data-aos="fade-up">
              <h2> Product Service Payment Flow  </h2>
              <p> &#8226; &nbsp; Open the URL <a target="_blank" href="https://cuet.rachnasagar.in/"><b>https://cuet.rachnasagar.in/</b></a> </p>
              <p> &#8226; &nbsp; Click on the register now button. </p>
              <p> &#8226; &nbsp; Fill the complete registration form (email should be working). </p>
              <p> &#8226; &nbsp; After submitting the registration form, user will receive login credential thru email provided. </p>
              <p> &#8226; &nbsp; After that user will select payment gateway either Paytm or Ccavenue. </p>
              <p> &#8226; &nbsp; User will redirect to payment gateway for payment process. </p>
              <p> &#8226; &nbsp; After complete the payment process user will redirect at the response page. </p>
              <p> &#8226; &nbsp; User will receive payment detail and invoice at register email id. </p>
              <p> &#8226; &nbsp; Your selected subjects and sections will be added automatically. </p>
            </div>
          </Col>
        </Row>
      </Container>    
    <Footer/>
    </>
  );
}
export default ProductServicePaymentFlow;

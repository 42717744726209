import React from 'react'
import RegisterCss from './Register.module.css'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import PaymentInfo from '../../assets/Payment.png';

export default function Success() {
    return (
        <div>
            <div className={`${RegisterCss.container}`}>
               <div class={`${RegisterCss.paymentSuccssBg}`}>
                   <Container>
                    <Row>
                        <Col>
                            <div className={`${RegisterCss.pymentImg}`}><img src={PaymentInfo}/></div>
                        </Col>
                        <Col>
                                <CheckCircleOutlineIcon className={`${RegisterCss.checkMark}`} data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="1500"/>
                                <p className={`${RegisterCss.payText}`}>Payment Completed Successfully !</p>
                                <p className={`${RegisterCss.thanksMsg}`}>Exam access code sent to your register email id.  </p>
                                <p className={`${RegisterCss.thanksMsg}`}>Thank you for your registration ! </p>
                                <Link to="/" title='Login'>
                                    <button class="btn btn-success btn-lg mt-3"><LockOpenIcon/> Login </button>
                                </Link>
                        </Col>
                    </Row>
                   </Container>
                    
               </div>
            </div>
            <div className={`${RegisterCss.area}`} >
                <ul className={`${RegisterCss.circles}`}>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                </ul>
            </div >
        </div>
        
    )
}

import {useContext} from 'react'
import SuperAdminCss from './SuperAdmin.module.css';
import Dropdown from 'react-bootstrap/Dropdown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import swaLogo from '../../assets/logo.png';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import userContext from '../../Store'

function AdminDash() {
  const {userData, logout} = useContext(userContext);

  return (
    <>
    {/* Header Section */}
   <div className={`${SuperAdminCss.HeaderTop}`}>
       <div className={`${SuperAdminCss.logoCol}`}>
          <div className={`${SuperAdminCss.logoDiv}`}>
             <img className={`${SuperAdminCss.logoimgs}`} src={swaLogo}/>
          </div>
       </div>
       <div className={`${SuperAdminCss.UserCol}`}>
           <div className={`${SuperAdminCss.rightcolm}`}>
             <Dropdown>
              Welcome : {userData.firstName}
               <Dropdown.Toggle className={`${SuperAdminCss.candidateButton}`}>
               <AccountCircleIcon className={`${SuperAdminCss.user}`}/>
               </Dropdown.Toggle>
               <Dropdown.Menu variant="light">
               <Dropdown.Item href=""><PersonIcon/>My Profile</Dropdown.Item>
               <Dropdown.Item onClick={logout}><ExitToAppTwoToneIcon/>Logout</Dropdown.Item>
               </Dropdown.Menu>
               </Dropdown>
           </div>
       </div>
       <div className={`${SuperAdminCss.clear}`}></div>
   </div>

  </>
  );
}

export default AdminDash;

import { useContext } from 'react'
import AdminDashBoardCss from './adminDashboard.module.css';
import Dropdown from 'react-bootstrap/Dropdown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import swaLogo from '../../assets/logo.png';
import newSwaLogo from "../../assets/fb_logo.png";
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import userContext from '../../Store'
import { Row, Col, Container } from 'react-bootstrap';

function AdminDash() {
   const { userData, logout, schLogo, schName } = useContext(userContext);

   return (
      <>
         {/* Header Section */}
         <div className={`${AdminDashBoardCss.HeaderTop}`}>
            <Container fluid>

               <Row>
               {(schName == "" || schName == undefined || schLogo == "null" || schLogo == undefined) ? 
                  <Col>
                     <div className={`${AdminDashBoardCss.logoCol}`}>
                        <div className={`${AdminDashBoardCss.logoDiv}`}>
                           <img className={`${AdminDashBoardCss.newSwaLogo}`} src={swaLogo} />
                        </div>
                     </div>
                  </Col>
               :
                  <> 
                     
                     <Col >
                        <div className={`${AdminDashBoardCss.logoCol}`}>
                           <div className={`${AdminDashBoardCss.logoNewDiv}`}>
                              <img className={`${AdminDashBoardCss.logoimgs}`} src={newSwaLogo} />
                           </div>
                        </div>
                     </Col>
                     <Col className={`${AdminDashBoardCss.logoSchool}`}>
                        <div className={`${AdminDashBoardCss.logoCol}`}>
                           <div className={`${AdminDashBoardCss.schLogoDiv}`}>
                              <img  className={`${AdminDashBoardCss.logoimgs2}`} src={schLogo} />
                              {/* <h6 style={{ fontSize: '14px', top: '0px', textAlign:'center' }}>{schName}</h6> */}
                           </div>
                        </div>
                     </Col>
                  </>    
               }
                  
                  <Col>
                     <div className={`${AdminDashBoardCss.UserCol}`}>
                        <div className={`${AdminDashBoardCss.rightcolm}`}>
                           <Dropdown>
                              <strong>Welcome</strong> {userData.firstName}
                              <Dropdown.Toggle className={`${AdminDashBoardCss.candidateButton}`}>
                                 <AccountCircleIcon className={`${AdminDashBoardCss.user}`} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu variant="light">
                                 <Dropdown.Item href=""><PersonIcon />My Profile</Dropdown.Item>
                                 <Dropdown.Item onClick={logout}><ExitToAppTwoToneIcon />Logout</Dropdown.Item>
                              </Dropdown.Menu>
                           </Dropdown>
                        </div>
                     </div>
                  </Col>
               </Row>
            </Container>
            {/*  */}

            <div className={`${AdminDashBoardCss.clear}`}></div>
         </div>

      </>
   );
}

export default AdminDash;

import StuDashBoardCss from './stuDashboard.module.css';
import LeftMenu from './LeftMenu';
import Header from './header';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Container, Row, Col } from 'react-bootstrap';
import {apiBaseURL,apiURL,apiKey, RouteURL} from './../../constant';
import React, { useEffect, useState, useContext } from "react";
import PracticeSetIcon from '../../assets/set_sub.png';
import Loader from "./../../Loader";
import userContext from './../../Store';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Assessment } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from "@mui/icons-material/Cancel";
function PracticeSet() {
	const[showSection, setSection]=useState([]);
	const[showSubjectList, setSubjectList]=useState(false);
	const[showChapter, setChapterList]=useState([]);
	const[showErrPopup, setErrPopup] =useState({status:false, msg:null});
	const[showLoader, setLoader]=useState(false)
	const[checkBtn, setCheckedBtn] = useState("")
	const {userData} = useContext(userContext);
	const[showSet_sec_1, setSet_sec_1]=useState(false);
	const[subjectList, setsubjectList]=useState([]);
	const[getSet, setSet] = useState([]);
	const[secIdWithSubject, setSecIdWithSubject] = useState({secID:'', subjectID:'',chapID:'',assessmentID:''})
	const [showInstructionOn, setInstructionOn] = useState(false);
	function closeCartBox(){	
		setInstructionOn(false);	
	}

	var subID = "";
	function checkBtnData(id, isSubject){
		subID = id;
		setSecIdWithSubject((prev)=>{
			return {...prev, secID:''}
		})
    setCheckedBtn(id)
	setLoader(true)
	if(id==1 || id==3){
		setSubjectList(false)
	}
	if(id==1 && isSubject==1){
		setSet([]);
		// call API Data to get set
		const xPostData ={
			"schoolCode":userData.schoolCode,
			"sectionID":id,
			"subjectID":1,
			"questionTypeID":2
		}
		fetch(apiBaseURL+apiURL.getExamSetList,{
			method: "POST",
			headers: {'Key' : apiKey},
			body:JSON.stringify(xPostData)
		})
		.then(responce => responce.json())
		.then((setData)=>{
			if(setData.status=="success"){
				if(setData.data==""){
					setErrPopup((prev)=>{
						return {...prev,status:true,msg:"No Record Found !"}
					})
					setLoader(false)
					
				}else{
					setLoader(false)
					setSet(setData.data)
					setSet_sec_1(true)
					setSubjectList(false)
				}
				
			}else{
				setErrPopup((prev)=>{
					return {...prev,status:true,msg:setData.message}
				})
				setLoader(false)
				setSet([])
			}
		})
		.catch((catch_err)=>{
			setErrPopup((prev)=>{
				return {...prev,status:true,msg:"Server error Occured"}
			})
			setLoader(false)
		})
	}else if(id==2 && isSubject==1){
		
		setSet([]);
		setSubjectList(true)
		setLoader(false)
		setSet_sec_1(false)
		setSecIdWithSubject((prev)=>{ return {...prev,secID:id}})
		getAssignSubjectToStudent(id)

	}else if(id==3 && isSubject==0){
		setSet([]);
		const xPostData ={
			"schoolCode":userData.schoolCode,
			"sectionID":id,
			"questionTypeID":2
		}
		fetch(apiBaseURL+apiURL.getExamSetList,{
			method: "POST",
			headers: {'Key' : apiKey},
			body:JSON.stringify(xPostData)
		})
		.then(responce => responce.json())
		.then((setData)=>{
			if(setData.status=="success"){
				if(setData.data ==""){
					setErrPopup((prev)=>{
						return {...prev,status:true,msg:"No Record Found !"}
					})
					setLoader(false)
				}else{
					setLoader(false)
					setSet(setData.data)
					setSubjectList(false)
					setSet_sec_1(true)
				}
				
			}else{
				setErrPopup((prev)=>{
					return {...prev,status:true,msg:setData.message}
				})
				setLoader(false)
				setSet_sec_1(false)
				setSet([])
			}
		})
		.catch((catch_err)=>{
			setErrPopup((prev)=>{
				return {...prev,status:true,msg:"Server error Occured"}
			})
			setLoader(false)
		})
	}else{
		setSubjectList(false)
		setSet_sec_1(false)
	}
}

  useEffect(()=>{
    getSectionOfAssignTostudent();
	getSubjectAssignToStudent()
  },[])
 

  // get section function
  function getSectionOfAssignTostudent(){
	setLoader(true)
	const xPostData ={
		"schoolCode":userData.schoolCode,
		"studentID":userData.userRefID
	}
	fetch(apiBaseURL+apiURL.getAssignedSection,{
		method : 'POST',
		headers: {'Key':apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((sectionData)=>{
		if(sectionData.status=="success"){
			setSection(sectionData.data.assignedSections)
		}else{
			setSection([])
			alert(sectionData.message)
		}
	})
	.catch((catch_err)=>{
		setSection([])
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})
}
// get section function

// get subject Assign to student fun start
function getSubjectAssignToStudent(){
	
	const xPostData ={
		"classID":12,
		"schoolCode":userData.schoolCode,
		"studentID":userData.userRefID,
	}
	fetch(apiBaseURL+apiURL.getAssignedSubjects,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((subjectData)=>{
		if(subjectData.status=="success"){
			setsubjectList(subjectData.data.assignedSubjects)
		}else{
			setsubjectList([])
			alert(subjectData.message)
		}
	})
	.catch((catch_err)=>{
		alert(catch_err)
	})
}

function getAssignSubjectToStudent(sectionID){
	
	const xPostData ={
		"section":sectionID,
		"schoolCode":userData.schoolCode,
		"userRefID":userData.userRefID,
	}
	fetch(apiBaseURL+apiURL.getSubjectsOfSection,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((subjectData)=>{
		if(subjectData.status=="success"){
			setsubjectList(subjectData.data.subjects)
		}else{
			setsubjectList([])
			alert(subjectData.message)
		}
	})
	.catch((catch_err)=>{
		alert(catch_err)
	})
}
// get subject Assign to student fun end

// get chapter functions start
function getChapters(subjectID){
	const xPostData ={
		"subjectID":subjectID,
		"classID":12
	}
	fetch(apiBaseURL+apiURL.getChaptersList,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((chapterData)=>{
		if(chapterData.status=="success"){
			setChapterList(chapterData.data.chapters)
			setSecIdWithSubject((prev)=>{
				return {...prev,subjectID:subjectID}
			})

		}else{
			setChapterList([])
			alert(chapterData.message)
		}
	})
	.catch((catch_err)=>{
		setChapterList([])
		alert(catch_err)
	})
}
// get chapter functions end

// get exam sets list fun start
function getExamSetList(subjectID){
	const xPostData ={
		"schoolCode":userData.schoolCode,
		"sectionID":secIdWithSubject.secID,
		"subjectID": secIdWithSubject.secID==2 ? subjectID : "",
		// "chapterID":chapterID,
		"questionTypeID":2	
	}

	console.log(xPostData)
	fetch(apiBaseURL+apiURL.getExamSetList,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((setData)=>{
		if(setData.status=="success"){
			if(setData.data==""){
				setErrPopup((prev)=>{
					return {...prev, status:true, msg:"No Record Found !"}
				})
				setSet([])
			}else{
				setLoader(false)
				setSet(setData.data)
				setSubjectList(true)
				setSet_sec_1(true)
				setSecIdWithSubject((prev)=>{
					return {...prev,subjectID:subjectID}
				})
			}
			
		}else{
			setErrPopup((prev)=>{
				return {...prev,status:true,msg:setData.message}
			})
			setLoader(false)
			setSet_sec_1(false)
			setSet([])
		}
	})
	.catch((catch_err)=>{
		setErrPopup((prev)=>{
			return {...prev,status:true,msg:"Server error Occured"}
		})
		setLoader(false)
	})
}


// function getAssessmentID(setId){
// 	alert("helo ")

// 	const xPostData ={
// 		"setID"  : setId,
// 		"schoolCode":userData.schoolCode,
// 		"studentID" : userData.userRefID,
// 		"sectionID":checkBtn,
// 		"subjectID":secIdWithSubject.subjectID,
// 		"chapterID":secIdWithSubject.chapID,
// 		"classID"  :12,
// 	}

// 	console.log(xPostData)
// 	console.log("akay  bjhsdamn")
// 	fetch(apiBaseURL+apiURL.getExamSetList,{
// 		method: "POST",
// 		headers: {'Key' : apiKey},
// 		body:JSON.stringify(xPostData)
// 	})
// 	.then(responce => responce.json())
// 	.then((assID)=>{
// 		if(assID.status=="success"){
// 			setSecIdWithSubject((prev)=>{
// 				return {...prev,assessmentID:assID.assessmentID}
// 			})
// 			// redirect to url
// 		}else{
			
// 		}
// 	})
// 	.catch((catch_err)=>{
// 		setErrPopup((prev)=>{
// 			return {...prev,status:true,msg:"Server error Occured"}
// 		})
// 		setLoader(false)
// 	})
// }


// get exam sets list fun end
const getAssessmentID = (setID) => {
	
	let assessmentID = 0;
	let assessmentName = ''

	Swal.fire({
		// title: 'Are you sure ?',
		html: 'Before proceeding further make sure you have read all general instructions carefully. <br/><br/>Do you really want to proceed ? <br>',
		// icon: 'question',
		confirmButtonText: 'Yes',
		confirmButtonColor: '#198754',
		focusCancel: true,
		showCancelButton: true,
		showLoaderOnConfirm: true,
		preConfirm: () => {
			
			const conf = {
				method:'post',
				headers:{'key':apiKey},
				body:JSON.stringify({'setID':setID,'schoolCode':userData.schoolCode,"studentID" : userData.userRefID,"sectionID":checkBtn,"subjectID":(checkBtn==2) ? secIdWithSubject.subjectID : 1 ,"classID"  :12})
			};
			console.log(conf)
			console.log("Ajay Bhati")
			
			setLoader(true);
			return fetch( apiBaseURL+apiURL.generatePracticeSetExam, conf )
					.then( response => response.json() )
					.then( ( result ) => {
						if( result.status=='success' ) {
							// alert("ok")
							setSecIdWithSubject((prev)=>{
								return {...prev,assessmentID:result.data.assessmentID}
							})
							assessmentID = result.data.assessmentID;
							assessmentName = result.data.assessmentName
							setLoader(false);
						}
						return result.message;
					} )
					.catch( (error) => {
						Swal.showValidationMessage("failed");
					} )
		},
		allowOutsideClick: () => !Swal.isLoading()
	}).then( (r) => {
		// console.log(assessmentID);
		if( r.isConfirmed ){
			// Swal.fire(
			// 	'Success', 'Successfully Done.', 'success'
			// );
			// // let assessmentID = secIdWithSubject.assessmentID;
			// const attemptURL = {
			// 	pathname: RouteURL.studentAttemptScreenPage+"/"+assessmentID+"/"+assessmentName
			// }
			// window.open(attemptURL.pathname, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=2000px,height=1124")
			
			if(assessmentID!=0){
				// Swal.fire(
				// 	'Success', 'Successfully Done.', 'success'
				// );
				// let assessmentID = secIdWithSubject.assessmentID;
				const attemptURL = {
					pathname: RouteURL.studentAttemptScreenPage+"/"+assessmentID+"/"+assessmentName+"/"+1
				}
				window.open(attemptURL.pathname, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=2000px,height=1124")
			}else{
				setLoader(false);
				Swal.fire(
					'failed', 'Sufficient  Questions are not available.', 'warning'
				);
			}
		}

	} );
}






  return (

   <div className={`${StuDashBoardCss.Backbody}`}>
	   {showInstructionOn && (
        <>
			<div className={`${StuDashBoardCss.addMoreSubjectBg_instruction}`}>
				<div className={`${StuDashBoardCss.generalInstructionBg_instruction}`}>
					<div className={`${StuDashBoardCss.headingBk}`}>
						<Row>
							<Col>
								<div className={`${StuDashBoardCss.SectionHeading}`}>General Instructions</div>
							</Col>
							<Col>
								<CancelIcon	onClick={() => {closeCartBox()}} className={`${StuDashBoardCss.crossbutton}`}/>
							</Col>
						</Row>
					</div>
					<div className={`${StuDashBoardCss.subjectBody_instruction}`}>
						<h5>Practice Sets </h5>
						<ol>
							<li>Each set contains 25 questions covering all the chapters/topics.</li>
							<li>Please make sure to attempt all the questions of any set in order to view the answers to all the questions.</li>
							<li>Each question carries 5 marks. There is a negative marking of 1 mark for every wrong answer.</li>
							<li>You can review answers of attempted questions only.</li>
						</ol>
					</div>
				</div>
			</div>
        </>
        )}
      <Header/>
      <LeftMenu/>
      <div className={`${StuDashBoardCss.backdiv}`}>
      	<div className={`${StuDashBoardCss.RowCOlm}`}>
             <div className={`${StuDashBoardCss.headingPractice}`}>Practice Sets
			 	<div className={`${StuDashBoardCss.headingHelpIcon}`} onClick={() => {setInstructionOn(true);}}>General Instructions <i class="fa"> <InfoIcon /> </i></div>
			 </div>
        </div>
        {showSection.map((item,index)=>{
          return(
            <FormControlLabel 
			    className={`${StuDashBoardCss.sectionBtndiv}`}
				checked={checkBtn==item.sectionID} 
				name='section' 
				value="sction" 
				control={<Radio />} 
				label={item.sectionName} 
				onChange={()=>{checkBtnData(item.sectionID, item.isSubject)}}
				key={`djd`+index}
			/>
          )
        })}
        <hr/>
        <Container fluid>

			
			<Row className='justify-content-around'>
				

				
				{showSubjectList &&
				<>
					<Col>
						<label>Subject</label>
						<select  className={`form-control ${StuDashBoardCss.selectSubject}`} onChange={(e)=>{getExamSetList(e.target.value)}}>
							<option className={`d-none`}>Select </option>
							{subjectList.map((item)=>{
								return(
									<option value={item.subjectID}>{item.subjectName}</option>
								)
							})}
						</select>
					</Col>

					{/* <Col>
						<label>Chapter</label>
						<select  className={`form-control ${StuDashBoardCss.selectSubject}`} onChange={(e)=>{getExamSetList(e.target.value)}}>
							<option className={`d-none`}>Select </option>
							{showChapter.map((item,index)=>{
								return(
									<option 
										value={item.chapterID} 
										data-atr={item.subjectID}
										key={`df`+index}
									>
										{item.chapterName}
									</option>
								)
							})}
						</select>
					</Col> */}
				</>
				}

		{showErrPopup.status && 
		<div className={`${StuDashBoardCss.popupBack}`}>
			<div className={`${StuDashBoardCss.errBox}`}>
				<CloseIcon className={`${StuDashBoardCss.crossPopup}`} onClick={()=>{setErrPopup(false)}}/>
				<Alert severity="error">
					{showErrPopup.msg}
				</Alert>
			</div>       
		</div>
		}
			</Row>
			<Row className='justify-content-around'>
				{/* {showSet_sec_1 &&
					<Col>
						<div onClick={()=> window.open('/student/attempt', '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=2000px,height=1124")} className={`${StuDashBoardCss.Setsback}`}>
							<div className={`${StuDashBoardCss.iconPrac}`}><img src={PracticeSetIcon}/></div>
							<div className={`${StuDashBoardCss.PracSetHeading}`}>Practice Set - 1</div>
						</div>
					</Col>
				} */}
				{getSet.map((item,index)=>{
					let assessmentID = 1;
					const attemptURL = {
						pathname: RouteURL.studentAttemptScreenPage+"/"+assessmentID
					}
					return(
						<>
						<Col className="col-md-2 col-6 col-sm-4" key={`col`+index}>
						    {/* <Link to={attemptURL}>
								<div className={`${StuDashBoardCss.Setsback}`}>
									<div className={`${StuDashBoardCss.iconPrac}`}><img src={PracticeSetIcon}/></div>
									<div className={`${StuDashBoardCss.PracSetHeading}`}>{item.setDesc}</div>
								</div>
							</Link> */}
							<div  className={`${StuDashBoardCss.Setsback}`} onClick={()=>{getAssessmentID(item.setID)}}>
								<div className={`${StuDashBoardCss.iconPrac}`}><img src={PracticeSetIcon}/></div>
								<div  className={`${StuDashBoardCss.PracSetHeading}`}>{item.setDesc}</div>
							</div>

							{/* <div onClick={()=> window.open('/student/attempt' AssessmentID={}, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=2000px,height=1124")} className={`${StuDashBoardCss.Setsback}`}>
								<div className={`${StuDashBoardCss.iconPrac}`}><img src={PracticeSetIcon}/></div>
								<div className={`${StuDashBoardCss.PracSetHeading}`}>{item.setDesc}</div>
							</div> */}
						</Col>
						</>
					)
				})}
			</Row>
           {/* section A end*/}

        </Container>
      </div>
	  

		{/* Loader start */}
			{showLoader &&
				<Loader/>
			}
		{/* Loader end */}
		
    </div>
  
  );
}

export default PracticeSet;

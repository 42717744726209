import React, { useEffect } from "react";
import Header from '../homePage/Header';
import Footer from '../homePage/Footer';

import AOS from "aos";
import "aos/dist/aos.css";

import refundPolicy from '../../assets/refundpolicy.png';

import { Button, Col, Container, Row } from "react-bootstrap";
import layout from "../layout/innerPage.css";
import innerResp from "../layout/innerResp.css";

const Concellationrefundpolicy = () => {

useEffect( () =>{
  AOS.init({
    offset:200,
    duration:3000,
  });
},[] );

 return (   
    <>
    <Header/>
    <div className="contactSection">
      <Container>
        <Row>
          {/* <Col md={2}> <img className="mw-100" src={contactleft}/>  </Col> */}
          <Col md={12} data-aos="zoom-out-right">
            <h1> Cancellation & Refund Policy </h1>
            <p style={{textAlign: "justify"}}>No cancellation or Refund (either totally or partially) could be provided, once the payment has been made. We suggest you to select the
            subjects as per your requirement and go through the subjects/sections you have added to make the purchase, before making the payment. 
            Register on <a target="_blank" href="https://cuet.rachnasagar.in"><b>https://cuet.rachnasagar.in</b></a> to access the CUET Section/Subject of your choice.</p>
          </Col>
          {/* <Col md={2}> <img className="mw-100" src={contactright}/> </Col> */}
        </Row>       
      </Container>
    </div>
    <Container>
      <div className="text-center p-2" data-aos="zoom-in">
        <img className="mw-100 animation" src={refundPolicy}/> 
      </div>
    </Container>
    <Footer/>
    </>
  );
}
export default Concellationrefundpolicy;

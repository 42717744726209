import React, { useEffect } from "react";
import Header from '../homePage/Header';
import Footer from '../homePage/Footer';

import AOS from "aos";
import "aos/dist/aos.css";

import disclaimer from '../../assets/disclaimerImg.png';

import {Container, Row , Col } from "react-bootstrap";
import layout from "../layout/innerPage.css";
import innerResp from "../layout/innerResp.css";

const Disclaimer = () => {

  useEffect( () =>{
    AOS.init({
      offset:200,
      duration:3000,
    });
  },[] );

 return (   
    <> 
    <Header/>
    <div className="contactSection">
      <Container>
        <Row>
          <Col md={12} data-aos="fade-left">
            <h1> Disclaimer </h1>
            <p style={{textAlign: "justify"}} >Welcome to CUET Online Portal by Rachna Sagar Pvt. Ltd. Before you explore the wealth of literary 
            content and resources available here, please take a moment to read and understand this disclaimer. The information provided on this 
            portal is for preparation purposes only. </p>            
          </Col>
        </Row>       
      </Container>
    </div>
    <Container>
    <p style={{textAlign: "justify"}} data-aos="fade-right"> While we strive to ensure the accuracy and reliability of the content, we make no representations or
    warranties of any kind, express or implied, about the reliability, suitability, or availability concerning the portal or the 
    information or related graphics contained on the portal for any purpose. Any reliance you place on such information is strictly 
    at your own risk.</p>   
      <Row>
        <Col md={8} data-aos="fade-left">
          <p style={{textAlign: "justify"}}>In no event, Rachna Sagar will be liable for any loss or damage, including without limitation, indirect or consequential loss or 
          damage, whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this portal.</p>
          <p style={{textAlign: "justify"}}>Every effort is made to keep the portal up and running smoothly. However, Rachna Sagar takes no responsibility for, and will not
          be liable for, the portal being temporarily unavailable due to technical issues beyond our control. By using our online portal,
          you agree to the terms outlined in this disclaimer.</p>
          <p>Happy Learning!</p>
        </Col>
        <Col md={4}>
        <div className="text-center p-2" data-aos="zoom-in">
          <img className="mw-100 animation" src={disclaimer}/> 
        </div>
        </Col>
      </Row>
    </Container>
    <Footer/>
    </>
  );
}
export default Disclaimer;

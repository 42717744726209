import React, { useEffect } from "react";
import Header from '../homePage/Header';
import Footer from '../homePage/Footer';

import AOS from "aos";
import "aos/dist/aos.css";

import { Button, Col, Container, Row } from "react-bootstrap";

import abobanner from '../../assets/about-banner.png';

import layout from "../layout/innerPage.css";
import innerResp from "../layout/innerResp.css";

//function Aboutus() {  
const Aboutus = () => {

  useEffect( () =>{
    AOS.init({
      offset:100,
      duration:2000,
    });
},[] );

 return (   
    <>
    <Header/>
    <div className="about-banner">
        <img data-aos="fade-right" className="abo1" src={abobanner}/>      
    </div>
    <div className="about-section" data-aos="fade-left">
        <h1> About Us </h1>
        <p> Rachna Sagar is committed to give the best to the readers. We aim to publish relevant, timely and informative series to serve the 
          educational community. We are committed to continuously improve all aspects of teaching and learning by publishing quality
           texts. </p> 
        <p>Veering away from a tendency to release the Paperback format only, the Company has launched “CUET Online Portal”- a well-researched 
          and innovative platform to prepare the aspirants for CUET UG-2024. Keeping abreast of global trends in education, this step towards 
          online preparation strategy is a substantial advancement in making CBT preparation more interactive, innovative and satisfying for 
          both learners & the faculty. </p> 
    </div>
    <div className="botton-banner"> Welcome to CUET UG-2024 Online Portal </div>
    <div className="about-section" data-aos="fade-right">
      <h5>“Online Preparation for Online Exam.”</h5>
      <p> CUET Online Portal by Rachna Sagar is the right fit for CBT preparation. Practice 35k+ questions for all sections & subjects, 
        available on our online Learning Portal. CUET Chapter-wise Quick Notes, Topic/Chapter-wise Practice, Practice Sets, Self-generated 
        Practice Exams, CUET Mock Test Paper and Reports are provided for holistic learning. </p>
    </div>
    <div className="botton-banner"> Highlights, Aims & Objectives </div>
    <div className="about-section" data-aos="fade-right">
      <p> NTA conducts CUET examination once in a year for admission into UG courses. Being a challenging exam it requires solid preparation via 
        CUET Portal to Prepare and Practice to qualify. Extended support for Entrance Exam Preparation through Digital means assures 
        Excellence. Online Portal to prepare for Common University Entrance Test UG-2024 increases users’ familiarity with CBT Process preparing
        them with best practice. CUET Online Portal aims to establish alignment between the preparation as per the online exam pattern and 
        thereby giving an evident boost to confidence. 
      </p>
      <p>
      <b>At the Portal, students can access </b>
      <ol>
        <li> Section-wise Chapter Specific Practice Questions  </li>
        <li> Chapter-wise Quick Notes & Topic-wise Practice Sets </li>
        <li> Full Syllabus Exam Practice via Mock Test </li>
      </ol>
      We encourage the learner to relate, perceive and collaborate with the latest exam trends of online examination essential CBT each 
      practice-learning present at the portal. Digital platform offers flexibility, a wide range of questions, an opportunity to learn at your 
      own pace & a comfortable atmosphere to practice. 
      </p>
      <div className="fontNew"> “Best Preparation is the Gateway to Success.” </div>
    </div>
    
    <Footer/>
    </>
  );
}
export default Aboutus;

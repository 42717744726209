import React, {useState, useEffect, useRef, useContext} from "react";
import SuperAdminCss from "./SuperAdmin.module.css";
import Header from "./Header";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import Table from "react-bootstrap/Table";
import { apiBaseURL, apiURL, apiKey } from "./../../constant";
import Loader from "../../Loader";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";
import userContext from './../../Store';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LogoutIcon from '@mui/icons-material/Logout';
import DoneAllIcon from '@mui/icons-material/DoneAll';
function ManageStudentSuperAdmin() {

  const[showStateList, setwStateList] = useState([]);
  const{userData} = useContext(userContext);
  
  const inputBox = useRef(null);
  const table = useRef(null);
  const[loader,setLoader] = useState(false)
  const[showUserDetailes, setUserDetailes] = useState([])
  const[showSchoolDetailes, setSchoolDetailes] = useState([]);
  const[updateStudent, setupdateStudent]=useState(false)
  const[showErr, setErr]=useState({status:false,errMsg:""});
  const[showSuccess, setSuccess]=useState({status:false, errMsg:""})
  const[addStudent, setaddStudent]=useState(false);
  const[subjectSection, setSubjectSection]=useState(false);
  const[subjectBox, setSubjectBox]=useState(false);
  const[sectionsList, setSections]=useState([]);
  const[subjectList, setSubjectList]=useState([]);
  const[deleteRecord, setDeleteRecord]=useState(false)

  useEffect(() => {
      getSchool();
	  getState();
	  getSections();
  }, []);



    // search data functions start
    function searchData() {
      let filter, tr, tds, i, txtValue;
      filter = inputBox.current.value.toUpperCase();
      tr = table.current.getElementsByTagName("tr");
      console.log(tr);
      for (i = 1; i < tr.length; i++) {
        tds = tr[i].getElementsByTagName("td");
        let isExist = false;
        for (let td of tds) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            isExist = true;
          }
        }
        if (isExist) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
    // search data functions end

  // get Student functions start
  function getStudent(schoolCode){
    setLoader(true)
    const xPostData ={
    //   "userType":3,
      "schoolCode":schoolCode
    }
    fetch(apiBaseURL+apiURL.getUsersList,{
        method: "POST",
        headers: {Key: apiKey},
        body:JSON.stringify(xPostData)
    })
    .then( response => response.json() )
    .then((data)=>{
        if(data.status == "success"){
            setUserDetailes(data.data.users)
        }else{
            setUserDetailes([])
            setErr((prev)=>{
              return{...prev, status:true, errMsg:data.message}
            })
        }
    })
    .catch((data)=>{
        setUserDetailes([])
        setErr((prev)=>{
          return{...prev, status:true, errMsg:data.message}
        })
    })
    .finally( ()=>{
        setLoader(false)
    } )
}
// get Student functions end

// get state functions start
function getState() {
    fetch(apiBaseURL + apiURL.getStateList, {
      method: "GET",
      headers: { key: apiKey },
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.status == "success") {
            setwStateList(data.data);
          } else {
            alert(data.message);
          }
        });
      })
      .catch((err) => {
        alert(err);
      });
  }
  // get state functions end


// update students function code end

const[UpdateStu, SetUpdateStu] = useState([]);

const updateStuOnchange = e =>{
	SetUpdateStu( (prev)=>{
		 return {...prev, [e.target.name] : e.target.value }
	} )
}

function upDataStuOnSubmit(){
  setLoader(true)
 const xPostData ={
       "schoolCode":userData.schoolCode,
       "stuName" :UpdateStu.firstName ,
       "fatherName": UpdateStu.guardianName,
       "address" : UpdateStu.userAddress,
       "stateID" :UpdateStu.stateCode,
       "pinCode": UpdateStu.zipCode,
       "contact" :UpdateStu.userContact,
       "email" :UpdateStu.userEmail,
       "dob" :UpdateStu.dateOfBirth,
       "userRefID":UpdateStu.userRefID
 }
  fetch(apiBaseURL+apiURL.updateUserDetails,{
    method: "POST",
    headers:{'Key' : apiKey},
    body:JSON.stringify(xPostData)
  })
  .then(response => response.json())
  .then((updatestu)=>{
    if(updatestu.status=="success"){
		setupdateStudent(false)
        setSuccess((prev)=>{
				return{...prev,status:true, errMsg:updatestu.message}
		   })
    }else{
      setErr((prev)=>{
				return{...prev,status:true, errMsg:updatestu.message}
		   })
    }
   
  })
  .catch((catch_err)=>{
		alert(catch_err)
	})
  .finally(()=>{
    setLoader(false)
  })
}
// update students function code end


// get school functions star
function getSchool(){
  const xPostData = {}
  fetch(apiBaseURL + apiURL.getSchoolList,{
      method: "POST",
      headers: {Key: apiKey},
      body:JSON.stringify(xPostData)
  })
  .then( res => res.json() )
  .then((Schooldata)=>{
      if(Schooldata.status == "success"){
          setSchoolDetailes(Schooldata.data.schools)
      }else{
          setSchoolDetailes([]);
          alert(Schooldata.message);
      }
  })
  .catch((err)=> {
      setSchoolDetailes([]);
      alert(err);
  })
}
// get school functions end


//   add Student state start
const[studentAdd, setStudentAdd]=useState({
	schoolCode:"",
	studentName: "",
	fathertName: "",
	address: "",
	studentState: "",
	pincode: "",
	contactNo: "",
	studentEmailID: "",
	dob : ""
})
// console.log(studentAdd)
const{schoolCode, studentName, fathertName, address, studentState, pincode, contactNo, studentEmailID, dob} = studentAdd;
const addStudentOnchange = e =>{
    setStudentAdd({...studentAdd,[e.target.name]: e.target.value});
} 

// add Student state end

// addStudent functions start 
function onSubmitcreateStudent(e){
	setLoader(true)
	e.preventDefault()
    const xPostData ={
		"stuName" : studentAdd.studentName,
		"fatherName" :studentAdd.fathertName,
		"address" : studentAdd.address,
		"state" : studentAdd.studentState,
		"pinCode" :studentAdd.pincode,
		"contact" :studentAdd.contactNo,
		"email" :studentAdd.studentEmailID,
		"dob" :studentAdd.dob,
		"schoolCode" : studentAdd.schoolCode,
        "userRefID":userData.userRefID
    }
	console.log(xPostData)
    fetch(apiBaseURL+apiURL.signup,{
        method: 'POST',
        headers: {'key' : apiKey},
        body:JSON.stringify(xPostData)
    })
	.then(response => response.json())
	.then((studentData)=>{
		if(studentData.status=="success"){
			setSuccess((prev)=>{
				setStudentAdd([])
				setaddStudent(false)
				return{
					...prev,
					status:true,
					errMsg:studentData.message
				}
		   })
		}else{
			setErr((prev)=>{
				return{
					...prev,
					status:true, 
					errMsg:studentData.message
				}
		   })
		}
	})
	.catch((catch_err)=>{
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})
}
// addStudent functions end 

// get sections fun start
function getSections(){
	setLoader(true)
	fetch(apiBaseURL+apiURL.getSectionList,{
		method: "GET",
		headers: {'Key': apiKey}
	})
	.then(response => response.json())
	.then((sectionData)=>{
		if(sectionData.status=="success"){
			setSections(sectionData.data.sections)
			
		}else{
			alert("else")
			setSections([])
			setErr((prev)=>{
				return{...prev,	status:true, errMsg:sectionData.message}
			})
		}
	})
	.catch((err)=>{
		setSections([])
		alert(err)
	})
	.finally(()=>{
		setLoader(false)
	})




	


}
// get sections fun end





// assign section and subject fun start
const[checkSection, setCheckSection]=useState([])

function sectionHendler(e, secID){

	setCheckSection((prev=>{
		let old = [...prev]
		if(e.target.checked){
			old.push(e.target.value)
		}else(
			old.splice(old.indexOf(e.target.value),1)
		)
		return old;
	}))
	
	
	if(secID==2 && e.target.checked){
		setSubjectBox(true)
	}else if(secID==2 && e.target.checked==false){
		setSubjectBox(false)
	}

	if(secID==2){
		const xPostData ={
			"section":secID,
		}
		fetch(apiBaseURL+apiURL.getSubjectsOfSection,{
			method: "POST",
			headers: {'Key' : apiKey},
			body:JSON.stringify(xPostData)		
		})
		.then(responce => responce.json())
		.then((dataSubjSecII)=>{
			if(dataSubjSecII.status=="success"){
				setSubjectList(dataSubjSecII.data.subjects)
			}else{
				setErr((prev)=>{
					return{
						...prev,
						status:true,
						errMsg:dataSubjSecII.message
					}
				})
			}
		})
		.catch((catch_err)=>{
			setSubjectList([])
			alert(catch_err)
		})
	}
}

// subjects hendlers start
const[checkSubjects, setCheckSubjects]=useState([])
function subjectHendler(e, secID){
	const updateSubjectList = checkSubjects;
	if(e.target.checked){
		updateSubjectList.push(e.target.value);
	}else{
		updateSubjectList.splice(checkSubjects.indexOf(e.target.value),1)
	}
	const forComaSeperateForSubject = checkSubjects.length
	? checkSubjects.reduce((total, item)=>{
		return total + ',' + item;
	})  
	:""; 
	setCheckSubjects(updateSubjectList);
	console.log(checkSubjects)
}
// subjects hendlers end



const[updateSecSub, setSecSub]=useState({})
function assignSectionSub(){
	setLoader(true)
	const xPostData ={
		"schoolCode":updateSecSub.schoolCode,
		"userRefID":updateSecSub.userRefID,
		"sectionIDs":checkSection,
		"subjectIDs":checkSubjects
	}
	console.log(xPostData)
	fetch(apiBaseURL+apiURL.updateAssignSectionSubjecttoUser,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(response => response.json())
	.then((secSubData)=>{
		if(secSubData.status=="success"){
			setSubjectSection("");
			setSubjectBox(false);
			setSuccess((prev)=>{return{...prev, status:true, errMsg:secSubData.message}
			})
		}else{
			setSuccess([])
			setErr((prev)=>{return{...prev, status:true, errMsg:secSubData.message}
			})
		}
	})
	.catch((secSubData)=>{
		setSuccess([])
		setErr((prev)=>{return{...prev, status:true, errMsg:secSubData.message}
		})
	})
	.finally(()=>{
		setLoader(false)
	})
}
// assign section and subject fun end

// Delete Student functions start
const[studentRecordSchCode, setStudentRecordSchCode]=useState([])
function deletStudent(schCode){
	setStudentRecordSchCode(schCode)
	setLoader(true);
	const xPostData = {
		// "schoolCode":studentRecordSchCode.schoolCode,
		"userID":studentRecordSchCode.userRefID
	}
  console.log(xPostData)
  fetch(apiBaseURL+apiURL.studentDelete,{
    method:"POST",
    headers:{'Key' : apiKey},
    body:JSON.stringify(xPostData)
  })
  .then(responce => responce.json())
  .then((deleteStudentData)=>{
    if(deleteStudentData.status=="success"){
		setDeleteRecord(false)
		setSuccess((prev)=>{
			return{...prev, status:true, errMsg:deleteStudentData.message}
		})
    }else{
		setErr((prev)=>{
			return{...prev, status:true, errMsg:deleteStudentData.message}
		})
	}
  })
  .catch((deleteStudentData)=>{
	setErr((prev)=>{
		return{...prev, status:true, errMsg:deleteStudentData.message}
	})
  })
  .finally(()=>{
	  setLoader(false);
  })
}
// Delete Student functions end

function openSidebar(itemUserDetail){
	setSecSub(itemUserDetail);
	 setSubjectSection(true)
	 setCheckSection(itemUserDetail.sectionIDs.split(","))
}

function verifyUserData(value, item, index, itemUserDetail){
	let verificationStatus = "0";
	if(value){
		verificationStatus ="1";
	}

	setLoader(true);
	const xPostData ={
		"loginID":itemUserDetail.userRefID,
		"verificationStatus":verificationStatus
		
	}
	fetch(apiBaseURL+apiURL.userVerification,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((verifyStuData)=>{
		if(verifyStuData.status=="success"){
			setUserDetailes((prev)=>{
				let oldData = [...prev];
				if(oldData[index]){
					oldData[index].verificationStatus = value?"1":"0"
				}
				return oldData;
			})
			setSuccess((prev)=>{
				return{...prev, status:true, errMsg:verifyStuData.message}
			})
		}else{

			setErr((prev)=>{
				return{...prev, status:true, errMsg:verifyStuData.message}
			})
		}
	})
	.catch((verifyStuData)=>{
		setErr((prev)=>{
			return{...prev, status:true, errMsg:verifyStuData.message}
		})
	})
	.finally(()=>{
		setLoader(false)
	})
}
// status change for student fun start
function studentStatusChange(value, item, index, itemUserDetail){
	setLoader(true)
	let status = "0";
	if(value){
		status ="1";
	}
	const xPostData ={
		"schoolCode":itemUserDetail.schoolCode,
		"status":status,
		"userRefID":itemUserDetail.userRefID
	}
	console.log(xPostData)
	fetch(apiBaseURL+apiURL.changeStudentStatus,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((stuStatusChange)=>{
		if(stuStatusChange.status=="success"){
			setUserDetailes((prev)=>{
				let oldData = [...prev];
				if(oldData[index]){
					oldData[index].status = value?"1":"0"
				}
				return oldData;
			})
			setSuccess((prev)=>{
				return{...prev, status:true, errMsg:stuStatusChange.message}
			})
		}else{

			setErr((prev)=>{
				return{...prev, status:true, errMsg:stuStatusChange.message}
			})
		}
	})
	.catch((stuStatusChange)=>{
		setErr((prev)=>{
			return{...prev, status:true, errMsg:stuStatusChange.message}
		})
	})
	.finally(()=>{
		setLoader(false)
	})
}

// status change for student fun end

// logout functions start
function doUserLogout(item){
	console.log(item.loginStatus)
	if(item.loginStatus==null){
		setErr((prev)=>{
			return{
				...prev,
				status:true,
				errMsg:"Already Logout"
			}
		})
		return
	}
	setLoader(true)
	const xPostData={
		"loginToken":item.loginStatus!=null ? item.loginStatus.loginToken : false
	}
	fetch(apiBaseURL+apiURL.logout,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((logOutData)=>{
		if(logOutData.status=="success"){
			document.getElementById('logoutBtn').style.background = "#f70c0c"
			setSuccess((prev)=>{
				return{
					...prev,
					status:true,
					errMsg:logOutData.message
				}
			})
		}else{
			setErr((prev)=>{
				return{
					...prev,
					status:true,
					errMsg:logOutData.message
				}
			})
		}
	})
	.catch((logOutData)=>{
		setErr((prev)=>{
			return{
				...prev,
				status:true,
				errMsg:logOutData.message
			}
		})
	})
	.finally(()=>{
		setLoader(false)
	})
}
// logout function end




  return (
    <div className={`${SuperAdminCss.backDiv}`}>
      {/* Header Section */}
      <div className={`${SuperAdminCss.rightArea}`}>
        {/* Header */}
        <Header />
        {/* Header */}
        <div className={`${SuperAdminCss.buttonBgs}`}>
          <Container fluid>
              <Row>
                  <Col>
                      <div className={`${SuperAdminCss.Heading}`}>Manage Student</div>
                  </Col>
                  <Col>
                      <div className={`${SuperAdminCss.backTohome}`}>
                          <Link to="/superAdmin"> <button className="btn btn-secondary"><HomeIcon/> DASH BOARD </button> </Link>
                      </div>
                  </Col>
              </Row>
              <hr/>
               {/* Manage Student Popup show */}
              <div className={`${SuperAdminCss.ManageStudentBg}`}>
                <Container fluid>
                  <div className={`${SuperAdminCss.tableBgsS}`}>
                    
					<div className={`${SuperAdminCss.selectSchoolbk}`}>
                    <Row>
                      <Col>
                            <label>Select School/Institute </label>
                              <select className="form-control" onChange={(event)=>{getStudent(event.target.value)}}>
                                  <option className="d-none">Select</option>
								  <option value="">All Users</option>
                                  {showSchoolDetailes.map((item)=>{
                                    // console.log(item)
                                    return(
                                      <option key={item.schoolCode} value={item.schoolCode} >{item.schoolName}</option>
                                    )
                                   })}
                              </select>
                          
                      </Col>
					  <Col className="text-right">
					    <label>Search </label>
                        <div className={`${SuperAdminCss.inputFiled2}`} >
                            <PersonSearchIcon  className={`${SuperAdminCss.searchIcons}`}/>
                            <input type="text" className="form-control" ref={inputBox} onKeyUp={searchData} placeholder="Search" style={{width: "100%"}}/>
                        </div>
                      </Col>
                    </Row>
					</div>
                    <Row>
                      <div className={`${SuperAdminCss.searchOption}`}></div>

                      <Table className={`${SuperAdminCss.TableManageT}`} striped  bordered  hover responsive ref={table}>
                        <thead>
                          <tr>
                            <th>Sr No.</th>
						    <th>Access Code</th>
                            <th>Student Name</th>
                            <th>Father's Name</th>
                            <th>School Code</th>
                            <th>Email ID</th>
							<th>Verified</th>
							<th>Logout</th>
                            <th>Assign Subject/Section</th>
                            <th>Active/Inactive</th>
                            <th>Update</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                          {showUserDetailes.map((item,index)=>{
							let itemUserDetail = {...item.userDetail};
                           return(
                              <React.Fragment key={index}>
                              <tr>
                                    <td>{index+1}</td>
									<td>{item.userName}</td>
                                    <td>{itemUserDetail.firstName+" "+itemUserDetail.lastName}</td>
                                    <td>{itemUserDetail.guardianName}</td>
                                    <td>{itemUserDetail.schoolCode}</td>
                                    <td>{itemUserDetail.userEmail}</td>
									{/* <td><DoneAllIcon className={`${SuperAdminCss.verifiedBtn}`}/></td> */}
									<td>
										<label className={`${SuperAdminCss.switch}`}>
											<input onChange={(e)=>{verifyUserData(e.target.checked, item,index, itemUserDetail)}} checked={item.verificationStatus=="1"? true:false} type="checkbox" /> <span className={`${SuperAdminCss.slider} ${SuperAdminCss.round}`}></span>
										</label>
									</td>
									{item.loginStatus==null ? <td> <LogoutIcon className={`${SuperAdminCss.LogoutNull}`} id='logoutBtn' onClick={()=>{doUserLogout(item)}} style={{background:'#f70c0c'}}/> </td> : <td> <LogoutIcon id='logoutBtn' onClick={()=>{doUserLogout(item)}} className={`${SuperAdminCss.buttonLogout}`}/> </td>}
                                    <td> <button onClick={()=>{openSidebar(itemUserDetail)}} className="btn btn-secondary"> <AddTaskIcon/> Assign Subject/Section </button> </td>
                                    <td>
                                    <label className={`${SuperAdminCss.switch}`}>
                                        <input onChange={(e)=>{studentStatusChange(e.target.checked, item, index, itemUserDetail)}} checked={item.status=="1"?true:false} type="checkbox" /> <span className={`${SuperAdminCss.slider} ${SuperAdminCss.round}`}></span>
                                    </label>
                                    </td>
                                    <td title="Edit Student"> <EditIcon onClick={()=>{setupdateStudent(true); SetUpdateStu(itemUserDetail)}} className={`${SuperAdminCss.editButton}`}/></td>
                                    <td onClick={()=>{setDeleteRecord(true); setStudentRecordSchCode(itemUserDetail)}} title="Delete Student"> <DeleteForeverIcon className={`${SuperAdminCss.delete}`}/></td>
                              </tr>
                              </React.Fragment>
                            )
                          })}
                         
                        </tbody>
                      </Table>
                    </Row>
                    <Row className={`${SuperAdminCss.fixedBottomButton}`}>
                        <Col>
                            <button onClick={()=>{setaddStudent(true)}} className="btn btn-primary"> <AddBoxIcon /> ADD STUDENT</button>
                        </Col>
                    </Row>
                  </div>
                </Container>
              </div>
            {/* Manage Student Popup show */}



			{/* Add Students popup start */}
			{addStudent && 
              	<div className={`${SuperAdminCss.AddStudentBk}`}>
                	<div className={`${SuperAdminCss.innerBox}`}>
                  		<div className={`${SuperAdminCss.addstuHeading}`}>Add Student</div>
                  			<form className={`${SuperAdminCss.addstuForm}`} onSubmit={onSubmitcreateStudent}>
                        		<div className={`${SuperAdminCss.addStuForm}`}>
									<div className="mt-2">
										<label>Select School</label>
										<select className="form-control" name="schoolCode" onChange={e=>addStudentOnchange(e)}>
											<option value="">All Users</option>
											<option className="d-none">Select</option>
											{showSchoolDetailes.map((item,index)=>{
												return(
													<option key={index} value={item.schoolCode}>{item.schoolName}</option>
												)
											})}
										</select>
									</div>
									<div className="mt-3">
                                <label>Student Name</label>
                                <input id='stuNameFi'  name="studentName" value={studentName} onChange={e=>addStudentOnchange(e)} type="text" placeholder="Student Name" className="form-control createStuFields"/>
                            </div>

                            <div className="mt-3">
                                <label>Father's Name</label>
                                <input  name="fathertName" value={fathertName} onChange={e=>addStudentOnchange(e)}  type="text" placeholder="Father Name" className="form-control createStuFields"/>
                            </div>
                            <div className="mt-3">
                                <label>Address</label>
                                <input  name="address" value={address} onChange={e=>addStudentOnchange(e)}  type="text" placeholder="Address" className="form-control createStuFields"/>
                            </div>

                            <div className="mt-3">
                                <label>Select State </label>
                                <select name="studentState"  className="form-control createStuFields" onChange={e=>addStudentOnchange(e)}>
                                 <option className="d-none">Select</option>
                                        {showStateList.map((item,index) => {
                                            return <option key={index} value={item.stateID}>{item.stateNameLang1}</option>
                                        })}       
                                </select>
                            </div>
                            <div className="mt-3">
                                <label>Pin code</label>
                                <input onKeyPress={(event) => {if (!/[0-9]/.test(event.key))   {event.preventDefault();}}}   name="pincode" value={pincode} onChange={e=>addStudentOnchange(e)} type="number" placeholder="Pincode" className="form-control createStuFields"/>
                            </div>
                            <div className="mt-3">
                                <label>Contact No</label>
                                <input maxLength="12"  onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  name="contactNo" value={contactNo} onChange={e=>addStudentOnchange(e)} type="number" placeholder="Contact" className="form-control createStuFields"/>
                            </div>
                            <div className="mt-3">
                                <label>E-mail ID</label>
                                <input  name="studentEmailID" value={studentEmailID} onChange={e=>addStudentOnchange(e)} type="email" placeholder="E-mail ID" className="form-control createStuFields"/>
                            </div>
                            <div className="mt-3">
                                <label>Date of birth</label>
                                <input  name="dob" value={dob} onChange={e=>addStudentOnchange(e)} type="date" className="form-control createStuFields"/>
                            </div>
                           
                            <div className={`mt-3 ${SuperAdminCss.footerbuttonRow}`}>
                                <input type="submit" className={`btn btn-success ${SuperAdminCss.SubmitBTN}`} value="SAVE"/> {' '}   
								<button className="btn btn-danger" onClick={()=>{setaddStudent(false); setStudentAdd([])}}> CLOSE </button>
                            </div>
                            	</div>
                  			</form>
                	</div>
              	</div>
            	}
			{/* Add Students popup end */}
				{/* update student start */}
				{updateStudent &&
				<div className={`${SuperAdminCss.AddStudentBk}`}>
                	<div className={`${SuperAdminCss.innerBox}`}>
                  		<div className={`${SuperAdminCss.addstuHeading}`}>Update Student</div>
                  			<form className={`${SuperAdminCss.addstuForm}`}>
                        		<div className={`${SuperAdminCss.addStuForm}`}>
									<div className="mt-3">
										<label>Student Name</label>
										<input  name="firstName" value={UpdateStu.firstName} onChange={updateStuOnchange} type="text" placeholder="Student Name" className="form-control createStuFields"/>
                            		</div>
									<div className="mt-3">
										<label>Father's Name</label>
										<input  name="guardianName" value={UpdateStu.guardianName} onChange={updateStuOnchange}  type="text" placeholder="Father Name" className="form-control createStuFields"/>
									</div>
									<div className="mt-3">
										<label>Address</label>
										<input  name="userAddress" value={UpdateStu.userAddress} onChange={updateStuOnchange}  type="text" placeholder="Address" className="form-control createStuFields"/>
									</div>
									<div className="mt-3">
										<label>Select State </label>
										<select name="stateID"  className="form-control createStuFields" onChange={updateStuOnchange}>
										<option className="d-none">Select</option>
											{showStateList.map((item,index) => {
												return <option key={index} value={UpdateStu.stateID}>{item.stateNameLang1}</option>
											})}       
										</select>
									</div>
									<div className="mt-3">
										<label>Pin code</label>
										<input onKeyPress={(event) => {if (!/[0-9]/.test(event.key))   {event.preventDefault();}}}   name="zipCode" value={UpdateStu.zipCode} onChange={updateStuOnchange} type="number" placeholder="Pincode" className="form-control createStuFields"/>
									</div>
									<div className="mt-3">
										<label>Contact No</label>
										<input maxLength="12"  onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  name="userContact" value={UpdateStu.userContact} onChange={updateStuOnchange} type="number" placeholder="Contact" className="form-control createStuFields"/>
									</div>
									<div className="mt-3">
										<label>E-mail ID</label>
										<input  name="userEmail" value={UpdateStu.userEmail} onChange={updateStuOnchange} type="email" placeholder="E-mail ID" className="form-control createStuFields"/>
									</div>
									<div className="mt-3">
										<label>Date of birth</label>
										<input  name="dateOfBirth" value={UpdateStu.dateOfBirth} onChange={updateStuOnchange} type="date" className="form-control createStuFields"/>
									</div>
									<div className={`mt-3 ${SuperAdminCss.footerbuttonRow}`}>
										<button type="button" onClick={upDataStuOnSubmit} className={`btn btn-success ${SuperAdminCss.SubmitBTN}`}>SAVE</button> {' '}   
										<button onClick={()=>{setupdateStudent(false)}} className="btn btn-danger"> CLOSE </button>
									</div>
                            	</div>
                  			</form>
                	</div>
              	</div>
			}
				{/* assign section and subject Popup start */}
				{subjectSection && 
					<div className={`${SuperAdminCss.basePopDiv}`}>
						<div className={`${SuperAdminCss.innerBoxPop}`}>
							<div className={`${SuperAdminCss.headingDivAssign}`}><AddTaskIcon/> Assign section and subject</div>
							<form>
								<div className={`${SuperAdminCss.secBox}`}>
									{sectionsList.map((item, sectionsL)=>{
										return(
											<>
											<label key={sectionsL} className={`${SuperAdminCss.labelRow}`}>
												<input  onChange={(e)=>{sectionHendler(e, item.sectionID)}}  name="section" type="checkbox" value={item.sectionID} checked={checkSection.includes(item.sectionID.toString())} />{item.sectionName}
											</label>
											</>
										)
									})}
								</div>
								{subjectBox && 
									<div className={`${SuperAdminCss.secBox}`}>
										<label>Subject for section - II</label>
										{subjectList.map((item, subjectkey)=>{
											if(item.subjectID<=13 || item.subjectID==15)
											{											
												return(
													<>
													<label key={subjectkey} className={`${SuperAdminCss.labelRow}`}>
														<input onClick={(e)=>{subjectHendler(e, item.subjectID)}} type="checkbox" name="subjects" value={item.subjectID} />{item.subjectName}
													</label>
													</>
												)
											}
										})}
									</div>
								}

								<div className={`${SuperAdminCss.btnRow}`}>
									<button onClick={()=>{assignSectionSub()}}  type="button" className="btn btn-success">SAVE !</button>{'  '}
									<button type="button" onClick={()=>{setSubjectSection(false); {setSubjectBox(false)}}} className="btn btn-danger">CLOSE</button>
								</div>
							</form>
						</div>
					</div>
				}
				{/* assign section and subject Popup end */}

                {/* err popup code start */}
                {showErr.status &&
                    <div className={`${SuperAdminCss.showErrbase}`}>
                      <div className={`${SuperAdminCss.inerBox}`}>
                        <div><ErrorOutlineIcon className={`${SuperAdminCss.errIcons}`}/></div>
                          <div className={`${SuperAdminCss.errMessageDiv}`}>{showErr.errMsg}</div>
                        <button onClick={(()=>{setErr(false)})} className='btn btn-danger mt-2 btn-lg'> OK </button>    
                      </div>
                    </div>
                  }
                {/* err popup code end */}

                  {/* success popup code start */}
                  {showSuccess.status &&
                    <div className={`${SuperAdminCss.showErrbase}`}>
                      <div className={`${SuperAdminCss.inerBox}`}>
                        <div><CheckCircleOutlineIcon className={`${SuperAdminCss.successIcons}`}/></div>
                          <div className={`${SuperAdminCss.successMes}`}>{showSuccess.errMsg}</div>
                        <button onClick={(()=>{setSuccess(false)})} className='btn btn-success mt-2 btn-lg'> OK </button>    
                      </div>
                    </div>
                  }
                {/* success popup code end */}

				   {/* Delete School Popup start */}
					{deleteRecord && 
						<div className={`${SuperAdminCss.popDeleteBase}`}>
							<div className={`${SuperAdminCss.innerBoxes}`} data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
									<ContactSupportIcon className={`${SuperAdminCss.questionICon}`}/>
									<div className={`${SuperAdminCss.areYousure}`}>Are you sure !</div>
									<div className={`${SuperAdminCss.doyoureally}`}>Once Deleted Never can be Recalled</div>
									<button onClick={()=>{setDeleteRecord(false)}} className={`btn btn-secondary mt-4 ${SuperAdminCss.buttonconfir}`}>CANCEL</button>{'  '}
									<button onClick={()=>{deletStudent()}} className={`btn btn-danger mt-4 ${SuperAdminCss.buttonconfir}`}>DELETE</button>

							</div>
						</div>
					}
		 		   {/* Delete School Popup end */}

                </Container>                       
                  </div>
                      
                </div>
              {/* Loader code start */}
              {loader &&
                <Loader/>
              }
              {/* Loader code end */}

    </div>
  );
}

export default ManageStudentSuperAdmin;
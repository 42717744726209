import { Col, Container, Row } from 'react-bootstrap';
import StuDashBoardCss from './stuDashboard.module.css';
import QuickNotes from '../../assets/quick_notes.png';
import TopicWise from '../../assets/topic_wise_practice.png';
import PracticeSet from '../../assets/practice_set.png';
import selfAssessment from '../../assets/self_asmt.png';
import finalExam from '../../assets/final_exam.png';
import report from '../../assets/report.png';
import examPaper from '../../assets/examPaper.png';
import RightSideFixedButton from './BySubjectWithButton';
import tagLine from '../../assets/tag.png';
import {Link } from 'react-router-dom';
import { useState, useContext } from 'react';
import userContext from './../../Store';


function StudentDash() {
	const {userData} = useContext(userContext);
   return (
    <div>
    <RightSideFixedButton/> 
	<>
    <div className={`${StuDashBoardCss.backDivButton}`}>
		<Container fluid>
			<Row className='justify-content-around'>
				<Col className='col-md-2 col-sm-4 col-12'>
					<Link to="/student/quickNotes" >
						<div  className={`${StuDashBoardCss.cricleBg}`}>
							<div className={`${StuDashBoardCss.boxWithIcon}`}>
								<img src={QuickNotes}/>
							</div>
							<p className={`${StuDashBoardCss.buttonBottomTxt}`}>Quick Notes</p>
						</div>
					</Link>
				</Col>
				<Col className='col-md-2 col-sm-4 col-12'>
					<Link to="/student/TopicWisePractice" >
						<div className={`${StuDashBoardCss.cricleBg}`}>
							<div className={`${StuDashBoardCss.boxWithIcon}`}>
								<img src={TopicWise}/>
							</div>
							<p className={`${StuDashBoardCss.buttonBottomTxt}`}>Topic/Chapter Wise Practice</p>
						</div>
					</Link>
				</Col>
				<Col className='col-md-2 col-sm-4 col-12'>
					<Link to="/student/practice-sets">
						<div className={`${StuDashBoardCss.cricleBg}`}>
							<div className={`${StuDashBoardCss.boxWithIcon}`}>
								<img src={PracticeSet}/>
							</div>
							<p className={`${StuDashBoardCss.buttonBottomTxt}`}>Practice Sets</p>
						</div>
					</Link>
				</Col>
				<Col className='col-md-2 col-sm-4 col-12'>
					<Link to="/student/Self-Generated-Exams">
						<div className={`${StuDashBoardCss.cricleBg}`}>
							<div className={`${StuDashBoardCss.boxWithIcon}`}>
								<img src={selfAssessment}/>
							</div>
							<p className={`${StuDashBoardCss.buttonBottomTxt}`}>Self-Generated Practice  Exams</p>
						</div>
					</Link>
				</Col>
				<Col  className='col-md-2 col-sm-4 col-12'>
					<Link to="/student/mock-cuet">
						<div className={`${StuDashBoardCss.cricleBg}`}>
							<div className={`${StuDashBoardCss.boxWithIcon}`}>
								<img src={finalExam}/>
							</div>
							<p className={`${StuDashBoardCss.buttonBottomTxt}`}>Mock CUET</p>
						</div>
					</Link>
				</Col>
				<Col  className='col-md-2 col-sm-4 col-12'>
					<Link to="/student/Report">
						<div className={`${StuDashBoardCss.cricleBg}`}>
							<div className={`${StuDashBoardCss.boxWithIcon}`}>
								<img src={report}/>
							</div>
							<p className={`${StuDashBoardCss.buttonBottomTxt}`}>Report</p>
						</div>
					</Link>
				</Col>
				{ userData.userRefID==240 && 
				<Col  className='col-md-2 col-sm-4 col-12'>
					<Link to="/student/examination-paper">
						<div className={`${StuDashBoardCss.cricleBg}`}>
							<div className={`${StuDashBoardCss.boxWithIcon}`}>
								<img src={examPaper}/>
							</div>
							<p className={`${StuDashBoardCss.buttonBottomTxt}`}>CUET Examination paper</p>
						</div>
					</Link>
				</Col>
				}

			</Row>
		</Container>
      
    </div>
    {/* tag line */}
    <div className={`${StuDashBoardCss.tagLineImg}`}>
      	<img src={tagLine}/>
    </div>
    {/* tag line */}
    </>
  </div> 
  
  );
}

export default StudentDash;

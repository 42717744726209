import React from "react";
import WelcomeCss from "./Welcomeuser.module.css";
import Step from "../../assets/step.png";
import Header from "../studentDashboard/header";
import { Link } from "react-router-dom";
import { useContext } from "react";
import userContext from "../../Store";

export default function Welcomeuser() {
  const { userData} = useContext(userContext);
  return (
    <div>
      <Header />
      <div className={`${WelcomeCss.container}`}>
        <div className={`${WelcomeCss.mainDiv}`}>
          <div className={`${WelcomeCss.followStep}`}>
            <img src={Step} />
          </div>
          <div className={`${WelcomeCss.TextBox}`}>
            <h1>Welcome {userData.firstName}</h1>
            <p>
            “We are happy you are here. This portal will help you with the right and necessary knowledge and practice based on the new curriculum of CUET.”
            </p>
            <h4>Step-by-step approach to use this portal</h4>
            <ol>
              <li>
              Brush up the concepts firstly by going through Quick Notes as a warm- up.
              </li>
              <li>
              Topic/Chapter-wise Practice will help you to learn the concepts by attempting the questions and getting its report.
              </li>
              <li>
              After learning the concepts through Topic/Chapter- wise Practice, you are ready  to attempt the Practice Sets for integrated practice which include all the topics.

              </li>
              <li>
              Time to check your Practice ! Self- Generated Practice Exams ( With Timer and Without Timer) will generate the paper for final assessment.

              </li>
              <li>
              Ready for the Mock CUET.Once you have gone through all the above steps sequentially, you can take the final exam with confidence. These papers are exactly according to the prescribed guidelines.
              </li>
              <li>
              You can check the report of Topic / Chapter-wise Practice , Practice Sets, Self -Generated Practice Exams and Mock CUET  immediately once you have  attempted these.
              </li>
            </ol>
            <h5 className={`${WelcomeCss.happyLearning}`}> BEST OF LUCK!!</h5>
          </div>

          <div className={`${WelcomeCss.btnDiv}`}>
            <Link to="/student">
              <button type="submit" className={`${WelcomeCss.logButton}`}>
                Let's Start
              </button>
            </Link>
          </div>
        </div>
        <div className={`${WelcomeCss.area}`}>
          <ul className={`${WelcomeCss.circles}`}>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

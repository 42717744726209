import React, { useState, useContext, useEffect } from "react";
import Header from '../homePage/Header';
import Footer from '../homePage/Footer';

import AOS from "aos";
import "aos/dist/aos.css";

import policyimg from '../../assets/policyImg.png';

import { Container, Row , Col } from "react-bootstrap";
import layout from "../layout/innerPage.css";
import innerResp from "../layout/innerResp.css";

const Privacypolicy = () => {  

  useEffect( () =>{
    AOS.init({
      offset:100,
      duration:3000,
    });
  },[] );

 return (   
    <>
    <Header/>
    <div className="privacy-main">
      <Container>
        <Row>     
          <Col md={9} data-aos="fade-right">
            <h1> PRIVACY POLICY </h1>
            <p>The following terms and conditions will be deemed to have been accepted by the User on usage of the website
            <a target="_blank" href="https://cuet.rachnasagar.in"><b> https://cuet.rachnasagar.in/</b></a> </p>
            <p>You are requested to read them carefully before you use the services of this site.</p>
          </Col> 
          <Col md={3}>
            <div className="text-center p-2" data-aos="zoom-in">
              <img className="mw-100 animation" src={policyimg}/> 
            </div>
          </Col>      
        </Row>       
      </Container>
    </div>
    <div className="botton-banner"> Cancellation & Refund Policy </div>
    <div className="privacy-section">
      <Container>
      <p data-aos="fade-right">As of now, we do not provide any option for cancellation of products and/or services you have purchased or 
      subscribed. Once a product/service has been purchased by you, we cannot provide any refund, either totally or partially. We suggest that 
      you first go through the demos and/or free to use contents/products/services before you subscribe to or purchase from swaadhyayan.com.</p> 
      </Container> 
      <div className="botton-banner"> Pricing Policy </div>
      <Container>
      <p data-aos="fade-left">All prices are subject to change without any notice. Our Pricing Policy includes all the taxes and norms that are 
      applicable as per the government policies.</p> 
      </Container> 
      <div className="botton-banner"> Advertisement </div>
      <Container>
      <p>The site may at its discretion display advertisements. The user’s correspondence or business dealings with, or participation in 
        promotions of, advertisers found on or through swaadhyayan.com service, including payment and delivery of related goods or services, 
        and any other terms, conditions, warranties or representations associated with such dealings, are solely between the user and such 
        advertiser. The user agrees that swaadhyayan.com shall not be responsible or liable for any loss or damage of any sort incurred as a 
        result of any such dealings or as a result of the presence of such advertisers on the swaadhyayan.com service.</p> 
      </Container> 
      <div className="botton-banner"> Personal Data </div>
      <Container>
      <p data-aos="fade-right">Personal data refers to any information that tells us something about you or that we can link to you. We will 
      only collect and process personal data about you where we have lawful bases. Lawful bases on which we would process our personal 
      information includes obtaining explicit consent from you for processing your personal information or processing for ‘legitimate 
      interests’ where processing is necessary by us to provide you with the required services. However, please note that your personal data may
      be shared with legal authorities, if required by law.<br/><br/>
      Swaadhyayan.com uses a variety of technologies and procedures to help protect personal information from unauthorized access, use or 
      disclosure.</p> 
      </Container> 
      <div className="botton-banner"> Google Analytics </div>
      <Container>
      <p data-aos="zoom-in-left">Swaadhyayan.com adopts Google Analytics software to carry out Site usage analytics. Google Analytics gathers 
      anonymous info from visitors to assist swaadhyayan.com in tracing Site usage and citations from other sites. The tracked data are employed
       principally to enhance the website experience for our visitors, however, we may also use the tracked data to help us in improving 
       marketing strategies for our Site.<br/><br/>
       Whatever data is gathered and administered by Google Analytics chiefly comprises the user’s IP address, network and geographical 
       location. All such information is directly collected from the user by Google Analytics, simply by installing a cookie on the user’s 
       computer. Swaadhyayan.com does not share any information it gathers from Google, and Google Analytics does not save any privately 
       identified information. Correspondingly, we cannot operate any info attained by Google Analytics to directly contact a visitor.</p> 
      </Container> 
      <div className="botton-banner"> Cookies </div>
      <Container>
      <p data-aos="zoom-in-right">Cookie is a small text file of letters and numbers that is stored by the browser on your computer when you 
      visit certain websites. A cookie allows a website to distinguish your computer from other users’ computers. Cookies do not contain 
      Personal Data unless you have already given this information to the Website. You may delete or block the cookies, if that is what you 
      choose to do. Note that this may make it less convenient for you to use our website. Swaadhyayan.com website may contain links to other
      websites of your interest. Please note that once the Visitor opts to link to other websites, swaadhyayan.com shall not be responsible 
      for protection of further disclosure of information and this Privacy Policy/statement cease to be applicable for such disclosure of 
      information. The Visitor may therefore, like to exercise caution and look at the privacy statement applicable to those websites. 
      Similarly your Financial Data like Account Number, Debit/Credit Card Number, etc., provided by you to pay us through third party Payment 
      Gateway is protected by the service provider only. Remember, swaadhyayan.com never asks for any such information from you directly and 
      is not in any case, responsible for the misuse of the same.<br/><br/>
      Swaadhyayan.com website may be available on various other third party websites. However, swaadhyayan.com shall not be responsible to the 
      contents and material of such other websites. If any person provides its information on other websites, where the link of swaadhyayan.com 
      or swaadhyayan.com logo is available, swaadhyayan.com shall not be responsible to any act or omission committed by third party websites 
      including but not limited to data theft or misuse of information of such a person.<br/><br/>
      Swaadhyayan.com reserves its right to amend the present Privacy Policy at any time and will place such amended Privacy Policy, if any, on its 
      website. This Privacy Policy is neither intended to and nor does it create any contractual rights whatsoever or any other legal rights, nor 
      does it create any obligations on swaadhyayan.com in respect of any other party or on behalf of any party. By accessing this website and any 
      of its pages, you are agreeing to the terms set out above.</p> 
      </Container> 
      <div className="botton-banner"> Security Policy </div>
      <Container>
      <p data-aos="zoom-in-left">The user might receive a password and User ID upon completing the service's registration process. The users are
      expected to maintain the confidentiality of the password and account, and shall be held solely responsible for all acts and omissions of
      password or unique ID. Swaadhyayan.com cannot and will not be liable for any loss or damage arising from the user’s failure to comply 
      with the terms and conditions of this agreement.</p> 
      </Container> 
      <div className="botton-banner"> Termination </div>
      <Container>
      <p data-aos="zoom-in-right">If users commit any breach of trust under these terms and conditions like disclose your user ID and Password 
      to another who is not authorized, swaadhyayan.com have right to terminate or discontinue services immediately without any notice.</p> 
      </Container> 
      <div className="botton-banner"> Arbitration </div>
      <Container>
      <p data-aos="zoom-in-left">Any dispute, difference or question arising out of these terms and conditions shall be settled amicably between
       the user and officer who is appointed by swaadhyayan.com, failing which, the same shall be referred to arbitrator under the Arbitration 
       and Conciliation Act 1996, and the venue of the arbitration shall be in Delhi only.</p> 
      </Container> 
      <div className="botton-banner"> Court of Jurisdiction </div>
      <Container>
      <p data-aos="zoom-in-right">Swaadhyayan.com controls and operates this website from its headquarters in Delhi, India. You agree that any 
      dispute shall be subject to the exclusive jurisdiction of Courts at Delhi only.</p> 
      </Container> 
      <div className="botton-banner"> Content and Liability Disclaimer </div>
      <Container>
      <p data-aos="fade-left">This disclaimer of liability applies to any damage or injury caused by any failure of performance, error, 
      omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or 
      destruction or unauthorized access to, alteration of, or use of record, whether for breach of contract, tortuous behavior, negligence, 
      or under any other cause of action.<br/><br/>
      The documents and graphics on this website may include technical inaccuracies or typographical errors. Changes are periodically updated 
      to the information herein. Swaadhyayan.com may make improvements and/or changes herein at any time. Swaadhyayan.com makes no 
      representations about the accuracy of the information contained in the documents and graphics on this website for any purpose. All 
      documents and graphics are provided ‘as is’. Swaadhyayan.com hereby disclaims all warranties and conditions with regard to this 
      information, including all implied warranties and conditions of merchantability, fitness for any particular purpose, title and 
      non-infringement. In no event, Swaadhyayan.com and/or its licensor/supplier shall not be liable to any party for any direct, indirect,
      special or other consequential damages for any use of the sites, the information, or on any other hyperlinked website, including, 
      without limitation, any lost profits, business interruption, loss of programs or other data on your information handling system or 
      otherwise, even if Swaadhyayan.com is expressly advised of the possibility of such damages.<br/><br/>
      Use of this website is at your own risk, and the swaadhyayan.com will not be held liable for any error or omission contained in this 
      website. In no event, shall swaadhyayan.com be liable for any special, indirect or consequential damages, or any damage whatsoever, 
      resulting from the loss of use, data or profits whether contract, negligence or any tort action arising out of, or in connection with, 
      the use or performance of the information available from swaadhyayan.com.</p> 
      </Container> 
    </div>      
    <Footer/>
    </>
  );
}
export default Privacypolicy;

import StuDashBoardCss from './stuDashboard.module.css';
import ReorderIcon from '@mui/icons-material/Reorder';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import { AiFillDashboard } from "react-icons/ai";
import QuickNotes from '../../assets/quick_notes.png';
import TopicWise from '../../assets/topic_wise_practice.png';
import PracticeSet from '../../assets/practice_set.png';
import selfAssessment from '../../assets/self_asmt.png';
import finalExam from '../../assets/final_exam.png';
import report from '../../assets/report.png';
import { useRef } from 'react';
import DashboardSharpIcon from '@mui/icons-material/DashboardSharp';
import { useState,useContext } from 'react';
import { Link, useLocation } from "react-router-dom";

import userContext from './../../Store';

function StudentDash() {
  const leftMenu = useRef();
  const [isShow, setShow]=useState(false);
  const pathName = useLocation().pathname;
  const {userData} = useContext(userContext);
  
  return (
    <div className={`${StuDashBoardCss.backDiv}`}>
		<div ref={leftMenu} className={`${StuDashBoardCss.leftNavigation}`}>
			<Link  to="/student">
				<div  className={`${StuDashBoardCss.leftButton}`}>
					<DashboardSharpIcon className={`${StuDashBoardCss.dashboardButton}`}/>
					<div className={`${StuDashBoardCss.leftText}`}>DASHBOARD</div>
				</div>
			</Link>
			<Link className={pathName=='/student/quickNotes'?StuDashBoardCss.ActiveButton:null} to="/student/quickNotes">
				<div  className={`${StuDashBoardCss.leftButton}`}>
					<div className={`${StuDashBoardCss.iconLeft}`}>
						<img src={QuickNotes}/>
					</div>
					<div className={`${StuDashBoardCss.leftText}`}>Quick Notes</div>
				</div>
			</Link>
			<Link className={pathName=='/student/TopicWisePractice'?StuDashBoardCss.ActiveButton:null} to="/student/TopicWisePractice">
				<div className={`${StuDashBoardCss.leftButton}`}>
					<div className={`${StuDashBoardCss.iconLeft}`}>
						<img src={TopicWise}/>
					</div>
					<div className={`${StuDashBoardCss.leftText}`}>Topic/Chapter Wise Practice</div>
				</div> 
			</Link>
			<Link className={pathName=='/student/practice-sets'?StuDashBoardCss.ActiveButton:null} to="/student/practice-sets">
				<div className={`${StuDashBoardCss.leftButton}`}>
					<div className={`${StuDashBoardCss.iconLeft}`}>
						<img src={PracticeSet}/>
					</div>
					<div className={`${StuDashBoardCss.leftText}`}>Practice Sets</div>
				</div>
			</Link>
			<Link className={pathName=='/student/Self-Generated-Exams'?StuDashBoardCss.ActiveButton:null} to="/student/Self-Generated-Exams">
				<div className={`${StuDashBoardCss.leftButton}`}>
					<div className={`${StuDashBoardCss.iconLeft}`}>
						<img src={selfAssessment}/>
					</div>
					<div className={`${StuDashBoardCss.leftText}`}>Self-Generated Practice Exams</div>
				</div>
			</Link>
			<Link className={pathName=='/student/mock-cuet'?StuDashBoardCss.ActiveButton:null} to="/student/mock-cuet">
				<div className={`${StuDashBoardCss.leftButton}`}>
					<div className={`${StuDashBoardCss.iconLeft}`}>
						<img src={finalExam}/>
					</div>
					<div className={`${StuDashBoardCss.leftText}`}>Mock CUET</div>
				</div>
			</Link>
			<Link className={pathName=='/student/Report'?StuDashBoardCss.ActiveButton:null} to="/student/Report">
				<div className={`${StuDashBoardCss.leftButton}`}>
					<div className={`${StuDashBoardCss.iconLeft}`}>
						<img src={report}/>
					</div>
					<div className={`${StuDashBoardCss.leftText}`}>Report</div>
				</div>
			</Link>

			{ userData.userRefID==240 &&
			<Link className={pathName=='/student/examination-paper'?StuDashBoardCss.ActiveButton:null} to="/student/examination-paper">
				<div className={`${StuDashBoardCss.leftButton}`}>
					<div className={`${StuDashBoardCss.iconLeft}`}>
						<img src={report}/>
					</div>
					<div className={`${StuDashBoardCss.leftText}`}>CUET Examination paper</div>
				</div>
			</Link>
			}

			<a href="http://203.122.47.149:7999/">
				<div className={`${StuDashBoardCss.leftButton}`}>
					<div className={`${StuDashBoardCss.iconLeft}`}>
						<img src={report}/>
					</div>
					<div className={`${StuDashBoardCss.leftText}`}>Cuet Mock Quiz</div>
				</div>
			</a>
			
			</div>
    </div>
  );
}

export default StudentDash;

import AdminDashBoardCss from './adminDashboard.module.css';
import ManageStudent from '../../assets/managestu.png';
import GenerateExam from '../../assets/genera.png';
import ManageQuestion from '../../assets/manage_question.png';
import report from '../../assets/report.png';

import { Link , useLocation} from 'react-router-dom';
import DashboardSharpIcon from '@mui/icons-material/DashboardSharp';

function AdminLeftMenu() {
  const pathName = useLocation().pathname;

  return (
    <div className={`${AdminDashBoardCss.backDiv}`}>
    <div className={`${AdminDashBoardCss.leftNavigation}`}>
     {/*  */}
     <Link  to="/admin">
        <div  className={`${AdminDashBoardCss.leftButton}`}>
        <DashboardSharpIcon className={`${AdminDashBoardCss.dashboardButton}`}/>
        <div className={`${AdminDashBoardCss.leftText}`}>ADMIN DASHBOARD</div>
      </div>
      </Link>
      <Link className={pathName=='/admin/manage-question'?AdminDashBoardCss.ActiveButton:null} to="/admin/manage-sch-ques">
        <div className={`${AdminDashBoardCss.leftButton}`}>
          <div className={`${AdminDashBoardCss.iconLeft}`}>
            <img src={ManageQuestion}/>
          </div>
          <div className={`${AdminDashBoardCss.leftText}`}>Manage Questions</div>
        </div>
      </Link>
      <Link className={pathName=='/admin/manage-student'?AdminDashBoardCss.ActiveButton:null} to="/admin/manage-student">
        <div className={`${AdminDashBoardCss.leftButton}`}>
          <div className={`${AdminDashBoardCss.iconLeft}`}>
            <img src={ManageStudent}/>
          </div>
          <div className={`${AdminDashBoardCss.leftText}`}>Manage Student</div>
        </div>
      </Link>
      {/* <Link className={pathName=='/admin/generate-exam'?AdminDashBoardCss.ActiveButton:null} to="/admin/generate-exam">
        <div className={`${AdminDashBoardCss.leftButton}`}>
          <div className={`${AdminDashBoardCss.iconLeft}`}>
            <img src={GenerateExam}/>
          </div>
          <div className={`${AdminDashBoardCss.leftText}`}>Generate Exam</div>
        </div>
      </Link> */}
      <Link className={pathName=='/admin/report'?AdminDashBoardCss.ActiveButton:null} to="/admin/report">
        <div className={`${AdminDashBoardCss.leftButton}`}>
          <div className={`${AdminDashBoardCss.iconLeft}`}>
            <img src={report}/>
          </div>
          <div className={`${AdminDashBoardCss.leftText}`}>Report</div>
        </div>
      </Link>
       {/*  */}

    </div>
  </div>
  );
}

export default AdminLeftMenu;

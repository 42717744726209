import './App.css';
import React from 'react';

function Loader() {
 




    return (
        <div className="loaderBg">
        <div className="loader">
               <div className="box">
                   <div className="circle"></div>
               </div>
               <div className="box">
                   <div className="circle"></div>
               </div>
        </div>
        </div>
       
    );
}

export default Loader;

import React, { useState, useEffect, useContext } from 'react';
import managQueCss from './ManageQue.module.css';
import GeneratePracticeSetCss from './GeneratePracticeSet.module.css';
import Alert from '@mui/material/Alert';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import Pagination from 'react-bootstrap/Pagination'
import WarningIcon from '@mui/icons-material/Warning';
import { apiBaseURL, apiURL, apiKey, RouteURL, ckFinderURL } from '../../constant';
import Loader from '../../Loader';
import userContext from './../../Store';

function GenerateFinalExam( {generateFinalExamHandler,questionsOfSetListX,questionsIDsOfSetX,questionsIDsOfSet,questionsOfSetList,sectionsList,setQuesProcessingData,quesProcessingDataHandler,quesProcessingData ,getSubTopicList,subTopicsList, getSubjectChapters, subjectList, questionsList, subjectChaptersList,searchQuestions,setQuestionsList,addSelectedQuestionsToSetQuestionListHandler} ) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const[showTopicList, setTopicList]=useState([]);
    const[showQuestionBox, setQuestionBox]=useState(false);
    const[questionsListOfSets, setQuestionsListOfSets]=useState([]);
    const[showLoader, setLoader]=useState(false)
    // const [showSubjectData, setSubjectData]=useState([]);
    const [showChapterList, setChapterList]=useState([]);
   const {userData} = useContext(userContext);


//    const 
   const[showSection_a_sets, setSection__sets]=useState(false);

const[showSection_b, setSection_b]=useState(false);
const[showSection_b_sets, setSection__sets_b]=useState(false);
const[showSection_a, setSection_a]=useState(false);

   
 useEffect(()=>{
    // getTopic(); 
    // getSubjects();
    getSet()
 },[])

function showSetQuestion(val){
    if(val== "set"){
        setQuestionBox(true);
    }else{
        setQuestionBox(false);
    }

}

// section set function start


// get Set function start
const[paperSetsList, setPaperSetsList]=useState([])
function getSet(){
    fetch(apiBaseURL+apiURL.getSetsList,{
        method: "GET",
        headers: {'key' : apiKey}
    })
    .then(response => response.json())
    .then((setData)=>{
        if(setData.status=="success"){
            // console.log(setData.data.sets);
            setPaperSetsList(setData.data.sets)
        }else{
            setPaperSetsList([])
            alert(setData.message)
        }
    })
    .catch((err)=>{
        setPaperSetsList([])
        alert(err)
    })

}
// get Set function end

// selected Data post to sets function start
const[postQuestion, setQuestion]=useState({
    questionID: "false"
})

const{questionID}= postQuestion;

const getQuesID = e =>{
    setQuestion({...postQuestion, [e.target.name]: e.target.value})
}

// console.log(postQuestion)
// selected Data post to sets function end






// const selectSetNoHandler = (e) => {

//     setQuesProcessingData( (prev)=>{
//         return {...prev,
//             'setNo':e.target.value,
//             'isFormOpened': true
//         }
//     } )

//     searchQuestions();

// }

// get questions function end




    return (
        <div>
            
            <div className={managQueCss.maincontainer}>
                

                {quesProcessingData.mode=='final' &&  quesProcessingData.isFormOpened  &&
                    <div className={`${managQueCss.colbgs}`}>
                        <Row>
                            <Col className='col-md-6'>
                                <div className={`${managQueCss.boxes}`}>
                                    <div className={`${managQueCss.scrollingDiv}`}>

                                    <form>
                                        <div className={`${GeneratePracticeSetCss.tableQuestions}`}>
                                            <div className='table-responsive'>
                                                <Table striped bordered hover>
                                                    <thead className={`${GeneratePracticeSetCss.headers_thead}`}>
                                                        <tr>
                                                            <th style={{width: '70px'}}>Sr. No.</th>
                                                            <th>
                                                                <label className={`${GeneratePracticeSetCss.containerChect}`}>
                                                                    <input 
                                                                        type="checkbox" 
                                                                        name='selectAllQues' 
                                                                        id='selectAllQues' 
                                                                        onClick={ (e)=>{
                                                                            // console.log( e.target.checked );
                                                                            let qxSet = document.getElementsByName('qxSet');
                                                                            if( e.target.checked ){
                                                                                qxSet.forEach( (x)=>{
                                                                                    x.checked = true;
                                                                                } )
                                                                            }else{
                                                                                qxSet.forEach( (x)=>{
                                                                                    x.checked = false;
                                                                                } )
                                                                            }
                                                                        } } 
                                                                    /> Select All (Questions)
                                                                    <span className={`${GeneratePracticeSetCss.checkmark}`}></span>
                                                                </label>
                                                            </th>
                                                        
                                                            {/* <th style={{width: '70px'}}>Set No.</th> */}
                                                            <th style={{width: '70px'}}>PassageID.</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {questionsList.filter( (item)=>{
                                                        if( item.setID >=1 ){
                                                            return false;
                                                        }
                                                        return true;
                                                    } ).map( (item,index)=>{
                                                        var isChacked = questionsIDsOfSetX.indexOf( item.questionID ) >= 0 ? true : false;
                                                        let questionIDs = item.passageDetail==null ? item.questionID : item.passageDetail.questionIDs;
                                                        // console.log( isChacked );
                                                        // var isExist = questionsIDsOfSet.indexOf( item.questionID ) >= 0 ? true : false;
                                                        return( 
                                                            <tr key={'dsfdsf'+index}>
                                                                <td>{index+1}</td>
                                                                <td>
                                                                    <label htmlFor={'qx'+item.questionID} className={`${GeneratePracticeSetCss.containerChect}`}>
                                                                        {isChacked &&
                                                                            <input id={'qx'+item.questionID} value={item.questionID} type="checkbox" name="qxSet" data-passageid={item.paragraphID} data-questionids={questionIDs} data-qxindex={index} onChange={ (e)=>{
                                                                                if( !e.target.checked ){
                                                                                    document.getElementById('selectAllQues').checked = false;
                                                                                }
                                                                                let questionids = e.target.getAttribute('data-questionids').split(',')
                                                                                questionids.forEach( (qID)=>{
                                                                                    document.getElementById('qx'+qID).checked = e.target.checked;
                                                                                } );
                                                                            } }
                                                                            defaultChecked={true}
                                                                            />
                                                                        }
                                                                        {!isChacked &&
                                                                            <input id={'qx'+item.questionID} value={item.questionID} type="checkbox" name="qxSet" data-passageid={item.paragraphID} data-questionids={questionIDs} data-qxindex={index} onChange={ (e)=>{
                                                                                if( !e.target.checked ){
                                                                                    document.getElementById('selectAllQues').checked = false;
                                                                                }
                                                                                let questionids = e.target.getAttribute('data-questionids').split(',')
                                                                                questionids.forEach( (qID)=>{
                                                                                    document.getElementById('qx'+qID).checked = e.target.checked;
                                                                                } );
                                                                            } }
                                                                            defaultChecked={false}
                                                                            />
                                                                        }
                                                                        <span className={`${GeneratePracticeSetCss.checkmark}`}></span>
                                                                        <span className={`${GeneratePracticeSetCss.QuestionsSpan}`} dangerouslySetInnerHTML={{__html: item.questionText}}></span>
                                                                    </label>
                                                                </td>
                                                                {/* <td>{item.setID}</td> */}
                                                                <td>{item.paragraphID}</td>
                                                                {/* <td>{questionIDs}</td> */}
                                                            </tr>
                                                        );
                                                    } )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                       
                                    </form>
                                </div>
                                
                                <button type='button' className='btn btn-success btnBottomF' onClick={addSelectedQuestionsToSetQuestionListHandler}>ADD</button>
                                      
                                </div>
                            </Col>

                            <Col className='col-md-6'>
                                <div className={`${managQueCss.boxes}`}>
                                <div className={`${managQueCss.scrollingDiv}`}>
                                    <div className={`${GeneratePracticeSetCss.tableQuestions}`}>
                                        <Table striped bordered hover>
                                            <thead className={`${GeneratePracticeSetCss.headers_thead}`}>
                                                <tr>
                                                    <th style={{width: '70px'}}>Sr. No.</th>
                                                    <th>Questions</th>
                                                    <th style={{width: '70px'}}>Topic</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {/*
                                                questionsList.filter( (item)=>{
                                                // console.log(questionsIDsOfSetX);
                                                if( questionsIDsOfSetX.indexOf(item.questionID) >=0 ){
                                                    return true
                                                }
                                                return false
                                            } )
                                            */}
                                            {questionsOfSetListX.map( (item,index)=>{
                                                let questionIDs = item.passageDetail==null ? item.questionID : item.passageDetail.questionIDs;
                                                return( 
                                                    <tr key={'vvv'+index}>
                                                        <td>{index+1}</td>
                                                        {/* <td dangerouslySetInnerHTML={{__html: item.questionText}}></td> */}
                                                        <td>
                                                            <label htmlFor={'qxx'+item.questionID} className={`${GeneratePracticeSetCss.containerChect}`}>
                                                                
                                                                <input id={'qxx'+item.questionID} value={item.questionID} type="checkbox" name="qxxSet" data-passageid={item.paragraphID} data-questionids={questionIDs} data-qxindex={index} defaultChecked={false} onChange={
                                                                    (e)=>{
                                                                        let questionids = e.target.getAttribute('data-questionids').split(',')
                                                                        questionids.forEach( (qID)=>{
                                                                            document.getElementById('qxx'+qID).checked = e.target.checked;
                                                                        } );
                                                                    }
                                                                }/>
                                                                <span className={`${GeneratePracticeSetCss.checkmark} ${GeneratePracticeSetCss.checkmarkX}`}></span>
                                                                <span className={`${GeneratePracticeSetCss.QuestionsSpan} ${GeneratePracticeSetCss.QuestionsSpanX}`} dangerouslySetInnerHTML={{__html: item.questionText}}></span>
                                                            </label>
                                                        </td>
                                                        <td>{index+1}</td>
                                                    </tr>
                                                );
                                            } )}
                                            </tbody>
                                        </Table>
                                       
                                    </div>
                                </div>
                                <button className='btn btn-success btnBottomF'
                                    onClick={ generateFinalExamHandler }
                                >Save and Generate</button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                   
                }
            </div>


           {/* Loader */}
                {showLoader && <Loader/>}
           {/* Loader */}
            
        </div>
    )
}

export default GenerateFinalExam;

import React, {useEffect, useState, useRef,useContext } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { apiBaseURL, apiURL, apiKey } from "./../../constant";
import SuperAdminCss from "./SuperAdmin.module.css";
import schoolReister from "./../../assets/schoolRegi.png";
import ManageStudent from "./../../assets/managestu.png";
import SubjectAllocate from "./../../assets/allocate_subject.png";
import Report from "./../../assets/report.png";
import Manage_orders from "./../../assets/manage_orderst.png";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Loader from "../../Loader";
import { Col, Container, Row, Table } from "react-bootstrap";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CloseIcon from "@mui/icons-material/Close";
import AddTaskIcon from '@mui/icons-material/AddTask';
import Header from "./Header";
import Pagination from "react-bootstrap/Pagination";
import { Link } from "react-router-dom";
import userContext from './../../Store';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import BUlkSample from './school.csv';
import BackupIcon from '@mui/icons-material/Backup';
import BulkSampleSec from './subsecassign.csv';
import { First } from "react-bootstrap/esm/PageItem";

// import CreateSchool from "./CreateSchool";

function SuperAdmin() {
  const[showSectionName, setSectionName]=useState([]);
  const[showErr, setErr]=useState({status:false,errMsg:""});
  const[showSuccess, setSuccess]=useState({status:false, errMsg:""})

  const [showSubjectList, setSubjectList] = useState([]);
  const [showStateList, setwStateList] = useState([]);
  const [showSchoolDetailes, setSchoolDetailes] = useState([]);
  const [showUserDetailes, setUserDetailes] = useState([])
  const[loader, setLoader] = useState(false)
  const[showSchoolType, setSchoolType]=useState([])
  const[showSubjectSecII, SetsubjectListSecII]=useState([])
  const[sectionNo, setSectionNo]=useState(true);
  const [insertRecord, setInsertRecord] = useState({status:false, msg:""})

  // const[selectedSubjects,setSelectedSubject] = useState({ 'schoolCode':'','subjects':[] });
  const {userData} = useContext(userContext);
  const inputBox = useRef(null);
  const table = useRef(null);
  const[subjectLists, setSubjectLists]=useState(false)
  const[sectionLists, setSectionLists]=useState([])
  const[noofStudentInSection, setNoofStudentInSection]=useState({
		section1: 0,		
		section3: 0,	
  }) 
  const[noofStudentInSubject, setNoofStudentInSubject]=useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0])
  const[checked, isChecked] = useState(false)
  const[assignSubSec, SetassignSubSec]=useState({
	  assignModel:false,
	  sectionsListed:false,
  })
  const[getTotalStudent, setTotalStudent] = useState('')
  const[deleteRecord, setDeleteRecord]=useState(false);
  const[ShowSchoolList, setShowSchoolList]=useState(false)
  const[editSchools, setEditSchools]=useState(false)
  const[ShowSchoolRegisterForm, setShowSchoolRegisterForm]=useState(false)
  const[totalStuValue1, setTotalStuValue1] = useState(0)
  const[totalStuValue2, setTotalStuValue2] = useState(0)
  const[totalStuValue3, setTotalStuValue3] = useState(0)

  const [studentWithSecAndStu, setStudentWithSecAndStu] =  useState([])
  const [subjectWithStudent, setSubjectWithStudent] = useState([])
  const[showAddStuPop, setAddStuPop]=useState(false);
  const [sectionData, setSecctionData] = useState()
  const [selectedImage,setSelectedImage] = useState([])
  const [discount, setDiscount] = useState('');
  var student = "";
  useEffect(() => {
    getSubject();
    getState();
    getSchool();
    getSchoolType();
    getSections()
  }, []);


    // search data functions start
    function searchData() {
      	let filter, tr, tds, i, txtValue;
      	filter = inputBox.current.value.toUpperCase();
      	tr = table.current.getElementsByTagName("tr");      
      	for (i = 1; i < tr.length; i++) {
        	tds = tr[i].getElementsByTagName("td");
        	let isExist = false;
        	for (let td of tds) {
          		txtValue = td.textContent || td.innerText;
          		if (txtValue.toUpperCase().indexOf(filter) > -1) {
            		isExist = true;
          		}
        	}
        	if (isExist) {
          		tr[i].style.display = "";
        	} else {
          		tr[i].style.display = "none";
        	}
      	}
    }
// search data functions end
    
// Assign section and subject fun start
  	function getSubject() {
		const xPostData ={"section":2}
		fetch(apiBaseURL+apiURL.getSubjectsOfSection,{
			method: "POST",
			headers: {'Key' : apiKey},
			body:JSON.stringify(xPostData)		
		})
		.then(responce => responce.json())
		.then((subjectNsecAssignDat)=>{
			if(subjectNsecAssignDat.status=="success"){
				setSubjectList(subjectNsecAssignDat.data.subjects)
			}else{
				setErr((prev)=>{
					return{...prev,	status:true, errMsg:subjectNsecAssignDat.message}
				})
			}
		})
		.catch((catch_err)=>{
			setSubjectList([])
			alert(catch_err)
		})
	}

	const bulFile = e =>{setbulkUploadData((prev)=>{ return {...prev, file:e.target.files[0]}})}
	function createMultiUserOnsubmit(e){	
		const xPostData =  new FormData();
		// xPostData.append("schoolCode", userData.schoolCode);
		xPostData.append("user_file", bulkUploadData.file)
		e.preventDefault()
		fetch(apiBaseURL+apiURL.bulkUploadUserForSuperAdmin,{
		 	method: "POST",
		 	headers: {'Key' : apiKey},
		 	body:xPostData
		})
		.then(response => response.json())
		.then((bulkuploData)=>{
		 	if(bulkuploData.status=="success"){
				setShowSchoolList(true)
			  	setInsertRecord((prev)=>{
				  	return{...prev, status:true, msg:bulkuploData.message}
			  	})
			  	setbulkUploadData(false)
		 	}else{
				//   alert(bulkuploData.message)
				setInsertRecord((prev)=>{
					return{...prev, status:true, msg:"Record Not Uploaded Successfully try Again !"}
				})
		 	}
		})
		.catch((err)=>{
		 	alert(err)
		})
	}
  	// Assign section and subject fun start
	
	//console.log("ajay bhati ")
	//console.log(totalStuValue1)
	//console.log(totalStuValue2)
	//console.log(totalStuValue3)
	//console.log("Ajay Bhati")
  	// get state functions start
  	function getState() {
		fetch(apiBaseURL + apiURL.getStateList, {
			method: "GET",
			headers: { key: apiKey },
		})
		.then((res) => {
			res.json().then((data) => {
				if (data.status == "success") {
					setwStateList(data.data);
				} else {
					alert(data.message);
				}
			});
		})
      	.catch((err) => {
        	alert(err);
      	});
  	}
  // get state functions end

    // get school functions star
    function getSchool(){
        const xPostData = {}
        fetch(apiBaseURL + apiURL.getSchoolList,{
            method: "POST",
            headers: {Key: apiKey},
            body:JSON.stringify(xPostData)
        })
        .then( res => res.json() )
        .then((Schooldata)=>{
            if(Schooldata.status == "success"){
                setSchoolDetailes(Schooldata.data.schools)
				console.log(Schooldata);
            }else{
                setSchoolDetailes([]);
                alert(Schooldata.message);
            }
        })
        .catch((err)=> {
            setSchoolDetailes([]);
            alert(err);
        })		
   }
  // get school functions end

  // get Student functions start
    function getStudent(schoolCode){
        setLoader(true)
        const xPostData ={
          "userType":3,
          "schoolCode":schoolCode
        }
        fetch(apiBaseURL+apiURL.getUsersList,{
            method: "POST",
            headers: {Key: apiKey},
            body:JSON.stringify(xPostData)
        })
        .then( response => response.json() )
        .then((data)=>{
            if(data.status == "success"){
                setUserDetailes(data.data.users)
            }else{
                setUserDetailes([])
                alert(data.message);
            }
        })
        .catch((err)=>{
            setUserDetailes([])
            alert(err);
        }).finally( ()=>{
            setLoader(false)
        } )
    }
  // get Student functions end

// get School Type function start
  function getSchoolType(){
    fetch(apiBaseURL+apiURL.getSchoolTypeList,{
      method: 'POST',
      headers: {Key : apiKey}
    })
    .then(response=> response.json())
    .then((schoolTypeData)=>{
      if(schoolTypeData.status == "success"){
        setSchoolType(schoolTypeData.data.schoolType)
      }else{
        setSchoolType([])
        alert(schoolTypeData.message);
      }
    })
    .catch((err)=>{
      setSchoolType([])
      alert(err);
    })
  }
// get School Type function end


// assign subject to School function start
const [schoolCodeGet, setSchoolData] =useState("")
const [selectedSchoolData, setSelectedSchoolData] = useState({})
function selectSchoolHendlers(schCode){
	downSchoolHendlers()
	upSchoolHendlers()
	setSchoolData(schCode)
	setSelectedSchool((prev)=>{
		return{...prev,	showSection:true,showSubjects:false}
	})
	showSchoolDetailes.map((item)=>{		
		if(item.schoolCode==schCode)
		{				
			setSelectedSchoolData(item);									
		}
	})	
	setSelectedSchool((prev)=>{
		return{...prev,	showSubjectspp:false}
	})
	//uncheck all sections
	const collection = document.getElementsByName("section");
	for (let i = 0; i < collection.length; i++) {
		if (collection[i].type == "checkbox") {
			collection[i].checked = false;
		}
	}	
}
function downSchoolHendlers(){	
	setSelectedSchool((prev)=>{
		return{...prev,	showSection:false,showSubjects:false}
	})		
}
function upSchoolHendlers(){		
	setSelectedSchool((prev)=>{
		return{...prev,	showSection:true,showSubjects:false}
	})		
}
const [selectedSchool, setSelectedSchool] = useState({
	showSection:false,
	showSubjects:false,
	showSubjectspp:false
});
const[bulkUploadData, setbulkUploadData]=useState({
	status:false,
	file:"",
})

function togglePopup(val){
	setbulkUploadData((prev)=>{
		 return {...prev, status:val}
	})
 }

const[sectionIDs, setSectionIDs] = useState([])
const[subjectIDs, setSubjectIDs] = useState([])

const[sectionTotal, setSectionTotal] = useState([])
const[subjectTotal, setSubjectTotal] = useState([])

const[sectionStudentCount, setSectionStudentCount] = useState([])
const[subjectStudentCount, setSubjectStudentCount] = useState([])
let uniqueSection = [];
let uniqueSubject = [];
let uniqueSectionTotalStudent = [];
let uniqueSubjectTotalStudent = [];
function changeStudentCountInSection(e)
{	
	let currentIndex = uniqueSection.indexOf(Number(e.target.id));
	if(e.target.value==0 || e.target.value=='')
	{
		if(currentIndex!=-1)
		{
			uniqueSection.splice(currentIndex, 1);
			uniqueSectionTotalStudent.splice(currentIndex, 1);
		}
	}else{
		if(uniqueSection.length==0)
		{
			uniqueSection.push(Number(e.target.id));
			uniqueSectionTotalStudent.push(Number(e.target.value));
		}else if(currentIndex==-1)
		{
			uniqueSection.push(Number(e.target.id));
			uniqueSectionTotalStudent.push(Number(e.target.value));
		}
		uniqueSectionTotalStudent[currentIndex] = Number(e.target.value);
	}		
	console.log(uniqueSection);	
	console.log(uniqueSectionTotalStudent);	
}
function changeStudentCountInSubject(e)
{
	let currentIndex = uniqueSubject.indexOf(Number(e.target.id));
	if(e.target.value==0 || e.target.value=='')
	{
		if(currentIndex!=-1)
		{
			uniqueSubject.splice(currentIndex, 1);
			uniqueSubjectTotalStudent.splice(currentIndex, 1);
		}
	}else{
		if(uniqueSubject.length==0)
		{
			uniqueSubject.push(Number(e.target.id));
			uniqueSubjectTotalStudent.push(Number(e.target.value));
		}else if(currentIndex==-1)
		{
			uniqueSubject.push(Number(e.target.id));
			uniqueSubjectTotalStudent.push(Number(e.target.value));
		}
		uniqueSubjectTotalStudent[currentIndex] = Number(e.target.value);		
	}		
	console.log(uniqueSubject);
	console.log(uniqueSubjectTotalStudent);	
}

function assignSectionAndSubjectTosch(){
	alert(document.getElementById("1").value);
	if(schoolCodeGet=="")
	{
		alert("Please select school first");
	}else{
		let sectionStudentCount = '{';
		uniqueSection.map((sec,index)=>{
			if(index!=uniqueSection.length-1)
			{
				sectionStudentCount +='"'+sec+'":{"totalStudent":"'+uniqueSectionTotalStudent[index]+'","sectionID":"'+sec+'"},';
			}else{
				sectionStudentCount +='"'+sec+'":{"totalStudent":"'+uniqueSectionTotalStudent[index]+'","sectionID":"'+sec+'"}';
			}
			
		})
		sectionStudentCount += "}";
		//sectionStudentCount.replace(' ','');
		let subjectStudentCount = "{";
		uniqueSubject.map((sub,index)=>{
			if(index!=uniqueSubject.length-1)
			{
				subjectStudentCount +='"'+sub+'":{"totalStudent":"'+uniqueSubjectTotalStudent[index]+'","sectionID":"'+sub+'"},';
			}else{
				subjectStudentCount +='"'+sub+'":{"totalStudent":"'+uniqueSubjectTotalStudent[index]+'","sectionID":"'+sub+'"}';
			}			
		})
		subjectStudentCount += "}";
		//subjectStudentCount.replace(' ','');		
		const xPostData ={
			"schoolCode": schoolCodeGet,
			"sectionIDs": uniqueSection,
			"subjectIDs": uniqueSubject,
			"sectionStudentCount": JSON.parse(sectionStudentCount),
			"subjectStudentCount": JSON.parse(subjectStudentCount)  
		}		
		//console.log(JSON.stringify(xPostData));		
		fetch(apiBaseURL+apiURL.allocateSubjecttoSchool,{
			method: "POST",
			headers: {'Key': apiKey},
			body:JSON.stringify(xPostData)
		})
		.then(responce => responce.json())
		.then((subAndSecData)=>{
			if(subAndSecData.status=="success"){
				setSuccess((prev)=>{
					return{...prev, status:true, errMsg:subAndSecData.message}
				})
			}else{
				setErr((prev)=>{
					return{...prev, status:true, errMsg:subAndSecData.message}
				})
			}
		})
		.catch((subAndSecData)=>{
			setErr((prev)=>{
				return{...prev, status:true, errMsg:subAndSecData.message}
			})
		})
		.finally(()=>{
			setLoader(false)
		})

	}  
}

const selectSubject = (target, index) =>{
  setSubjectList((prev)=>{
    let newData = [...prev];
    console.log(prev)
    if(newData[index]!=undefined){
      newData[index].isChecked = target.checked
    }
    return newData
  })
}
const assignSubjectSections = (schoolCode) => {
	SetassignSubSec((prev)=>{
		return{
			...prev,
			assignModel:true
		}
	})
}

function closeAssignSubModelPop(){
	SetassignSubSec((prev)=>{
		return{
			...prev,
			assignModel:false
		}
	})
	setSelectedSchool((prev)=>{
		return{
			...prev,
			showSection:false,
			showSubjectspp:false
		}
	})

}

const[checkSecToschool, setCheckSecToschool]=useState([])
function assignSecNDsubjectHeldler(e){
	const updateSecTionFSch = checkSecToschool;
	if(e.target.checked){
		updateSecTionFSch.push(e.target.value);
	}else{
		updateSecTionFSch.splice(checkSecToschool.indexOf(e.target.value),1)
	}

	const checkSectionForSchool = checkSecToschool.length
	?checkSecToschool.reduce((total, item)=>{
		return total + ','  + item;
	})
	:"";
	setCheckSecToschool(updateSecTionFSch);

	
	let secID = e.target.value;
	if(secID==2 && e.target.checked){
		setSelectedSchool((prev)=>{
			return{...prev,	showSubjectspp:true}
		})
	}else if(secID==2 && e.target.checked==false){
		setSelectedSchool((prev)=>{
			return{...prev,	showSubjectspp:false}
		})
	}
}
const[checkedSubjectTosch, setAssignSubjectTosch] = useState([])
function selectSubjectToschool(e){
	const updateSubjectTosch = checkedSubjectTosch;
	if(e.target.checked){
		updateSubjectTosch.push(e.target.value);
	}else{
		updateSubjectTosch.splice(checkedSubjectTosch.indexOf(e.target.value),1)
	}
	const checkSubjectToSch =checkedSubjectTosch.length
	?checkedSubjectTosch.reduce((total, item)=>{
		return total + ',' + item;
	})
	:"";
	setAssignSubjectTosch(updateSubjectTosch);
}
// assign subject to School function end






//   School Register Code Start
const[createSchool, setcreateSchool]=useState({
    schoolType: "",
	schoolName: "",
	School_address: "",
	School_district: "",
	School_state: "",
	School_pincode:"",
	School_contactNo: "",
	studentNoAcc: "",
	emailID:"",
	totalStudent: "",
	payMethod: "",
	remark: ""
}) 
const{schoolType,schoolName, School_address, School_district, School_state, School_pincode, School_contactNo, studentNoAcc, emailID, totalStudent, payMethod, remark}=createSchool;
const addSchool = e =>{
	setcreateSchool({...createSchool,[e.target.name]: e.target.value});
	
}


// console.log(createSchool)

function create_School_On_submit(e){
	e.preventDefault();
	// console.log(selectedImage)
	// return
	var finalArray = [...studentWithSecAndStu, ...subjectWithStudent];
	
	var stuWithSecAndStu=[];
	stuWithSecAndStu.push({['2']:subjectWithStudent})

	var finlStudData = [...studentWithSecAndStu, ...stuWithSecAndStu]

	
	var orderDetail_res="";
	orderDetail_res += "{";
	let totalStudent = 0;
	finlStudData.map((schData, schIndex)=>{

		if(schData[1]!=undefined){
			totalStudent= totalStudent + parseInt(schData[1].student)
			orderDetail_res += '"'+"1"+'": {"amount": "'+schData[1].amount+'",';
			orderDetail_res += '"totalLimit": "'+schData[1].student+'",';
			orderDetail_res += '"sectionName": "'+"Section I[English]"+'"},';
		}
		if(schData[3]!=undefined){
			totalStudent= totalStudent + parseInt(schData[3].student)
			orderDetail_res += '"'+"3"+'": {"amount": "'+schData[3].amount+'",';
			orderDetail_res += '"totalLimit": "'+schData[3].student+'",';
			orderDetail_res += '"sectionName": "'+"Section III"+'"},';
		}
		if(schData[2]!=undefined  && schData[2]!=""){
			let amount = 0;
			schData[2].map((subject, subIndex)=>{
				totalStudent= totalStudent + parseInt(subject.student)
				amount += parseInt(subject.amount)
			})
			
			orderDetail_res += '"'+"2"+'": {"amount": "'+amount+'",';
			orderDetail_res += '"subjects":';
			orderDetail_res += '{';                
			schData[2].map((subVal,index)=>{   
				orderDetail_res += '"'+(index+1)+'": {"amount": "'+subVal.amount+'","totalLimit": "'+subVal.student+'","subjectID": "'+subVal.subjectID+'","subjectName": "'+subVal.subjectName+'"},';
			}) 
			orderDetail_res += '},';
			orderDetail_res += '"sectionName": "'+"Section II"+'"},';

		}
		
	})
	orderDetail_res += "}";
	let orderDetailFinal = orderDetail_res.replaceAll('},}','}}'); 
	if(finalArray[0]==undefined){
		setErr((prev)=>{return{...prev,	status:true,errMsg:"Please select atleast one section"}})		
		return false;
	}
	
	if(createSchool.schoolType=="")
	{
		setErr((prev)=>{return{...prev,	status:true,errMsg:"Please select School/Institute"}})		
		return false;
	}else if(createSchool.schoolName==""){
		setErr((prev)=>{return{...prev,	status:true,errMsg:"Please type School/Institute name"}})
		return false;
	}else if(createSchool.School_address==""){
		setErr((prev)=>{return{...prev,	status:true,errMsg:"Please type School/Institute name"}})
		return false;
	}else if(createSchool.School_district==""){
		setErr((prev)=>{return{...prev,	status:true,errMsg:"Please type District"}})
		return false;
	}else if(createSchool.School_state==""){
		setErr((prev)=>{return{...prev,	status:true,errMsg:"Please select State"}})
		return false;
	}else if(createSchool.School_pincode==""){
		setErr((prev)=>{return{...prev,	status:true,errMsg:"Please type pincode"}})
		return false;
	}else if(createSchool.School_contactNo==""){
		setErr((prev)=>{return{...prev,	status:true,errMsg:"Please type contact no."}})
		return false;
	}else if(createSchool.emailID==""){
		setErr((prev)=>{return{...prev,	status:true,errMsg:"Please type email id"}})
		return false;

	}else if(checkSec.length==0){
		setErr((prev)=>{return{...prev,	status:true,errMsg:"Please select sections"}})
		return false;
	}else if((totalAmount+totalAmountSectionB)==0){
		setErr((prev)=>{return{...prev,	status:true,errMsg:"Total amount not proper"}})
		return false;
	}	
	

	//create data for subjects
	
	
    // I usually remove the prefix to only keep data, but it depends on your server
    
	const xPostData = {
		"schoolTypeRefID":createSchool.schoolType,
		"schoolName":createSchool.schoolName,
		"schoolAddress":createSchool.School_address,
		"schoolDistrict":createSchool.School_district,
		"stateID":createSchool.School_state,
		"zipCode":createSchool.School_pincode,
		"conntactNo":createSchool.School_contactNo,
		"email":createSchool.emailID,
		"totalStudent":totalStudent,
		"orderDetails": orderDetailFinal,
		"totalAmount":totalAmount+totalAmountSectionB-((totalAmount+totalAmountSectionB) * discount)/100,
		"paid":createSchool.payMethod,
		"remarks":createSchool.remark
	}
	
	
	fetch(apiBaseURL+apiURL.addSchoolWithAssignSubject,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})

	.then(response => response.json())
	.then((createSchoolData)=>{
		if(createSchoolData.status == "success"){
			setShowSchoolRegisterForm(false);
			setSchoolDetailes([]);
			getSchool();
			//reset all data
			setSectionLists([]);
			setSubjects([]);
			setTotalAmount(0);
			setTotalAmountSectionB(0);
			setNoofStudentInSubject([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);
			setNoofStudentInSection({section1:0,section3:0})
			//reset all data
			setSuccess((prev)=>{
				return{
					...prev,
					status:true,
					errMsg:createSchoolData.message
				}
			})
		}else{
			setErr((prev)=>{
				return{
					...prev,
					status:true,
					errMsg:createSchoolData.message
				}
			})
		}
	})
	.catch((err)=>{
		alert(err)
	})
	.finally(()=>{
        setLoader(false)

	})
}

//   School Register Code end

// get Section function start
function getSections(){
	fetch(apiBaseURL+apiURL.getSectionList,{
		method: "GET",
		headers: {'Key' : apiKey}
	})
	.then(response => response.json())
	.then((sectionListData)=>{
		if(sectionListData.status=="success"){
			setSectionName(sectionListData.data.sections)
			//console.log(sectionListData.data.sections);
		}else{
			setSectionName([])
			alert(sectionListData.message)
		}
	})
	.catch((catch_err)=>{
		setSectionName([])
		alert(catch_err)
	})
}
// get Section function end

// secHendler function start
const[checkSec, setSec]=useState([])
const[totalAmount, setTotalAmount]=useState(0)
const[totalAmountSectionB, setTotalAmountSectionB]=useState(0)

function sectionHendles(e, sectionPrice){

	let val1 = noofStudentInSection.section1;	
	let val3 = noofStudentInSection.section3;	
	
	var updatedList = checkSec;	
	if (e.target.checked) {
		updatedList.push(e.target.value);
		setTotalStudent([])
		setSecctionData(e.target.value)
		if(e.target.value!=2){				
			if(e.target.value==1){
				setTotalAmount( (totalAmount + (sectionPrice*val1)))
				
			}else{
				setTotalAmount( (totalAmount + (sectionPrice*val3)))
			}				
		}
		//add section
		sectionLists.push(e.target.value);			
	}else {
		
		updatedList.splice(checkSec.indexOf(e.target.value),1);
		console.log("helo")
		console.log(studentWithSecAndStu)
		if(e.target.value!=2){
			
			alert(e.target.value)
			let stuIndex = studentWithSecAndStu.findIndex(res=>res[e.target.value]!=undefined ? res[e.target.value].section==e.target.value!=-1 : "")
			studentWithSecAndStu.splice(stuIndex,1)
			if(e.target.value==1){
				setTotalAmount( totalAmount - (sectionPrice*val1));
			}else if(e.target.value==3){
				setTotalAmount( totalAmount - (sectionPrice*val3));
			}							
		}			
		if(e.target.value==2){
			
			setSubjects([]);
			setTotalAmountSectionB(0);
			setNoofStudentInSubject([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);
			// studentWithSecAndStu.findIndex(res=>res.section=3)
			setSubjectWithStudent([]);
		}

		//remove section
		sectionLists.splice(sectionLists.indexOf(e.target.value),1)				
	}    
	// , seperate val start
	const checkedItems = checkSec.length  ? checkSec.reduce((total, item) => {  return total + ','  + item;}) : "";
	//   console.log(checkSec)
	// , seperate val end		
	let secID = e.target.value;
	if(secID==2 && e.target.checked){
		setSubjectLists(true)
	}else{
		setSubjectLists(false)
	}

	if(secID==2){
		const xPostData ={
			"section":secID,
		}
		fetch(apiBaseURL+apiURL.getSubjectsOfSection,{
			method: "POST",
			headers: {'Key' : apiKey},
			body:JSON.stringify(xPostData)		
		})
		.then(responce => responce.json())
		.then((dataSubjSecII)=>{
			if(dataSubjSecII.status=="success"){
				SetsubjectListSecII(dataSubjSecII.data.subjects)
			}else{
				setErr((prev)=>{
					return{
						...prev,
						status:true,
						errMsg:dataSubjSecII.message
					}
				})
			}
		})
		.catch((catch_err)=>{
			setSectionName([])
			alert(catch_err)
		})
	}
	//console.log(sectionLists);

}

// subject Hendler Add School function start
const[checkSubject, setSubjects]=useState([])
const[SelectSubjectAmount, setSelectSubjectAmount]=useState(0);

function subjectHeldlersAssSchool(e, subjectPrice){
	const subjectUpdateList = checkSubject;
	
	let val2 = totalStuValue2!='' ? totalStuValue2 : 1;
	if(e.target.checked){
		subjectUpdateList.push(e.target.value);		
	}else{
		let subIndex = subjectWithStudent.findIndex(res=>res.subjectID==e.target.value)

		if(subIndex!=-1){
			subjectWithStudent.splice(subIndex,1)
		}
		subjectUpdateList.splice(checkSubject.indexOf(e.target.value),1)

	}
	setSubjects(subjectUpdateList);
}
//totalAmountSectionB
function addRemoveSubjectsCount(e){	
	if(checkSubject.indexOf(e.target.id)==-1)
	{
		alert("Please select the subject first.");
		e.target.value = "";
	}else{
		noofStudentInSubject[e.target.id] = e.target.value;
	}
	let sectionBtotal = 0;
	checkSubject.map((val)=>{
		if(!isNaN(noofStudentInSubject[val]))
		{
			sectionBtotal +=300*Number(noofStudentInSubject[val]);
		}
	})
	//apply discount
	// if(sectionBtotal==300)
	// {
	// 	sectionBtotal = 300;
	// }else if(sectionBtotal==600)
	// {
	// 	sectionBtotal = 500;
	// }else if(sectionBtotal==900)
	// {
	// 	sectionBtotal = 600;
	// }else{
	// 	sectionBtotal = (sectionBtotal/300) * 175;
	// }
	setTotalAmountSectionB(sectionBtotal);
}

// subject Hendler Add School function end

function getData(e){
	student=e.target.value=="" ? "" : e.target.value;
	let changeValue = Math.round(e.target.value);
	e.target.value = changeValue;
	
	//alert(sectionLists.indexOf("1"))
	if(e.target.id=='StuValue1')
	{
		if(sectionLists.indexOf("1")==-1){
			alert("Please select the sections First.");
			e.target.value="";
		}else{
			noofStudentInSection.section1=Math.round(e.target.value);
		}				
	}else if(e.target.id=='StuValue3'){		
		if(sectionLists.indexOf("3")==-1){
			alert("Please select the sections First.");
			e.target.value="";
		}else{
			noofStudentInSection.section3=Math.round(e.target.value);
		}			
	}else if(e.target.id=='StuValue2'){		
			
	}
	setTotalAmount(calculateAmount());
}
function calculateAmount()
{
	let totalFinalAmount = 0;
	sectionLists.map((val)=>{
		if(val==1)
		{
			totalFinalAmount += 300 * Number(noofStudentInSection.section1)
		}else if(val==3){
			totalFinalAmount += 300 * Number(noofStudentInSection.section3)
		}
	})	
	return totalFinalAmount;
}

// delete school functions start
const[deleteSchCode, setDeleteSchCode]=useState([])
function deletSchool(){
	setLoader(true);
	const xPostData = {
		"schoolCode":deleteSchCode
	}
  console.log(xPostData)
  fetch(apiBaseURL+apiURL.deleteSchool,{
    method:"POST",
    headers:{'Key' : apiKey},
    body:JSON.stringify(xPostData)
  })
  .then(responce => responce.json())
  .then((deleteSchoolData)=>{
    if(deleteSchoolData.status=="success"){
		setSchoolDetailes([])
		getSchool()
		setShowSchoolList(true)
		setDeleteRecord(false)
		setSuccess((prev)=>{
			return{...prev, status:true, errMsg:deleteSchoolData.message}
		})
    }else{
		setErr((prev)=>{
			return{...prev, status:true, errMsg:deleteSchoolData.message}
		})
	}
  })
  .catch((deleteSchoolData)=>{
	setErr((prev)=>{
		return{...prev, status:true, errMsg:deleteSchoolData.message}
	})
  })
  .finally(()=>{
	  setLoader(false);
  })

}
// delete school functions end

// change status school function start
// const[schCodeonToggle, setSchCodeonToggle]=useState([])
function changeStatusSchfun(value, item, index){
	setLoader(true)
	let status = "0";
	if(value){
		status  ="1"
	}

	const xPostData ={
		"schoolCode":item.schoolCode,
		"status":status
	}
	fetch(apiBaseURL+apiURL.changeSchoolStatus,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((schStatusData)=>{
		if(schStatusData.status=="success"){
			setSuccess((prev)=>{
				return{...prev, status:true, errMsg:schStatusData.message}
			})
			setSchoolDetailes((prev)=>{
				let oldData = [...prev];
				if(oldData[index]){
					oldData[index].status = value?"1":"0"
				}
				return oldData;
			})
		}else{
			setErr((prev)=>{
				return{...prev, status:true, errMsg:schStatusData.message}
			})
		}
	})
	.catch((schStatusData)=>{
		setErr((prev)=>{
			return{...prev, status:true, errMsg:schStatusData.message}
		})
	  })
	  .finally(()=>{
		  setLoader(false);
	  })


}
// change status school function end


// update school functions start
const[UdateSch, setUdateSch]=useState({})

const updateSchOnchange = e =>{
	setUdateSch((prev)=>{
		return{...prev, [e.target.name]: e.target.value}
	})
}

function updateSchools(e){
	e.preventDefault()
	setLoader(true)
	const xPostData ={
		"schoolID":UdateSch.schoolID,
		"schoolTypeRefID":UdateSch.schoolTypeRefID,
		"schoolName":UdateSch.schoolName,
		"schoolAddress":UdateSch.schoolAddress,
		"schoolDistrict":UdateSch.schoolDistrict,
		"stateID":UdateSch.stateName,
		"zipCode":UdateSch.zipCode,
		"conntactNo":UdateSch.conntactNo,
		"email":UdateSch.email,
	}
	console.log(xPostData)
	fetch(apiBaseURL+apiURL.updateSchoolWithAssignSubject,{
		method: "POST",
		headers: {'key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((updateSchoolData)=>{
		if(updateSchoolData.status=="success"){
			setSuccess((prev)=>{
				return{...prev, status:true, errMsg:updateSchoolData.message}
			})
		}else{
			setErr((prev)=>{
				return{...prev, status:true, errMsg:updateSchoolData.message}
			})
		}
	})
	.catch((updateSchoolData)=>{
		setErr((prev)=>{
			return{...prev, status:true, errMsg:updateSchoolData.message}
		})
	})
	.finally(()=>{
		setLoader(false)
	})
}

function closeSchoolForm()
{
	setShowSchoolRegisterForm(false); 
	setcreateSchool([]);
	setSectionLists([]);
	setSubjects([]);
	setTotalAmount(0);
	setTotalAmountSectionB(0);
	setNoofStudentInSubject([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);
	setNoofStudentInSection({section1:0,section3:0})
}

// update school functions end

	// let value1= '';
	// if(sectionData==1){
	// 	let val = totalStuValue1!='' ? totalStuValue1 : 1;
	// 	value1=val
	// }else if(sectionData==1){
	// 	let val = totalStuValue2!='' ? totalStuValue2 : 1;
	// 	value1=val
	// }else{
	// 	let val = totalStuValue3!='' ? totalStuValue3 : 1;
	// 	value1=val
	// }

function getStudentData(e,sectionID){
	
	if(e.target.value!=""){
		let data ={
			"amount" : 300 * e.target.value,
			"section" : sectionID,
			"sectionName": sectionID==1 ? "Section I" : "Section III",
			"student" : e.target.value
		}
	
		studentWithSecAndStu.push({[sectionID]:data})
	}
}

function getStudentDataBySubject(e,subjectID,subjectName){
	
	let data ={
		"section" : 2,
		"sectionName": "Section II",
		"subjectName" : subjectName,
		"amount" : 300 * e.target.value,
		"subjectID" : subjectID,
		"student" : e.target.value
	}
	// subjectWithStudent.push(data)
	subjectWithStudent.push(data)
}


  return (
    <div className={`${SuperAdminCss.backDiv}`}>
      {/* Header Section */}
      <div className={`${SuperAdminCss.rightArea}`}>
        {/* Header */}
        <Header />
        {/* Header */}

        <div className={`${SuperAdminCss.buttonBgs}`}>
          <Container fluid className="schoolRegister">
				<Row className="d-flex justify-content-center ">
					{!userData.userRole.includes('invoice') &&
						<>
						<Col>
								<div className={`${SuperAdminCss.cricleBg}`} onClick={()=>{setShowSchoolList(true)}}>
									<div className={`${SuperAdminCss.boxWithIcon}`}>
										<img src={schoolReister} alt='*'/>
									</div>
									<p className={`${SuperAdminCss.buttonBottomTxt}`}>Manage School</p>
								</div>
						</Col>
						<Col>
							<Link to="/superAdmin/manage-student">
								{/* <div className={`${SuperAdminCss.cricleBg}`} onClick={() => {dispatch({ type: "clickManageStudent" });}}> */}
								<div className={`${SuperAdminCss.cricleBg}`}>

									<div className={`${SuperAdminCss.boxWithIcon}`}>
										<img src={ManageStudent} alt='*'/>
									</div>
									<p className={`${SuperAdminCss.buttonBottomTxt}`}> Manage Student </p>
								</div>
							</Link>
						</Col>

						{/* <Col>
							<Link to="/superAdmin/manage-demo-user">
								<div className={`${SuperAdminCss.cricleBg}`}>

									<div className={`${SuperAdminCss.boxWithIcon}`}>
										<img src={ManageStudent} alt='*'/>
									</div>
									<p className={`${SuperAdminCss.buttonBottomTxt}`}> Manage Demo </p>
								</div>
							</Link>
						</Col> */}

						{/* <Col>
								<div className={`${SuperAdminCss.cricleBg}`} onClick={ () =>{assignSubjectSections()}}>
									<div className={`${SuperAdminCss.boxWithIcon}`}>
										<img src={SubjectAllocate} alt='*' />
									</div>
									<p className={`${SuperAdminCss.buttonBottomTxt}`}> Assign Section/Subject</p>
								</div>
						</Col> */}
						<Col>
								<Link to="/superAdmin/Report"> 
									<div className={`${SuperAdminCss.cricleBg}`}>
										<div className={`${SuperAdminCss.boxWithIcon}`}>
											<img src={Report} alt='*' />
										</div>
										<p className={`${SuperAdminCss.buttonBottomTxt}`}> Report </p>
									</div>
								</Link>
						</Col>
						</>
					}
					<Col>
							<Link to="/Manage-Orders"> 
								<div className={`${SuperAdminCss.cricleBg}`}>
									<div className={`${SuperAdminCss.boxWithIcon}`}>
										<img src={Manage_orders} alt='*' />
									</div>
									<p className={`${SuperAdminCss.buttonBottomTxt}`}> Manage Orders </p>
								</div>
							</Link>
					</Col>
				</Row>
            {/* Register Form start*/}

            {ShowSchoolList && (
              <div className={`${SuperAdminCss.SchoolpopupForm}`}>
                <Container fluid>
                  <div className={`${SuperAdminCss.tableBgs}`}>
                    <Row>
                      <Col>
                        <div className={`${SuperAdminCss.stulist}`}>
                          School List
                        </div>
                      </Col>
                      <Col className="text-right">
                        <div className={`${SuperAdminCss.inputFiled}`}>
                          <PersonSearchIcon className={`${SuperAdminCss.searchIcons}`}/>
                          <input  type="text" className="form-control" ref={inputBox} onKeyUp={searchData} placeholder="Search"/>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div className={`${SuperAdminCss.searchOption}`}></div>
					  <div className="tables">
                      <Table  className={`${SuperAdminCss.TableManageT}`} striped bordered hover responsive  ref={table}>
                        <thead className={`${SuperAdminCss.fixedHead}`}>
                          <tr>
                            <th>Sr No.</th>
                            <th>School Type</th>
                            <th>School Code</th>
                            <th>School/Institute Name</th>
                            <th>Address</th>
                            <th>District</th>
                            <th>State</th>
                            <th>Pin Code</th>
                            <th>Contact</th>
                            <th>E-mail ID</th>
                            <th>Total Student</th>
                            <th>Active Inactive</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {showSchoolDetailes.map((item, index)=>{
                            return(
                              <tr key={"school"+item.schoolID}>
                                <td>{index+1}</td>
                                <td>{item.schoolType}</td> 
                                <td>{item.schoolCode}</td> 
                                <td>{item.schoolName}</td>
                                <td>{item.schoolAddress}</td>
                                <td>{item.schoolDistrict}</td>
                                <td>{item.stateName}</td>
                                <td>{item.zipCode}</td>
                                <td>{item.conntactNo}</td>
                                <td>{item.email}</td>
                                <td>{item.studentStrength}</td>
                                <td>
                                  <label className={`${SuperAdminCss.switch}`}>
                                    <input checked={item.status=="1"?true:false} onChange={(e)=>{changeStatusSchfun(e.target.checked, item, index);}} type="checkbox" />
                                    <span className={`${SuperAdminCss.slider} ${SuperAdminCss.round}`}></span>
                                  </label>
                                </td>
                                <td onClick={()=>{setEditSchools(true); setUdateSch(item)}} title="Edit School"> <EditIcon className={`${SuperAdminCss.editButton}`}/> </td>
                                <td title="Delete School" onClick={()=>{setDeleteRecord(true); setDeleteSchCode(item.schoolCode)}}>
                                  <DeleteForeverIcon className={`${SuperAdminCss.delete}`}/>
                                </td>
                              </tr>
                            )
                          })}
                      
                        </tbody>
                      </Table>
					  </div>
                    </Row>
                    <Row>
                      
                    </Row>
					<Row className={`${SuperAdminCss.rowFixbuttonsec,SuperAdminCss.schoolBar}`}>
						<Col>
                          <button onClick={() =>{setShowSchoolList(false)}} className="btn btn-secondary" ><CloseIcon/> CLOSE</button>{" "}
                          <button onClick={()=>{setShowSchoolRegisterForm(true)}} className="btn btn-primary" style={{marginRight:'3px'}}> <AddBoxIcon/> ADD SCHOOL</button>
                      	{/* </Col>
                         <Col> */}
                              {/* <button onClick={(()=>{setAddStuPop(true)})} className="btn btn-primary"> <AddBoxIcon /> ADD STUDENT </button>{' '} */}
                              <a  href={BUlkSample} download className="btn btn-secondary">  <span style={{color:"#fff"}}>BULK DATA SHEET ENTRY FORM  </span> </a>{' '}
                              <button className="btn btn-info" onClick={()=>{togglePopup(true);setShowSchoolList(false)}}>  BULK UPLOAD </button>{' '}
                               
                         </Col>
                    </Row>
                  </div>
                </Container>
              </div>
            )}
            {/* Register Form end*/}

            {/* Add New school popup start */}
            {ShowSchoolRegisterForm && (
                  <div className={`${SuperAdminCss.SchoolRegisterBk}`}>
                  <Container>
                      <form className={`${SuperAdminCss.schoolRegisterf}`} onSubmit={create_School_On_submit} enctype="multipart/form-data">
                          <div className={`${SuperAdminCss.formBgs}`}>
                              <CancelIcon	onClick={closeSchoolForm} className={`${SuperAdminCss.crossIcon}`}/>
                                    <p className={`${SuperAdminCss.heading}`}> School Register </p>
                                    <hr />
                                <Row>
                                  <Col className="col-md-4 col-sm-4 col-12">
                                      <label className={`${SuperAdminCss.labelText}`}>School/Institute</label>
                                      <select  className={`form-control ${SuperAdminCss.inputsStyle}`} name="schoolType" onChange={e=>addSchool(e)} >
                                         	   <option className="d-none">Select</option>
                                              {showSchoolType.map((item,index)=>{
                                                return(
                                                	<option key={index} value={item.schoolTypeID}>{item.schoolTypeName}</option>
                                                )
                                              })}
                                      </select>
                                  </Col>
                                  <Col className="col-md-4 col-sm-4 col-12">
                                      <label className={`${SuperAdminCss.labelText}`}> School/Institute Name</label>
                                      <input name="schoolName" value={schoolName}  onChange={e=>addSchool(e)} className={`form-control ${SuperAdminCss.inputsStyle}`} type="text"	placeholder="School/Institute Name"/>
                                  </Col>
                                  <Col className="col-md-4 col-sm-4 col-12">
                                      <label className={`${SuperAdminCss.labelText}`}> Address </label>
                                      <input name="School_address" value={School_address}  onChange={e=>addSchool(e)} className={`form-control ${SuperAdminCss.inputsStyle}`}	type="text"	placeholder="Address"/>
                                  </Col>

                                  <Col className="col-md-4 col-sm-4 col-12">
                                      <label className={`${SuperAdminCss.labelText}`}> District </label>
                                      <input name="School_district"  value={School_district}  onChange={e=>addSchool(e)}	className={`form-control ${SuperAdminCss.inputsStyle}`}	type="text"	placeholder="District"/>
                                  </Col>
                                  <Col className="col-md-4 col-sm-4 col-12">
                                      <label className={`${SuperAdminCss.labelText}`}> State </label>
                                      <select name="School_state"   onChange={e=>addSchool(e)}	className={`form-control ${SuperAdminCss.inputsStyle}`}>
                                          <option className="d-none">Select</option>
                                              {showStateList.map((item, index) => {
                                                  return <option key={index} value={item.stateID}>{item.stateNameLang1}</option>
                                              })}
                                      </select>
                                  </Col>
                                  <Col className="col-md-4 col-sm-4 col-12">
                                      <label className={`${SuperAdminCss.labelText}`}> Pin Code </label>
                                      <input name="School_pincode" value={School_pincode} onKeyPress={(e) => {if (!/[0-9]/.test(e.key))   {e.preventDefault();}}}   onChange={e=>addSchool(e)} className={`form-control ${SuperAdminCss.inputsStyle}`} type="number" placeholder="Pin Code"/>
                                  </Col>

                                  <Col className="col-md-4 col-sm-3 col-12">
                                      <label className={`${SuperAdminCss.labelText}`}> Contact </label>
                                      <input name="School_contactNo" value={School_contactNo}  onKeyPress={(e) => {if (!/[0-9]/.test(e.key))   {e.preventDefault();}}}   onChange={e=>addSchool(e)} className={`form-control ${SuperAdminCss.inputsStyle}`} type="number" placeholder="Contact"/>
                                  </Col>
                                  <Col className="col-md-4 col-sm-4 col-12">
                                      <label className={`${SuperAdminCss.labelText}`}> Email ID </label>
                                      <input name="emailID" value={emailID}  onChange={e=>addSchool(e)} className={`form-control ${SuperAdminCss.inputsStyle}`}	type="email" placeholder="E-mail ID"/>
                                  </Col>
									<Col className="col-md-4 col-sm-4 col-12">
										<label className={`${SuperAdminCss.labelText}`}> Discount</label>
										<select name="discount" onChange={(e)=>{setDiscount(e.target.value)}}  className={`form-control ${SuperAdminCss.inputsStyle}`}>
											<option className="d-none">Select</option>
											<option value="5">5</option>
											<option value="10">10</option>
											<option value="15">15</option>
											<option value="20">20</option>
											<option value="25">25</option>
											<option value="30">30</option>
										</select>
									</Col>

                                    <Col className="col-md-12 col-sm-12 col-12">
                                        <label className={`${SuperAdminCss.labelText}`}>Sections/Subjects </label>
                                        <div className={`${SuperAdminCss.rowSec}`}>
                                            {showSectionName.map((item, section) => {
												let id="StuValue"+(section+1);
												if(id=="StuValue2")
												{
													return(
														<div className={`${SuperAdminCss.checkBoxOuter}`}>
															<label key={section} className={`${SuperAdminCss.checkBoxInputs1}`}>
																<input name="section" onChange={(e)=>{addSchool(e); sectionHendles(e, 0)} } value={item.sectionID} type="checkbox" />{item.sectionName}
															</label>
														</div>
													)
												}else{
													return(
														<div className={`${SuperAdminCss.checkBoxOuter}`}>
															<label key={section} className={`${SuperAdminCss.checkBoxInputs1}`}>
																<input name="section" onChange={(e)=>{addSchool(e); sectionHendles(e, item.sectionPrice)} } value={item.sectionID} type="checkbox" />{item.sectionName}
															</label>														
															<input type="number" onChange={(e)=>{getData(e)}} onBlur={(e)=>{getStudentData(e,item.sectionID)}} id={id} className={`${SuperAdminCss.numberOfLogin}`} />
														</div>
													)
												}
												
                                            })} 
                                        </div>
                                    </Col>

									

                                  <Col className="col-md-4 col-sm-4 col-12">
                                    <label className={`${SuperAdminCss.labelText}`}>Total Amount </label>
                                    <div className={`${SuperAdminCss.rowSec1}`}>
                                        {/* <input name="totalPrice" onChange={(e)=>{addSchool(e)}} className={`form-control ${SuperAdminCss.inputsStyle}`} type="text" value={getTotalStudent!='' ? totalAmount*getTotalStudent : totalAmount}/> */}
										<input name="totalPrice" onChange={(e)=>{addSchool(e)}} className={`form-control ${SuperAdminCss.inputsStyle}`} type="text" value={totalAmount+totalAmountSectionB-((totalAmount+totalAmountSectionB) * discount)/100}/>
                                    </div>
                                  </Col>
								  <Col className="col-md-4 col-sm-4 col-12">
                                    <label className={`${SuperAdminCss.labelText}`}>School Logo </label>
                                    <div className={`${SuperAdminCss.rowSec1}`}>
										<input accept="image/png, image/jpg, image/jpeg" name="logoFile" onChange={(event) => {
											setSelectedImage(event.target.files[0]);
											}} className={`form-control ${SuperAdminCss.inputsStyle}`} type="file"/>
                                    </div>
                                  </Col>
                                  <Col className="col-md-4 col-sm-4 col-12">
                                      <label className={`${SuperAdminCss.labelText}`}> Paid</label>
                                      <select name="payMethod"  onChange={e=>addSchool(e)} className={`form-control ${SuperAdminCss.inputsStyle}`}>
                                          <option className="d-none">Select</option>
                                          <option value="Online">Online</option>
                                          <option value="Offline">Offline</option>
                                      </select>
                                  </Col>
                              </Row>
							  <label>Remark</label>
							  <textarea onChange={e=>addSchool(e)} name="remark" value={remark} className={`form-control ${SuperAdminCss.inputsStyle}`}>
							  </textarea>
                              <input type="submit" className="btn btn-success lg mt-3" value="SAVE"/>
                          </div>
                      </form>
                  </Container>
                  </div>
            )}
            {/* Add New school popup end */}


            {/* Assign subjects/section To School popup start */}
            {assignSubSec.assignModel &&
				<div className={`${SuperAdminCss.AllocateSubjectList}`}>
					<div className={`${SuperAdminCss.allo_subjact}`}>
					<div className={`${SuperAdminCss.addstuHeading}`}>
						Assign Section and Subject
					</div>
					<div className={`${SuperAdminCss.AlocateSubjectFormBk}`}>
						<div className={`${SuperAdminCss.checkboxBgs}`}>
						<label>Select School/Institute</label>
						<select className="form-control mb-3" onChange={(e)=>{selectSchoolHendlers(e.target.value)}} >
							<option className="d-none">Select</option>
							{showSchoolDetailes.map((item,index)=>{
								return(
									<option key={index} value={item.schoolCode}>{item.schoolName}</option>
								)
							})}
						</select>
						{/*    sections start    */}
						{selectedSchool.showSection &&
							<>
								{showSectionName.map((item, section) => {									
									//console.log(item);
									if(sectionNo && item.sectionID==2)
									{
							
										return(
											<>
												<label key={section} className={`${SuperAdminCss.checkBoxInputs}`}>
													<input name="section" id={item.sectionID} onChange={(e)=>{assignSecNDsubjectHeldler(e)} } value={item.sectionID} type="checkbox" />{item.sectionName}
												</label>
												{/* <input id={item.sectionID} disabled={true} className={`form-control ${SuperAdminCss.inputWithSubject}`} type="number" placeholder="" />												 */}
												<input type="number" onChange={(e)=>{getData(e)}} id={item.sectionID} className={`${SuperAdminCss.numberOfLogin}`} />
												<div className={`${SuperAdminCss.clear}`}></div>
											</>
										)
									}else{										
										
										let isSectionAssigned = "false";																		
										let noofStudent = 0;
										selectedSchoolData.assignedSectionWithStrength.map((dataV)=>{
											if(dataV.sectionID==item.sectionID)
											{
												noofStudent = dataV.totalStudentInSection;												
												isSectionAssigned = "true";
											}
										})																															
										if(isSectionAssigned=="true")
										{	
																			
											sectionIDs.push(item.sectionID);
											let sectionIDS = item.sectionID
											uniqueSection.push(item.sectionID);
											uniqueSectionTotalStudent.push(noofStudent);
											return(
												<>
													<label key={section} className={`${SuperAdminCss.checkBoxInputs}`}>
														{/* <input name="section" id={item.sectionID} onChange={(e)=>{assignSecNDsubjectHeldler(e)} }  type="checkbox" defaultChecked={isSectionAssigned}/>{item.sectionName} */}
														<input name="section" id={item.sectionID} onChange={(e)=>{assignSecNDsubjectHeldler(e)} }  type="checkbox"/>{item.sectionName}
													</label>
													<input key={item.sectionID+noofStudent} id={item.sectionID}  disabled={false} className={`form-control ${SuperAdminCss.inputWithSubject}`} type="number" defaultValue={noofStudent} onChange={(e)=>{changeStudentCountInSection(e)}} />												
													<div className={`${SuperAdminCss.clear}`}></div>
												</>
											)
										}else{										
											return(
												<>
													<label key={section} className={`${SuperAdminCss.checkBoxInputs}`}>
														{/* <input name="section" id={item.sectionID} onChange={(e)=>{assignSecNDsubjectHeldler(e)} }  type="checkbox" defaultChecked={isSectionAssigned}/>{item.sectionName} */}
														<input name="section" id={item.sectionID} onChange={(e)=>{assignSecNDsubjectHeldler(e)} }  type="checkbox" />{item.sectionName}
													</label>
													<input key={item.sectionID+noofStudent} id={item.sectionID} disabled={false} className={`form-control ${SuperAdminCss.inputWithSubject}`} type="number" defaultValue={noofStudent} onChange={(e)=>{changeStudentCountInSection(e)}} />												
													<div className={`${SuperAdminCss.clear}`}></div>
												</>
											)
										}																		
									}									
								})} 
							</>
						}
						{/* sections end */}
						{selectedSchool.showSubjectspp && 
							<div className={`${SuperAdminCss.subjectsecBox}`}>
								<label>Subjects for section - II</label>
								{showSubjectList.map((item, index) => {
									if(item.subjectID<=13)
									{									
										let noofstudent = 0;
										let isSubjectAssigned = "false";
										selectedSchoolData.assignedSubjectWithStrength.map((dataV)=>{
											if(dataV.subjectID==item.subjectID)
											{
												noofstudent = dataV.totalStudentInSubject;
												isSubjectAssigned = "true";
											}
										})
										if(isSubjectAssigned=="true")
										{
											subjectIDs.push(item.subjectID);
											uniqueSubject.push(item.subjectID);
											uniqueSubjectTotalStudent.push(noofstudent);
											return(
												<React.Fragment key={index}>
													<label>
														{/* <input key={index} type="checkbox" onChange={(e)=>{selectSubjectToschool(e)}} value={item.subjectID}  defaultChecked={isSubjectAssigned} /> {item.subjectName} */}
														<input key={index} type="checkbox" onChange={(e)=>{selectSubjectToschool(e)}} value={item.subjectID}  /> {item.subjectName}
													</label>
													<input key={item.subjectID+noofstudent} id={item.subjectID}  className={`form-control ${SuperAdminCss.inputWithSubject}`} type="number" defaultValue={noofstudent}  onChange={(e)=>{changeStudentCountInSubject(e)}} />
													<div className={`${SuperAdminCss.clear}`}></div>
												</ React.Fragment>
											)
										}else{
											return(
												<React.Fragment key={index}>
													<label>
														<input key={index} type="checkbox" onChange={(e)=>{selectSubjectToschool(e)}} value={item.subjectID} /> {item.subjectName}
													</label>
													<input key={item.subjectID+noofstudent}  id={item.subjectID}   className={`form-control ${SuperAdminCss.inputWithSubject}`} type="number" defaultValue={noofstudent}  onChange={(e)=>{changeStudentCountInSubject(e)}}/>
													<div className={`${SuperAdminCss.clear}`}></div>
												</ React.Fragment>
											)
										}
									}
								})}
							</div>
						}
						</div>
						<div className={`mt-3 ${SuperAdminCss.buttoninFooter}`}>
							<button type="button" className="btn btn-success" onClick={()=>{assignSectionAndSubjectTosch()}}> SAVE </button>
							<button type="button" onClick={()=>{closeAssignSubModelPop()}} className="btn btn-danger"> CLOSE </button>
							<a href={BulkSampleSec} download className="btn btn-secondary"  style={{marginRight:"10px"}}><span style={{color:"#fff"}}> ENTRY SHEET </span></a>
                            <button className="btn btn-info" onClick={()=>{togglePopup(true);SetassignSubSec((prev)=>{return {...prev,showSubjectspp:false }})}}>  BULK UPLOAD </button>{' '}
						</div>
					</div>
					</div>
				</div>
              }
            {/*  Assign subjects/section To School popup end */}




          

			 {/* Add Schools Section-II subject popup start */}
			 {subjectLists && (
              	<div className={`${SuperAdminCss.subjectListPopupBg}`}>
					<div className={`${SuperAdminCss.popupInnerDiv}`}>
						<p className={`${SuperAdminCss.heading}`}>Subjects for section II </p>
						<hr />
						<div className={`${SuperAdminCss.checkboxBgs}`}>
							{showSubjectSecII.map((item, index) => {
								return (
								<React.Fragment key={item.subjectID}>
									<label>
									<input id={"subjectID"+item.subjectID} onChange={e=>{addSchool(e); subjectHeldlersAssSchool(e, item.subjectPrice)}} name="subjects" value={item.subjectID} key={index} type="checkbox" /> {item.subjectName} 
									</label>
									<input  onChange={e=>{addSchool(e);addRemoveSubjectsCount(e)}} name={"studentNoAcc"+item.subjectID} id={item.subjectID} onBlur={(e)=>{getStudentDataBySubject(e,item.subjectID,item.subjectName)}}  className={`form-control ${SuperAdminCss.inputWithSubject}`} type="number" placeholder="" />
									<div className={`${SuperAdminCss.clear}`}></div>
								</React.Fragment>
								);
							})}
						</div>
						<div className={`'mt-3 ${SuperAdminCss.buttoninFooter}`}>
							<button onClick={()=>{setSubjectLists(false)}} type="button" className="btn btn-success"> OK </button>
						</div>
					</div>
              	</div>
            )}
            {/*Add Schools Section-II subject  popup end */}

			{/* Edit school popup start */}
            {editSchools && (
                <div className={`${SuperAdminCss.SchoolRegisterBk}`}>
					<Container>
						<form className={`${SuperAdminCss.schoolRegisterf}`}>
							<div className={`${SuperAdminCss.formBgs}`}>
								<CancelIcon	onClick={() => setEditSchools(false)} className={`${SuperAdminCss.crossIcon}`}/>
									<p className={`${SuperAdminCss.heading}`}> Update School </p>
									<hr />
									<Row>
									<Col className="col-md-4 col-sm-4 col-12">
										<label className={`${SuperAdminCss.labelText}`}>School/Institute</label>
										<select  className={`form-control ${SuperAdminCss.inputsStyle}`} name="schoolTypeRefID" onChange={updateSchOnchange} >
												<option className="d-none">Select</option>
												{showSchoolType.map((item,index)=>{
													return(
													<option key={index} value={UdateSch.schoolTypeRefID}>{item.schoolTypeName}</option>
													)
												})}
										</select>
									</Col>
									<Col className="col-md-4 col-sm-4 col-12">
										<label className={`${SuperAdminCss.labelText}`}> School/Institute Name</label>
										<input name="schoolName" value={UdateSch.schoolName}  onChange={updateSchOnchange} className={`form-control ${SuperAdminCss.inputsStyle}`} type="text"	placeholder="School/Institute Name"/>
									</Col>
									<Col className="col-md-4 col-sm-4 col-12">
										<label className={`${SuperAdminCss.labelText}`}> Address </label>
										<input name="schoolAddress"  value={UdateSch.schoolAddress}  onChange={updateSchOnchange} className={`form-control ${SuperAdminCss.inputsStyle}`}	type="text"	placeholder="Address"/>
									</Col>
									<Col className="col-md-4 col-sm-4 col-12">
										<label className={`${SuperAdminCss.labelText}`}> District </label>
										<input name="schoolDistrict" value={UdateSch.schoolDistrict} onChange={updateSchOnchange}	className={`form-control ${SuperAdminCss.inputsStyle}`}	type="text"	placeholder="District"/>
									</Col>
									<Col className="col-md-4 col-sm-4 col-12">
										<label className={`${SuperAdminCss.labelText}`}> State </label>
										<select name="stateName"   onChange={updateSchOnchange} className={`form-control ${SuperAdminCss.inputsStyle}`}>
											<option className="d-none">Select</option>
												{showStateList.map((item, index) => {
													return <option key={index} value={UdateSch.stateID}>{item.stateNameLang1}</option>
												})}
										</select>
									</Col>
									<Col className="col-md-4 col-sm-4 col-12">
										<label className={`${SuperAdminCss.labelText}`}> Pin Code </label>
										<input name="zipCode" value={UdateSch.zipCode} onKeyPress={(e) => {if (!/[0-9]/.test(e.key))   {e.preventDefault();}}}   onChange={updateSchOnchange} className={`form-control ${SuperAdminCss.inputsStyle}`} type="number" placeholder="Pin Code"/>
									</Col>
									<Col className="col-md-4 col-sm-4 col-12">
										<label className={`${SuperAdminCss.labelText}`}> Contact </label>
										<input name="conntactNo" value={UdateSch.conntactNo}  onKeyPress={(e) => {if (!/[0-9]/.test(e.key))   {e.preventDefault();}}}   onChange={updateSchOnchange} className={`form-control ${SuperAdminCss.inputsStyle}`} type="number" placeholder="Contact"/>
									</Col>
									<Col className="col-md-4 col-sm-4 col-12">
										<label className={`${SuperAdminCss.labelText}`}> Email ID </label>
										<input name="email" value={UdateSch.email}  onChange={updateSchOnchange} className={`form-control ${SuperAdminCss.inputsStyle}`}	type="email" placeholder="E-mail ID"/>
									</Col>
								</Row>
								<input onClick={updateSchools} type="submit" className="btn btn-success lg mt-3" value="UPDATE !"/>
							</div>
						</form>
					</Container>
                </div>
            )}

           {/* Edit school popup end */}

			
			{/* err popup code start */}
				{showErr.status &&
					<div className={`${SuperAdminCss.showErrbase}`}>
						<div className={`${SuperAdminCss.inerBox}`}>
							<div><ErrorOutlineIcon className={`${SuperAdminCss.errIcons}`}/></div>
								<div className={`${SuperAdminCss.errMessageDiv}`}>{showErr.errMsg}</div>
							<button onClick={(()=>{setErr(false)})} className='btn btn-danger mt-2 btn-lg'> OK </button>    
						</div>
					</div>
				}
		   {/* err popup code end */}

		    {/* success popup code start */}
				{showSuccess.status &&
					<div className={`${SuperAdminCss.showErrbase}`}>
						<div className={`${SuperAdminCss.inerBox}`}>
							<div><CheckCircleOutlineIcon className={`${SuperAdminCss.successIcons}`}/></div>
								<div className={`${SuperAdminCss.successMes}`}>{showSuccess.errMsg}</div>
							<button onClick={(()=>{setSuccess(false)})} className='btn btn-success mt-2 btn-lg'> OK </button>    
						</div>
					</div>
				}
			{/* success popup code end */}


            {loader &&
                 <Loader/>                
            }
          {/* Allocate Subect For Student Only end*/}

		  {/* Delete School Popup start */}
		  {deleteRecord && 
			<div className={`${SuperAdminCss.popDeleteBase}`}>
				<div className={`${SuperAdminCss.innerBoxes}`} data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
						<ContactSupportIcon className={`${SuperAdminCss.questionICon}`}/>
						<div className={`${SuperAdminCss.areYousure}`}>Are you sure !</div>
						<div className={`${SuperAdminCss.doyoureally}`}>Delete these record  this process cannot be undone.</div>
						<button onClick={()=>{setDeleteRecord(false)}} className={`btn btn-secondary mt-4 ${SuperAdminCss.buttonconfir}`}>CANCEL</button>{'  '}
						<button onClick={()=>{deletSchool()}} className={`btn btn-danger mt-4 ${SuperAdminCss.buttonconfir}`}>DELETE</button>

				</div>
			</div>
		  }
		  {/* Delete School Popup end */}
		  {bulkUploadData.status && 
          <div className={`${SuperAdminCss.bulkUploadPop}`}>
               <div className={`${SuperAdminCss.innerBox}`} data-aos="flip-left">
                    <BackupIcon className={`${SuperAdminCss.iconsUpload}`}/>
                    <form className={`${SuperAdminCss.uolpadFilesBulk}`} onSubmit={createMultiUserOnsubmit}>
                         <label>Please Choose File</label>
                         <input name='user_file'  onChange={(e)=>bulFile(e)} className='form-control mt-1' type="file" accept=".csv" />
                         <div className={`${SuperAdminCss.buttonSection}`}>
                              <input type='submit' className='btn btn-success' value="SUBMIT !"/> {' '}
                              <div type='reset' className='btn btn-danger'  onClick={()=>togglePopup(false)}>CANCEL !</div>
                         </div>

                    </form>
               </div>
          </div>
     	}

		{insertRecord.status && 
			<div className={`${SuperAdminCss.popDeleteBase}`}>
				<div className={`${SuperAdminCss.innerBoxes}`} data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
					<div className={`${SuperAdminCss.doyoureally}`}>{insertRecord.msg}</div>
					<button onClick={()=>{setInsertRecord((prev)=>{return {...prev,status:false, msg:'' }})}} className={`btn btn-secondary mt-4 ${SuperAdminCss.buttonconfir}`}>OK</button>{'  '}
				</div>
			</div>
		}

          </Container>
        </div>

      </div>
      {/* Right Area */}
    </div>
  );
}

export default SuperAdmin;
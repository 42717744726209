import React, { useEffect, useState } from 'react'
import {apiBaseURL,apiURL,apiKey,RouteURL} from './constant';
import {useNavigate, useLocation, Route} from 'react-router-dom';
const userContext = React.createContext();
export function UserProvider({children}) {
    const [userData, setUserData] = useState({});
    const [error, setError] = useState({status:false, msg:'', fun:null});
    const [loader, setLoader] = useState(false);
    const [schName, setSchName] =  useState();
    const [schLogo, setSchLogo] =  useState();
    let navigate = useNavigate();
    let location = useLocation();
  

   
    useEffect(()=>{
        // console.log(location.action);
        if( location.pathname!==RouteURL.studentLoginPage && location.pathname!==RouteURL.loginPage && location.pathname!==RouteURL.signupPage  && location.pathname!==RouteURL.masterLoginPage){            
            checkLogin();
        }

        let token = localStorage.getItem('token');
        if( token!==null && (location.pathname===RouteURL.studentLoginPage || location.pathname===RouteURL.masterLoginPage ) ){
            checkLogin(true);
        }

    }, [])
    async function login(data){
        
        let token = localStorage.getItem('token');
        setError({status:false, msg:'', fun:null});
        setLoader(true);
        
        try{
            let result= await fetch(`${apiBaseURL}${apiURL.login}`,{
            method: 'POST',
            headers: {
                "Contant-Type": "applaction/json",
                    "Accept":"applaction/json",
                    'key':apiKey
                    },
            body:JSON.stringify(data)
            });

            result = await result.json();
            setLoader(false);
            // console.log(result);
            if(result.status==='success'){
                // const {firstName, middleName, lastName, userName, userTypeID} = {...result.data.userDetail}
                // setUserData({
                //     name:`${firstName} ${middleName} ${lastName}`,
                //     userTypeId:userTypeID,
                //     token:result.data.loginToken
                // });
                setUserData(result.data.userDetail);
                setSchLogo(result.data.schoolData[0].schoolLogo)
                setSchName(result.data.schoolData[0].schoolName)
                localStorage.setItem('token', result.data.loginToken);

                if( result.data.userTypeID===1 ){
                    navigate(RouteURL.superAdmin);

                }else if( result.data.userTypeID===2 ){
                    navigate(RouteURL.adminDashboard);

                }else if(result.data.userTypeID===3){
                    navigate(RouteURL.studentWelcomePage);
                    
                }else if(result.data.userTypeID===4){
                    navigate(RouteURL.dtpDashboardPage);
                
                }else if(result.data.userTypeID===5){
                    navigate(RouteURL.superAdmin);    
                    
                }else{
                    navigate(RouteURL.studentWelcomePage);
                }
            }else{
                setError((prev)=>{
                    return {...prev, status:true, msg:result.message}
                });
                if(token){
                    // navigate(RouteURL.studentLoginPage);
                    window.location.reload(true);
                    // console.log("kdhfjfhj");
                }
            }
        } catch(err){
            console.log(err);
            setLoader(false);
            setError((prev)=>{
                return {...prev, status:true, msg:"Oops.. Please check your internet connection"}
            });
        }
      
    }

    function logout(){
        let token = localStorage.getItem('token');
        const xPostData ={
            "loginToken":  token
        }
        fetch(apiBaseURL+apiURL.logout,{
            method: "POST",
            headers: {'Key' :apiKey},
            body:JSON.stringify(xPostData)
        })
        .then(responce => responce.json())
        .then((logoutData)=>{
            if(logoutData.status=="success"){
                // setError({status:false, msg:'', fun:null});
                setLoader(false);
                setUserData({});
                localStorage.removeItem('token');
                navigate(RouteURL.homePage);
            }else{
                return false
            }
        })
        
        
    }   

    async function checkLogin(funcMode=false){
        if(userData.userID===undefined){
            let token = localStorage.getItem('token');
          
            if(token==null){
                logout();
            } else {
                setLoader(true)
                try{
                    let result= await fetch(`${apiBaseURL}${apiURL.loginCheck}`,{
                    method: 'POST',
                    headers: {
                        "Contant-Type": "applaction/json",
                            "Accept":"applaction/json",
                            'key':apiKey
                            },
                    body:JSON.stringify({loginToken:token})
                    });
                    result = await result.json();
                    setLoader(false)
                    if(result.status==='success'){
                        setUserData(result.data.userDetail);
                        
                        // console.log(result.data.userDetail)  
                        localStorage.setItem('token', result.data.loginToken);
                        setSchLogo(result.data.schoolData[0].schoolLogo)
                        setSchName(result.data.schoolData[0].schoolName)
                        // console.log("skldjfklsdj fksdklfjskldjf klsdjfkl")
                        if(funcMode){
                            if( result.data.userTypeID===1 ){
                                navigate(RouteURL.superAdmin);
            
                            }else if( result.data.userTypeID===2 ){
                                navigate(RouteURL.adminDashboard);
            
                            }else if(result.data.userTypeID===3){
                                navigate(RouteURL.studentWelcomePage);
                                
                            }else if(result.data.userTypeID===4){
                                navigate(RouteURL.dtpDashboardPage);
                            
                            }else if(result.data.userTypeID===5){
                                navigate(RouteURL.superAdmin);    
                                
                            }else{
                                navigate(RouteURL.studentWelcomePage);
                            }
                        }
                    } else {
                        logout();
                    }
                } catch(err){
                    console.log(err);
                }
            }
        }
        // setLoader(true)
        // try{
        //     let result= await fetch(`${apiBaseURL}${apiURL.login}`,{
        //     method: 'POST',
        //     headers: {
        //         "Contant-Type": "applaction/json",
        //             "Accept":"applaction/json",
        //             'key':apiKey
        //             },
        //     body:JSON.stringify(data)
        //     });
        //     result = await result.json();
        //     setLoader(false);


           
        // } catch(err){
        //     console.log(err);
        //     setLoader(false);
        //     setError((prev)=>{
        //         return {...prev, status:true, msg:"Some Error Occured!"}
        //     });
        // }

        
    }


    return(
        <userContext.Provider value={{
            userData:userData,
            schName:schName,
            schLogo:schLogo,
            login:login,
            logout:logout,
            checkLogin:checkLogin,
            
            error:error,
            loader:loader
        }}>
            {children}
        </userContext.Provider>

    )




}


export default userContext;







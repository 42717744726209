import React, {useState,useEffect} from "react";
import EditQueCss from "./EditQue.module.css";
import { Container, Row, Col } from "react-bootstrap";
import PaginationBar from './Pagination';

import {wirisMathML} from '../../constant';
import ScreenMCQ from "../questionScreens/mcq";


function EditQue({userData,quesProcessingData,questionsList,editQuesSegregationButtonHandler,editQuesButtonHandler,deleteQuesButtonHandler,quesProcessingDataHandler,getSubjectChapters,getSubTopicList,searchQuestions,setCurrentPage,currentPage,isLoaderActive,topicList,subTopicsList,subjectList,subjectChaptersList}) {

    useEffect(() => {
        const LoadExternalScript = () => {
          const externalScript = document.createElement("script");
          externalScript.onerror = loadError;
          externalScript.id = "external";
          externalScript.async = true;
          externalScript.type = "text/javascript";
          externalScript.setAttribute("crossorigin", "anonymous");
          document.body.appendChild(externalScript);
          externalScript.src = wirisMathML;
        };
        var elm = document.getElementById('external');
        if(elm!=null){
            elm.remove();
        }
        LoadExternalScript();
    }, [currentPage]);

    function loadError(onError) {
        console.error(`Failed ${onError.target.src} didn't load correctly`);
    }


    // if(  )

    const currentQuestionData = questionsList.length >=1 ? questionsList[currentPage-1] : false;
    // console.log(currentQuestionData)

    
    return (
        <div>
            <div className={`${EditQueCss.mainDiv}`}>
                
                {quesProcessingData.isFormOpened && currentQuestionData!=false &&
                    <>
                        <ScreenMCQ userData={userData} editQuesSegregationButtonHandler={editQuesSegregationButtonHandler} editQuesButtonHandler={editQuesButtonHandler} deleteQuesButtonHandler={deleteQuesButtonHandler} questionData={currentQuestionData} totalQuestions={questionsList.length} currentPage={currentPage} EditQueCss={EditQueCss}/>

                        <div className={`${EditQueCss.fotter}`}>
                            <PaginationBar questionsList={questionsList} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                        </div>
                    </>
                }

            </div>
        </div>
    );
}

export default EditQue;

import StuDashBoardCss from "./stuDashboard.module.css";
import RegisterCss from './Register.module.css';
import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CancelIcon from "@mui/icons-material/Cancel";
import GoogleIcon from '@mui/icons-material/Google';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HelpIcon from '@mui/icons-material/Help';
import {apiBaseURL,apiURL,apiKey,baseURL} from './../../constant';
import userContext from '../../Store';
import Loader from "./../../Loader";
import PaidIcon from '@mui/icons-material/Paid'; 
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
function App() {
	useEffect(()=>{
		getAssignSections();		
	},[])

 const[isLoaderActive, setIsLoaderActive] = useState(true);
 const[isPaymentStart, setIsPaymentStart] = useState(false);
 const[userDetailsPayment, setUserDetailsPayment] = useState();
 const{userData} = useContext(userContext);
 const[assignSection, setassignSection] = useState([])
 const[subjectList, setSubjectList]=useState([])
 const[showLoader, setLoader]=useState(false)
 const[showSubSec, setSubSec]=useState(false)
 const[totalAmount, setTotalAmount] = useState(0)
 const[totalAmountAC, setTotalAmountAC] = useState(0)
 const[totalDiscountedAmount, setTotalDiscountedAmount] = useState(0)
 const[selectedSecSub, setSelectedSecSub]=useState([]) 
 const[postDataSend , setPostDataSend] = useState({});
 const[responceData, setResponceData] = useState({ 
	userRefID:0,
	orderDetail:{},        
	bankTransID:"",
	transactionAmount:0,
	payMode:1,
	orderType:1,
	remarks:"",
	status:1,
});
 const [recCustID , setRecCustID] = useState({});
 const [recUserID , setRecUserID] = useState({});
//console.log(userData);
//  get Assign Sections start
function getAssignSections(){
	setLoader(true)
	const xPostData ={
		"schoolCode":userData.schoolCode
	}
	//console.log(xPostData)
	fetch(apiBaseURL+apiURL.getAssignedSection,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((secTiondata)=>{
		if(secTiondata.status=="success"){
			//console.log(secTiondata.data);			
			setassignSection(secTiondata.data.assignedSections)
		}else{
			setassignSection([])
			alert(secTiondata.message)
		}
	})
	.catch((catch_err)=>{
		setassignSection([])
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})
}
//  get Assign Sections end

// get Assign subjects fun start
function getAssignSubjects(e,price){
	let secID = e.target.value;	
	if(secID==2 && e.target.checked){
		setSubSec(true);
	}else if(secID==2 && e.target.checked==false){
		setSubSec(false);
		setTotalAmount(0);
		setTotalDiscountedAmount(0);
	}	
	if(e.target.checked)
	{
		if(secID!=2)
		{
			//setTotalAmount(Number(totalAmount)+Number(price));
			setTotalAmountAC(Number(totalAmountAC)+Number(price));
			
						
		}
		selectedSecSub.push({"type":"section","id":e.target.value,"price":price,"name":e.target.name})
	}else{
		if(secID!=2)
		{
			//setTotalAmount(Number(totalAmount)+Number(price));
			setTotalAmountAC(Number(totalAmountAC)-Number(price));	
		}		
		selectedSecSub.map((val,index)=>{				
			if(val['id']==e.target.value)
			{					
				selectedSecSub.splice(index, 1);
			}
		})
	}
	//console.log(selectedSecSub);		
	
	const xPostData ={
		"section":secID,
		"classID":12,
		"schoolCode":userData.schoolCode
	}
	if(secID==2){
		fetch(apiBaseURL+apiURL.getSubjectsOfSection,{
			method: "POST",
			headers: {"Key" : apiKey},
			body:JSON.stringify(xPostData)
		})
		.then(responce => responce.json())
		.then((subjectData)=>{
			if(subjectData.status=="success"){
				setSubjectList(subjectData.data.subjects)
			}else{
				alert(subjectData.message)
			}
		})
		.catch((catch_err)=>{
			setassignSection([])
			alert(catch_err)
		})
		.finally(()=>{
			setLoader(false)
		})
	}else{
		return false
	}
	
}
// get Assign subjects fun end
// add subject ammount
function getSelectedSubjects(e, price){	
	if(e.target.checked)
	{
		//alert("hmm...");
		setTotalAmount(Number(totalAmount)+Number(price));
		setTotalDiscountedAmount(Number(totalDiscountedAmount)+Number(price));
		selectedSecSub.push({"type":"subject","id":e.target.value,"price":price,"name":e.target.name})
	}else{
		setTotalAmount(Number(totalAmount)-Number(price));
		setTotalDiscountedAmount(Number(totalDiscountedAmount)-Number(price));
		selectedSecSub.map((val,index)=>{				
			if(val['id']==e.target.value)
			{					
				selectedSecSub.splice(index, 1);
			}
		})
	}
	//console.log(selectedSecSub);		
}
// add subject ammount
function closeCartBox(){
	setIsPaymentStart(false)	
	setAddSubject(false);
	setInstructionOn(false);
	setSelectedSecSub([]);	
	
	setTotalAmount(0);	
	setTotalAmountAC(0);
	setTotalDiscountedAmount(0);
	setSubSec(false)
}
function goforPayment(){	
	if(totalAmount==0 && totalAmountAC==0)
	{
		alert('Please select section/subject first.');
	}else{
		setIsLoaderActive(true);
		setPostDataSend({}); 		
        let finalSelectedSections = [];
		let finalSelectedSubjects = [];
		let finalSectionArr = [];	
		let finalSubjectArr = [];		
		selectedSecSub.map((word,index) => {word.type == "section" ? finalSelectedSections.push(word) : finalSelectedSubjects.push(word)});         
        let orderDetail_res = "";
        orderDetail_res    += "{";
        finalSelectedSections.map((val)=>{
			finalSectionArr.push(Number(val.id));			
            /*Discount Calculator*/
			var totalNoOfSubjects = finalSelectedSubjects.length;
			var perSubjectPrice = 0;
			if(totalNoOfSubjects==1)
			{
				perSubjectPrice = 300;
			}else if(totalNoOfSubjects==2){
				perSubjectPrice = 250;
			}else if(totalNoOfSubjects==3){
				perSubjectPrice = 200;
			}else if(totalNoOfSubjects>3){
				perSubjectPrice = 175;
			}
			/*Discount Calculator*/
            if(val.id==2)
            {
				orderDetail_res += '"'+val.id+'": {"amount": "'+(totalNoOfSubjects*perSubjectPrice)+'",';
                orderDetail_res += '"subjects":';
                orderDetail_res += '{';
                finalSelectedSubjects.map((subVal,index)=>{
					// orderDetail_res += '"'+(index+1)+'": {"amount": '+subVal.price+',"subjectName": "'+subVal.name+'"},';
					orderDetail_res += '"'+(index+1)+'": {"amount": "'+perSubjectPrice+'","subjectName": "'+subVal.name+'"},';
					finalSubjectArr.push(Number(subVal.id));
				})                
                orderDetail_res += '},';				
            }else{
				orderDetail_res += '"'+val.id+'": {"amount": "'+val.price+'",';
			}
            orderDetail_res += '"sectionName": "'+val.name+'"},';            
        })
        orderDetail_res += "}";
        let orderDetailFinal = orderDetail_res.replaceAll('},}','}}');		
		if(userData.sectionIDs!=null)
		{
			userData.sectionIDs.split(",").map((valsec)=>{
				finalSectionArr.push(Number(valsec));
			})
		}
		if(userData.subjectIDs!=null)
		{
			userData.subjectIDs.split(",").map((valsec)=>{
				finalSubjectArr.push(Number(valsec));
			})
		}
        let postData = {
			"userRefID":userData.userRefID,
			"orderDetails":JSON.parse(orderDetailFinal),
			// "transactionAmount":(totalAmount+totalAmountAC),
			"transactionAmount":(Number(totalAmountLogic(totalDiscountedAmount))+totalAmountAC),
			"sectionIDs":finalSectionArr,
			"subjectIDs":finalSubjectArr,
			"schoolCode":"SWASCH1"            
        } 
		//console.log(postData);      
		setPostDataSend(postData);
        fetch(apiBaseURL+apiURL.studentPurchaseSectionSubject, {
            method: 'POST',
            headers:{ 'key': apiKey },
            body: JSON.stringify(postData)
        })
        .then(response => response.json())
        .then( (result)=>{
            setIsLoaderActive(false);
            if( result.status=='success' ){
                setAddSubject(false);
				setInstructionOn(false);
				setIsPaymentStart(true);                                 
                //setUserDetailsPayment(result.data.userRefID);
                setResponceData((prev)=>{
                    return {...prev, userRefID:userData.userRefID,orderDetail:JSON.parse(orderDetailFinal)}
                });                
                setRecCustID({'recCustID':userData.userRefID}) 
                setRecUserID({'recUserCode':result.data.orderNumber})
            }else{
                alert(result.message);
				
            }
			setSelectedSecSub([]);
        })
        .catch( (err)=>{
            alert("Registration Failed. Please retry.");
        });
	}	
}
function paymentWithPaytm()
{
	let totalAmountAfterDiscount = Number(totalAmountAC)+Number(totalAmountLogic(totalDiscountedAmount));        
	let url = baseURL+"paytm-payment/index-dashboard.php?txnAmount="+totalAmountAfterDiscount+"&custId="+userData.userRefID+"&orderId="+recUserID.recUserCode+"&mobileNo="+userData.userContact+"&email="+userData.userEmail;
	window.location = url;
}
function paymentWithCCAvenue()
{
	let totalAmountAfterDiscount = Number(totalAmountAC)+Number(totalAmountLogic(totalDiscountedAmount));	
	let url = baseURL+"ccavenue-payment/index-dashboard.php?cusName="+userData.firstName+" "+userData.lastName+"&cusAddress="+userData.userAddress+"&cusCity="+userData.stateName+"&cusState="+userData.stateCode+"&cusPin="+userData.zipCode+"&txnAmount="+totalAmountAfterDiscount+"&custId="+userData.userRefID+"&orderId="+recUserID.recUserCode+"&mobileNo="+userData.userContact+"&email="+userData.userEmail;
	window.location = url;
}
function totalAmountLogic(totAmount)
{
	if(totAmount==0)
	{
		return totAmount;
	}else if(totAmount==300)
	{
		return totAmount;
	}else if(totAmount==600)
	{
		return 500;
	}else if(totAmount==900)
	{
		return 600;
	}else if(totAmount>900){
		return ((totAmount/300)*175)
	}
}
function resetForm()
{	
	setSelectedSecSub([]);
	setTotalAmount(0);	
	setTotalAmountAC(0);
	setTotalDiscountedAmount(0);	
}

  const [showAddSubject, setAddSubject] = useState(false);
  const [showInstructionOn, setInstructionOn] = useState(false);
  return (
    <div className={`${StuDashBoardCss.buttonBg}`}>
        <nav className={`${StuDashBoardCss.social}`}>
            <ul>
				{/* <li onClick={() => {setInstructionOn(true);}}> General Instructions<i class="fa"> <HelpIcon /> </i></li> */}
				<li onClick={() => {setAddSubject(true);resetForm();}}> Buy More Section/Subject<i class="fa"> <ShoppingCartIcon /> </i></li>
                <li><a href="https://www.google.co.in/" target="_blank" ><span  className={`${StuDashBoardCss.colorBlack}`}>Google Search</span> <i class="fa"> <GoogleIcon /> </i></a></li>
				
            </ul>
        </nav>
        {showInstructionOn && (
        <>
			<div className={`${StuDashBoardCss.addMoreSubjectBg}`}>
				<div className={`${StuDashBoardCss.generalInstructionBg}`}>
					<div className={`${StuDashBoardCss.headingBk}`}>
						<Row>
							<Col>
								<div className={`${StuDashBoardCss.SectionHeading}`}>Add Section and Subjects</div>
							</Col>
							<Col>
								<CancelIcon	onClick={() => {closeCartBox()}} className={`${StuDashBoardCss.crossbutton}`}/>
							</Col>
						</Row>
					</div>
					<div className={`${StuDashBoardCss.SubjectBodyArea}`}>
						Instruction
						
					</div>
				</div>
			</div>
        </>
      )}
	  {showAddSubject && (
        <>
			<div className={`${StuDashBoardCss.addMoreSubjectBg}`}>
				<div className={`${StuDashBoardCss.subjectBody}`}>
					<div className={`${StuDashBoardCss.headingBk}`}>
						<Row>
							<Col>
								<div className={`${StuDashBoardCss.SectionHeading}`}>Buy More Section/Subject</div>
							</Col>
							<Col>
								<CancelIcon	onClick={() => {closeCartBox()}} className={`${StuDashBoardCss.crossbutton}`}/>
							</Col>
						</Row>
					</div>
					<div className={`${StuDashBoardCss.SubjectBodyArea}`}>
						{assignSection.map((item,index)=>{
							//console.log(item);							
							if(userData.sectionIDs!=null)
							{
								if(userData.sectionIDs.split(",").find(element => element == item.sectionID) && item.sectionID!=2)	{								
								return(
									<label className={`${StuDashBoardCss.containerSeCheck}`}>
										<input onClick={(e)=>{getAssignSubjects(e, item.sectionPrice)}} value={item.sectionID} price={item.sectionPrice} name={item.sectionName} type="checkbox" disabled="true"/>
										<div className={`${StuDashBoardCss.StatusPerchased}`}>{item.sectionName}</div>
										<div className={`${StuDashBoardCss.StatusText}`}>(Purchased)</div>
										{/* <div className={`${StuDashBoardCss.StatusTextMrp}`}>( MRP - {item.sectionPrice} INR )</div> */}
									</label>
								)
								}else{								
									return(
										<label className={`${StuDashBoardCss.containerSeCheck}`}>
											<input onClick={(e)=>{getAssignSubjects(e, item.sectionPrice)}} value={item.sectionID} price={item.sectionPrice} name={item.sectionName} type="checkbox"/>
											{item.sectionName}											
												{/* <div className={`${StuDashBoardCss.StatusTextMrp}`}>( MRP - {item.sectionPrice} INR )</div> */}
										</label>
									)
								}

							}else{
								return(
									<label className={`${StuDashBoardCss.containerSeCheck}`}>
										<input onClick={(e)=>{getAssignSubjects(e, item.sectionPrice)}} value={item.sectionID} price={item.sectionPrice} name={item.sectionName} type="checkbox"/>
										{item.sectionName}	
										<div className={`${StuDashBoardCss.StatusTextMrp}`}>( MRP - {item.sectionPrice} INR )</div>									
									</label>
								)
							}
							
						})}
						{showSubSec && 
							<>
							<div className={`${StuDashBoardCss.subjectBox}`}>
								<label className={`${StuDashBoardCss.subjectTxt}`}>Subject for section - II</label>
								{subjectList.map((item)=>{
									if(item.subjectID<=13 || 
									   item.subjectID==15 || 
									   item.subjectID==16 || 
									   item.subjectID==17 || 
									   item.subjectID==18 || 
									   item.subjectID==19 || 
									   item.subjectID==20 || 
									   item.subjectID==21 ||
									   item.subjectID==22)
									{
										if(userData.subjectIDs!=null){
											if(userData.subjectIDs.split(",").find(element => element == item.subjectID))	{										
												return(
													<label className={`${StuDashBoardCss.containerSeCheck}`}>											
														<input onClick={(e)=>{getSelectedSubjects(e, item.subjectPrice)}}  value={item.subjectID} price={item.subjectPrice} name={item.subjectName} type="checkbox" disabled="true"/>
														<div className={`${StuDashBoardCss.StatusPerchased}`}>{item.subjectName}</div>
														<div className={`${StuDashBoardCss.StatusText}`}>(Purchased)</div>
														{/* <div className={`${StuDashBoardCss.StatusTextMrp}`}>( MRP - {item.subjectPrice} INR )</div> */}
													</label>	
												)									
											}else{
												return(
													<label className={`${StuDashBoardCss.containerSeCheck}`}>											
														<input onClick={(e)=>{getSelectedSubjects(e, item.subjectPrice)}}  value={item.subjectID} price={item.subjectPrice} name={item.subjectName} type="checkbox"/>
														{item.subjectName}
														{/* <div className={`${StuDashBoardCss.StatusTextMrp}`}>( MRP - {item.subjectPrice} INR )</div>												 */}
													</label>	
												)	
											}									
										}else{
											return(
												<label className={`${StuDashBoardCss.containerSeCheck}`}>											
													<input onClick={(e)=>{getSelectedSubjects(e, item.subjectPrice)}}  value={item.subjectID} price={item.subjectPrice} name={item.subjectName} type="checkbox"/>
													{item.subjectName}
													{/* <div className={`${StuDashBoardCss.StatusTextMrp}`}>( MRP - {item.subjectPrice} INR )</div>												 */}
												</label>	
											)

										}
									}

								})}	
							</div>
							</>
						}

						<Row>
							<Col>
								<button className="btn btn-primary mt-3" onClick={goforPayment}><CurrencyRupeeIcon/> Pay </button>
							</Col>
							<Col>
								<div className={`${StuDashBoardCss.amountBox}`}>
									{/* <div className={`${StuDashBoardCss.amountDigit}`}><CurrencyRupeeIcon/> {totalAmount}</div>*/}
									<div className={`${StuDashBoardCss.amountDigit}`}><CurrencyRupeeIcon/> {totalAmountAC+totalAmountLogic(totalDiscountedAmount)}</div>
									
								</div>
							</Col>
						</Row>
						
					</div>
				</div>
			</div>
        </>
      )}
	  { isPaymentStart && (
        <>
			<div className={`${StuDashBoardCss.addMoreSubjectBg}`}>
				<div className={`${StuDashBoardCss.subjectBody}`}>
					<div className={`${StuDashBoardCss.headingBk}`}>
						<Row>
							<Col>
								<div className={`${StuDashBoardCss.SectionHeading}`}>Payment Methods</div>
							</Col>
							<Col>
								<CancelIcon	onClick={() => {closeCartBox()}} className={`${StuDashBoardCss.crossbutton}`}/>
							</Col>
						</Row>
					</div>
					<div className={`${StuDashBoardCss.SubjectBodyArea}`} style={{"textAlign":"center"}}>
						<p style={{"fontSize":"22px","marginTop":"10px"}}>Please select the payment gateway for start the payment process.</p>
						{/* <PaytmButton btnValue={signupFormData.values.totalAmount} paymentStatusPaytm={paymentStatusPaytm}/> */}
						<button type="reset" className={`${RegisterCss.logButtonPaytm}`} onClick={paymentWithPaytm} ><span style={{"color": "#052E70"}}>PAY</span><span style={{"color": "#00BBF2"}}>TM</span></button>
						<button type="reset" className={`${RegisterCss.logButtonPaytm}`} onClick={paymentWithCCAvenue}  style={{"marginTop": "10px"}}><span style={{"color": "#157BC5"}}>CC</span> <span style={{"color": "#161415"}}>Avenue</span></button>
						
						{/* <PaytmButton btnValue={signupFormData.values.totalAmount} paymentStatusPaytm={paymentStatusPaytm}/> */}
						
					</div>
				</div>
			</div>
        </>
      )}
	  {/* Loader section start */}
		{showLoader && 
			<Loader/>
		}
	  {/* Loader section end */}
	  
    </div>
  );
}

export default App;

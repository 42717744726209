import StuDashBoardCss from './stuDashboard.module.css';
import Dropdown from 'react-bootstrap/Dropdown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LeftMenu from './LeftMenu';
import DashBoardButton from './dashBoardButton';
import Header from './header';


import PersonIcon from '@mui/icons-material/Person';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import { useRef } from 'react';
import swaLogo from '../../assets/logo.png';

function StudentDash() {
  const rightBox = useRef();
  const LeftMenuHide = (leftMenu) => {
    leftMenu.style.width = "52px";
    rightBox.current.style.marginLeft = "52px"
  }

  const LeftMenuShow = (showMenu)=>{
    showMenu.style.width = "280px";
    rightBox.current.style.marginLeft = "280px"
  }

  return (
    <div className={`${StuDashBoardCss.backDiv}`}>
       {/* Header Section */}
       
     <div ref={rightBox} className={`${StuDashBoardCss.rightArea}`}>
     <Header/>

      {/* Box Container */}
        <div className={`${StuDashBoardCss.containerBox}`}>
          <DashBoardButton />
         </div>
      {/* Box Container */}

   
     </div>
     {/* Right Area */}
    </div>
  );
}

export default StudentDash;

import React, {useEffect } from "react";
import Header from '../homePage/Header';
import Footer from '../homePage/Footer';

import AOS from "aos";
import "aos/dist/aos.css";


import {Container, Row, Col } from "react-bootstrap";

import layout from "../layout/innerPage.css";
import innerResp from "../layout/innerResp.css";

import Accordion from 'react-bootstrap/Accordion';

function Faq() {  

  useEffect( () =>{
    AOS.init({
      offset:200,
      duration:3000,
    });
  },[] );

 return (   
    <>
    <Header/>
    <div className="contactSection" data-aos="fade-right">
        <Container>
          <Row>
            <Col md={12}>
              <h1 style={{margin:"4px 0"}}> FAQs </h1>                     
            </Col>            
          </Row>                  
        </Container>
      </div> 
      <Container>
      <div className='faqs' data-aos="zoom-in-down">
        <Row>
          <Col md={12}>
            <Accordion >
              <Accordion.Item eventKey="0" >
                <div className='bor'>
                  <Accordion.Header><strong className="bor1"> Q. 1. </strong> How CUET Online Portal help in CBT Preparation? </Accordion.Header>
                  <Accordion.Body><strong className="bor1"> Ans. </strong> The portal allows CUET Aspirants to experience online exam, as it advocates online 
                  preparation of the CBT exam to assure better preparedness.  </Accordion.Body>
                </div>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <div className='bor'>
                  <Accordion.Header><strong className="bor1"> Q. 2. </strong> What type of questions are included in the portal?  </Accordion.Header>
                  <Accordion.Body><strong className="bor1"> Ans. </strong> Chapter-wise & Topic-wise Objective Type Questions are provided for CUET UG 2024 preparation. </Accordion.Body>
                </div>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <div className='bor'>
                <Accordion.Header><strong className="bor1"> Q. 3. </strong> Can I Access the portal in 2 devices?  </Accordion.Header>
                  <Accordion.Body><strong className="bor1"> Ans. </strong> CUET Online portal can be accessed by a user in one device at a time. </Accordion.Body>
                </div>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <div className='bor'>
                <Accordion.Header><strong className="bor1"> Q. 4. </strong> How online preparation helps to appear for CUET exam? </Accordion.Header>
                  <Accordion.Body><strong className="bor1"> Ans. </strong> Online preparation for CUET exam provides flexibility, access to diverse 
                  Practice Sets, and simulated test environments, enhancing convenience and readiness for success. </Accordion.Body>
                </div>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <div className='bor'>
                <Accordion.Header><strong className="bor1"> Q. 5. </strong> How to access the CUET online portal? </Accordion.Header>
                  <Accordion.Body><strong className="bor1"> Ans. </strong> The Portal can be accessed by using the Login Credentials shared on your email
                   ID, after successful registration. </Accordion.Body>
                </div>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <div className='bor'>
                <Accordion.Header><strong className="bor1"> Q. 6. </strong> How do I register for CUET on the online portal? </Accordion.Header>
                  <Accordion.Body><strong className="bor1"> Ans. </strong> <a target="_blank" href="https://cuet.rachnasagar.in/signup">Visit 
                  <b>&nbsp; https://cuet.rachnasagar.in/signup.&nbsp;</b>  Fill the required details in the Registration Form and complete the payment process. </a> &nbsp; 
                  </Accordion.Body>
                </div>
              </Accordion.Item> 
              <Accordion.Item eventKey="7">
                <div className='bor'>
                <Accordion.Header><strong className="bor1"> Q. 7. </strong>How can I retrieve my password if I forget it?  </Accordion.Header>
                  <Accordion.Body><strong className="bor1"> Ans. </strong>User’s Login credentials are shared via email. In case you’re unable 
                  to access it check your Inbox or you can contact our team for assistance. </Accordion.Body>
                </div>
              </Accordion.Item>       
              <Accordion.Item eventKey="8">
                <div className='bor'>
                <Accordion.Header><strong className="bor1"> Q. 8. </strong> What all subjects/sections does the Portal include? </Accordion.Header>
                  <Accordion.Body><strong className="bor1"> Ans. </strong> CUET Online Portal by Rachna Sagar includes 35k+ Questions MCQs for 
                  all 3 Sections and 19 Domain specific subjects, to practice. </Accordion.Body>
                </div>
              </Accordion.Item>   
              <Accordion.Item eventKey="9">
                <div className='bor'>
                <Accordion.Header><strong className="bor1"> Q. 9. </strong> What is the mode of payment for Registration Fee? </Accordion.Header>
                  <Accordion.Body><strong className="bor1"> Ans. </strong> You can make the payment in Online Mode only.</Accordion.Body>
                </div>
              </Accordion.Item>  
              <Accordion.Item eventKey="10">
                <div className='bor'>
                <Accordion.Header><strong className="bor1"> Q. 10. </strong> How can you check results of attempted papers on the Portal?  </Accordion.Header>
                  <Accordion.Body><strong className="bor1"> Ans. </strong> The evaluation/results of the papers you’ve attempted on the Portal will be visible in the Reports Section.  </Accordion.Body>
                </div>
              </Accordion.Item>                   
            </Accordion>            
          </Col>
        </Row>
      </div>
      </Container>
      <Footer/>
    </>
  );
}
export default Faq;

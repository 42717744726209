import React,{ useState } from 'react'
import EditQueCss from "./EditQue.module.css";

import Pagination from '@mui/material/Pagination';

function PaginationBar({questionsList,currentPage,setCurrentPage}) {

    const currentQuestionHandler = (event,value) => {
        setCurrentPage(value);
    }

    var limit = questionsList.length < 1 ? 0 : questionsList.length;
    // var limit = 10;

    return(
        <div>
            <div className={`${EditQueCss.pagination}`}>
                <Pagination count={limit} currentpage={currentPage} color="primary" shape="rounded" onChange={currentQuestionHandler}/>
            </div>
        </div>
    )
}


function PaginationX({questionsList}) {
    return (
        <div>
            <div className={`${EditQueCss.pagination}`}>
                        <nav class="pagination-outer" aria-label="Page navigation">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a href="#" class="page-link" aria-label="Previous">
                                        <span aria-hidden="true">«</span>
                                    </a>
                                </li>

                                {
                                    questionsList.map( (q,i)  => (
                                        <li class="page-item active"><a class="page-link" href="#">{i}</a></li>
                                    ) )
                                }

                                {/* <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item"><a class="page-link" href="#">4</a></li>
                                <li class="page-item"><a class="page-link" href="#">5</a></li>
                                <li class="page-item"><a class="page-link" href="#">6</a></li>
                                <li class="page-item"><a class="page-link" href="#">7</a></li>
                                <li class="page-item"><a class="page-link" href="#">8</a></li>
                                <li class="page-item"><a class="page-link" href="#">9</a></li>
                                <li class="page-item"><a class="page-link" href="#">10</a></li> */}
                                <li class="page-item">
                                    <a href="#" class="page-link" aria-label="Next">
                                        <span aria-hidden="true">»</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
            
        </div>
    )
}

export default PaginationBar;

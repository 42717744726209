import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import managQueCss from './ManageQue.module.css';
import Button from 'react-bootstrap/Button';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

import { Container, Row, Col, Modal, Alert } from 'react-bootstrap'

import EditQue from './EditQue'
import GenerateFinalExam from './GenerateFinalExam';
import GeneratePracticeSet from './GeneratePracticeSet';
import Exam_Setting from './Exam_setting';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import Header from '../adminDashboard/Header';
import AddpassageQue from './AddPassageQue'
import EditQuestionPopup from './EditQuestionPopup';
import EditPassagePopupPage from './EditPassagePopupPage';

import HomeIcon from '@mui/icons-material/Home';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import { apiBaseURL, apiURL, apiKey, ckFinderURL } from '../../constant';
import userContext from '../../Store';
import Loader from "../../Loader";

import Swal from 'sweetalert2';
import { FormatColorResetOutlined, ReplayOutlined } from '@mui/icons-material';

const ckEditorConfiguration = {
    toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'superscript', 'subscript', '|', 'alignment', 'insertTable', 'bulletedList', 'numberedList', '|', 'uploadImage', 'specialCharacters', 'MathType', 'ChemType'],
    ckfinder: {
        uploadUrl: ckFinderURL
    }
};

const quesProcessingDataConf = {
    mode: '',
    section: '',
    classID: 12,
    topic: '',
    subTopic: '',
    subject: '',
    chapter: '',
    setNo: '',
    isFormOpened: false,
    isWithPassage: "false",
    quesID: '',
    quesParagraphID: '',
    quesParagraph: '',
    quesHeading : '',
    quesOptionA : '',
    quesOptionB : '',
    quesOptionC : '',
    quesOptionD : '',
    quesRightOption : '0',
    quesExplanation : '',
    questionTypeID: "",
    questionBank:''
    // examType:'',
    
    // examTopic:''
};
const sectionsShowHideConf = {
    subject:false,
    topic:false,
    subTopic:false,
    chapter:false,
    examType: false,
    examTopic: false,
    questionBank : false,
}
const modalConf = {status:false,messageClass : "info",message:""};

function ManageQSchool() {

    const {userData} = useContext(userContext);

    const loginToken = localStorage.getItem('token');

    const [modalShow, setModalShow] = useState(modalConf);
    const [ckInit,setCkInit] = useState(false);
    const [isLoaderActive,setIsLoaderActive] = useState(true);
    const [isPopupLoaderActive,setIsPopupLoaderActive] = useState(true);
    const [sectionsList, setSectionsList] = useState([]);
    const [topicList, setTopicList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [subjectChaptersList, setSubjectChaptersList] = useState([]);
    const [questionBankTypeList, setQuestionBankTypeList] = useState([]);
    const [subTopicsList, setSubTopicsList] = useState([]);
    const [quesProcessingData, setQuesProcessingData] = useState(quesProcessingDataConf);
    const [sectionsShowHide, setSectionsShowHide] = useState(sectionsShowHideConf);
    const [questionsList, setQuestionsList]=useState([]);
    const [questionsOfSetList, setQuestionsOfSetList]=useState([]);
    const [questionsOfSetListX, setQuestionsOfSetListX]=useState([]);
    
    const [questionsIDsOfSet, setQuestionsIDsOfSet]=useState([]);
    const [questionsIDsOfSetX, setQuestionsIDsOfSetX]=useState([]);

    const [passageList, setPassageList]=useState([]);
    const [passagePopup, setPassagePopup]=useState(false);
    const [editPassagePopupState, setEditPassagePopupState]=useState(false);
    const [editQuestionPopup, setEditQuestionPopup]=useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [quesSegrigationModalShow,setQuesSegrigationModalShow] = useState( {status:false, paragraphID:'', questionID:'', qbTypeID:''} );
    const [paperSetsList, setPaperSetsList]=useState([])
    const[examTypeList , setExamTypeList] = useState([]);
    const[quesTypeID, setQuesTypeID] = useState()

    useEffect( ()=>{
        setIsLoaderActive(true)
        fetch( apiBaseURL+apiURL.getSectionList,{
            method:'GET',
            headers: { 'key':apiKey }
        } )
            .then( response => response.json() )
            .then( (result)=>{
                setIsLoaderActive(false);
                if(result.status=='success' ){
                    setSectionsList(result.data.sections);
                }
            } ).catch( (err) => {
                setIsLoaderActive(false);
            } ).finally( ()=>{
                setIsLoaderActive(false)
            } );

        getSet();
    },[] );
    const modalCloseHandler = e => {
        setModalShow(false);
    }

    const questionBankHandler=(quesBank)=>{
        setQuesProcessingData((prev)=>{
            return {...prev, questionBank:quesBank}
        })
    }
    const sectionChangeHandler = (e) => {
        var issubject = e.target[e.target.selectedIndex].getAttribute('data-issubject')
        if( issubject==1 ){
            setSectionsShowHide( (prev)=>{
                return {...prev, 
                    'subject' : true,
                    'topic':false,
                    'subTopic':false,
                    'chapter':false
                };
            } );
            setTopicList([]);
            getSubjectsOfSectionHandler(e.target.value);
        }else{
            setSectionsShowHide( (prev)=>{
                return {...prev, 
                    'subject' : false,
                    'topic':true,
                    'subTopic':false,
                    'chapter':false,
                    "examType":true,
                    "examTopic":false
                };
            } );
            setSubjectList([]);
            getTopicsOfSectionHandler(e.target.value);
        }
    }

    const subjectChangeHandler = (e) => {
        var istopic = e.target[e.target.selectedIndex].getAttribute('data-istopic')
        var ischapter = e.target[e.target.selectedIndex].getAttribute('data-ischapter')
        var istopic = (istopic === 'true');
        var ischapter = (ischapter === 'true');
        setSectionsShowHide( (prev)=>{
            return {...prev, 
                'topic': istopic,
                'subTopic':false,
                'chapter':ischapter,
                'examType' :true
            };
        } );

        if( istopic ){
            getTopicsOfSectionHandler(quesProcessingData.section,e.target.value);
        }
        if( ischapter ){
            getSubjectChapters(e.target.value);
        }
        if(sectionsShowHide.examType){
            setQuesProcessingData((prev)=>{
                return {...prev, questionTypeID:''}
            })
           
        }

    }

    function questionTypeHandle(questionType){
        // setQuesTypeID(questionType)
        setQuesProcessingData((prev)=>{
            return {...prev, questionTypeID:questionType}
        })
    }
    function examTypeHandler(e){
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getQuestionBankTypes,{
            method:'GET',
            headers: { 'key':apiKey }
        } )
            .then( response => response.json() )
            .then( (result)=>{
                setIsLoaderActive(false);
                if(result.status=='success' ){
                    setExamTypeList(result.data.questionBankType);
                }else{
                    setExamTypeList([]);
                }
            } )
            .catch( (err) => {
                setIsLoaderActive(false);
                setExamTypeList([]);
            } )
            .finally( ()=>{
                setIsLoaderActive(false);
            } ); 
    }
    const getSubjectsOfSectionHandler = (sectionID) =>{
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getSubjectsOfSection,{
            method:'POST',
            headers: { 'key':apiKey },
            body:JSON.stringify( {"section":sectionID} )
        } )
            .then( response => response.json() )
            .then( (result)=>{
                setIsLoaderActive(false);
                if(result.status=='success' ){
                    setSubjectList(result.data.subjects);
                }else{
                    setSubjectList([]);
                }
            } )
            .catch( (err) => {
                setIsLoaderActive(false);
                setSubjectList([]);
            } )
            .finally( ()=>{
                setIsLoaderActive(false);
            } );  
    }


    const getTopicsOfSectionHandler = (sectionID,subjectID=null) =>{
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getTopics,{
            method:'POST',
            headers: { 'key':apiKey },
            body:JSON.stringify( {"section":sectionID,"subject":subjectID} )
        } )
            .then( response => response.json() )
            .then( (result)=>{
                setIsLoaderActive(false);
                if(result.status=='success' ){
                    setTopicList(result.data.topics);
                }else{
                    setTopicList([]);
                }
            } )
            .catch( (err) => {
                setIsLoaderActive(false);
                setTopicList([]);
            } )
            .finally( ()=>{
                setIsLoaderActive(false);
            } );    
    }


    const generatePracticeSetHandler = (e) => {
        var markedCheckbox = document.getElementsByName('qxxSet');
        let checkedQues = [];
        for(var checkbox of markedCheckbox){
            if(checkbox.checked){
                checkedQues.push( parseInt(checkbox.value) );
            }
        }
        console.log(questionsIDsOfSetX);
        console.log(checkedQues);

        const xPostData = {
                    "userRefID":userData.userID,
                    "questionID":[...questionsIDsOfSetX],
                    "oldQuestionID":[...checkedQues],
                    "setID":quesProcessingData.setNo,
                    // "questionTypeID" : quesProcessingData.questionTypeID
                    "questionTypeID" : quesTypeID,
                    "schoolCode": userData.schoolCode
                };
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(xPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.generatePracticeSet, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status==='success' ){
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'success',message:"Successfully Updated." }
                    } );
                    searchQuestions(e,'2');
                    searchQuestionsOfSetHandler(quesProcessingData.setNo);
                }else{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err)=>{
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );

    }
    const generateFinalExamHandler = (e) => {
        var markedCheckbox = document.getElementsByName('qxxSet');
        let checkedQues = [];
        for(var checkbox of markedCheckbox){
            if(checkbox.checked){
                checkedQues.push( parseInt(checkbox.value) );
            }
        }
        // console.log(questionsIDsOfSetX);
        // console.log(checkedQues);

        const xPostData = {
                    "userRefID":userData.userID,
                    "questionID":[...questionsIDsOfSetX],
                    "oldQuestionID":[...checkedQues],
                    "setID":quesProcessingData.setNo,
                    // "questionTypeID" : quesProcessingData.questionTypeID
                    "questionTypeID" : quesTypeID,
                    "schoolCode": userData.schoolCode
                };
        console.log(xPostData)
        console.log("helo Ajay ")
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(xPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.selfSchoolGeneratePracticeSet, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status==='success'){
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'success',message:"Successfully Updated." }
                    } );
                    searchQuestions(e,'5');
                    searchQuestionsOfSetHandler(quesProcessingData.setNo,5);
                }else{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err)=>{
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );

    }

    const addSelectedQuestionsToSetQuestionListHandler = (e) => {
        // console.log( quesProcessingData.isWithPassage );
        // console.log( questionsIDsOfSetX );
        // var markedCheckbox = document.getElementsByName('qxSet');
        // for(var checkbox of markedCheckbox){
        //     if(checkbox.checked){
        //         let questionids = checkbox.getAttribute('data-questionids').split(',')
        //         questionids.forEach( (qID)=>{
        //             document.getElementById('qx'+qID).checked = true;
        //         } );
        //     }
        // }

        var markedCheckbox = document.getElementsByName('qxSet');
        let checkedQues = [];
        let checkedQuesData = [];
        for(var checkbox of markedCheckbox){
            if(checkbox.checked){
                checkedQues.push( parseInt(checkbox.value) );

                // console.log( parseInt(checkbox.value) );
                // console.log( questionsIDsOfSetX.indexOf( parseInt(checkbox.value) ) )
                // console.log("==================");
                if( questionsIDsOfSetX.indexOf( parseInt(checkbox.value) ) < 0 ){
                    let qxindex = checkbox.getAttribute('data-qxindex')
                    console.log( questionsList[qxindex] );
                    checkedQuesData.push( questionsList[qxindex] );
                }

            }
        }

        setQuestionsOfSetListX([...questionsOfSetListX, ...checkedQuesData])
        setQuestionsIDsOfSetX([...questionsIDsOfSetX, ...checkedQues]);
    }

    const selectSetNoHandler = (e) => {
        setQuesProcessingData( (prev)=>{
            return {...prev,
                'setNo':e.target.value,
                'isFormOpened': false
            }
        } )
        // searchQuestions(e,'2');
        searchQuestionsOfSetHandler(e.target.value);
        // console.log( quesProcessingData.section );
    }

    const searchQuestionsOfSetHandler = (setID,questionTypeID=2) => {
        const xPostData = {
                            "classID":12,
                            "sectionID":quesProcessingData.section,
                            "topicID":quesProcessingData.topic,
                            "subTopicID":quesProcessingData.subTopic,
                            "subjectID":quesProcessingData.subject,
                            "chapterID":quesProcessingData.chapter,
                            "isWithPassage":quesProcessingData.isWithPassage,
                            "questionTypeID":questionTypeID,
                            "setID":setID,
                            "schoolCode": userData.schoolCode
                            
                        };
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(xPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.selfSchoolSearchQuestionsOfSet, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status==='success' ){
                    // console.log( result.data.questions );
                    let qList = result.data.questions;
                    setQuestionsOfSetList( result.data.questions );
                    setQuestionsOfSetListX( result.data.questions );

                    setQuestionsIDsOfSet( result.data.questionIDs );
                    setQuestionsIDsOfSetX(result.data.questionIDs);
                }else{
                    setQuestionsOfSetList([]);
                    setQuestionsOfSetListX([]);

                    setQuestionsIDsOfSet([]);
                    setQuestionsIDsOfSetX([]);
                }
            } )
            .catch( (err)=>{
                setQuestionsOfSetList([]);
                setQuestionsOfSetListX([]);

                setQuestionsIDsOfSet([]);
                setQuestionsIDsOfSetX([]);
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );
    }


    const editQuesSegregationButtonHandler = (popupStatus,questionData) => {
        getQuestionBankTypeList();
        let questionTypeID = questionData.questionTypeID==null || questionData.questionTypeID=='' ? '' : questionData.questionTypeID;
        let paragraphID = questionData.paragraphID==null || questionData.paragraphID=='' ? '' : questionData.paragraphID;
        setQuesSegrigationModalShow( (prev) => {
            return {...prev,'status': popupStatus, paragraphID:paragraphID, questionID:questionData.questionID, qbTypeID:questionTypeID}
        } );
    }
    const editQuesSegregationModalCloseHandler = (popupStatus) => {
        setQuesSegrigationModalShow( (prev) => {
            return {...prev,'status': popupStatus, paragraphID:'', questionID:'', qbTypeID:''}
        } );
    }

    
    function getSet(){
        fetch(apiBaseURL+apiURL.getSetsList,{
            method: "GET",
            headers: {'key' : apiKey}
        })
        .then(response => response.json())
        .then((setData)=>{
            if(setData.status=="success"){
                // console.log(setData.data.sets);
                setPaperSetsList(setData.data.sets)
            }else{
                setPaperSetsList([])
                alert(setData.message)
            }
        })
        .catch((err)=>{
            setPaperSetsList([])
            alert(err)
        })

    }

    const qbTypeChangeHandler = (e) => {
        setQuesSegrigationModalShow( (prev) => {
            return {...prev,'qbTypeID': e.target.value}
        } );
        // console.log( quesSegrigationModalShow );
    }
    const getQuestionBankTypeList = () => {
        fetch( apiBaseURL+apiURL.getQuestionBankTypes,{
            method:'GET',
            headers: { 'key':apiKey }
        } )
            .then( response => response.json() )
            .then( (result)=>{
                setIsPopupLoaderActive(false);
                if(result.status=='success' ){
                    setQuestionBankTypeList(result.data.questionBankType);
                }
            } ).catch( (err) => {
                setIsPopupLoaderActive(false);
            } );
    }
    function updateQuestionTypeIDHandler(){
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify( {...quesSegrigationModalShow} )
        };
        setIsPopupLoaderActive(true);
        fetch( apiBaseURL+apiURL.updateQuestionTypeID, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status==='success' ){
                    searchQuestions();
                    editQuesSegregationModalCloseHandler(false);
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'success',message:"Successfully Updated." }
                    } );
                }else{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err)=>{
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsPopupLoaderActive(false);
            } );
    }


    const editQuesClosePopupHandler = () => {
        setQuesProcessingData((prev)=>{
            return {
                    ...prev, 
                    quesID:'',
                    quesParagraphID: '',
                    quesHeading : '',
                    quesOptionA : '',
                    quesOptionB : '',
                    quesOptionC : '',
                    quesOptionD : '',
                    quesRightOption : '0',
                    quesExplanation : ''
                }
        });
        setEditQuestionPopup(false)
    }

    const editPassageClosePopupHandler = () => {
        setQuesProcessingData((prev)=>{
            return {
                    ...prev, 
                    quesID:'',
                    quesParagraphID: '',
                    quesHeading : '',
                    quesOptionA : '',
                    quesOptionB : '',
                    quesOptionC : '',
                    quesOptionD : '',
                    quesRightOption : '0',
                    quesExplanation : ''
                }
        });
        setEditPassagePopupState(false)
    }

    const editQuesButtonHandler = (popupState,questionData) => {
        setQuesProcessingData((prev)=>{
            return {
                    ...prev, 
                    quesID: questionData.questionID,
                    quesParagraphID: questionData.paragraphID,
                    quesHeading: questionData.questionText,
                    quesOptionA: questionData.optionText1,
                    quesOptionB: questionData.optionText2,
                    quesOptionC: questionData.optionText3,
                    quesOptionD: questionData.optionText4,
                    quesRightOption: questionData.correctAnswerIDs,
                    quesExplanation: questionData.questionExplanation
                }
        });
        setEditQuestionPopup(popupState)
    }
    
    const quesProcessingDataHandler = e => setQuesProcessingData((prev)=>{
        // console.log(prev);
        // alert(e.target.value)
        // alert(e.target.name)
        if( e.target.name=='mode' && e.target.value!=prev.mode ){
            prev = {...quesProcessingDataConf};
            setSectionsShowHide(sectionsShowHideConf);
        }
        if( e.target.name=='section' && e.target.value!=prev.section ){
            prev = {...prev,
                setNo:'',
                topic: '',
                subTopic: '',
                subject: '',
                chapter: '',
                // examTopic:'',
                isFormOpened: false
            }
        }
        let qbType = e.target.value>=3 ? e.target.value : ""
        if(e.target.name=='questionTypeID' &&  quesProcessingData.section==2 && (e.target.value==5 || e.target.value==4 || e.target.value==3)){
            setSectionsShowHide((prev)=>{
                return {...prev,examTopic:true, chapter:false}
            })
            prev = {...prev, isWithPassage:'',chapter:'', isFormOpened: false}
        }
        if(e.target.name=='questionTypeID' && e.target.value!=qbType  &&  quesProcessingData.section==2 ){
            setSectionsShowHide((prev)=>{
                return {...prev,examTopic:false, chapter:true}
            })
            prev = {...prev, isWithPassage:'', topic:'', isFormOpened: false}
        }
        console.log(quesProcessingData.section)
        

        if(quesProcessingData.section==1 ){
            setSectionsShowHide((prev)=>{
                return {...prev,examTopic:false}
            })
        }
        if(quesProcessingData.section==3 ){
            setSectionsShowHide((prev)=>{
                return {...prev,examTopic:false}
            })
        }
        
        if(e.target.name=='questionTypeID' &&  quesProcessingData.section==3 && e.target.value==1){
            setSectionsShowHide((prev)=>{
                return {...prev,topic:true, chapter:false}
            })
            prev = {...prev, isWithPassage:'',chapter:'', isFormOpened: false}
        }
        if(e.target.name=='questionTypeID' &&  quesProcessingData.section==3 && e.target.value!=1){
            setSectionsShowHide((prev)=>{
                return {...prev,topic:false,subTopic:false, chapter:false}
            })
            prev = {...prev, isWithPassage:'',chapter:'',topic:'', subTopic:'', isFormOpened: false}
        }


        if( quesProcessingData.section==1 && e.target.name=='questionTypeID' ){
            prev = {...prev, subTopic: '', isFormOpened: false}
            if(quesProcessingData.questionTypeID==3 || quesProcessingData.questionTypeID==4 || quesProcessingData.questionTypeID==5 ){
                setSectionsShowHide((prev)=>{
                    return {...prev,subTopic:false}
                })
                prev = {...prev, subTopic:''}
               
            }
        }

        

        if(quesProcessingData.section==1 && e.target.name=='topic' ){
            prev = {...prev, subTopic: '', isFormOpened: false}
            if(quesProcessingData.questionTypeID==3 || quesProcessingData.questionTypeID==4 || quesProcessingData.questionTypeID==5 ){
                setSectionsShowHide((prev)=>{
                    return {...prev,subTopic:false}
                })
                prev = {...prev, subTopic:''}
            }
        }

        if( e.target.name=='subject' ){
            prev = {...prev, chapter: '', isFormOpened: false}
        }

        if( e.target.name=='subTopic' || e.target.name=='chapter' ){
            if( quesProcessingData.mode=='add' ){
                prev = {...prev, isWithPassage:'', isFormOpened: false}
            }else{
                prev = {...prev, isFormOpened: false}
            }

        }

        if( e.target.name=='isWithPassage' ){

            if( e.target.value=='true' && quesProcessingData.mode=='add' ){
                getPassages(e);
            }
            
            if(!ckInit && quesProcessingData.mode=='add' ){
                setIsLoaderActive(true);
            }

            if( quesProcessingData.mode=='add' || quesProcessingData.mode=='practice'  || quesProcessingData.mode=='final' ){
                prev = {
                    ...prev, 
                    isFormOpened: true, 
                    quesID:'',
                    quesParagraphID: '', 
                    quesParagraph:'',
                    quesHeading : '', 
                    quesOptionA : '',
                    quesOptionB : '',
                    quesOptionC : '',
                    quesOptionD : '',
                }
            }else{
                prev = {
                    ...prev, 
                    isFormOpened: false, 
                    quesID:'',
                    quesParagraphID: '', 
                    quesParagraph:'',
                    quesHeading : '', 
                    quesOptionA : '',
                    quesOptionB : '',
                    quesOptionC : '',
                    quesOptionD : '',
                }
            }
        }
       
        return {...prev, [e.target.name] : e.target.value };
    });

    const ckInitHandler = ( stats ) => {
        if(stats){
            setIsLoaderActive(false);
        }
        setCkInit(stats);
    };

    const ckeditorHandler = ( targetName, targetValue ) => {
        setQuesProcessingData( (prev) => {
            return {...prev, [targetName]:targetValue }
        } );
    };


    const searchQuestions = (e,questionTypeID=null) => {

        const xPostData = {
                            "classID":12,
                            "sectionID":quesProcessingData.section,
                            "topicID":quesProcessingData.topic,
                            "subTopicID":quesProcessingData.subTopic,
                            "subjectID":quesProcessingData.subject,
                            "chapterID":quesProcessingData.chapter,
                            "isWithPassage":quesProcessingData.isWithPassage,
                            "questionBankType":quesTypeID,
                            "schoolCode" : userData.schoolCode,
                            "schoolBankType": quesProcessingData.questionBank ? quesProcessingData.questionBank : ""
                        };
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(xPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.selfSearchQuestions, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status==='success' ){
                    // console.log( result.data.questions );
                    let qList = result.data.questions;
                    setQuestionsList( result.data.questions );
                    setQuesProcessingData( (prev) => {
                        return {...prev, isFormOpened:true }
                    } );

                    if( qList.length <1 ){
                        setModalShow( (prev)=>{
                            return {...prev, status:true,messageClass:'danger',message:"No Question Found" }
                        } );
                    }

                }else{
                    setQuestionsList([]);
                    setQuesProcessingData( (prev) => {
                        return {...prev, isFormOpened:false }
                    } );
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err)=>{
                setQuestionsList([]);
                setQuesProcessingData( (prev) => {
                    return {...prev, isFormOpened:false }
                } );
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );
    }


    function getPassages(){
        const chpPostData = {
                            "classID":12,
                            "sectionID":quesProcessingData.section,
                            "topicID":quesProcessingData.topic,
                            "subTopicID":quesProcessingData.subTopic,
                            "subjectID":quesProcessingData.subject,
                            "chapterID":quesProcessingData.chapter,
                            "questionTypeID": quesProcessingData.questionTypeID,
                            "schoolCode": userData.schoolCode
                        };
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(chpPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getSelfSchoolPassagesList, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status=='success' ){
                    setPassageList( result.data.passages );
                    // console.log( passageList )
                }else{
                    setPassageList([]);
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err)=>{
                setPassageList([]);
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );
    }

    function getSubjectChapters(subjectID){
        setSectionsShowHide( (prev)=>{
            return {...prev, 'chapter':true};
        } );
        const chpPostData = { "classID":12,"subjectID":subjectID };
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(chpPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getChapterList, conf )
            .then( response => response.json() )
            .then( (result) => {
                if( result.status=='success' ){
                    setSubjectChaptersList(result.data.chapters);
                }else{
                    setSubjectChaptersList([]);
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err) => {
                setSubjectChaptersList([]);
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );
    }



    function getSubTopicList(topicID){
        
        setSectionsShowHide( (prev)=>{
            return {...prev, 'subTopic':true};
        } );

        const topicPostData = { "topicID":topicID };
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(topicPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getSubTopics, conf )
            .then( response => response.json() )
            .then( (result) => {
                if( result.status=='success' ){
                    setSubTopicsList(result.data.subTopics);
                }else{
                    setSubTopicsList([]);
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err) => {
                setSubTopicsList([]);
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );
    }


    const addNewPassageQuesHandler = (popupState,passageID) => {
        setQuesProcessingData((prev)=>{
            return {...prev, quesParagraphID: passageID }
        });
        setPassagePopup(popupState)
    }

    const editPassageHandler = (popupState,passageID,paragraphText) => {
        setQuesProcessingData((prev)=>{
            return {...prev, quesParagraphID: passageID, quesParagraph:paragraphText }
        });
        setEditPassagePopupState(popupState)
    }


    // const editQuesButtonHandler = (popupState,questionData,questionID) => {
    //     // setQuesProcessingData((prev)=>{
    //     //     return {...prev, quesParagraphID: passageID }
    //     // });
    //     setEditPopup(popupState)
    // }


    const addNewQuesHandler = (e) => {
        const conf = {
            method:'post',
            headers:{'key':apiKey,'loginToken':loginToken},
            body:JSON.stringify({...quesProcessingData,'userID':userData.userRefID,"schoolCode":userData.schoolCode})
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.selfCreateQuestion, conf )
            .then( response => response.json() )
            .then( (result) => {
                if( result.status=='success' ){
                    // alert( result.message );

                    if( quesProcessingData.quesParagraphID!='' ){
                        getPassages(e);
                    }

                    setQuesProcessingData( (prev)=>{
                        return {...prev, 
                            quesHeading : '',
                            quesOptionA : '',
                            quesOptionB : '',
                            quesOptionC : '',
                            quesOptionD : '',
                            quesRightOption : '0',
                            quesExplanation : ''
                        }
                    } );
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'success',message:result.message }
                    } );
                }else{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( 
                (err)=>{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                    } );
                }
            )
            .finally( (x) => {
                setIsLoaderActive(false);
            } );
    }

    const deleteQuesButtonHandler = (questionID) => {

        Swal.fire({
            title: 'Are you sure ?',
            html: 'Do you really want to delete this question ? <br> Note: This action will permanently delete this question.',
            icon: 'question',
            confirmButtonText: 'Yes, Delete it.',
            confirmButtonColor: '#d33',
            focusCancel: true,
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                const conf = {
                    method:'post',
                    headers:{'key':apiKey,'loginToken':loginToken},
                    body:JSON.stringify({'questionID':questionID,'userID':userData.userRefID})
                };
                setIsLoaderActive(true);
                return fetch( apiBaseURL+apiURL.selfSchoolQuestionDelete, conf )
                        .then( response => response.json() )
                        .then( ( result ) => {
                            if( result.status!=='success' ) {
                                throw new Error(result.message)
                            }
                            searchQuestions();
                            return result.message;
                        } )
                        .catch( (error) => {
                            Swal.showValidationMessage("failed");
                        } )
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then( (r) => {

            if( r.isConfirmed ){
                Swal.fire(
                    'Success', 'Question Successfully Deleted.', 'success'
                );
            }

        } );
    }


    const deletePassageButtonHandler = (passageID) => {

        Swal.fire({
            title: 'Are you sure ?',
            html: 'Do you really want to delete this passage ? <br> Note: This action will permanently delete this passage along with its questions.',
            icon: 'question',
            confirmButtonText: 'Yes, Delete it.',
            confirmButtonColor: '#d33',
            focusCancel: true,
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                const conf = {
                    method:'post',
                    headers:{'key':apiKey,'loginToken':loginToken},
                    body:JSON.stringify({'passageID':passageID,'userID':userData.userRefID})
                };
                setIsLoaderActive(true);
                return fetch( apiBaseURL+apiURL.deletePassage, conf )
                        .then( response => response.json() )
                        .then( ( result ) => {
                            if( result.status!=='success' ) {
                                throw new Error(result.message)
                            }
                            getPassages();
                            return result.message;
                        } )
                        .catch( (error) => {
                            Swal.showValidationMessage("failed");
                        } )
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then( (r) => {

            if( r.isConfirmed ){
                Swal.fire(
                    'Success', 'Question Successfully Deleted.', 'success'
                );
            }

        } );
    }


    const updateQuesHandler = (e) => {

        const conf = {
            method:'post',
            headers:{'key':apiKey,'loginToken':loginToken},
            body:JSON.stringify({...quesProcessingData,'userID':userData.userRefID,"schoolCode": userData.schoolCode})
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.selfSchoolUpdateQuestion, conf )
            .then( response => response.json() )
            .then( (result) => {
                if( result.status=='success' ){

                    searchQuestions();
                    editQuesClosePopupHandler();

                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'success',message:result.message }
                    } );
                }else{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch(
                (err)=>{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                    } );
                }
            )
            .finally( (x) => {
                setIsLoaderActive(false);
            } );
    }


    const savePassage = (e)=>{
        const conf = {
            method:'post',
            headers:{'key':apiKey,'loginToken':loginToken},
            body:JSON.stringify({
                'classID': quesProcessingData.classID,
                'sectionID': quesProcessingData.section,
                'topicID': quesProcessingData.topic,
                'subTopicID': quesProcessingData.subTopic,
                'subjectID': quesProcessingData.subject,
                'chapterID': quesProcessingData.chapter,
                'quesParagraph':quesProcessingData.quesParagraph,
                'questionBankType':quesProcessingData.questionTypeID,
                'userID':userData.userRefID,
                "schoolCode": userData.schoolCode
            })
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.selfSchoolCreatePassage, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status=='success' ){
                    setQuesProcessingData( (prev)=>{
                        return {...prev, quesParagraph : ''}
                    } );
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'success',message:result.message }
                    } );
                    getPassages(e);
                }else{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err)=>{
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x) => {
                setIsLoaderActive(false);
            } );
    }


    const updatePassageHandler = (e)=>{
        const conf = {
            method:'post',
            headers:{'key':apiKey,'loginToken':loginToken},
            body:JSON.stringify({
                'quesParagraph':quesProcessingData.quesParagraph,
                'quesParagraphID':quesProcessingData.quesParagraphID,
                'userID':userData.userRefID,
                "schoolCode": userData.schoolCode
            })
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.selfSchoolUpdatePassage, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status=='success' ){
                    setQuesProcessingData( (prev)=>{
                        return {...prev, quesParagraph : '',quesParagraphID:''}
                    } );
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'success',message:result.message }
                    } );
                    setEditPassagePopupState(false);
                    getPassages(e);
                }else{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err)=>{
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x) => {
                setIsLoaderActive(false);
            } );
    }

 

    return (
        <div>
            <Header/>
            
            <div className={managQueCss.maincontainer}>
                <div className={managQueCss.addQueBar}>
                    <div className={managQueCss.buttonDiv}>  
                        
                        { ( userData.userRole.includes('add') || userData.userRole.includes('all') ) && 
                            <button 
                                className={`${managQueCss.addbtn} `+ ( quesProcessingData.mode=='add' ? `btn btn-success` : `btn btn-primary` ) +` addMore`} 
                                onClick={ 
                                    (e)=>{ e.target.name='mode'; e.target.value='add'; quesProcessingDataHandler(e); }
                                }
                            >
                                <AddCircleOutlineIcon/> 
                                Add Question
                            </button>
                        }

                        { ( userData.userRole.includes('view') || userData.userRole.includes('all') ) && 
                            <button 
                                className={`${managQueCss.editQue} `+ ( quesProcessingData.mode=='view' ? `btn btn-success` : `btn btn-primary` ) +` addMore`} 
                                onClick={ 
                                    (e)=>{ e.target.name='mode'; e.target.value='view'; quesProcessingDataHandler(e); }
                                }
                            >
                                <ArticleOutlinedIcon/> 
                                My Questions
                            </button>
                        }


                        { ( userData.userRole.includes('generate') || userData.userRole.includes('all') ) && 
                            <>
                                {/* <button 
                                    className={`${managQueCss.editQue} `+ ( quesProcessingData.mode=='practice' ? `btn btn-success` : `btn btn-outline-primary` ) +` addMore`} 
                                    onClick={ 
                                        (e)=>{ e.target.name='mode'; e.target.value='practice'; quesProcessingDataHandler(e); setQuesTypeID(2); }
                                    }
                                >
                                    <HistoryEduIcon/> 
                                    Manage Practice Sets
                                </button> */}
                               
                                <button 
                                    className={`${managQueCss.editQue} `+ ( quesProcessingData.mode=='final' ? `btn btn-success` : `btn btn-outline-primary` ) +` addMore`} 
                                    onClick={ 
                                        (e)=>{ e.target.name='mode'; e.target.value='final'; quesProcessingDataHandler(e); setQuesTypeID(5); }
                                    }
                                >
                                    <ReceiptLongIcon/> 
                                    Manage Mock CUET
                                </button>
                                {/* <button 
                                    className={`${managQueCss.editQue} `+ ( quesProcessingData.mode=='finalGenerate' ? `btn btn-success` : `btn btn-outline-primary` ) +` addMore`} 
                                    onClick={ 
                                        (e)=>{ e.target.name='mode'; e.target.value='finalGenerate'; quesProcessingDataHandler(e); }
                                    }
                                >
                                    <AutoFixHighIcon/> 
                                    Generate Final Exam
                                </button> */}
                                {/* <button 
                                    className={`${managQueCss.editQue} `+ ( quesProcessingData.mode=='Exam_setting' ? `btn btn-success` : `btn btn-primary` ) +` addMore`} 
                                    onClick={ 
                                        (e)=>{ e.target.name='mode'; e.target.value='Exam_setting'; quesProcessingDataHandler(e); }
                                    }
                                >
                                    <PlaylistAddCheckIcon/> 
                                   Exam Setting
                                </button> */}
                            </>
                        }

                             <div className={ `${managQueCss.backbutton}` }>
                                {userData.userTypeID==2 &&
                                    <Link to="/admin"><div className={managQueCss.backLinkDash}> <HomeIcon/> Back </div></Link>
                                }
                            </div>
                    </div>
                   
                       

                   
                </div>

                { ( quesProcessingData.mode=='add' || quesProcessingData.mode=='view' || quesProcessingData.mode=='Exam_setting') && 
                    
                    <Container fluid>
                        <div className={`${managQueCss.selectSubject}`}>
                            {/* <Row className='justify-content-start'> */}
                            <Row className={`${managQueCss.sectionDiv}`}>
                                <Col className="col-md-2 col-sm-12 col-12">
                                    <select 
                                        required 
                                        name='section' 
                                        className={`${managQueCss.slcDivSelect}`}
                                        onChange={ (e)=>{ quesProcessingDataHandler(e);sectionChangeHandler(e); examTypeHandler(e)} }
                                        value={quesProcessingData.section}
                                    >
                                        <option className={`d-none`}>Select Section</option>
                                        {
                                            sectionsList.map( (section,index) => (
                                                <option 
                                                    value={section.sectionID}
                                                    data-issubject={section.isSubject}
                                                    key={"section"+section.sectionID}
                                                >
                                                    {section.sectionName}
                                                </option>
                                            ) )
                                        }
                                    </select>
                                </Col>
                                {sectionsShowHide.subject && 
                                    <Col className='col-md-2 col-sm-12 col-12'>
                                        <select 
                                            required 
                                            className={`${managQueCss.selectSubject}`}
                                            name='subject'
                                            id='subject'
                                            value={quesProcessingData.subject}
                                            onChange={ (e)=>{quesProcessingDataHandler(e);subjectChangeHandler(e); examTypeHandler(e)} }
                                            // onChange={ (e)=>{quesProcessingDataHandler(e);getSubjectChapters(e.target.value)} }
                                        >
                                            <option className={`d-none`}>Select Subject</option>
                                            {
                                                subjectList.map( subject => (
                                                    <option
                                                        value={subject.subjectID}
                                                        data-istopic={subject.showTopic}
                                                        data-ischapter={subject.showChapter}
                                                        key={subject.subjectAbbreviation}
                                                    >
                                                        {subject.subjectName}
                                                    </option>
                                                ) )
                                            }
                                        </select>
                                    </Col>
                                        
                                }

                                {sectionsShowHide.examType && 
                                    <Col className='col-md-2 col-sm-12 col-12'>
                                        <select 
                                            required 
                                            className={`${managQueCss.selectSubject}`}
                                            name='questionTypeID'
                                            id='examType'
                                            value={quesProcessingData.questionTypeID}
                                            onChange={ (e)=>{quesProcessingDataHandler(e); setQuesTypeID(e.target.value)} }
                                            // onChange={ (e)=>{quesProcessingDataHandler(e);getSubjectChapters(e.target.value)} }
                                        >
                                            <option className={`d-none`}>Select Question Type</option>
                                            {
                                                examTypeList.map( exam => (
                                                    (exam.questionTypeID==1 || exam.questionTypeID==5) 
                                                        ? 
                                                            <option
                                                            value={exam.questionTypeID}
                                                            key={exam.questionTypeID}
                                                        >
                                                        {exam.questionTypeDesc }
                                                        </option>
                                                        :
                                                         ""
                                                ) )
                                            }
                                        </select>
                                    </Col>
                                        
                                }

                                {sectionsShowHide.examTopic && 
                                    <Col className='col-md-2 col-sm-12 col-12'>
                                        <select 
                                            required 
                                            className={`${managQueCss.selectSubject}`}
                                            name='topic'
                                            id='examTopic'
                                            value={quesProcessingData.topic}
                                            onChange={ (e)=>{quesProcessingDataHandler(e)} }
                                        >
                                            <option className={`d-none`}>Select Topic</option>
                                            {/* {
                                                examTypeList.map( exam => (
                                                    <option
                                                        value={exam.questionTypeID}
                                                        key={exam.questionTypeID}
                                                    >
                                                        {exam.questionTypeDesc}
                                                    </option>
                                                ) )
                                            } */}
                                            <option  value=''>No Topic</option>
                                             <option  value='10'>A</option>
                                             <option  value='11'>B1</option>
                                             <option  value='12'>B2</option>

                                        </select>
                                    </Col>
                                        
                                }


                                {sectionsShowHide.topic && 
                                    <Col className='col-md-2 col-sm-12 col-12'>
                                        <select 
                                            className={`${managQueCss.selectSubject}`}
                                            required
                                            name='topic'
                                            id='topic'
                                            onChange={ (e)=>{ quesProcessingDataHandler(e);getSubTopicList(e.target.value) } }
                                        >
                                        <option className={`d-none`}>Select Topic</option>
                                            {topicList
                                                .map( topic => (
                                                    <option 
                                                        value={topic.questionCatID}
                                                        key={"topic"+topic.questionCatID}
                                                    >
                                                        {topic.questionCatDesc}
                                                    </option>
                                                ) )
                                            }
                                        </select>
                                    </Col>
                                }
                                {sectionsShowHide.subTopic &&
                                
                                    <Col className='col-md-2 col-sm-12 col-12'>
                                        <select 
                                            className={`${managQueCss.selectSubject}`}
                                            name='subTopic'
                                            id='subTopic'
                                            required
                                            onChange={ quesProcessingDataHandler }
                                        >
                                            <option className={`d-none`}>Select Sub-Topic</option>
                                            {
                                                subTopicsList.map( sTopic => (
                                                    <option
                                                        value={sTopic.subTopicID}
                                                        key={'sTopic'+sTopic.subTopicID}
                                                    >
                                                        {sTopic.subTopicDesc}
                                                    </option>
                                                ) )
                                            }
                                        </select>
                                    </Col>
                                }
                                {sectionsShowHide.chapter && 
                                    <Col className='col-md-2 col-sm-12 col-12'>
                                        <select 
                                            required 
                                            className={`${managQueCss.selectSubject}`}
                                            name='chapter'
                                            id='chapter'
                                            onChange={ quesProcessingDataHandler }
                                        >
                                            <option className={`d-none`}>Select Chapter</option>
                                            {
                                                subjectChaptersList.map( chapter => (
                                                    <option
                                                        value={chapter.chapterID}
                                                        key={'chp'+chapter.chapterID}
                                                    >
                                                        {chapter.chapterName}
                                                    </option>
                                                ) )
                                            }
                                        </select>
                                    </Col>
                                }
                                <Col className="col-md-2 col-sm-12 col-12">
                                    <select 
                                        required 
                                        name='isWithPassage'
                                        className={`${managQueCss.selectSubject}`}
                                        value={quesProcessingData.isWithPassage}
                                        onChange={quesProcessingDataHandler}
                                    >
                                        <option className={`d-none`}>Select Question Type</option>
                                        <option value={true}>Passage </option>
                                        <option value={false}>Without Passage </option>
                                    </select>
                                </Col>
                                
                                { ( quesProcessingData.mode=='view' ) && 
                                    <Col className="col-md-2 col-sm-12 col-12">
                                        <button type='button' className="btn btn-success btn-sm my-2" onClick={ searchQuestions }>Search</button>
                                    </Col>
                                }

                            </Row>
                        </div>

                    </Container>
                }

                { ( quesProcessingData.mode=='practice' || quesProcessingData.mode=='final' ) && 
                    <Container fluid>
                        <div className={`${managQueCss.selectSubject}`}>
                            {/* <Row className='justify-content-start'> */}
                            <Row className={`${managQueCss.sectionDiv}`}>
                                <Col className="col-md-2 col-sm-12 col-12">
                                    <select 
                                        required 
                                        name='questionBank' 
                                        className={`${managQueCss.slcDivSelect}`}
                                        onChange={ (e)=>{ quesProcessingDataHandler(e);questionBankHandler(e.target.value); } }
                                    >
                                            <option className={`d-none`}>Select Question Bank</option>
                                
                                            {/* <option s value="SWASCH1">
                                                Forever Question Bank
                                            </option> */}
                                            <option value={userData.schoolCode}>
                                            School/Institution Question Bank
                                            </option>
                                               
                                           
                                    </select>
                                </Col>

                                <Col className="col-md-2 col-sm-12 col-12">
                                    <select 
                                        required 
                                        name='section' 
                                        className={`${managQueCss.slcDivSelect}`}
                                        onChange={ (e)=>{ quesProcessingDataHandler(e);sectionChangeHandler(e); } }
                                        value={quesProcessingData.section}
                                    >
                                        <option className={`d-none`}>Select Section</option>
                                        {
                                            sectionsList.map( (section,index) => (
                                                <option 
                                                    value={section.sectionID}
                                                    data-issubject={section.isSubject}
                                                    key={"section"+section.sectionID}
                                                >
                                                    {section.sectionName}
                                                </option>
                                            ) )
                                        }
                                    </select>
                                </Col>
                                {sectionsShowHide.subject && 
                                    <Col className='col-md-2 col-sm-12 col-12'>
                                        <select 
                                            required 
                                            className={`${managQueCss.selectSubject}`}
                                            name='subject'
                                            id='subject'
                                            value={quesProcessingData.subject}
                                            onChange={ (e)=>{quesProcessingDataHandler(e);subjectChangeHandler(e)} }
                                            // onChange={ (e)=>{quesProcessingDataHandler(e);getSubjectChapters(e.target.value)} }
                                        >
                                            
                                            {quesProcessingData.section==='1' && 
                                                <option className={`d-none`}>Select Language</option>
                                            }
                                            {quesProcessingData.section!=='1' && 
                                                <option className={`d-none`}>Select Subject</option>
                                            }
                                            
                                            {
                                                subjectList.map( subject => (
                                                    <option
                                                        value={subject.subjectID}
                                                        data-istopic={subject.showTopic}
                                                        data-ischapter={subject.showChapter}
                                                        key={subject.subjectAbbreviation}
                                                    >
                                                        {subject.subjectName}
                                                    </option>
                                                ) )
                                            }
                                        </select>
                                    </Col>
                                        
                                }
                                {sectionsShowHide.topic && 
                                    <Col className='col-md-2 col-sm-12 col-12'>
                                        <select 
                                            className={`${managQueCss.selectSubject}`}
                                            required
                                            name='topic'
                                            id='topic'
                                            // onChange={ (e)=>{ quesProcessingDataHandler(e);} }
                                            onChange={ (e)=>{ quesProcessingDataHandler(e);getSubTopicList(e.target.value) } }
                                        >
                                        <option className={`d-none`}>Select Topic</option>
                                            {topicList
                                                .map( topic => (
                                                    <option 
                                                        value={topic.questionCatID}
                                                        key={"topic"+topic.questionCatID}
                                                    >
                                                        {topic.questionCatDesc}
                                                    </option>
                                                ) )
                                            }
                                        </select>
                                    </Col>
                                }
                                {sectionsShowHide.chapter && 
                                    <Col className='col-md-2 col-sm-12 col-12'>
                                        <select 
                                            required 
                                            className={`${managQueCss.selectSubject}`}
                                            name='chapter'
                                            id='chapter'
                                            onChange={ quesProcessingDataHandler }
                                        >
                                            <option className={`d-none`}>Select Chapter</option>
                                            {
                                                subjectChaptersList.map( chapter => (
                                                    <option
                                                        value={chapter.chapterID}
                                                        key={'chp'+chapter.chapterID}
                                                    >
                                                        {chapter.chapterName}
                                                    </option>
                                                ) )
                                            }
                                        </select>
                                    </Col>
                                }
                                {sectionsShowHide.subTopic &&
                                        <Col className='col-md-3 col-sm-12 col-12'>
                                            {/* <label>Sub-Topic</label> */}
                                            <select 
                                                className={`${managQueCss.selectSubject}`}
                                                name='subTopic'
                                                id='subTopic'
                                                required
                                                // onChange={ quesProcessingDataHandler }
                                                // onChange={ (e)=>{ quesProcessingDataHandler(e);getSubTopicList(e.target.value) } }
                                                onChange={ (e)=>{ quesProcessingDataHandler(e) } }
                                            >
                                                <option className={`d-none`}>Select Sub-Topic</option>
                                                {
                                                    subTopicsList.map( sTopic => (
                                                        <option
                                                            value={sTopic.subTopicID}
                                                            key={'sTopic'+sTopic.subTopicID}
                                                        >
                                                            {sTopic.subTopicDesc}
                                                        </option>
                                                    ) )
                                                }
                                            </select>
                                        </Col>
                                    }
                                <Col className="col-md-2 col-sm-12 col-12">
                                    <select 
                                        required 
                                        name='isWithPassage'
                                        className={`${managQueCss.selectSubject}`}
                                        value={quesProcessingData.isWithPassage}
                                        onChange={quesProcessingDataHandler}
                                    >
                                        <option className={`d-none`}>Select Question Type</option>
                                        <option value={true}>Passage </option>
                                        <option value={false}>Without Passage </option>
                                    </select>
                                </Col>
                                
                                {quesProcessingData.mode=='practice' && 
                                    <>
                                        <Col className="col-md-2 col-sm-12 col-12">
                                            <select 
                                                className='form-control form-control-sm' 
                                                name='setNo'
                                                id='setNo'
                                                value={quesProcessingData.setNo}
                                                onChange={selectSetNoHandler}
                                            >
                                            <option className='d-none'>Select Set</option>
                                                {paperSetsList.filter( (item)=>{
                                                    if( item.availablePracticeExam==1 ){
                                                        return true
                                                    }
                                                    return false
                                                } ).map((item,sets)=>{
                                                    return(
                                                        <option 
                                                            value={item.setID}
                                                            key={sets}
                                                        >
                                                            {item.setDesc}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </Col>
                                        <Col className="col-md-2 col-sm-12 col-12">
                                            <button type='button' className="btn btn-success btn-sm my-2" onClick={ (e)=>{ searchQuestions(e,'2');searchQuestionsOfSetHandler(quesProcessingData.setNo); } }>Search</button>
                                        </Col>
                                    </>
                                }

                                {quesProcessingData.mode=='final' && 
                                    <>
                                        <Col className="col-md-2 col-sm-12 col-12">
                                            <select 
                                                className='form-control form-control-sm' 
                                                name='setNo'
                                                id='setNo'
                                                value={quesProcessingData.setNo}
                                                onChange={selectSetNoHandler}
                                            >
                                            <option className='d-none'>Select Set</option>
                                                {paperSetsList.filter( (item)=>{
                                                    if( item.availableFinalExam==1 ){
                                                        return true
                                                    }
                                                    return false
                                                } ).map((item,sets)=>{
                                                    
                                                    return(
                                                        <option 
                                                            value={item.setID}
                                                            key={sets}
                                                        >
                                                            {item.setDesc}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </Col>
                                        <Col className="col-md-2 col-sm-12 col-12">
                                            <button type='button' className="btn btn-success btn-sm my-2" onClick={ (e)=>{ searchQuestions(e,'5');searchQuestionsOfSetHandler(quesProcessingData.setNo,5); } }>Search</button>
                                        </Col>
                                    </>
                                }

                            </Row>
                        </div>
                    </Container>
                }


                { quesProcessingData.mode=='finalGenerate' && 
                    <Container fluid>
                        <div className={`${managQueCss.selectSubject}`}>
                            {/* <Row className='justify-content-start'> */}
                            <Row className={`${managQueCss.sectionDiv}`}>
                                <Col className="col-md-2 col-sm-12 col-12">
                                    <input 
                                        type='date'
                                        name='examStartDate'
                                        id='examStartDate'
                                        className={`${managQueCss.slcDivSelect} ${managQueCss.formControlSm}`}
                                    />
                                </Col>
                                <Col className="col-md-2 col-sm-12 col-12">
                                    <select 
                                        required 
                                        name='section' 
                                        className={`${managQueCss.slcDivSelect}`}
                                        onChange={ (e)=>{ quesProcessingDataHandler(e);sectionChangeHandler(e); } }
                                        value={quesProcessingData.section}
                                    >
                                        <option className={`d-none`}>Select Section</option>
                                        {
                                            sectionsList.map( (section,index) => (
                                                <option 
                                                    value={section.sectionID}
                                                    data-issubject={section.isSubject}
                                                    key={"section"+section.sectionID}
                                                >
                                                    {section.sectionName}
                                                </option>
                                            ) )
                                        }
                                    </select>
                                </Col>
                                {sectionsShowHide.subject && 
                                    <Col className='col-md-2 col-sm-12 col-12'>
                                        <select 
                                            required 
                                            className={`${managQueCss.selectSubject}`}
                                            name='subject'
                                            id='subject'
                                            value={quesProcessingData.subject}
                                            onChange={ (e)=>{quesProcessingDataHandler(e);subjectChangeHandler(e)} }
                                        >
                                            
                                            {quesProcessingData.section==='1' && 
                                                <option className={`d-none`}>Select Language</option>
                                            }
                                            {quesProcessingData.section!=='1' && 
                                                <option className={`d-none`}>Select Subject</option>
                                            }
                                            
                                            {
                                                subjectList.map( subject => (
                                                    <option
                                                        value={subject.subjectID}
                                                        data-istopic={subject.showTopic}
                                                        data-ischapter={subject.showChapter}
                                                        key={subject.subjectAbbreviation}
                                                    >
                                                        {subject.subjectName}
                                                    </option>
                                                ) )
                                            }
                                        </select>
                                    </Col>
                                        
                                }
                                <Col className="col-md-2 col-sm-12 col-12">
                                    <button type='button' className="btn btn-success btn-sm my-2" onClick={ (e)=>{ searchQuestions(e,'5');searchQuestionsOfSetHandler(quesProcessingData.setNo,5); } }>Generate and assigne</button>
                                </Col>

                            </Row>
                        </div>
                    </Container>
                }
                
                {quesProcessingData.mode=='view' && 
                    <EditQue
                        userData={userData}
                        quesProcessingData={quesProcessingData}
                        questionsList={questionsList}
                        sectionsList={sectionsList}
                        quesProcessingDataHandler={quesProcessingDataHandler}
                        getSubjectChapters={getSubjectChapters}
                        getSubTopicList={getSubTopicList}
                        searchQuestions={searchQuestions}
                        setCurrentPage={setCurrentPage}
                        editQuesSegregationButtonHandler={editQuesSegregationButtonHandler}
                        editQuesButtonHandler={editQuesButtonHandler}
                        deleteQuesButtonHandler={deleteQuesButtonHandler}
                        currentPage={currentPage}
                        isLoaderActive={isLoaderActive}
                        topicList={topicList}
                        subTopicsList={subTopicsList}
                        subjectList={subjectList}
                        subjectChaptersList={subjectChaptersList}
                    />
                }


                {quesProcessingData.mode=='practice' &&
                    <GeneratePracticeSet
                        userData={userData}
                        questionsOfSetListX={questionsOfSetListX}
                        generatePracticeSetHandler={generatePracticeSetHandler}
                        quesProcessingData={quesProcessingData}
                        questionsList={questionsList}
                        questionsOfSetList={questionsOfSetList}
                        questionsIDsOfSet={questionsIDsOfSet}
                        questionsIDsOfSetX={questionsIDsOfSetX}
                        sectionsList={sectionsList}
                        addSelectedQuestionsToSetQuestionListHandler={addSelectedQuestionsToSetQuestionListHandler}
                        quesProcessingDataHandler={quesProcessingDataHandler}
                        setQuesProcessingData={setQuesProcessingData}
                        getSubjectChapters={getSubjectChapters}
                        getSubTopicList={getSubTopicList}
                        searchQuestions={searchQuestions}
                        setCurrentPage={setCurrentPage}
                        editQuesButtonHandler={editQuesButtonHandler}
                        deleteQuesButtonHandler={deleteQuesButtonHandler}
                        currentPage={currentPage}
                        isLoaderActive={isLoaderActive}
                        topicList={topicList}
                        subTopicsList={subTopicsList}
                        subjectList={subjectList}
                        subjectChaptersList={subjectChaptersList}
                    />
                }
                {quesProcessingData.mode=='Exam_setting' &&
                    <Exam_Setting
                        userData={userData}
                        quesProcessingData={quesProcessingData}
                        questionsList={questionsList}
                        sectionsList={sectionsList}
                        quesProcessingDataHandler={quesProcessingDataHandler}
                        setQuesProcessingData={setQuesProcessingData}
                        getSubjectChapters={getSubjectChapters}
                        getSubTopicList={getSubTopicList}
                        searchQuestions={searchQuestions}
                        setCurrentPage={setCurrentPage}
                        editQuesButtonHandler={editQuesButtonHandler}
                        deleteQuesButtonHandler={deleteQuesButtonHandler}
                        currentPage={currentPage}
                        isLoaderActive={isLoaderActive}
                        topicList={topicList}
                        subTopicsList={subTopicsList}
                        subjectList={subjectList}
                        subjectChaptersList={subjectChaptersList}
                    />
                }


                {quesProcessingData.mode=='final' &&
                    <GenerateFinalExam
                        userData={userData}
                        questionsOfSetListX={questionsOfSetListX}
                        generateFinalExamHandler={generateFinalExamHandler}
                        quesProcessingData={quesProcessingData}
                        questionsList={questionsList}
                        questionsOfSetList={questionsOfSetList}
                        questionsIDsOfSet={questionsIDsOfSet}
                        questionsIDsOfSetX={questionsIDsOfSetX}
                        sectionsList={sectionsList}
                        addSelectedQuestionsToSetQuestionListHandler={addSelectedQuestionsToSetQuestionListHandler}
                        quesProcessingDataHandler={quesProcessingDataHandler}
                        setQuesProcessingData={setQuesProcessingData}
                        getSubjectChapters={getSubjectChapters}
                        getSubTopicList={getSubTopicList}
                        searchQuestions={searchQuestions}
                        setCurrentPage={setCurrentPage}
                        editQuesButtonHandler={editQuesButtonHandler}
                        deleteQuesButtonHandler={deleteQuesButtonHandler}
                        currentPage={currentPage}
                        isLoaderActive={isLoaderActive}
                        topicList={topicList}
                        subTopicsList={subTopicsList}
                        subjectList={subjectList}
                        subjectChaptersList={subjectChaptersList}
                    />
                }
                
               

                
                {quesProcessingData.mode=='add' && 
                
                    <>
                        {/* <div className={`${managQueCss.selectTopic}`}> */}
                            {/* <form method='get' className={`${managQueCss.selectSubject}`}>
                                <Row className='justify-content-start'>
                                    {sectionsShowHide.subject && 
                                        <Col className='col-md-3 col-sm-12 col-12'>
                                            <label>Subject</label>
                                            <select 
                                                required 
                                                className={`${managQueCss.selectSubject}`}
                                                name='subject'
                                                id='subject'
                                                onChange={ (e)=>{quesProcessingDataHandler(e);getSubjectChapters(e.target.value)} }
                                            >
                                                <option className={`d-none`}>Select Subject</option>
                                                {
                                                    subjectList.map( subject => (
                                                        <option
                                                            value={subject.subjectID}
                                                            key={subject.subjectAbbreviation}
                                                        >
                                                            {subject.subjectName}
                                                        </option>
                                                    ) )
                                                }
                                            </select>
                                        </Col>
                                            
                                    }
                                    {sectionsShowHide.topic && 
                                        <Col className='col-md-3 col-sm-12 col-12'>
                                            <label>Topic</label>
                                            <select 
                                                className={`${managQueCss.selectSubject}`}
                                                required
                                                name='topic'
                                                id='topic'
                                                onChange={ (e)=>{ quesProcessingDataHandler(e);getSubTopicList(e.target.value) } }
                                            >
                                            <option className={`d-none`}>Select Topic</option>
                                                {topicList
                                                    .map( topic => (
                                                        <option 
                                                            value={topic.questionCatID}
                                                            key={"topic"+topic.questionCatID}
                                                        >
                                                            {topic.questionCatDesc}
                                                        </option>
                                                    ) )
                                                }
                                            </select>
                                        </Col>
                                    }
                                    {sectionsShowHide.subTopic &&
                                        <Col className='col-md-3 col-sm-12 col-12'>
                                            <label>Sub-Topic</label>
                                            <select 
                                                className={`${managQueCss.selectSubject}`}
                                                name='subTopic'
                                                id='subTopic'
                                                required
                                                onChange={ quesProcessingDataHandler }
                                            >
                                                <option className={`d-none`}>Select Sub-Topic</option>
                                                {
                                                    subTopicsList.map( sTopic => (
                                                        <option
                                                            value={sTopic.subTopicID}
                                                            key={'sTopic'+sTopic.subTopicID}
                                                        >
                                                            {sTopic.subTopicDesc}
                                                        </option>
                                                    ) )
                                                }
                                            </select>
                                        </Col>
                                    }
                                    {sectionsShowHide.chapter && 
                                        <Col className='col-md-3 col-sm-12 col-12'>
                                            <label>Chapter</label>
                                            <select 
                                                required 
                                                className={`${managQueCss.selectSubject}`}
                                                name='chapter'
                                                id='chapter'
                                                onChange={ quesProcessingDataHandler }
                                            >
                                                <option className={`d-none`}>Select Chapter</option>
                                                {
                                                    subjectChaptersList.map( chapter => (
                                                        <option
                                                            value={chapter.chapterID}
                                                            key={'chp'+chapter.chapterID}
                                                        >
                                                            {chapter.chapterName}
                                                        </option>
                                                    ) )
                                                }
                                            </select>
                                        </Col>
                                    }
                                </Row>
                            </form> */}

                            {/* {quesProcessingData.section=='1' &&
                                <form method='get' className={`${managQueCss.selectSubject}`}>
                                    <Row className='justify-content-start'>
                                        <Col className='col-md-3 col-sm-12 col-12'>
                                            <label>Topic</label>
                                            <select 
                                                className={`${managQueCss.selectSubject}`}
                                                required
                                                name='topic'
                                                id='topic'
                                                onChange={ (e)=>{ quesProcessingDataHandler(e);getSubTopicList(e.target.value) } }
                                            >
                                            <option className={`d-none`}>Select Topic</option>
                                                {topicList
                                                    .map( topic => (
                                                        <option 
                                                            value={topic.questionCatID}
                                                            key={"topic"+topic.questionCatID}
                                                        >
                                                            {topic.questionCatDesc}
                                                        </option>
                                                    ) )
                                                }
                                            </select>
                                        </Col>
                                        <Col className='col-md-3 col-sm-12 col-12'>
                                            <label>Sub-Topic</label>
                                            <select 
                                                className={`${managQueCss.selectSubject}`}
                                                name='subTopic'
                                                id='subTopic'
                                                required
                                                onChange={ quesProcessingDataHandler }
                                            >
                                                <option className={`d-none`}>Select Sub-Topic</option>
                                                {
                                                    subTopicsList.map( sTopic => (
                                                        <option
                                                            value={sTopic.subTopicID}
                                                            key={'sTopic'+sTopic.subTopicID}
                                                        >
                                                            {sTopic.subTopicDesc}
                                                        </option>
                                                    ) )
                                                }
                                            </select>
                                        </Col>
                                    </Row>
                                </form> 
                            }
                            {quesProcessingData.section=='2'&&
                                <form method='get' className={`${managQueCss.selectSubject}`}>
                                    <Row className='justify-content-start'>
                                    <Col className='col-md-3 col-sm-12 col-12'>
                                        <label>Subject</label>
                                        <select 
                                            required 
                                            className={`${managQueCss.selectSubject}`}
                                            name='subject'
                                            id='subject'
                                            onChange={ (e)=>{quesProcessingDataHandler(e);getSubjectChapters(e.target.value)} }
                                        >
                                            <option className={`d-none`}>Select Subject</option>
                                            {
                                                subjectList.map( subject => (
                                                    <option
                                                        value={subject.subjectID}
                                                        key={subject.subjectAbbreviation}
                                                    >
                                                        {subject.subjectName}
                                                    </option>
                                                ) )
                                            }
                                        </select>
                                    </Col>
                                    <Col className='col-md-3 col-sm-12 col-12'>
                                        <label>Chapter</label>
                                        <select 
                                            required 
                                            className={`${managQueCss.selectSubject}`}
                                            name='chapter'
                                            id='chapter'
                                            onChange={ quesProcessingDataHandler }
                                        >
                                            <option className={`d-none`}>Select Chapter</option>
                                            {
                                                subjectChaptersList.map( chapter => (
                                                    <option
                                                        value={chapter.chapterID}
                                                        key={'chp'+chapter.chapterID}
                                                    >
                                                        {chapter.chapterName}
                                                    </option>
                                                ) )
                                            }
                                        </select>
                                    </Col>
                
                                    
                
                                    </Row>
                                </form>
                            } */}
                        {/* </div>  */}
                        
                        <div className={`${managQueCss.addQueFormContainer}`}>

                            {/* { isLoaderActive && <div className={managQueCss.loaderClass}>
                                <div className="spinner-border text-primary" role="status"></div>
                            </div> } */}



                            {/* { (quesProcessingData.subTopic!='' || quesProcessingData.chapter!='') &&
                                <div className={`${managQueCss.passageTypeBtn}`}>
                                    <Button 
                                        variant={ quesProcessingData.isWithPassage=='true' ? "success addMore2" : "outline-success addMore2" } 
                                        onClick={ 
                                            (e) => { e.target.name='isWithPassage'; e.target.value=true; quesProcessingDataHandler(e); }
                                        }
                                    >
                                        Passage
                                    </Button>
                                    <Button 
                                        variant={ quesProcessingData.isWithPassage=='false' ? "success" : "outline-success" } 
                                        onClick={ 
                                            (e) => { e.target.name='isWithPassage'; e.target.value=false; quesProcessingDataHandler(e); }
                                        }
                                    > 
                                        With Out Passage
                                    </Button>
                                </div>
                            } */}




                            {/* passage based section - start */}
                            { quesProcessingData.isFormOpened && quesProcessingData.isWithPassage=='true' &&
                                <div>

                                    <div className={`${managQueCss.queTextBox}`}>
                                        <div className={`${managQueCss.lableText}`}><p>New Passage Text</p></div>
                                        <div className={`${managQueCss.ckEditorBox}`}>
                                            <CKEditor 
                                                data={quesProcessingData.quesParagraph}
                                                editor={ClassicEditor}
                                                config={ckEditorConfiguration}
                                                onReady={(editor) => {
                                                    // console.log('init')
                                                }}
                                                onChange={ (e, editor) => {
                                                    ckeditorHandler( 'quesParagraph', editor.getData() );
                                                } }
                                            />
                                        </div>
                                    </div>
                                    <div className={`${managQueCss.popupSavBtn}`}>
                                        <button 
                                            className='btn btn-success my-4' 
                                            onClick={()=>{savePassage()}}
                                        >
                                            Save Passage
                                        </button>
                                    </div>

                                    <div className={`${managQueCss.passageS}`}>
                                        <table className={`${managQueCss.passageTable}`}>
                                            <thead>
                                                <tr>
                                                    <th className={`${managQueCss.passageId}`}>Passage ID</th>
                                                    {/* <th className={`${managQueCss.srNo}`}>Sr. No.</th> */}
                                                    <th>Passage</th>
                                                    <th className={`${managQueCss.numQue}`}>Number of Question</th>
                                                    <th className={`${managQueCss.addQBtn}`}>Add</th>
                                                    { ( userData.userRole.includes('edit') || userData.userRole.includes('all') ) &&
                                                        <th className={`${managQueCss.addQBtn}`}>Edit</th>
                                                    }
                                                    { ( userData.userRole.includes('delete') || userData.userRole.includes('all') ) &&
                                                        <th className={`${managQueCss.addQBtn}`}>Delete</th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {passageList.map((item, index)=>{
                                                    return(
                                                        <tr key={"passage"+index}>
                                                            <td>{index+1}</td>
                                                            {/* <td >1.</td> */}
                                                            <td dangerouslySetInnerHTML={{__html:item.paragraphText}}></td>
                                                            <td className={`${managQueCss.numQue}`}>{item.questionCount}</td>
                                                            <td>
                                                                <button 
                                                                    className='btn btn-primary' 
                                                                    onClick={
                                                                        ()=>{
                                                                            addNewPassageQuesHandler(true,item.paragraphID)
                                                                        }
                                                                    }
                                                                >
                                                                    Add Ques.
                                                                </button>
                                                            </td>
                                                            { ( userData.userRole.includes('edit') || userData.userRole.includes('all') ) &&
                                                                <td>
                                                                    <button
                                                                        className='btn btn-primary' 
                                                                        onClick={
                                                                            ()=>{
                                                                                editPassageHandler(true,item.paragraphID,item.paragraphText)
                                                                            }
                                                                        }
                                                                    >
                                                                        Edit Passage
                                                                    </button>
                                                                </td>
                                                            }
                                                            {/* { ( userData.userRole.includes('delete') || userData.userRole.includes('all') ) &&
                                                                <td>
                                                                    <button 
                                                                        className='btn btn-secondary' 
                                                                        onClick={
                                                                            ()=>{
                                                                                deletePassageButtonHandler(item.paragraphID)
                                                                            }
                                                                        }
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </td>
                                                            } */}
                                                        </tr>
                                                    )

                                                })}
                                            </tbody>


                                        
                                        </table>
                                    </div>

                                    
                                </div>
                            }
                            {/* passage based section - end */}




                            {/* non-passage based section - start */}
                            { quesProcessingData.isFormOpened && quesProcessingData.isWithPassage!='true' &&
                                <div className={`${managQueCss.addQueForm}`}>                                                
                                                            
                                    
                                    <div className={`${managQueCss.queTextBox}`}>
                                        <div className={`${managQueCss.lableText}`}><p>Question Heading</p></div>
                                        <div className={`${managQueCss.ckEditorBox}`}>
                                            
                                            <CKEditor
                                                data={quesProcessingData.quesHeading}
                                                editor={ClassicEditor}
                                                config={ckEditorConfiguration}
                                                onReady={(editor) => {
                                                    ckInitHandler(true);
                                                    // console.log('init')
                                                }}
                                                onChange={ (e, editor) => {
                                                    ckeditorHandler( 'quesHeading', editor.getData() );
                                                } }
                                            />
                                        </div>
                                    </div>
                                    <div className={`${managQueCss.optionContainer}`}>
                                        <div className={`${managQueCss.optionTextBox}`}>
                                            <div className={`${managQueCss.lableText}`}><p>(A)</p></div>
                                            <div className={`${managQueCss.ckEditorBox}`}>
                                                <CKEditor
                                                    data={quesProcessingData.quesOptionA}
                                                    editor={ClassicEditor}
                                                    config={ckEditorConfiguration}
                                                    onReady={(editor) => {
                                                        // console.log('init')
                                                    }}
                                                    onChange={ (e, editor) => {
                                                        ckeditorHandler( 'quesOptionA', editor.getData() );
                                                    } }
                                                />
                                            </div>
                                        </div>
                                        <div className={`${managQueCss.optionTextBox}`}>
                                            <div className={`${managQueCss.lableText}`}><p>(B)</p></div>
                                            <div className={`${managQueCss.ckEditorBox}`}>
                                                <CKEditor
                                                    data={quesProcessingData.quesOptionB}
                                                    editor={ClassicEditor}
                                                    config={ckEditorConfiguration}
                                                    onReady={(editor) => {
                                                        // console.log('init')
                                                    }}
                                                    onChange={ (e, editor) => {
                                                        ckeditorHandler( 'quesOptionB', editor.getData() );
                                                    } }
                                                />
                                            </div>
                                        </div>
                                        <div className={`${managQueCss.optionTextBox}`}>
                                            <div className={`${managQueCss.lableText}`}><p>(C)</p></div>
                                            <div className={`${managQueCss.ckEditorBox}`}>
                                                <CKEditor
                                                    data={quesProcessingData.quesOptionC}
                                                    editor={ClassicEditor}
                                                    config={ckEditorConfiguration}
                                                    onReady={(editor) => {
                                                        // console.log('init')
                                                    }}
                                                    onChange={ (e, editor) => {
                                                        ckeditorHandler( 'quesOptionC', editor.getData() );
                                                    } }
                                                />
                                            </div>
                                        </div>
                                        <div className={`${managQueCss.optionTextBox}`}>
                                            <div className={`${managQueCss.lableText}`}><p>(D)</p></div>
                                            <div className={`${managQueCss.ckEditorBox}`}>
                                                <CKEditor
                                                    data={quesProcessingData.quesOptionD}
                                                    editor={ClassicEditor}
                                                    config={ckEditorConfiguration}
                                                    onReady={(editor) => {
                                                        // console.log('init')
                                                    }}
                                                    onChange={ (e, editor) => {
                                                        ckeditorHandler( 'quesOptionD', editor.getData() );
                                                    } }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${managQueCss.queTextBox}`}>

                                        <div className={`${managQueCss.explnBox}`}>
                                            <div className={`${managQueCss.lableText}`}><p>Explanation</p></div>
                                            <div className={`${managQueCss.CorrectAns}`}>
                                                <select 
                                                    className={`${managQueCss.optBtn}`}
                                                    value={ quesProcessingData.quesRightOption }
                                                    onChange={ (e)=> { e.target.name='quesRightOption'; quesProcessingDataHandler(e); } }
                                                >
                                                    <option value="0" className={`${managQueCss.selectOpt}`}>Correct Answer</option>
                                                    <option value="1">A</option>
                                                    <option value="2">B</option>
                                                    <option value="3">C</option>
                                                    <option value="4">D</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className={`${managQueCss.ckEditorBox}`}>
                                            <CKEditor
                                                data={quesProcessingData.quesExplanation}
                                                editor={ClassicEditor}
                                                config={ckEditorConfiguration}
                                                onReady={(editor) => {
                                                    // console.log('init')
                                                }}
                                                onChange={ (e, editor) => {
                                                    ckeditorHandler( 'quesExplanation', editor.getData() );
                                                } }
                                            />
                                        </div>
                                    </div>
                                    <div className={`${managQueCss.submitBtn}`}>
                                        <button 
                                            className={`${managQueCss.saveQue}, btn btn-primary`}
                                            onClick={ addNewQuesHandler }
                                        >
                                            Save Question
                                        </button>
                                    </div>
                                </div>
                            }
                            {/* non-passage based section - end */}
                        </div>
                    </>

                
                }

            </div>


            {passagePopup &&
                <AddpassageQue  quesProcessingData={quesProcessingData} addNewQuesHandler={addNewQuesHandler} quesProcessingDataHandler={quesProcessingDataHandler} ckeditorHandler={ckeditorHandler} setPassagePopup={setPassagePopup}/>
            }

            {editQuestionPopup &&
                <EditQuestionPopup  quesProcessingData={quesProcessingData} updateQuesHandler={updateQuesHandler} quesProcessingDataHandler={quesProcessingDataHandler} ckeditorHandler={ckeditorHandler} editQuesClosePopupHandler={editQuesClosePopupHandler}/>
            }

            {editPassagePopupState &&
                <EditPassagePopupPage isLoaderActive={isLoaderActive} quesProcessingData={quesProcessingData} updatePassageHandler={updatePassageHandler} ckeditorHandler={ckeditorHandler} editPassageClosePopupHandler={editPassageClosePopupHandler}/>
            }
            

            <Modal 
                className='modelMsg'
                show={quesSegrigationModalShow.status}
                onHide={ ()=>{ editQuesSegregationModalCloseHandler(false) } }
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Modify Segregation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { isPopupLoaderActive && <div className={managQueCss.loaderClass}>
                        <div className="spinner-border text-primary" role="status"></div>
                    </div> }

                    <div className='mb-3'>
                        <label htmlFor=''></label>
                        <select 
                            required 
                            className={`form-control form-control-lg`}
                            name='questionBankType'
                            id='questionBankType'
                            value={quesSegrigationModalShow.qbTypeID}
                            onChange={ qbTypeChangeHandler }
                        >
                            <option className={`d-none`}>Select Question Bank Type</option>
                            {
                                questionBankTypeList.map( qbType => (
                                    <option
                                        value={qbType.questionTypeID}
                                        key={'qbt'+qbType.questionTypeID}
                                    >
                                        {qbType.questionTypeDesc}
                                    </option>
                                ) )
                            }
                        </select>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="secondary" 
                        onClick={ ()=>{ editQuesSegregationModalCloseHandler(false) } }
                    >Close</Button>
                    <Button 
                        variant="primary"
                        onClick={updateQuestionTypeIDHandler}
                    >Save changes</Button>
                </Modal.Footer>
            </Modal>




            <Modal 
                className='modelMsg'
                show={modalShow.status}
                onHide={modalCloseHandler}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body className='p-0'>
                    <Alert className='mb-0' variant={modalShow.messageClass} onClick={modalCloseHandler} dismissible>
                        <p className='p-0 m-0'>{modalShow.message}</p>
                    </Alert>
                </Modal.Body>
            </Modal>
            

            {isLoaderActive && <Loader/>}
            
        </div>
    )
}

export default ManageQSchool;

import React, {useEffect, useState, useContext} from 'react';
import StuDashBoardCss from './stuDashboard.module.css';
import { Row, Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import {apiBaseURL,apiURL,apiKey, RouteURL, wirisMathML} from './../../constant';
import PracticeSetIcon from '../../assets/practice.png';

import { useParams } from 'react-router-dom'
import userContext from '../../Store';
import Loader from '../../Loader';
import Swal from 'sweetalert2';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { ExitToApp } from '@mui/icons-material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

function AttemptedMcq() {
  const {assessmentID} = useParams()
  const {assessmentName} = useParams()
  const {schoolBankType} = useParams()
  const {userData} = useContext(userContext);
  
  const [questions, setQuestions] = useState([]);
  const [currentQues, setCurrent] = useState({
    quesInd:0,
    questionData:{},
  })

  const[QueData, setQuesData] = useState([])
  const[selectQuestion, setSelectQuestion] = useState({correctAnsMarks:0, wrongAnsMarks:0,attemptOptionID:''})
  const[checkQues, setCheckQues]= useState(0)
  const[getfinalReportData, setFinalReportData] = useState([])
  const[rightAns, setRightAns] =useState()
  const[wrongAns,setWrongAns] =useState()
  const[showLoader, setLoader]=useState(false)
  const[indexData, setIndexData] = useState(0)
  const[currentQuesAttemptedOptionID,setCurrentQuesAttemptedOptionID] = useState(0);
  const[showAttemptError, setShowAttemptError] = useState(false)
  const[showErrPopup, setErrPopup] =useState({status:false, msg:null});
  const[QuestionTypeID, setQuestionTypeID] = useState()
  const[totalTime, setTotalTime] = useState()
  const[currentTime, setCurrentTime] = useState()
  const[getSetID, setIdData] = useState()
  const [setNameData, setSetNameData] = useState()
  const [activeBtn, setActiveBtn] = useState(0)
  const [seqQuesData, setSeqQuesData] = useState([])

  const [attemptedQues,setAttemptedQues] = useState([])
  const [timeStatus,setTimeStatus] = useState(false)
  const [finalSubmitStatus, setFinalSubmitStatus] = useState(false)
  const [assNameData, setAssNameData] = useState()
  const [imagePath, setImagePath] = useState()
  useEffect(()=>{
   
    setLoader(true);
    // alert(assessmentID)
    // console.log( "assessmentID : "+assessmentID );

    // setQuestions(questionsData);
    // setCurrent({
    //   quesInd:0,
    //   questionData:questionsData[0]
    // })
      //  add to get all question by assessment id
      const xPostData ={
        "schoolCode":userData.schoolCode,
        "userRefID" : userData.userRefID,
        "assessmentID": assessmentID,
        "schoolBankType" : schoolBankType,
        "isDemoUser":userData.isDemoUser
      }
      fetch(apiBaseURL+apiURL.startAssessmentAttempt,{
        method: "POST",
        headers: {'Key' : apiKey},
        body:JSON.stringify(xPostData)
      })
      .then(responce => responce.json())
      .then((quesData)=>{
        if(quesData.status=="success"){
          // setAllQuestion(quesData.data)
          setQuestions(quesData.data.questionsData);
          setSeqQuesData(quesData.data.questionSequence)
          setTotalTime(quesData.data.assessmentDetails.expiryDateTime)
          setCurrentTime(quesData.data.assessmentDetails.currentDateTime)
          setCurrent({
            quesInd:0,
            questionData:quesData.data.questionsData[0]
          })
          setQuestionTypeID(quesData.data.questionsData[0].questionTypeID)
          setIdData(quesData.data.questionsData[0].setID)
          let setdata = quesData.data.assessmentDetails.setName[0]!=undefined ? quesData.data.assessmentDetails.setName[0].setDesc : ""
          let assName = quesData.data.assessmentDetails.questionBankName[0]!=undefined ? quesData.data.assessmentDetails.questionBankName[0].questionTypeDesc : "";
          let ImagePath = quesData.data.assessmentDetails.questionBankImageIcon!=undefined ? quesData.data.assessmentDetails.questionBankImageIcon : "";
          setSetNameData(setdata)
          setAssNameData(assName)
          setImagePath(ImagePath)
          // console.log(quesData.data.assessmentDetails.setName[0].setDesc)
          // console.log("ajay bhati")
        }else{
          // setAllQuestion([]);
          setShowAttemptError(true)
          setErrPopup((prev)=>{
            return{...prev, status: true, msg:"Subject Not Assign to This Student !"}
          })
          setQuestions([]);
        }
      })
      .catch((catch_err)=>{
        alert(catch_err)
      }).finally( (x)=>{
        setLoader(false);
      } )
     
  }, [])
  
  
  useEffect(() => {
    const LoadExternalScript = () => {
      const externalScript = document.createElement("script");
      externalScript.onerror = loadError;
      externalScript.id = "external";
      externalScript.async = true;
      externalScript.type = "text/javascript";
      externalScript.setAttribute("crossorigin", "anonymous");
      document.body.appendChild(externalScript);
      externalScript.src = wirisMathML;
    };
    var elm = document.getElementById('external');
    if(elm!=null){
        elm.remove();
    }
    LoadExternalScript();
  }, []);

  useEffect(()=>{
    timerStart();
  },[QuestionTypeID])


  function loadError(onError) {
    console.error(`Failed ${onError.target.src} didn't load correctly`);
  }


  const currentQuesAttemptedOptionIDHandler = (questionID) => {
    let hasAttempted = false;
    for(let quest of getfinalReportData){
        if( quest.questionID==questionID ){
            hasAttempted = true;
            setCurrentQuesAttemptedOptionID( quest.attemptAnswerID );
        }
    }

    if( !hasAttempted ){
        setCurrentQuesAttemptedOptionID(0);
    }

    // console.log(currentQuesAttemptedOptionID);
  }
  // console.log(questions)
  // console.log()


  function SelectQuestionData(quesData , ChouseOpt){
    // currentQuesAttemptedOptionIDHandler(quesData.questionID);


    if( attemptedQues.indexOf(indexData)==-1 ){
      setAttemptedQues(attemptedQues => [...attemptedQues,indexData] );
    }
    // console.log(attemptedQues);
  //   let id = indexData ==0 ? "btn1" : "btn"+indexData;
  //  document.getElementById(id).style.backgroundColor= "#9966ff"
    // attempt screen
    let isQuesExist = false;
    let rightMarks = 0;
    let wrongMarks = 0;
    
    for(let que of QueData){
      if(que.questionID==quesData.questionID){
        isQuesExist = true;
        if(que.correctAnswerIDs==ChouseOpt){
          setCheckQues(checkQues-1)
          rightMarks = rightMarks + que.marks;
          wrongMarks = 0;
          
        } else {
          if(checkQues>=1){
            rightMarks = rightMarks;
            // wrongMarks = wrongMarks;
            wrongMarks = wrongMarks+ que.negativeMark
          }else{
            setCheckQues(checkQues+1)
            rightMarks = 0;
            wrongMarks = wrongMarks+ que.negativeMark;
          }
              
        }
      }	

    }

    
    if(!isQuesExist){
      setCheckQues(0)
      // alert("add new question")
      setQuesData(QueData => [...QueData,quesData] );
      if(quesData.correctAnswerIDs==ChouseOpt){
        rightMarks = rightMarks+quesData.marks
        wrongMarks=0;
      
      } else {
        setCheckQues(checkQues+1)
        rightMarks=0
        wrongMarks= wrongMarks+quesData.negativeMark
      }
      
    }

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;

    let data = {
      "1": quesData.optionText1,
      "2": quesData.optionText2,
      "3": quesData.optionText3,
      "4": quesData.optionText4
    }
    let quesArray = {
      "schoolCode":userData.schoolCode,
      "userRefID" : userData.userRefID,
      "assessmentID": assessmentID,
      "questionID": quesData.questionID,
      "attemptAnswerID": ChouseOpt,
      "correctMarks" : rightMarks,
      "negativeMarks" : wrongMarks,
      "attemptDate" : today,
      "correctAnswerID": quesData.correctAnswerIDs,
      "subjectID" : quesData.subjectID ? quesData.subjectID : null,
      "classID" : quesData.classID,
      "sectionID" : quesData.sectionID,
      "questionTypeID": quesData.questionTypeID ? quesData.questionTypeID : "",
      "topicID" : quesData.topicID ? quesData.topicID : "",
      "subTopicID": quesData. subTopicID ? quesData. subTopicID : "",
      "schoolBankType":schoolBankType,
      "setID": getSetID,
      "attemptStatus":1
      
      // "selectedOptionText" : data[ChouseOpt],
      // "correctedAnswer" : quesData.correctAnswerText
    }
    
    let isArray = false;
    let i=0;
    for(let quest of getfinalReportData){

      if(quest.questionID==quesData.questionID){
        isArray=true;
        let data = getfinalReportData;
        data[i] = quesArray;
        setFinalReportData(getfinalReportData => data );
      }
      i++
    }
    if(!isArray){
      setFinalReportData(getfinalReportData => [...getfinalReportData,quesArray] );
    }
    // setFinalReportData(getfinalReportData => [...getfinalReportData,quesArray] );
    
  }

  
  // next prev functions start
  function prevQues(){
    let index = currentQues.quesInd-1;
    if(index!=-1){
        currentQuesAttemptedOptionIDHandler( questions[index].questionID );
        setCurrent({
            quesInd:index,
            questionData:questions[index]
        })
        setIndexData(index);
        document.getElementById('next').style.display='block';
        document.getElementById('previous').style.display='block';
    //   console.log(questions[index]);
    }else{
      // document.getElementById('previous').style.display='none';
    }

    if( currentQues.quesInd==1 ){
      document.getElementById('previous').style.display='none';
    }
    
  }
  function goQues(index){
    // let id = (index+1)
    // console.log(getfinalReportData);
  
    currentQuesAttemptedOptionIDHandler( questions[index].questionID );
    setCurrent({
      quesInd:index,
      questionData:questions[index]
    })
    document.getElementById('next').style.display='block';
    document.getElementById('previous').style.display='block';

    if( index==questions.length-1 ){
      document.getElementById('next').style.display='none';
    }

    if( index<1 ){
      document.getElementById('previous').style.display='none';
    }

    setIndexData(index);

    // console.log(index);

  } 
  function nextQues(){
    if(currentQues.quesInd+1<questions.length)
    {
      let index = currentQues.quesInd+1;      
      currentQuesAttemptedOptionIDHandler( questions[index].questionID );
        setCurrent({
          quesInd:index,
          questionData:questions[index]
        })
        setIndexData(index);
        // let cQuesIndex = "btn"+(currentQues.quesInd)
        // alert(cQuesIndex)
        document.getElementById('next').style.display='block';
        document.getElementById('previous').style.display='block';
        // document.getElementById('previous').style.background='#000';
    }else{
      // document.getElementById('next').style.display='none';
    }

    // console.log( (currentQues.quesInd+1)+"  ==  "+questions.length );

    if( currentQues.quesInd+2 >= questions.length ){
      document.getElementById('next').style.display='none';
    }
    
  }
  function padLeadingZeros(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }
  // var timerStatus='';
  function startTimer(){
    var countDownDate = new Date(totalTime).getTime();
    // Update the count down every 1 second
    
      var x = setInterval(function() {
      var now = new Date().getTime();

      var distance = countDownDate - now;
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      var h1 = padLeadingZeros(hours,2);
      var m1 = padLeadingZeros(minutes,2);
      var s1 = padLeadingZeros(seconds,2);
      
      document.getElementById("timer").innerHTML = h1 + ":"+ m1 +":"+ s1;
      if (distance < 0) {
        clearInterval(x);
        autoSubmitStatus();
        
        document.getElementById("timer").innerHTML = "EXPIRED";
        
      }
    }, 1000);
    
  }
  function timerStart(){
    if(QuestionTypeID==3 || QuestionTypeID==5){
      startTimer();  
      // timerStatus = setInterval(startTimer,1000) 
    }
  }


  function finalReportData(){
    var unattemptData = [];
    var attemptedQuestionIDs = [];
    let attemptedDate = "";
    for(let selectedQues of getfinalReportData){
      attemptedDate = selectedQues.attemptDate;
      if( attemptedQuestionIDs.indexOf(selectedQues.questionID) == -1 ){
        attemptedQuestionIDs.push(selectedQues.questionID);
      }
    }

    for(let unAttempt of questions){
      if( attemptedQuestionIDs.indexOf(unAttempt.questionID) == -1 ){
        let UnAttemptedQuesArray = {
          "schoolCode":userData.schoolCode,
          "userRefID" : userData.userRefID,
          "assessmentID": assessmentID,
          "questionID": unAttempt.questionID,
          "attemptAnswerID": 0,
          "correctMarks" : 0,
          "negativeMarks" : 0,
          "attemptDate" : attemptedDate,
          "correctAnswerID": unAttempt.correctAnswerIDs,
          "subjectID" : unAttempt.subjectID ? unAttempt.subjectID : null,
          "classID" : unAttempt.classID,
          "sectionID" : unAttempt.sectionID,
          "questionTypeID": unAttempt.questionTypeID,
          "topicID" : unAttempt.topicID,
          "subTopicID": unAttempt.subTopicID,
          "schoolBankType":schoolBankType,
          "setID": getSetID,
          "attemptStatus":0
        }

        unattemptData.push(UnAttemptedQuesArray)
      }
    }
    let finalQuesdata = getfinalReportData.concat(unattemptData);
    console.log(finalQuesdata)
    console.log("final data")
    let count = 40;
    let isSubmit = false;
    if(attemptedQuestionIDs[0]==undefined){
      Swal.fire(
        'Oops !', 'The session is unattempted. Report will not be generated.'
      );
      return
      
    }
    if(count!=40){
      Swal.fire(
        'Failed', 'You have Select Atleast 40 Question Try Again.', 'success'
      ); 
    }else{
      Swal.fire({
        // title: 'Are you sure ?',
        html: 'Do you really want to proceed ? <br>',
        // icon: 'question',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#198754',
        focusCancel: true,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          
          const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify({"questionData":finalQuesdata,"questionSequence":seqQuesData})
          };
          setLoader(true);
          return fetch( apiBaseURL+apiURL.attemptSubmit, conf )
              .then( response => response.json() )
              .then( ( result ) => {
                if( result.status=='success' ) {
                  setLoader(false);
                  isSubmit= true;
                }
                return result.message;
              } )
              .catch( (error) => { 
                Swal.showValidationMessage("failed");
              } )
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then( (r) => {
        // console.log(assessmentID);
        if( r.isConfirmed ){
         if(isSubmit){
          // Swal.fire(
          //   'Success', 'Successfully Submitted Your Exam.', 'success'
          // ); 
          setFinalSubmitStatus(true)
          setLoader(false)
          let pathname ="/student";
          // window.open(pathname, "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=2000px,height=1124");
          
         }else{
          Swal.fire(
            'Failed', 'Exam Not  Submitted Successfully !.', 'warning'
          ); 
          setLoader(false)
         }
            
        }
  
      } );
    }
    
  }

    // console.log("ajay bhati")
    // console.log(getfinalReportData)
    // console.log("ajay bhati")
  function autoSubmitStatus(){
    setTimeStatus(true)
  }
  function autoSubmitFinalReportSubmit(){ 

    var unattemptData = [];
    var attemptedQuestionIDs = [];
    let attemptedDate = "";
    for(let selectedQues of getfinalReportData){
      attemptedDate = selectedQues.attemptDate;
      if( attemptedQuestionIDs.indexOf(selectedQues.questionID) == -1 ){
        attemptedQuestionIDs.push(selectedQues.questionID);
      }
    }

    for(let unAttempt of questions){
      if( attemptedQuestionIDs.indexOf(unAttempt.questionID) == -1 ){
        let UnAttemptedQuesArray = {
          "schoolCode":userData.schoolCode,
          "userRefID" : userData.userRefID,
          "assessmentID": assessmentID,
          "questionID": unAttempt.questionID,
          "attemptAnswerID": 0,
          "correctMarks" : 0,
          "negativeMarks" : 0,
          "attemptDate" : attemptedDate,
          "correctAnswerID": unAttempt.correctAnswerIDs,
          "subjectID" : unAttempt.subjectID ? unAttempt.subjectID : null,
          "classID" : unAttempt.classID,
          "sectionID" : unAttempt.sectionID,
          "questionTypeID": unAttempt.questionTypeID,
          "topicID" : unAttempt.topicID,
          "subTopicID": unAttempt.subTopicID,
          "schoolBankType":schoolBankType,
          "setID": getSetID
        }

        unattemptData.push(UnAttemptedQuesArray)
      }
    }
    let finalQuesdata = getfinalReportData.concat(unattemptData);
      var isSubmit = false;
      const conf = {
        method:'post',
        headers:{'key':apiKey},
        body:JSON.stringify({"questionData":finalQuesdata,"questionSequence":seqQuesData})
      };
      setLoader(true);
      fetch( apiBaseURL+apiURL.attemptSubmit, conf )
        .then( response => response.json() )
        .then( ( result ) => {
          if( result.status=='success' ) {
            window.close();
            isSubmit= true;
          }
          return result.message;
        } )
        .catch( (error) => {          
          Swal.fire({
            title: 'Oops..',
            html: 'Something went wrong. Please try again.',
            icon: 'warning',
            confirmButtonText: 'Yes',
            confirmButtonColor: '#d33',
            focusCancel: false,
            showCancelButton: false,
            showLoaderOnConfirm: true,
          }).then( (r) => {
            if( r.isConfirmed ){
              autoSubmitFinalReportSubmit()
            }
          })

      })
      .finally(()=>{
        setLoader(false)
      })
    
  }
  
 // next prev functions end


  let pasageData = (currentQues.questionData.passageDetail!=null || currentQues.questionData.passageDetail!=undefined)  ? currentQues.questionData.passageDetail.paragraphText : ""
  let assName = assessmentName.split("_")
  let topicID = currentQues.questionData.topicID;
  let title ="";
//   if(topicID!=undefined){
//     if(topicID==10){
//       title="Section- A";
//     }else if(topicID==11){
//       title="Section- B1";
//     }else if(topicID==12){
//       title="Section- B2";
//     }else if(topicID==8){
//       title="Literary Aptitude";
//     }else if(topicID==1){
//       title="Reading Comprehension";
//     }else if(topicID==2){
//       title="Vocabulary";
//     }else{
//       title=""
//     }
//   }

  if(topicID!=undefined){
    if(topicID==10){
      title="Section- A";
    }else if(topicID==11){
      title="Section- B1";
    }else if(topicID==12){
      title="Section- B2";
    }else if(topicID==8){
        title="Literary Aptitude";
    }else if(topicID==2){
        title="Vocabulary";
    }else{
      title=""
    }
  }


//  ajay
let quesLegthData = questions.length!==0 ? questions.length : false;
let passageTitle = '';
let  title1 ='';
let  title2='';
for(let x=0; x<=quesLegthData; x++ ){
    if(topicID!=undefined){
      if(topicID==1 && (x>=0 && x<=10) && (currentQues.quesInd+1>=0 && currentQues.quesInd+1<=10)){
        title="Reading Comprehension";
        title1 = "Passage-1";
        title2 = "Question: 1-10"
      }else if(topicID==1 && (x>10 && x<=20) && (currentQues.quesInd+1>10 && currentQues.quesInd+1<=20)){
        title="Reading Comprehension";
        title1 = "Passage-2";
        title2 = "Question: 11-20"
      }else if(topicID==1 && (x>20 && x<=30) && (currentQues.quesInd+1>20 && currentQues.quesInd+1<=30)){
        title="Reading Comprehension ";
        title1 = "Passage-3";
        title2 = "Question: 21-30"
      }else if(topicID==1 && (x>30 && x<=40) && (currentQues.quesInd+1>30 && currentQues.quesInd+1<=40)){
        title="Reading Comprehension";
        title1 = "Passage-4";
        title2 = "Question: 31-40"
      }else if(topicID==1 && (x>40 && x<=50) && (currentQues.quesInd+1>40 && currentQues.quesInd+1<=50)){
        title="Reading Comprehension" 
        title1 = "Passage-5"; 
        title2 ="Question: 41-50";
      }else if(topicID==1 && (x>50 && x<=60) && (currentQues.quesInd+1>50 && currentQues.quesInd+1<=60)){
        title="Reading Comprehension ";
        title1 = "Passage-6"; 
        title2 =" Question: 51-60";
      }else if(topicID==1 && (x>60 && x<=70) && (currentQues.quesInd+1>60 && currentQues.quesInd+1<=70)){
        title="Reading Comprehension ";
        title1 = "Passage-7"; 
        title2 =" Question: 61-70";
      }else if(topicID==1 && (x>70 && x<=80) && (currentQues.quesInd+1>70 && currentQues.quesInd+1<=80)){
        title="Reading Comprehension";
        title1 = "Passage-8"; 
        title2 =" Question: 71-80";
      }
    }
}
// ajay
  // let data =50;

  // function startTimer(){
  //   data--
  //   if(data>=0){
  //     document.getElementById('timer').innerHTML =data
  //   }else{
  //     alert("time out")
  //   }
  // }
  
  
  
  
  return (
    
   <>
    {/* {showAttemptError && 
      <>
        <div class="row">
          <div class="col-md-4">
            <div style={{background:"#efefef", textAlign:"center",color:"#000", padding:"10px", borderRadius:8, margin:'30% 10px'}}>
              <h5>Subject not assign to this student</h5>
            </div>
          </div>
        </div>
      </>
    } */}

    {showErrPopup.status && 
      <div className={`${StuDashBoardCss.popupBack}`}>
        <div className={`${StuDashBoardCss.errBox}`}>
          <CloseIcon className={`${StuDashBoardCss.crossPopup}`} onClick={()=>{setErrPopup(false); window.close()}}/>
          <Alert severity="error">
            {showErrPopup.msg}
          </Alert>
        </div>       
      </div>
		}

    {!showAttemptError && 
       <div className={`${StuDashBoardCss.MainMcqBk}`}>
          <div className={`${StuDashBoardCss.mainWrappr}`}>
            <div className={`${StuDashBoardCss.HeaderPracticeAss}`}>
           
                  <div className={`${StuDashBoardCss.logoPracticeIcon}`}>
                    <img src={imagePath}/>
                  </div>
                  <div className={`${StuDashBoardCss.PracticeText}`}>
                    {/* {assName[0]} */}
                    {assNameData}{setNameData!="" ? " "+ "("+" "+setNameData+")" : ""}
                  </div>
                {(QuestionTypeID==3 || QuestionTypeID==5) &&
                  <div className={`${StuDashBoardCss.timerDiv}`}>
                    <div><span id="timer"></span></div>
                  </div> 
                }

            </div>
            {title!="" ?  <div className={`${StuDashBoardCss.titleShow}`}>{title}<br/>{title1}{title2!='' ? ","+title2 : ""}</div> : ""}
         
        <div className={`${StuDashBoardCss.rowGrid}`}>
              {/* <div >
                <p dangerouslySetInnerHTML={{__html:pasageData}}></p>
              </div> */}

              {/* <div className={`${StuDashBoardCss.colFixed}`}>
                <div className={`${StuDashBoardCss.QuestionNo}`}>{title} </div>
              </div> */}
              <div className={`${StuDashBoardCss.colFixed}`}>
                <div className={`${StuDashBoardCss.QuestionNo}`}>Question - {currentQues.quesInd+1} </div>
              </div>

               <div className={`${StuDashBoardCss.questionDiv}`}>
                  {currentQues.questionData.passageDetail!=null ? 
                    <div className={`${StuDashBoardCss.questionDiv}`}>
                     <p dangerouslySetInnerHTML={{__html:pasageData}}></p>
                    </div>
                    :
                    "" 
                  }
               

                <b dangerouslySetInnerHTML={{__html:currentQues.questionData.questionText}}></b>
                <div className={`${StuDashBoardCss.SpaceVar}`}></div>
                    {/* {currentQues.questionData.quesPart.map((item)=> <div>{item}</div>
                  )
                } */}
                <div className={`${StuDashBoardCss.SpaceVar}`}></div>
                <div className={`${StuDashBoardCss.SpaceVar}`}></div>

                 <Row>
                   
                      <Col className='col-md-6 col-sm-12 col-12'>
                        <label>
                          <div className={`${StuDashBoardCss.optionsRow}`}>
                            <div className={`${StuDashBoardCss.divOne}`}>
                              <input 
                                value={currentQues.questionData.optionID1} 
                                type="radio" 
                                name="options" 
                                onChange={(e)=>{SelectQuestionData(currentQues.questionData, e.target.value); setCurrentQuesAttemptedOptionID(1); }} 
                                checked={currentQuesAttemptedOptionID==1 ? true : false}
                            /> A.
                            </div>
                            <div>
                              <b dangerouslySetInnerHTML={{__html:currentQues.questionData.optionText1}}></b>
                            </div>

                           </div> 
                        </label>
                    </Col>
                    <Col className='col-md-6 col-sm-12 col-12'>
                        <label>
                          <div className={`${StuDashBoardCss.optionsRow}`}>
                            <div className={`${StuDashBoardCss.divOne}`}>
                              <input 
                                value={currentQues.questionData.optionID2} 
                                type="radio" 
                                name="options" 
                                onChange={(e)=>{SelectQuestionData(currentQues.questionData, e.target.value); setCurrentQuesAttemptedOptionID(2);}} 
                                checked={currentQuesAttemptedOptionID==2 ? true : false}
                            /> B.
                            </div>
                            <div>
                              <b dangerouslySetInnerHTML={{__html:currentQues.questionData.optionText2}}></b>
                            </div>

                           </div> 
                        </label>
                    </Col>
                    <Col className='col-md-6 col-sm-12 col-12'>
                        <label>
                          <div className={`${StuDashBoardCss.optionsRow}`}>
                            <div className={`${StuDashBoardCss.divOne}`}>
                              <input 
                                value={currentQues.questionData.optionID3} 
                                type="radio" 
                                name="options"  
                                onChange={(e)=>{SelectQuestionData(currentQues.questionData, e.target.value); setCurrentQuesAttemptedOptionID(3);}}
                                checked={currentQuesAttemptedOptionID==3 ? true : false}
                            /> C.
                            </div>
                            <div>
                              <b dangerouslySetInnerHTML={{__html:currentQues.questionData.optionText3}}></b>
                            </div>

                           </div> 
                        </label>
                    </Col>
                    <Col className='col-md-6 col-sm-12 col-12'>
                        <label>
                          <div className={`${StuDashBoardCss.optionsRow}`}>
                            <div className={`${StuDashBoardCss.divOne}`}>
                              <input 
                                value={currentQues.questionData.optionID4} type="radio" name="options"
                                onChange={(e)=>{SelectQuestionData(currentQues.questionData, e.target.value); setCurrentQuesAttemptedOptionID(4);}} 
                                checked={currentQuesAttemptedOptionID==4 ? true : false}
                            /> D.
                            </div>
                            <div>
                              <b dangerouslySetInnerHTML={{__html:currentQues.questionData.optionText4}}></b>
                            </div>

                           </div> 
                        </label>
                    </Col>
                </Row>

                {/* <Row>
                  {currentQues.questionData.Options.map((item, index)=>{
                    return(
                      <Col className='col-md-6 col-sm-12 col-12' key={index}>
                        <label>
                          <div className={`${StuDashBoardCss.optionsRow}`}>
                            <div className={`${StuDashBoardCss.divOne}`}>
                              <input type="radio" name="options" /> A.
                            </div>
                            <div>
                                {item}
                            </div>

                           </div> 
                        </label>
                    </Col>
                    )
                  })}
                  
                 
                </Row> */}
                </div>


               
             
               
        </div>

        <div className={`${StuDashBoardCss.QuestionNavigation}`}>
          <div className={`${StuDashBoardCss.innerContainer}`}>
            <div className={`${StuDashBoardCss.navigationInner}`}>
                <div id='previous' style={{display:"none"}} className={`${StuDashBoardCss.ButtonNext_pre}`} onClick={ (e)=>{ prevQues(e); setActiveBtn(currentQues.quesInd-1); } }>
                  Prev
                </div>

                <div className={`${StuDashBoardCss.QuestionDiv}`}>
                  {questions.map((item, index)=>{
                    
                    // console.log(index);
                    // console.log(attemptedQues);
                    // console.log(attemptedQues.indexOf(index) )
                    // console.log("==============");

                    var styleX = attemptedQues.indexOf(index)!=-1 ? "#9966ff" : ( activeBtn==index ?  "#bfbfbf" : "" );
                    return(
                      <div className={`${StuDashBoardCss.qNoCricle}`} >
                        <div 
                          className={`${StuDashBoardCss.innerCricle}`} 
                          id={"btn"+(index+1)} 

                          style={{background:styleX}}
                          
                          onClick={ ()=>{ goQues(index);setActiveBtn(index)} }
                        >
                          {index+1}
                        </div>
                      </div>
                    )
                  })}
                       
                </div>

                <div id='next' className={`${StuDashBoardCss.ButtonNext}`} onClick={ (e)=>{ nextQues(e); setActiveBtn(currentQues.quesInd+1); } }>
                  Next
                </div>
            </div>
        </div>
        <button className={`${StuDashBoardCss.PracticeSubmit}`} onClick={()=>{finalReportData()}}>Submit</button>
        </div>
        </div>

      </div>
    }
      {/* Loader start */}
			{showLoader &&
				<Loader/>
			}

      {timeStatus && 
        <div className={`${StuDashBoardCss.basePopConfirm}`}>
          <div className={`${StuDashBoardCss.innerWhiteBox}`} data-aos="zoom-in">
            <QuestionMarkIcon className={`${StuDashBoardCss.iconAreYouSure}`} data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="900"/>
            <p className={`${StuDashBoardCss.messageTxt}`}>Time Out <br/>Your Exam Has been Submitted </p>
            <button onClick={()=>{autoSubmitFinalReportSubmit(); setTimeStatus(false)}} className='btn btn-success'>OK</button> {' '}
          </div>
        </div>
	    }

        {finalSubmitStatus && 
            <div className={`${StuDashBoardCss.basePopConfirm}`}>
            <div className={`${StuDashBoardCss.innerWhiteBox}`} data-aos="zoom-in">
                {/* <QuestionMarkIcon className={`${StuDashBoardCss.iconAreYouSure}`} data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="900"/> */}
                <p className={`${StuDashBoardCss.messageTxt}`}> Submitted Successfully </p>
                <button onClick={()=>{setFinalSubmitStatus(false); window.close();}} className='btn btn-success'>OK</button> {' '}
            </div>
            </div>
		  }
		{/* Loader end */}

   </>
  
  );
}

export default AttemptedMcq;

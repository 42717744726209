import {useContext} from 'react'
import StuDashBoardCss from './stuDashboard.module.css';
import Dropdown from 'react-bootstrap/Dropdown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import { Quiz } from '@mui/icons-material';
import swaLogo from '../../assets/logo.png';
import swaNewLogo from "../../assets/fb_logo.png";
import { Link } from  "react-router-dom";
import userContext from '../../Store';
import { Row, Col, Container } from 'react-bootstrap';

function Header() {
  const {userData, logout,schName,schLogo} = useContext(userContext);


  return (
    <div className={`${StuDashBoardCss.backDiv}`}>
       {/* Header Section */}
      <div className={`${(schLogo == "null" || schLogo == undefined) ? StuDashBoardCss.HeaderTop2 : StuDashBoardCss.HeaderTop}`}>

         <Container fluid>

            <Row>
            {(schName == "" || schName == undefined || schLogo == "null" || schLogo == undefined) ? 
               <Col style={{marginLeft:'-12px'}}>
                  <div className={`${StuDashBoardCss.logoCol}`}>
                     <div className={`${StuDashBoardCss.logoDiv}`}>
                        <img className={`${StuDashBoardCss.newSwaLogo}`} src={swaLogo} />
                     </div>
                  </div>
               </Col>
            :
               <> 
               
                  <Col  className='col-md-3 col-3 col-3'>
                     <div className={`${StuDashBoardCss.logoCol}`}>
                        <div className={`${StuDashBoardCss.logoNewDiv}`}>
                           <img className={`${StuDashBoardCss.logoimgs}`} src={swaNewLogo} />
                        </div>
                     </div>
                  </Col>
                  <Col className={`col-md-6 col-6 col-6 ${StuDashBoardCss.logoSchool}`}>

                     <div className={`${StuDashBoardCss.logoCol}`}>
                        <div className={`${StuDashBoardCss.schLogoDiv}`}>
                           <img className={`${StuDashBoardCss.logoimgs}`} src={schLogo} />
                           {/* <img className={`${StuDashBoardCss.logoimgs}`} src="https://cuet.swaadhyayan.com/connect/public/images/ojs.png" /> */}
                           {/* <img className={`${StuDashBoardCss.logoimgs}`} src="https://cuet.swaadhyayan.com/connect/public/images/shardasislogo.png" /> */}
                           
                           {/* <h6 style={{ fontSize: '14px', top: '0px', textAlign:'center' }}>{schName}</h6> */}
                        </div>
                     </div>
                  </Col>
               </>    
            }
              
               <Col>
                  <div className={`${StuDashBoardCss.UserCol}`}>
                  
                     <div className={`${StuDashBoardCss.rightcolm}`}>
                        
                        <Dropdown>
                           <strong>Welcome</strong> {userData.firstName}
                           <Dropdown.Toggle className={`${StuDashBoardCss.candidateButton}`}>
                              <AccountCircleIcon className={`${StuDashBoardCss.user}`} />
                           </Dropdown.Toggle>
                           <Dropdown.Menu variant="light">
                              <Dropdown.Item href=""><PersonIcon /><Link to="/student/profile"> My Profile </Link></Dropdown.Item>
                              <Dropdown.Item onClick={logout}><ExitToAppTwoToneIcon />Logout</Dropdown.Item>
                           </Dropdown.Menu>
                        </Dropdown>
                     </div>
                  </div>
               </Col>
            </Row>
         </Container>
          <div className={`${StuDashBoardCss.clear}`}></div>
      </div>
    
     {/* Right Area */}
    </div>
  );
}

export default Header;

import React, { useReducer, useState, useEffect} from "react";
import SuperAdminCss from "./SuperAdmin.module.css";
import Header from "./Header";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import selfAssessment from "../../assets/self_asmt.png";
import FinalExam from "../../assets/final_exam.png";
import PracticeSet from "../../assets/practice_set.png";
import Table from "react-bootstrap/Table";
import PreviewIcon from "@mui/icons-material/Preview";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DangerousIcon from '@mui/icons-material/Dangerous';
import { apiBaseURL, apiURL, apiKey,wirisMathML } from "./../../constant";
import Loader from "../../Loader";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Button } from "@mui/material";
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

const reducer = (state, action)=>{
    switch (action.type) {
        // Practice Sets case start
        case "practiceSets":
            return {
                showPracticesSets_Section: (state.showPracticesSets_Section = true)
            };
        case "section_a_ass_list":
            return{
                showSection_AList: (state.showSection_AList = true),
                showPracticesSets_Section: (state.showPracticesSets_Section = true)
            };
        case "section_b_ass_list":
            return{
                showSection_BList: (state.showSection_BList = true),
                showPracticesSets_Section: (state.showPracticesSets_Section = true)
            };
        case "PsetSection_a_viewdetales":
            return{
                PracticeSet_section_a_viewDetailes: (state.PracticeSet_section_a_viewDetailes = true),
                showSection_AList: (state.showSection_AList = true),
                showPracticesSets_Section: (state.showPracticesSets_Section = true)
            };
        case "hideViewDe_sec_a_pop":
            return{
                PracticeSet_section_a_viewDetailes: (state.PracticeSet_section_a_viewDetailes = false),
                showSection_AList: (state.showSection_AList = true),
                showPracticesSets_Section: (state.showPracticesSets_Section = true)
            };
        case "review_answers_sec_a":
            return{
                showReview_ans_sec_a: (state.showReview_ans_sec_a = true),
                PracticeSet_section_a_viewDetailes: (state.PracticeSet_section_a_viewDetailes = true),
                showPracticesSets_Section: (state.showPracticesSets_Section = true)
            };
        case "hideReviewAns_sec_a":
            return{
                showReview_ans_sec_a: (state.showReview_ans_sec_a = false),
                PracticeSet_section_a_viewDetailes: (state.PracticeSet_section_a_viewDetailes = true),
                showPracticesSets_Section: (state.showPracticesSets_Section = true)
            };
        // Practice Sets case end
    }
}

const reportFilterConf = {
    schoolCode:'',
    examType:'',
    sectionID:'',
    subjectID:'',
    chapterID:'',
    studentID:''
}


function SuperAdminReport() {

    useEffect(()=>{
        getSchool();
        getSectionsList();
    },[])
    
    const [showSchoolDetailes, setSchoolDetailes] = useState ([]);
    const [studentsList, setStudentsList] = useState ([]);
    const [showLoader, setLoader]= useState(false);
    const [isLoaderActive,setIsLoaderActive] = useState(true);
    const [sectionsList, setSectionsList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [subjectChaptersList, setSubjectChaptersList] = useState([]);
    const [attemptedAssessmentList, setAttemptedAssessmentList] = useState([]);
    const [attemptedAssessmentListShow, setAttemptedAssessmentListShow] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState([])
    const [showReviewAnswer, setReviewAnswer] = useState(false);
    const [showReportDetail, setReportDetail] = useState(false)
    const [showViewDetailespA, setViewDetailespA] = useState(false);
    const[reportData, setReportData] = useState([])
    const [questionID, setQuestionID] = useState()
    const [getReportData, setReportDataSchoolWise] =useState()
    const [reportFilter, setReportFilter] = useState(reportFilterConf);
    const[showErrPopup, setErrPopup] =useState({status:false, msg:null});



    useEffect(() => {
      const LoadExternalScript = () => {
        const externalScript = document.createElement("script");
        externalScript.onerror = loadError;
        externalScript.id = "external";
        externalScript.async = true;
        externalScript.type = "text/javascript";
        externalScript.setAttribute("crossorigin", "anonymous");
        document.body.appendChild(externalScript);
        externalScript.src = wirisMathML;
      };
      var elm = document.getElementById('external');
      if(elm!=null){
          elm.remove();
      }
      LoadExternalScript();
  }, [showViewDetailespA,showReviewAnswer]);
  
  function loadError(onError) {
    console.error(`Failed ${onError.target.src} didn't load correctly`);
  }

    
    const reportFilterHanlder = (e) => {

        if( e.target.name!='undefined' || e.target.value!='undefined' ){
            setReportFilter( (prev)=>{
                return {...prev,[e.target.name]:e.target.value }
            } );
        }
        if(e.target.name=='subjectID'){
          setAttemptedAssessmentListShow(false)
        }

        if( e.target.name=='schoolCode' ){
            getStudent( e.target.value );
            // setReportFilter((prev)=>{
            //   return {...prev, sectionID:'', subjectID:'', examType:''}
            // })
            setAttemptedAssessmentListShow(false)
        }

        if( e.target.name=='sectionID' ){
          setAttemptedAssessmentListShow(false)
            if( e.target.value=='2' ){
                getSubjectsOfSectionHandler(2);
            }
            setReportFilter( (prev)=>{
                return {
                    ...prev,
                    subjectID:'',
                    chapterID:''
                }
            } );
        }

        if( e.target.name=='examType' ){
            setReportFilter( (prev)=>{
                return {
                    ...prev,
                    sectionID:''
                }
            } );
        }

        if( e.target.name=='subjectID' && reportFilter.examType=='2' ){
            getSubjectChapters(e.target.value);
        }

        console.log(reportFilter);
    }


    const getSectionsList = () => {
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getSectionList,{
            method:'GET',
            headers: { 'key':apiKey }
        } )
        .then( response => response.json() )
        .then( (result)=>{
            setIsLoaderActive(false);
            if(result.status=='success' ){
                setSectionsList(result.data.sections);
            }
        } ).catch( (err) => {
            setIsLoaderActive(false);
        } ).finally( ()=>{
            setIsLoaderActive(false)
        } );
    }

    const getSubjectsOfSectionHandler = (sectionID) =>{
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getSubjectsOfSection,{
            method:'POST',
            headers: { 'key':apiKey },
            body:JSON.stringify( {"section":sectionID} )
        } )
            .then( response => response.json() )
            .then( (result)=>{
                setIsLoaderActive(false);
                if(result.status=='success' ){
                    setSubjectList(result.data.subjects);
                }else{
                    setSubjectList([]);
                }
            } )
            .catch( (err) => {
                setIsLoaderActive(false);
                setSubjectList([]);
            } )
            .finally( ()=>{
                setIsLoaderActive(false);
            } );  
    }


    function getSubjectChapters(subjectID){
        const chpPostData = { "classID":12,"subjectID":subjectID };
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(chpPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getChapterList, conf )
            .then( response => response.json() )
            .then( (result) => {
                if( result.status=='success' ){
                    setSubjectChaptersList(result.data.chapters);
                }else{
                    setSubjectChaptersList([]);
                    // setModalShow( (prev)=>{
                    //     return {...prev, status:true,messageClass:'danger',message:result.message }
                    // } );
                }
            } )
            .catch( (err) => {
                setSubjectChaptersList([]);
                // setModalShow( (prev)=>{
                //     return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                // } );
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );
    }


    //  get School fuction start
    function getSchool(){
        const xPostData = {};
        fetch(apiBaseURL+apiURL.getSchoolList,{
            method: "POST",
            headers: {Key : apiKey},
            body:JSON.stringify(xPostData)
        })
        .then( response => response.json() )
        .then( (schoolData) => {
            if(schoolData.status == "success"){
                setSchoolDetailes(schoolData.data.schools)
            }else{
                alert(schoolData.message);
            }
        })
        .catch((err)=>{
            alert(err);
        })
    }
    //  get School fuction end

    // get Student detailes function start
    function getStudent(schoolCode){
      setLoader(true)
      const xPostData = {
        "activeOnly":true,
        "orderBy":"loginID",
        "schoolCode":schoolCode,
        "userType":3,
      };
      fetch(apiBaseURL+apiURL.getUsersList,{
        method: "POST",
        headers : {Key : apiKey},
        body:JSON.stringify(xPostData)
      })
      .then((res)=>{
        res.json()
        .then((studentData)=>{
          // console.log(studentData)
          if(studentData.status == "success"){
            setStudentsList(studentData.data.users)
            setLoader(false)
          }else{
              setStudentsList([])
              alert(studentData.message);
            }
        })
      })
      .catch((err)=>{
          setStudentsList([])
            alert(err);
      }).finally( ()=>{
        setLoader(false)
      } )
    }
    // get Student detailes function end


    const getStudentAssessmentReportHandler = () => {
        setLoader(true)
        const xPostData = {
            "schoolCode":reportFilter.schoolCode,
            "questionTypeID":reportFilter.examType,
            "sectionID":reportFilter.sectionID,
            "subjectID":reportFilter.subjectID,
            "chapterID":reportFilter.chapterID,
            "userRefID":reportFilter.studentID,
            "questionBankTypeID": getReportData!=undefined ? getReportData : 1
        };
        fetch(apiBaseURL+apiURL.getStudentAssessmentReport,{
            method: "POST",
            headers : {Key : apiKey},
            body:JSON.stringify(xPostData)
        })
        .then((res)=>{
            res.json()
            .then((result)=>{
            // console.log(result)
            if(result.status == "success"){
              if(result.data.attemptedAssessment[0]==undefined){
                setErrPopup((prev)=>{
                  return {...prev, status:true, msg:"No record found"}
                })
              }else{
                setAttemptedAssessmentList(result.data.attemptedAssessment)
                setAttemptedAssessmentListShow(true);
                setLoader(false)
              }
                
            }else{
                setAttemptedAssessmentList([])
                setAttemptedAssessmentListShow(false);
                setErrPopup((prev)=>{
                  return {...prev, status:true, msg:result.message}
                })
                }
            })
        })
        .catch((err)=>{
                setAttemptedAssessmentList([])
                alert(err);
                setAttemptedAssessmentListShow(false);
        }).finally( ()=>{
            setLoader(false)
        } )
    }


    function getReviewQues(questionID){
        setLoader(true)
        setQuestionID(questionID)
        const xPostData ={
          "questionID":questionID,
          "questionBankTypeID": getReportData!=undefined ? getReportData : 1
          }
          fetch(apiBaseURL+apiURL.viewAttemptQuestionExplanation,{
              method : 'POST',
              headers: {'Key':apiKey},
              body:JSON.stringify(xPostData)
          })
          .then(responce => responce.json() )
          .then((selectedQuesData)=>{
              if(selectedQuesData.status=="success"){
                  setSelectedQuestion(selectedQuesData.data)
                  setReviewAnswer(true);
                  setReportDetail(true)
              }else{
                  setSelectedQuestion([])
                  alert(selectedQuesData.message)
              }
          })
          .catch((catch_err)=>{
              setSelectedQuestion([])
              alert(catch_err)
          })
          .finally(()=>{
              setLoader(false)
          })
      
      }



    const showAttemptedReportCard = (assessmentID) => {
        setLoader(true)
        const xPostData = {
            "assessmentID":assessmentID,
            "userRefID":reportFilter.studentID
        };
        fetch(apiBaseURL+apiURL.viewStudentAssessmentReport,{
            method: "POST",
            headers : {Key : apiKey},
            body:JSON.stringify(xPostData)
        })
        .then((res)=>{
            res.json()
            .then((result)=>{
            if(result.status == "success"){
                setReportData(result.data.viewAssessmentDetail)
                setViewDetailespA(true);
                setLoader(false)
            }else{
                setReportData([])
                setViewDetailespA(false);
                alert(result.message);
                }
            })
        })
        .catch((err)=>{
                setReportData([])
                setViewDetailespA(false);
                alert(err);
        }).finally( ()=>{
            setLoader(false)
        } )
    }


  const [state, dispatch] = useReducer(reducer, {showPracticesSets_Section: false});

  return (
    <div className={`${SuperAdminCss.backDiv}`}>
      {/* Header Section */}
      <div className={`${SuperAdminCss.rightArea}`}>
        {/* Header */}
        <Header />
        {/* Header */}
        <div className={`${SuperAdminCss.buttonBgs}`}>
          <Container fluid>
              <Row>
                  <Col>
                      <div className={`${SuperAdminCss.Heading}`}>Report</div>
                  </Col>
                  <Col>
                      <div className={`${SuperAdminCss.backTohome}`}>
                          <Link to="/superAdmin"> <button className="btn btn-secondary"><HomeIcon/> Dashboard </button> </Link>
                      </div>
                  </Col>
              </Row>
              <hr/>
              <div className={`${SuperAdminCss.backSelect}`}>
                <Row>
                    <Col>
                        <label>School Name</label>
                        <select className="form-control" name='schoolCode' onChange={reportFilterHanlder}> 
                            <option className="d-none"> Select </option>
                             {showSchoolDetailes.map((item)=>{
                               return(
                                 <option value={item.schoolCode}>{item.schoolName}</option>
                               )
                             })}
                        </select>
                    </Col>
                </Row>
              </div>

            {reportFilter.schoolCode!='' && <Row>
                <Col className="col-md-4 col-sm-12 col-12">
                  <div 
                    // onClick={(()=>{dispatch({type: "practiceSets"})})} 
                    onClick={ (e)=>{ e.target.name='examType';e.target.value=2;reportFilterHanlder(e);setAttemptedAssessmentListShow(false) } }
                    className={`${SuperAdminCss.learnBtn}`}>
                    <div className={`${SuperAdminCss.logoCircle}`}>
                      <img src={PracticeSet} />
                    </div>
                    <div className={`${SuperAdminCss.headingDiv}`} style={{background:reportFilter.examType=='2'&& "#9966FF"}}>
                    Practice Sets
                    </div>
                  </div>
                </Col>

                <Col className="col-md-4 col-sm-12 col-12">
                  <div
                    onClick={ (e)=>{ e.target.name='examType';e.target.value='selfgenerated';reportFilterHanlder(e);setAttemptedAssessmentListShow(false); } } 
                    className={`${SuperAdminCss.learnBtn}`}>
                    <div className={`${SuperAdminCss.logoCircle}`}>
                      <img src={selfAssessment} />
                    </div>
                    <div className={`${SuperAdminCss.headingDiv}`} style={{background: ( reportFilter.examType=='3' || reportFilter.examType=='4' || reportFilter.examType=='selfgenerated' )&& "#9966FF"}}>
                    Self-Generated Practice Exams
                    </div>
                  </div>
                </Col>

                

                <Col className="col-md-4 col-sm-12 col-12">
                  <div
                    onClick={ (e)=>{ e.target.name='examType';e.target.value=5;reportFilterHanlder(e);setAttemptedAssessmentListShow(false); } } 
                    className={`${SuperAdminCss.learnBtn}`}>
                    <div className={`${SuperAdminCss.logoCircle}`}>
                      <img src={FinalExam} />
                    </div>
                    <div className={`${SuperAdminCss.headingDiv}`} style={{background:reportFilter.examType=='5'&& "#9966FF"}}>
                    Mock CUET
                    </div>
                  </div>
                </Col>
          </Row>}


            { ( reportFilter.examType=='selfgenerated' || reportFilter.examType==3 || reportFilter.examType==4 ) &&
                <div className={`${SuperAdminCss.partSectionRow}`}>
                    <div className={`${SuperAdminCss.buttonsPart}`} 
                        onClick={ (e)=>{ e.target.name='examType';e.target.value=4;reportFilterHanlder(e); } } 
                    >without time limit</div>
                    <div className={`${SuperAdminCss.buttonsPart}`} 
                        onClick={ (e)=>{ e.target.name='examType';e.target.value=3;reportFilterHanlder(e); } }
                    >with time limit</div>
                </div>
            }

            
            {/* Practice set Report Start */}
            {reportFilter.schoolCode!='' && (reportFilter.examType!='' && reportFilter.examType!='selfgenerated') && 
              <div className={`${SuperAdminCss.backSection}`}>
                {sectionsList.map((item)=>{
                  return(
                    <FormControlLabel className={`${SuperAdminCss.sectionbox} ${SuperAdminCss.sectionboxX}`} checked={reportFilter.sectionID==item.sectionID} control={<Radio />} label={item.sectionName} 
                        onClick={ 
                            (e)=>{
                                e.target.name = "sectionID"
                                e.target.value = item.sectionID
                                reportFilterHanlder(e)
                            }
                        }
                    />
                  )
                })}
              </div>
            }
            {/* Practice set report end */}


            <Row>
                  {(reportFilter.sectionID!='' && reportFilter.examType==5) && reportFilter.schoolCode!="SWASCH1"  &&
                      <Col>
                      <>
                        <select className='form-control' onChange={(e)=>{setReportDataSchoolWise(e.target.value);setAttemptedAssessmentListShow(false)}}>
                          <option hidden>Select Report</option>
                          <option value="1">Forever Report</option>
                          <option value="2">School/Institution Report</option>
                        </select>
                      </>
                      </Col>
                  }
                {reportFilter.schoolCode!='' && (reportFilter.examType!='' && reportFilter.examType!='selfgenerated') && reportFilter.sectionID=='2' && <Col>
                    {/* <label>Subject Name</label> */}
                    <select className="form-control" name='subjectID' onChange={reportFilterHanlder}> 
                        <option className="d-none"> Select Subject </option>
                            {subjectList.map((item)=>{
                            return(
                                <option value={item.subjectID}>{item.subjectName}</option>
                            )
                            })}
                    </select>
                </Col>}
                {/* {reportFilter.schoolCode!='' && reportFilter.examType=='2' && reportFilter.subjectID!='' &&
                    <Col>
                        <select className="form-control" name='chapterID' onChange={reportFilterHanlder}> 
                            <option className="d-none"> Select Chapter </option>
                                {subjectChaptersList.map((item)=>{
                                return(
                                    <option value={item.chapterID}>{item.chapterName}</option>
                                )
                                })}
                        </select>
                    </Col>
                } */}
                {reportFilter.schoolCode!='' && (reportFilter.examType>1 && reportFilter.examType<6 ) && reportFilter.sectionID!='' && 
                    <>
                        <Col>
                            {/* <label>Student Name</label> */}
                            <select className="form-control" name='studentID' onChange={reportFilterHanlder}> 
                                <option className="d-none"> Select Student </option>
                                    {studentsList.filter(item => item.userDetail != null).map((item)=>{
                                        let userDetail = item.userDetail;
                                        return(
                                            <option 
                                                value={item.loginID}
                                                key={'student'+item.loginID}
                                            >{userDetail.firstName}</option>
                                        )
                                    })}
                            </select>
                        </Col>
                        <Col>
                            <div className='btn btn-success' onClick={getStudentAssessmentReportHandler}>Go</div>
                        </Col>
                    </>
                }
            </Row>


            {/* Practice sets Reports code start */}
              {/* {state.showPracticesSets_Section &&
                <div className={`${SuperAdminCss.backSection}`}>
                    <div style={{background:state.showSection_AList && "#bdbdbd"}} className={`${SuperAdminCss.sectionbox}`} onClick={(()=>{dispatch({type: "section_a_ass_list"})})}> Section - A </div>
                    <div style={{background:state.showSection_BList && "#bdbdbd"}} className={`${SuperAdminCss.sectionbox}`} onClick={(()=>{dispatch({type: "section_b_ass_list"})})}> Section - B </div>
                </div>
              } */}

              {/* Report Section - A Start */}
              {attemptedAssessmentListShow &&
              <div className={`${SuperAdminCss.AssessmentList}`}>
              {/* <Breadcrumb>
                  <Breadcrumb.Item href="#"> PRACTICE SETS</Breadcrumb.Item>
                  <Breadcrumb.Item active>SECTION - A</Breadcrumb.Item>
              </Breadcrumb> */}

              <Table striped bordered hover responsive>
                <thead>
                  <tr className={`${SuperAdminCss.headerTr}`}>
                    <th>S No.</th>
                    <th>Assessment Name</th>
                    <th>Total Marks</th>
				            <th>Marks Obtained</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>1</td>
                    <td>Mathematics - self-1</td>
                    <td>80/100</td>
                    <td>
                      <span className={`${SuperAdminCss.Attempted}`}>
                        <AssignmentTurnedInIcon className={`${SuperAdminCss.AttemptedIcon}`}/> Attempted
                      </span>
                    </td>
                    <td>
                      <div onClick={(()=>{dispatch({type: "PsetSection_a_viewdetales"})})} className={`${SuperAdminCss.viewDetailesButton}`}>
                        <PreviewIcon style={{ color: "#999" }} /> View Detailes
                      </div>
                    </td>
                  </tr> */}
                  {attemptedAssessmentList.map((item,index)=>{
                      var assessName = item.questionBankName[0].questionTypeDesc;
                      var setName = item.setName[0]!=undefined ? item.setName[0].setDesc : "";
                      return (
                        <tr>
                            <td>{index+1}</td>
                            <td>{assessName}{setName!="" ? "/"+setName : ""}</td>
                            <td>{item.totalMarks}</td>
                            <td>{item.obtainedMarks!="" ? item.obtainedMarks : 0 }</td>
                            <td>
                                <span className={`${SuperAdminCss.Attempted}`}>
                                    <AssignmentTurnedInIcon	className={`${SuperAdminCss.AttemptedIcon}`}/>Attempted
                                </span>
                            </td>
                            <td>
                                <div onClick={ (e)=>{showAttemptedReportCard(item.assessmentID)} }	className={`${SuperAdminCss.viewDetailesButton}`}>
                                    <PreviewIcon style={{ color: "#999" }} /> View Details
                                </div>
                            </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </div>
              }

              {/* View Detailes Popup start */}
              {/* {state.PracticeSet_section_a_viewDetailes &&
              <div className={`${SuperAdminCss.viewDetailesPop}`}>
              <div className={`${SuperAdminCss.innerdivReport}`}>
                <div className={SuperAdminCss.headerTp}>
                  <Row>
                    <Col>
                      <div className={SuperAdminCss.headingCol}>
                        View Detailes
                      </div>
                    </Col>
                    <Col>
                      <CancelIcon onClick={(()=>{dispatch({type: "hideViewDe_sec_a_pop"})})} className={`${SuperAdminCss.closeViewDetailes}`}/>
                    </Col>
                  </Row>
                 
                  
                </div>

                <div className={`${SuperAdminCss.tableBackDeta}`}>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr className={`${SuperAdminCss.headerTr}`}>
                        <th>Q. No.</th>
                       
                        <th>Answer</th>
                        <th>Your Answer</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>A</td>
                        <td>A</td>
                        <td>
                          <span>
                            <CheckBoxIcon  className={`${SuperAdminCss.checkMark}`} />
                          </span>
                        </td>
                        <td>
                          <div onClick={(()=>{dispatch({type: "review_answers_sec_a"})})} className={`${SuperAdminCss.viewDetailesButton}`}>
                            <PreviewIcon style={{ color: "#999" }} /> Review Answer
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>A</td>
                        <td>C</td>
                        <td>
                          <span>
                            <DangerousIcon className={`${SuperAdminCss.wrongSign}`}/>
                          </span>
                        </td>
                        <td>
                          <div className={`${SuperAdminCss.viewDetailesButton}`}>
                            <PreviewIcon style={{ color: "#999" }} /> Review Answer
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              </div>
              } */}
              {/* View Detailes Popup end */}


              {/* View Detailes */}
                {showViewDetailespA && (
                  <div className={`${SuperAdminCss.viewDetailesPop}`}>
                    <div className={`${SuperAdminCss.innerdivReport}`}>
                        <div className={SuperAdminCss.headerTp}>
                        <div className={SuperAdminCss.headingCol}>
                            Detailed Report
                        </div>
                        <div className={SuperAdminCss.crossIcon}>
                            <CancelIcon
                            className={`${SuperAdminCss.crossiconDiv}`}
                            onClick={() => {
                                setViewDetailespA(false);
                            }}
                            />
                        </div>
                        </div>

                        <div className={`${SuperAdminCss.tableBackDeta}`}>
                        <Table striped bordered hover responsive>
                            <thead>
                            <tr className={`${SuperAdminCss.headerTr}`}>
                                <th>Q. No.</th>
                                <th>Correct Answer</th>
                                <th>Your Answer</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {reportData.map((item,index)=>{
                               let correctID = ''
                               if(item.correctAnswerID==1){
                                 correctID= '(A)';
                               }else if(item.correctAnswerID==2){
                                 correctID= '(B)';
                               }else if(item.correctAnswerID==3){
                                 correctID= '(C)';
                               }else if(item.correctAnswerID==4){
                                 correctID= '(D)';
                               }
                               let inCorrectID = ''
                               if(item.attemptAnswerID==1){
                                 inCorrectID= '(A)';
                               }else if(item.attemptAnswerID==2){
                                 inCorrectID= '(B)';
                               }else if(item.attemptAnswerID==3){
                                 inCorrectID= '(C)';
                               }else if(item.attemptAnswerID==4){
                                 inCorrectID= '(D)';
                               }
                            // let ans = reportData[0]!= undefined ? reportData[0].optionTextData[0].attemptAnswerText : "" ;
                            return (
                                <>
                                <tr>
                                    <td>{index+1}</td>
                                    <td><b dangerouslySetInnerHTML={{__html:item.optionTextData[0].attemptAnswerText!=null ? "<div style='display:flex'><div style='width:40%'>"+correctID+ "</div>"+ "<div style='width:40%'>"+ item.optionTextData[0].correctAnswerText+"</div>"+"</div>" : "Unattempted"}}></b></td>
                                    <td><b dangerouslySetInnerHTML={{__html:item.optionTextData[0].attemptAnswerText ? "<div style='display:flex'><div style='width:40%'>"+inCorrectID+"</div>"+ "<div style='width:40%'>"+item.optionTextData[0].attemptAnswerText +"</div>"+"</div>" : "Unattempted"}}></b></td>
                                    {item.isCorrect==1 ? 
                                        <td>
                                        <span>
                                        <CheckBoxIcon
                                            className={`${SuperAdminCss.checkMark}`}
                                        />
                                        </span>
                                        </td>
                                        :
                                        <td>

                                          {
                                            item.optionTextData[0].attemptAnswerText==null ?
                                            <span><b>Unattempted</b></span>
                                            :
                                            <span>
                                              <DisabledByDefaultIcon
                                              className={`${SuperAdminCss.wrongSign}`}
                                              />
                                            </span>
                                          }
                                        </td>
                                    }
                                    <td>
                                    {item.optionTextData[0].attemptAnswerText ? 
                                        <div
                                        onClick={() => {
                                          getReviewQues(item.questionID)
                                        }}
                                        className={`${SuperAdminCss.viewDetailesButton}`}
                                        >
                                        <PreviewIcon style={{ color: "#999" }} />
                                        Review Answer
                                        </div>
                                    : <div className={`${SuperAdminCss.viewDetailesButtond}`}>
                                        <PreviewIcon style={{ color: "#999" }} />
                                        Review Answer
                                    </div>
                                    }
                                    </td>
                                </tr>
                                </>
                            )
                            })}
                            </tbody>
                        </Table>
                        </div>
                    </div>
                    </div>
                )}


              {/* Review Answer popup start */}
              {/* {state.showReview_ans_sec_a &&
               <div className={`${SuperAdminCss.ReviewAnswer_section}`}>
                <div className={`${SuperAdminCss.innerdivReport}`}> 
                <Row>
                    <Col>
                      <div className={SuperAdminCss.headingCol}>
                        Review Answer
                      </div>
                    </Col>
                    <Col>
                      <CancelIcon onClick={(()=>{dispatch({type: "hideReviewAns_sec_a"})})} className={`${SuperAdminCss.closeViewDetailes}`}/>
                    </Col>
                  </Row>
                <div className={`${SuperAdminCss.rowGrid}`}>
                    <div className={`${SuperAdminCss.colFixed}`}>
                      <div className={`${SuperAdminCss.QuestionNo}`}>Question - 1 </div>
                    </div>
                  <div className={`${SuperAdminCss.questionDiv}`}>
                      <b>
                      In the following question, the Assertion and Reason have put forward. Read the statement carefully and choose the correct alternative from the following:
                      </b>
                   <div className={`${SuperAdminCss.SpaceVar}`}></div>
                    <div>Assertion: Self-pollen cannot effect fertilisation in self-incompatible pistil.</div>
                        <div>Reason : Self-incompatibility prevent the germination of plllen grains of retard growth of pollen tube.</div>
                        <div className={`${SuperAdminCss.SpaceVar}`}></div>
                        <div className={`${SuperAdminCss.SpaceVar}`}></div>
                     <Row>
                          <Col className='col-md-6 col-sm-12 col-12'>
                                <label>
                                  <div className={`${SuperAdminCss.optionsRow}`}>
                                    <div className={`${SuperAdminCss.divOne}`}>
                                      <input type="radio" name="options" /> A.
                                    </div>
                                    <div>
                                    Both the Asseration and the Reason are correct and the Reason is the correct explanation of the Asseration
                                    </div>
                                  </div> 
                                </label>
                          </Col>
                          <Col className='col-md-6 col-sm-12 col-12'>
                              <label>
                                <div className={`${SuperAdminCss.optionsRow}`}>
                                  <div className={`${SuperAdminCss.divOne}`}>
                                    <input type="radio" name="options" /> B.
                                  </div>
                                  <div>
                                  The Assertion and the Reason are correct but the Reason is not the correct explanation the Assertion
                                  </div>
                                </div> 
                              </label>
                          </Col>
                          <Col className='col-md-6 col-sm-12 col-12'>
                                <label>
                                  <div className={`${SuperAdminCss.optionsRow}`}>
                                    <div className={`${SuperAdminCss.divOne}`}>
                                      <input type="radio" name="options" /> C.
                                    </div>
                                    <div>
                                    Assertion is true but the Reason is false
                                    </div>
                                  </div> 
                                </label>
                          </Col> 
                          <Col className='col-md-6 col-sm-12 col-12'>
                                <label>
                                  <div className={`${SuperAdminCss.optionsRow}`}>
                                    <div className={`${SuperAdminCss.divOne}`}>
                                      <input type="radio" name="options" /> D.
                                    </div>
                                    <div>
                                    The statement of the Assertion is false but the Reason is true.
                                    </div>
                                  </div> 
                                </label>
                          </Col>
                    </Row>
                </div>
                </div>
              <hr/>
                <div className={`${SuperAdminCss.explanationBox}`}>
                              <div className={`${SuperAdminCss.headingExpl}`}>Explanation</div>
                              <p className={`${SuperAdminCss.expContent}`}>Lorem Ipsum is simply dummied text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                </div>
              </div>
              </div>
              } */}


          {showReviewAnswer && (
            <div className={`${SuperAdminCss.reviewAnswerBk}`}>
              <div  className={`${SuperAdminCss.innerdivReport}`}>
                <div className={SuperAdminCss.headerTp}>
                  <div className={SuperAdminCss.headingCol}>
                    Review Answer/Explanation<br/>
                    Ques ID: {questionID}
                  </div>
                  <div className={SuperAdminCss.crossIcon}>
                    <CancelIcon
                      className={`${SuperAdminCss.crossiconDiv}`}
                      onClick={() => {
                        setReviewAnswer(false);
                      }}
                    />
                  </div>
                </div>

                {/* Display Answer and expl */}
                <div style={{minHeight: 'auto'}} className={`${SuperAdminCss.rowGrid}`}>
              <div className={`${SuperAdminCss.colFixed}`}>
                <div className={`${SuperAdminCss.QuestionNo}`}>Question: </div>
              </div>
               <div className={`${SuperAdminCss.questionDiv}`}>
               <b dangerouslySetInnerHTML={{__html:selectedQuestion[0].questionText}}></b>
                <div className={`${SuperAdminCss.SpaceVar}`}></div>
                {/* <div>Assertion: Self-pollen cannot effect fertilisation in self-incompatible pistil.</div>
                <div>Reason : Self-incompatibility prevent the germination of plllen grains of retard growth of pollen tube.</div> */}
                <div className={`${SuperAdminCss.SpaceVar}`}></div>
                <div className={`${SuperAdminCss.SpaceVar}`}></div>
                <Row>
                  <Col className='col-md-6 col-sm-12 col-12'>
                      
                        <label>
                          <div className={`${SuperAdminCss.optionsRow}`}>
                            <div className={`${SuperAdminCss.divOne}`}>
                              <input checked={selectedQuestion[0].optionID1==selectedQuestion[0].correctAnswerIDs  ? true : false} type="radio" name="options" /> A.
                            </div>
                            <div>
                              <b dangerouslySetInnerHTML={{__html:selectedQuestion[0].optionText1}}></b>
                            </div>

                           </div> 
                        </label>
                                    
                  </Col>
                  <Col className='col-md-6 col-sm-12 col-12'>
                      
                      <label>
                        <div className={`${SuperAdminCss.optionsRow}`}>
                          <div className={`${SuperAdminCss.divOne}`}>
                            <input checked={selectedQuestion[0].optionID2==selectedQuestion[0].correctAnswerIDs  ? true : false} type="radio" name="options" /> B.
                          </div>
                          <div>
                          <b dangerouslySetInnerHTML={{__html:selectedQuestion[0].optionText2}}></b>
                          </div>

                         </div> 
                      </label>
                                  
                </Col>
                <Col className='col-md-6 col-sm-12 col-12'>
                      
                      <label>
                        <div className={`${SuperAdminCss.optionsRow}`}>
                          <div className={`${SuperAdminCss.divOne}`}>
                            <input checked={selectedQuestion[0].optionID3==selectedQuestion[0].correctAnswerIDs  ? true : false} type="radio" name="options" /> C.
                          </div>
                          <div>
                          <b dangerouslySetInnerHTML={{__html:selectedQuestion[0].optionText3}}></b>
                          </div>

                         </div> 
                      </label>
                                  
                </Col> 
                <Col className='col-md-6 col-sm-12 col-12'>
                      
                      <label>
                        <div className={`${SuperAdminCss.optionsRow}`}>
                          <div className={`${SuperAdminCss.divOne}`}>
                            <input checked={selectedQuestion[0].optionID4==selectedQuestion[0].correctAnswerIDs  ? true : false} type="radio" name="options" /> D.
                          </div>
                          <div>
                          <b dangerouslySetInnerHTML={{__html:selectedQuestion[0].optionText4}}></b>
                          </div>

                         </div> 
                      </label>
                                  
                </Col>
                </Row>
                </div>
                </div>
                <hr/>
                
                {selectedQuestion[0].questionExplanation!="" ?
                  <div className={`${SuperAdminCss.explanationBox}`}>
                    <div className={`${SuperAdminCss.headingExpl}`}>Explanation</div>
                    <p className={`${SuperAdminCss.expContent}`}> <b dangerouslySetInnerHTML={{__html:selectedQuestion[0].questionExplanation}}></b></p>
                  </div>
                  :
                  ""
                }
            
                {/* Display Answer and expl */}

                
              </div>
            </div>
          )}
              {/* Review Answer popup start */}



              {/* Report Section - A Start */}

            {/* Report Section - B Start */}
            {state.showSection_BList &&
              <div className={`${SuperAdminCss.AssessmentList}`}>
              <Breadcrumb>
                  <Breadcrumb.Item href="#"> PRACTICE SETS</Breadcrumb.Item>
                  <Breadcrumb.Item active>SECTION - B</Breadcrumb.Item>
              </Breadcrumb>

              <Table striped bordered hover responsive>
                <thead>
                  <tr className={`${SuperAdminCss.headerTr}`}>
                    <th>S No.</th>
                    <th>Assessment Name</th>
                    <th>Marks</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Mathematics - self-1</td>
                    <td>80/100</td>
                    <td>
                      <span className={`${SuperAdminCss.Attempted}`}>
                        <AssignmentTurnedInIcon className={`${SuperAdminCss.AttemptedIcon}`}/> Attempted
                      </span>
                    </td>
                    <td>
                      <div className={`${SuperAdminCss.viewDetailesButton}`}>
                        <PreviewIcon style={{ color: "#999" }} /> View Detailes
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
              }
              {/* Report Section - B Start */}


            {/* Practice sets Reports code end */}

          </Container>                       
        </div>
              {showLoader &&
              <Loader/>
              }
              {showErrPopup.status && 
                <div className={`${SuperAdminCss.popupBack}`}>
                  <div className={`${SuperAdminCss.errBox}`}>
                    <CloseIcon className={`${SuperAdminCss.crossPopup}`} onClick={()=>{setErrPopup(false)}}/>
                    <Alert severity="error">
                      {showErrPopup.msg}
                    </Alert>
                  </div>       
                </div>
              }
      </div>
      {/* Right Area */}
    </div>
  );
}

export default SuperAdminReport;

import React, { useState, useContext, useEffect } from "react";
import Header from '../homePage/Header';
import Footer from '../homePage/Footer';
import AOS from "aos";
import "aos/dist/aos.css";
import { Button, Col, Container, Row } from "react-bootstrap";

import contactleft from '../../assets/contactLeft.png';
import contactright from '../../assets/contactRight.png';
import contactani from '../../assets/contactAni.png';
import imgLoader from '../../assets/loader.gif';

import layout from "../layout/innerPage.css";
import innerResp from "../layout/innerResp.css";

//function Contactus() {  
const Contactus = () =>{

  const [formData, setFormData] = useState({
    firstName:"",
    lastName:"",
    email:"",
    mobile:"",
    msg:""
  });
  const [error, setError] = useState({status:false, msg:""});
  
  const [isLoader, setIsLoader] = useState(false);
  
  useEffect( () =>{
      AOS.init({
        offset:100,
        duration: 2000,
      });
  },[] );

  function sendData(event){

    event.preventDefault();
    event.stopPropagation();
  
    if(formData.firstName==""){
      setError({status:true, msg:"please fill the first name"})
      return;
    } else if(formData.lastName==""){
      setError({status:true, msg:"please fill the last name"})
      return;
    } else if(formData.email==""){
      setError({status:true, msg:"please fill the email"})
      return;
    }  else if(formData.mobile==""){
      setError({status:true, msg:"please fill the mobile number"})
      return;
    } else if(formData.msg==""){
      setError({status:true, msg:"please fill the message"})
      return;
    }     
  
    setError({status:false, msg:""})
    setIsLoader(true);
    const data = {
      "firstName":formData.firstName,
      "lastName":formData.lastName,
      "email":formData.email,
      "mobile":formData.mobile,
      "message":formData.msg,
      "action": "contactus"
    } 

    

    //fetch(apiBaseURL+apiURL.contactUs,{
    fetch('https://cuet.rachnasagar.in/cuetnta/api/contactus',{
      method:'POST',
      headers:{
        'key' : 'cuetnta',
      },
      body:JSON.stringify(data)
    } )
      .then( response => response.json() )
      .then( (result) => {
        console.log(result)
        setIsLoader(false)
        if(result.status=="1"){
          
          setFormData({
            firstName:"",
            lastName:"",
            email:"",
            mobile:"",
            msg:""
          })
          setError({status:true, msg:"Your request has been successfully submitted. We'll reach you shortly."})
        } else {
          // show error
          
          setError({status:true, msg:result.message})
        }
      })
      .catch( (error)=>{
         console.log(error)
        //  show error
        setError({status:true, msg:"network error try again!"})
        setIsLoader(false)
      } );
  
   }
  
   function setData(val, type){  
    setFormData((prev)=>{  
      return {...prev, [type]:val}
    })
   }

 return (   
    <>
    <Header/>
    <div className="contactSection">
      <Container>
        <Row>
          <Col md={2}> <img className="mw-100" src={contactleft}/>   </Col>
          <Col md={8}>
            <h1> Contact </h1>
            <p data-aos="zoom-in"> If you have any questions about our products or services, please call one of our numbers or send us an email. We welcome your 
              suggestions to and feedback. </p>
          </Col>
          <Col md={2}> <img className="mw-100" src={contactright}/>   </Col>
        </Row>
      </Container>
    </div>
    <Container>
      <form onSubmit={sendData}  className="contact-from" data-aos="fade-up"> 
              
        <Row>
          <Col md={6}>
            <div className="form-group">
              <label>First Name</label>
              <input type="name" className="form-control form-contact"  value={formData.firstName}  onChange={(e)=>{setData(e.target.value, "firstName")}} />        
            </div>             
          </Col>
          <Col md={6}>
            <div className="form-group">
              <label>Last Name</label>
              <input type="name" className="form-control form-contact"  value={formData.lastName}  onChange={(e)=>{setData(e.target.value, "lastName")}} /> 
            </div>        
          </Col>
          <Col md={6}>
            <div className="form-group">
              <label>Email *</label>
              <input type="email" className="form-control form-contact"  value={formData.email}  onChange={(e)=>{setData(e.target.value, "email")}} />
            </div>
          </Col>
          <Col md={6}>
            <div className="form-group">
              <label>Mobile *</label>
              <input type="text" className="form-control form-contact"  value={formData.mobile}  onChange={(e)=>{setData(e.target.value, "mobile")}} />
            </div>
          </Col>
          <Col md={12}>
            <div className="form-group">
              <label>Message</label>
              <textarea className="form-control form-contact" rows="3"  value={formData.msg} onChange={(e)=>{setData(e.target.value, "msg")}}></textarea> 
            </div>

          </Col>
        </Row> 

        <Row>
        <Col md={12} xs={12}>
              { error.status &&               
                <div className="alert alert-success" style = {{padding:'7px 3px', textAlign:'center', margin:'10px 4px 8px 4px'}} role="alert">{error.msg}</div> 
              }

              {isLoader && 
                      <div className="alert alert-danger" style = {{padding:'7px 3px', textAlign:'center', margin:'10px 4px 8px 4px'}} role="alert">
                        <img src={imgLoader}/> Processing ...
                      </div>
              }
          </Col>
          <Col md={2} >
          <button type="submit" className="contact-btn">Send</button>
          </Col>
         
      </Row>           
      </form>
     </Container>  
     <div className="contactSection">
      <Container>
        <Row className="d-flex justify-content-center align-items-center">
          <Col className="mt-2" md={7}>
            <address data-aos="zoom-in-right">
              <h5>Head Office</h5>
              <p> Rachna Sagar Pvt. Ltd.<br/> 4583/15, Daryaganj, New Delhi,110002(INDIA)<br/> +91-11-43585858, +91 9717998871 </p>
              <h5>Customer Queries :</h5>
              <p>ecommerce@rachnasagar.in</p>
            </address>      
          </Col>
          {/* <Col md={{ span: 4, offset: 1 }}> */}
          <Col className="mt-2 rightImg" md={5}>
            <img className="mw-100 animation" src={contactani}/>
          </Col>
        </Row>
      </Container>
    </div>
    <Footer/>
    </>
  );
}
export default Contactus;

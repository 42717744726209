import React, { useState, useContext, useEffect } from "react";

import navBarCss from '../homePage/navBarCss.module.css';
import Logocuet from "../../assets/cuetlogo.png";
import Togetherlogo from "../../assets/togetherLogo.png";
import { Link, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

//function Header() { 
const Header = () =>{ 
  const pathName = useLocation().pathname;
 return (   
    <>
    <div className="topsection"> </div>
    <div className="shadowB">
      <div className="width-section">
          <Row>
              {/* <Col md="3" col="12"> <img className="cuetlogo" src={Logocuet} /> </Col> */}
              <Col md="11" xs="12" className="navigation">
                <Navbar expand="lg">
                 <Navbar.Brand><Link to={'/'}><img className="cuetlogo" src={Logocuet} /></Link></Navbar.Brand> 
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav"> 
                    <ul className="navbar-nav">                  
                      <li> <Link className={pathName=='/'?navBarCss.ActiveButton:null} to="/">HOME</Link></li>
                      <li> <Link className={pathName=='/aboutus'?navBarCss.ActiveButton:null} to="/aboutus">ABOUT US</Link></li>
                      {/* <li> <Link className={pathName=='/bloag'?navBarCss.ActiveButton:null} to="/bloag">BLOG</Link></li> */}
                     {/* <li> <a target="_blank" href="https://blog.rachnasagar.in">BLOG  </a></li> */}
                     <li> <Link className={pathName=='/products'?navBarCss.ActiveButton:null} to="/products">PRODUCTS</Link></li>
                      <li> <Link className={pathName=='/contactus'?navBarCss.ActiveButton:null} to="/contactus">CONTACT US</Link></li>
                    </ul>
                  </Navbar.Collapse>
              </Navbar>
              </Col>
              <Col md="1"> 
                <div className="togetherLogo">
                   <img src={Togetherlogo} /> 
                </div>
               </Col>
          </Row>
      </div>
    </div>
    </>
  );
}
export default Header;

import React, { useState, useContext, useEffect } from "react";
import Header from '../homePage/Header';
import Footer from '../homePage/Footer';

import {Container, Col, Row, Button } from "react-bootstrap";
import layout from "../layout/innerPage.css";
import innerResp from "../layout/innerResp.css";

import Accordion from 'react-bootstrap/Accordion';

import blogBanner from '../../assets/blog-banner.jpg';
import blogBanner1 from '../../assets/bannerBlog1.jpg';
import blogBanner2 from '../../assets/bannerBlog2.jpg';
import blogBanner3 from '../../assets/bannerBlog3.jpg';

function Blog() {  
 return (   
    <>
    <Header/>
      <div className="width-section p-3">
        <Row>
          <Col md={9}>
            <Row>
              <Col md={6}> <img className="mw-100 pb-3" src={blogBanner} /> </Col>
              <Col md={6}> 
                <h1 className="faqhedi"> Top Answer Constructing Tips <br/> for Class 12 Board Exams to Score Good </h1>
                <div className="postedOn"> 
                  <h6 > POSTED ON JUNE 7, 2023 </h6>
                  <p> Students can develop an all-rounded approach toward learning once they establish familiarity with the exam… Read More </p> 
                </div>             
              </Col>
              <Col md={4}> 
                <div className="post-thumb">
                  <img className="mw-100" src={blogBanner1} />
                  <h5> ‘Together with’ Phygital Editions for Holistic Learning via Physical Textbooks and Digital Resources </h5>
                  <h6> POSTED ON DECEMBER 2, 2023 </h6>
                  <p> ‘Together with’ Phygital Editions as per NEP 2020 & NCF 2023 has been released by…Read More  </p>
                </div>             
               </Col>
              <Col md={4}> 
                <div className="post-thumb">
                  <img className="mw-100" src={blogBanner2} />
                  <h5>Latest Update: CBSE has released Set 2 of Additional Practice Questions for Grades 10 & 12</h5>
                  <h6>POSTED ON NOVEMBER 18, 2023</h6>
                  <p>‘Central Board of Secondary Education (CBSE) has recently unveiled Set 2 of Additional Practice Questions…Read More </p>
                </div>
               </Col>
              <Col md={4}>
                <div className="post-thumb">
                  <img className="mw-100" src={blogBanner3} />
                  <h5>What are the common mistakes committed by students while preparing for Board Exams?</h5>
                  <h6>POSTED ON OCTOBER 17, 2023</h6>
                  <p>As a student you must have encountered the situations when you put in a lot…Read More </p>
                </div>
              </Col>
              <Col md={4}> 
                <div className="post-thumb">
                  <img className="mw-100" src={blogBanner1} />
                  <h5> ‘Together with’ Phygital Editions for Holistic Learning via Physical Textbooks and Digital Resources </h5>
                  <h6> POSTED ON DECEMBER 2, 2023 </h6>
                  <p> ‘Together with’ Phygital Editions as per NEP 2020 & NCF 2023 has been released by…Read More  </p>
                </div>             
               </Col>
              <Col md={4}> 
                <div className="post-thumb">
                  <img className="mw-100" src={blogBanner2} />
                  <h5>Latest Update: CBSE has released Set 2 of Additional Practice Questions for Grades 10 & 12</h5>
                  <h6>POSTED ON NOVEMBER 18, 2023</h6>
                  <p>‘Central Board of Secondary Education (CBSE) has recently unveiled Set 2 of Additional Practice Questions…Read More </p>
                </div>
               </Col>
              <Col md={4}>
                <div className="post-thumb">
                  <img className="mw-100" src={blogBanner3} />
                  <h5>What are the common mistakes committed by students while preparing for Board Exams?</h5>
                  <h6>POSTED ON OCTOBER 17, 2023</h6>
                  <p>As a student you must have encountered the situations when you put in a lot…Read More </p>
                </div>
              </Col>
            </Row>          
          </Col>
          <Col md={3}>
            <div className="search-box">
              <input type="text" id="search" placeholder="Search..."/>
              <button id="search-button"><i className="fa fa-search"></i></button>
            </div>    
            <div className="recentPost">
              <h2>Recent Posts</h2>
              <div className="recentpara">
                <ul>
                  <li>Top Answer Constructing Tips for Class 12 Board Exams to Score Good</li>
                  <li>‘Together with’ Phygital Editions for Holistic Learning via Physical Textbooks and Digital Resources</li>
                  <li>Latest Update: CBSE has released Set 2 of Additional Practice Questions for Grades 10 & 12</li>
                  <li>What are the common mistakes committed by students while preparing for Board Exams?</li>
                  <li>Rachna Sagar Unveils CBSE Sample Papers EAD for Class 10: A Comprehensive resource for 2024 Board Exam Preparation</li>
                </ul>
              </div>
              <h2>Archives</h2>
              <div className="recentpara">                
                <select>
                  <option value="someOption">2024 January</option>
                  <option value="otherOption">2023 February</option>
                  <option value="otherOption">2022 March</option>
                  <option value="otherOption">2021 April</option>
                </select>
                <ul>
                  <li>2024 January</li>
                  <li>2020 February</li>
                  <li>2018 March</li>
                  <li>2023 April</li>
                  <li>2019 May</li>
                  <li>2022 June</li>
                  <li>2009 July</li>
                  <li>2007 August</li>
                  <li>2005 September</li>
                  <li>2011 October</li>
                  <li>2014 November</li>
                  <li>2016 December</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Col md={12}>
            <ul id="border-pagination">
              <li><a class="" href="#">«</a></li>
              <li><a href="#" className="active">1</a></li>
              <li><a href="#">2</a></li>
              <li><a href="#">3</a></li>
              <li><a href="#">4</a></li>
              <li><a href="#">5</a></li>
              <li><a href="#">6</a></li>
              <li><a href="#">»</a></li>
            </ul> 

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header> Item #1</Accordion.Header>
                <Accordion.Body> 1111111 </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>  Item #2</Accordion.Header>
                <Accordion.Body> 2222222 </Accordion.Body>
              </Accordion.Item>
            </Accordion>
      </Col>
      <Footer/>
    </>
  );
}
export default Blog;

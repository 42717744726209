import React, { useEffect } from "react";
import Header from '../homePage/Header';
import Footer from '../homePage/Footer';

import AOS from "aos";
import "aos/dist/aos.css";

import {Container, Row, Col } from "react-bootstrap";

import layout from "../layout/innerPage.css";
import innerResp from "../layout/innerResp.css";

const TermCondition = () => { 

  useEffect( () =>{
    AOS.init({
      offset:100,
      duration:3000,
    });
  },[] );

 return (   
    <>
    <Header/>
    <div className="contactSection" data-aos="zoom-in">
        <Container>
          <Row>
            <Col md={12}>
              <h1 style={{margin: "4px 0"}}> Terms and Conditions </h1>                     
            </Col>            
          </Row>         
        </Container>
      </div> 
      <Container>
        <Row>
          <Col md={12}>
            <div className="product-service" data-aos="fade-up">
              <h3> The following terms and conditions will be deemed to have been accepted by the User on usage of the website 
               &nbsp; <a target="_blank" href="https://cuet.rachnasagar.in/"><b style={{color:"#fff"}}>https://cuet.rachnasagar.in/.</b></a> You
               are requested to read them carefully before you use the services of this site. </h3>   
              <ul>
                <li className="termli">The term ‘User’ shall refer to the user who is browsing the Website. The term ‘CUET’ shall refer to CUET 
                and/or its affiliates/subsidiary companies. The term ‘Site’ refers to<a target="_blank" href="https://cuet.rachnasagar.in/"> 
                <b> https://cuet.rachnasagar.in/.</b> </a></li>

                <li className="termli">By using the Site, you agree to follow and be bound by the following terms and conditions concerning your
                 use of the Site. CUET may revise the Terms of Use at any time without any notice.</li>

                <li className="termli"> All the content present on this site is the exclusive property of CUET. The software, text, images,
                 graphics, video and audio used on this site solely belong to CUET. No material from this site may be copied, modified, 
                 reproduced, republished, uploaded, transmitted, posted or distributed in any form, whatsoever without prior written permission 
                 from CUET. All rights not expressly granted herein are reserved. Unauthorized use of the materials appearing on this site may 
                 violate copyright, trademark and other applicable laws, and could result in criminal and/or civil penalties. </li>

                <li className="termli"> CUET does not make any warranties, express or implied, including without limitation, those of 
                merchantability and fitness for a particular purpose, with respect to any information, data, statements or products made 
                available on the Site. </li>

                <li className="termli"> CUET does not accept any responsibility towards the contents and/or information practices of third party
                 Sites, which have links through CUET Site. The said links to internal or external website locations are only for the purpose of
                 facilitating your visit or clarifying your query. </li>

                <li className="termli"> The Site, and all content, materials, information, software, products and services provided on the Site,
                 are provided on an ‘as is’ and ‘as available’ basis. CUET expressly disclaims all warranties of any kind, whether express or 
                 implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and 
                 non-infringement.<br/> CUET shall have no responsibility for any damage to a User's computer system or loss of data that may 
                result from the download of any content, materials and information from the Site. </li>

                <li className="termli"> The User agrees to indemnify, defend and hold CUET harmless from and against all losses, expenses, 
                damages and costs, including reasonable attorneys' fees, arising out of or relating to any misuse by the User of the content
                 and services provided on the Site.<br/> The information contained in the Site has been obtained from sources believed to be 
                 reliable. CUET disclaims all warranties as to the accuracy, completeness or adequacy of such information. </li>

                 <li className="termli"> The User's right to privacy is of paramount importance to CUET. Any information provided by the User 
                 will not be shared with any third party. CUET reserves the right to use the information to provide the User a personalized 
                 online experience. </li>

                 <li className="termli"> The Site provides links to Websites and access to content, products and services from third parties, 
                 including users, advertisers, affiliates and sponsors of the Site. You agree that CUET is not responsible for the availability 
                 of, and content provided on, third party Websites.  </li>

                 <li className="termli">The User is requested to peruse the policies posted by other Websites regarding privacy and other topics
                  before use. </li>
                 
                 <li className="termli"> CUET reserves the right in its sole discretion to review, improve, modify or discontinue, temporarily 
                 or permanently, the Site or any content or information on the Site with or without notice to User. </li> 

                <li className="termli"> User agrees that CUET shall not be liable to User or any third party for any modification or 
                discontinuance of the Site.</li>                         
              </ul>        
            </div>           
          </Col>
        </Row>
      </Container>    
    <Footer/>
    </>
  );
}
export default TermCondition;

import React, { useState, useEffect, useContext } from 'react';
import managQueCss from './ManageQue.module.css';
import GeneratePracticeSetCss from './GeneratePracticeSet.module.css';
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { apiBaseURL, apiURL, apiKey, RouteURL, ckFinderURL } from '../../constant';
import Loader from '../../Loader';

function Exam_Setting({sectionsList,userData,quesProcessingData,questionsList,editQuesSegregationButtonHandler,editQuesButtonHandler,deleteQuesButtonHandler,quesProcessingDataHandler,getSubjectChapters,getSubTopicList,searchQuestions,setCurrentPage,currentPage,isLoaderActive,topicList,subTopicsList,subjectList,subjectChaptersList}) {
    const [show, setShow] = useState(false);
    const[showTopicList, setTopicList]=useState([]);
    const[showQuestionBox, setQuestionBox]=useState(false);
    const[showSubtopicList, setSubtopicList]=useState([]);
    const[showLoader, setLoader]=useState(false)
    const [showSubjectData, setSubjectData]=useState([]);
    const [showChapterList, setChapterList]=useState([]);

 useEffect(()=>{
    getTopic(); 
    getSubjects()
 },[])

// section set function start

// section set function end
const[showSection_a, setSection_a]=useState(false);
const[showSection_a_sets, setSection__sets]=useState(false);

const[showSection_b, setSection_b]=useState(false);
const[showSection_b_sets, setSection__sets_b]=useState(false);

const[showSaveBtn, setSaveBtn]=useState(false)
const[showSaveBtn2, setSaveBtn2]=useState(false)





// get Topics functions start 

function getTopic(){
    fetch(apiBaseURL+apiURL.getTopics,{
         method: 'GET',
         headers: {'key': apiKey}
    })
    .then(response => response.json())
    .then((topicData)=>{
      if(topicData.status=="success"){
           setTopicList(topicData.data)
      }else{
           setTopicList([]);
           alert(topicData.message);
      }
    })
    .catch((err)=>{
           setTopicList([]);
           alert(err)
    })
}
// get Topics functions end 

// get subTopic function start
function getsubTopic(questionCatID){
    setLoader(true)
    const subPostData = {
        "topicID": questionCatID
    }
    fetch(apiBaseURL+apiURL.getSubTopics,{
        method: "POST",
        headers: {'Key' : apiKey},
        body:JSON.stringify(subPostData)
    })
    .then(response => response.json())
    .then((subTopicData)=>{
        if(subTopicData.status=="success"){
            setSubtopicList(subTopicData.data.subTopics)
        }else{
            setSubtopicList([])
            alert(subTopicData.message)
        }
    })
    .catch((err)=>{
        setSubtopicList([])
        alert(err);
    })
    .finally(()=>{
         setLoader(false)
    })
}
// get subTopic function end

// get Subject function start
function getSubjects(){
    fetch(apiBaseURL+apiURL.getSubjectList,{
        method : 'GET',
        headers: {Key : apiKey}
    })
    .then(response => response.json())
    .then((subjectData)=>{
        if(subjectData.status == "success"){
            setSubjectData(subjectData.data.subjects)
        }else{
            setSubjectData([]);
            alert(subjectData.message)
        }
    })
    .catch((err)=>{
        setSubjectData([]);
        alert(err);
    })
}
// get Subject function end

// get Chapter function start
function getChapter(subjectId){
    setLoader(true)
    const xPostData ={
        "classID" : "12",
        "subjectID":subjectId
    }
    fetch(apiBaseURL+apiURL.getChaptersList,{
        method: 'POST',
        headers: {Key : apiKey},
        body:JSON.stringify(xPostData)
    })
    .then(response => response.json())
    .then((chapterData)=>{
        if(chapterData.status == "success"){
            setChapterList(chapterData.data.chapters)
        }else{
            setChapterList([]);
            alert(chapterData.message)
        }
    })
    .catch((err)=>{
        setChapterList([]);
        alert(err);
    })
    .finally(()=>{
        setLoader(false);
    })
}
// get Chapter function end



    return (
        <div>
            
            <div className={managQueCss.maincontainer}>
                          <div className={`${managQueCss.sectionDiv}`}>
                            <Row>
                                <Col className='col-md-4 col-sm-4 col-12'>
                                        <label onClick={()=>{setSection_a(true); setSection_b(false);  setSection__sets_b(false)}} className={`${managQueCss.RowLabel}`} >
                                            <input name='section' type="radio"/> Section - A
                                        </label>
                                        <label onClick={()=>{setSection_b(true); setSection_a(false); setSection__sets(false);}}  className={`${managQueCss.RowLabel}`}>
                                            <input name='section' type="radio"/> Section - B
                                        </label>
                                </Col>
                                {showSection_a && 
                                <>
                                
                                <Col className='col-md-4 col-sm-4 col-12'>
                                    <select className='form-control' onChange={(event)=>getsubTopic(event.target.value)}>
                                        <option className='d-none'>Select Topic</option>
                                        {showTopicList.map((item)=>{
                                            return(
                                                <option  value={item.questionCatID}>{item.questionCatDesc}</option>
                                            )
                                        })}
                                    </select>
                                </Col>
                                <Col className='col-md-4 col-sm-4 col-12'>
                                    <select className='form-control' onChange={()=>{setSection__sets(true); setSection__sets_b(false) }}>
                                        <option className='d-none'>Select</option>
                                        <option value="set"> Section Wise With Out Time Limit </option>
                                        <option value="set"> Section Wise With Time Limit </option>
                                    </select>
                                </Col>
                                </>
                                }
                                {showSection_b && 
                                <>
                               
                                 <Col className='col-md-4 col-sm-4 col-12'>
                                
                                 <select className='form-control' onChange={(event)=>getChapter(event.target.value)}>
                                     <option className='d-none'>Select Subject</option>
                                     {showSubjectData.map((item)=>{
                                         return(
                                             <option value={item.subjectID}>{item.subjectName}</option>
                                         )
                                     })}
                                 </select>
                                </Col>
                                <Col className='col-md-4 col-sm-4 col-12'>
                                    <select className='form-control' onChange={()=>{setSection__sets_b(true); setSection__sets(false); }}>
                                        <option className='d-none'>Select</option>
                                        <option value="set"> Section Wise With Out Time Limit </option>
                                        <option value="set"> Section Wise With Time Limit </option>
                                    </select>
                                </Col>
                                </>
                                }
                            </Row>
                            </div>

                            {/* section - A show questions start  */}
                            {showSection_a_sets &&
                            <div className={`${managQueCss.colbgs}`}>
                                      <div className={`${managQueCss.boxes}`}>
                                        <div className={`${managQueCss.showVal}`}>
                                                     {showSubtopicList.map((item)=>{
                                                        return(
                                                            <div className={`${managQueCss.labelRow}`}>
                                                            <label className={`${managQueCss.labelWithInput}`} onClick={()=>{setSaveBtn(true)}}>
                                                                <input type="checkbox"/>{item.subTopicDesc}
                                                            </label>
                                                                <input className={`${managQueCss.noOfQueInput}`} type="number" placeholder="No of questions"/>
                                                            </div>
                                                        )
                                                        
                                                    })}
                                                {showSaveBtn &&
                                                    <button className='btn btn-lg btn-success'>SAVE</button>
                                                }
                                        </div>
                                    </div>
                            </div>
                            }
                            {/* section - A show questions end  */}

                            {/* section - B show questions start  */}
                            {showSection_b_sets &&
                            <div className={`${managQueCss.colbgs}`}>
                                  <Row>
                                      <Col>
                                      <div className={`${managQueCss.boxes}`}>
                                      <Row>
                                     
                                        
                                            
                                        </Row>
                                        <div className={`${managQueCss.showVal}`}>
                                                {showChapterList.map((item)=>{
                                                    return(
                                                        <div className={`${managQueCss.labelRow}`}>
                                                            <label className={`${managQueCss.labelWithInput}`} onClick={()=>{setSaveBtn2(true)}}>
                                                            <input type="checkbox"/>{item.chapterName}
                                                            </label>
                                                             <input className={`${managQueCss.noOfQueInput}`} type="number" placeholder="No of questions"/>
                                                        </div>
                                                    )
                                                })}
                                                 {showSaveBtn2 &&
                                                    <button className='btn btn-lg btn-success'>SAVE</button>
                                                }
                                        </div>
                                    </div>
                                      </Col>
                                  </Row>
                            </div>
                            }
                            {/* section - B show questions end  */}
            </div>


           {/* Loader */}
                {showLoader &&
                    <Loader/>
                }
           {/* Loader */}
            
        </div>
       
    )
}

export default Exam_Setting;
